import { ReactElement } from "react";
import { Startup } from "../../../../../../Types/Startup";
import {
  StyledTable,
  StyledTableContainer,
} from "../../../../SharedComponents/SolutionFitMatrixComponents/SolutionFitMatrixTable/SolutionFitMatrixTable";
import { styled, TableBody } from "@mui/material";

import theme from "../../../../../../theme";
import SuggestedStartupsItem from "./SuggestedStartupsItem";

const CustomStyledTable = styled(StyledTable)(() => ({
  "& tr:first-of-type": {
    boxShadow: "none",
  },
  "& td.startup-name, & td:first-of-type": {
    backgroundColor: theme.palette.surface.medium,
    "& a": {
      color: theme.palette.text.mediumEmphasis,
    },
  },
}));

interface SuggestedStartupsProps {
  startups: Startup[];
  detailsView: boolean;
  handleCreateOpportunity: (startup: Startup) => void;
}

export default function SuggestedStartups({
  startups,
  detailsView,
  handleCreateOpportunity,
}: SuggestedStartupsProps): ReactElement {
  return (
    <StyledTableContainer>
      <CustomStyledTable>
        <TableBody sx={{ display: "flex" }}>
          {startups.length &&
            startups.map((startup) => {
              return (
                <SuggestedStartupsItem
                  data-testid="suggested-startup-item"
                  key={startup.id}
                  startup={startup}
                  detailsView={detailsView}
                  createOpportunity={handleCreateOpportunity}
                />
              );
            })}
        </TableBody>
      </CustomStyledTable>
    </StyledTableContainer>
  );
}
