import { useContext } from "react";
import { UserContext } from "../Context/UserContext";
import { VentureClient } from "../Types/VentureClient";
import { Project } from "../Types/Project";
import { LeadProject } from "../Types/LeadProject";

export interface useRolesReturns {
  isExternalUser: boolean;
  isAdmin: boolean;
  ventureClientId?: number;
  canEdit: boolean;
  canManage: boolean;
  isViewer: boolean;
}

function useRoles(
  project?: Project,
  leadProject?: LeadProject,
  ventureClient?: VentureClient
): useRolesReturns {
  const user = useContext(UserContext);

  const businessUnitId = project?.businessUnit.id;
  const ventureClientId =
    leadProject?.ventureClient.id ||
    project?.businessUnit.ventureClientId ||
    ventureClient?.id;

  const vcRight = user?.rights.find(
    (right) =>
      right.scopeType === "Venture Client" && right.scopeId === ventureClientId
  );

  const buRight = user?.rights.find(
    (right) =>
      right.scopeType === "Business Unit" && right.scopeId === businessUnitId
  );

  const isOwnProject =
    leadProject?.projectOwnerId === user?.id ||
    leadProject?.leaders?.some(
      (leader) => leader.projectLeader?.userId === user?.id
    ) ||
    project?.projectOwnerId === user?.id ||
    project?.adoptionOwner?.userId === user?.id ||
    project?.projectLeader?.userId === user?.id ||
    project?.startupIntelligenceId === user?.id ||
    project?.programManager?.userId === user?.id;

  const isAdmin = user?.roleName === "Admin";

  const canManage =
    isAdmin || vcRight?.canManage || buRight?.canManage || isOwnProject;

  const canEdit =
    isAdmin ||
    canManage ||
    vcRight?.canOverwrite ||
    buRight?.canOverwrite ||
    isOwnProject;

  const isViewer =
    !isAdmin &&
    !user?.rights.some((right) => right.canManage || right.canOverwrite);

  const getVentureClientId = () => {
    return (
      user?.rights.find((right) => right.ventureClientId)?.ventureClientId ||
      user?.rights.find((right) => right.scopeType === "Venture Client")
        ?.scopeId
    );
  };

  return {
    isExternalUser: user?.roleName === "Client User",
    isAdmin,
    ventureClientId: getVentureClientId(),
    canEdit,
    canManage,
    isViewer,
  };
}

export default useRoles;
