import { Button, Paper, Stack, Typography, styled, Box } from "@mui/material";
import { Project } from "../../../../../Types/Project";
import { capitalizeFirstLetter, formatDate } from "../../../../../utils";
import ContactName from "../../../../Contacts/ContactCard/ContactName/ContactName";
import { ReactElement, useState } from "react";
import ProjectStatusOverview from "./ProjectStatusOverview";
import theme from "../../../../../theme";
import { ProjectHistory } from "../../../../../Types/ProjectHistory";
import EditProjectStatus from "./EditProjectStatus";
import CustomExpendableText from "../../../../UI/CustomExpendableText";
import ManageStatusComment from "./ManageStatusComment";
import {
  CallSplit,
  Check,
  East,
  Flag,
  Pause,
  PlayArrow,
  Stop,
  Stream,
} from "@mui/icons-material";
import parse from "html-react-parser";
import HoverableStatusChip from "../../../../UI/HoverableStatusChip";
import useRoles from "../../../../../Hooks/useRoles";

interface IconWrapperBoxProps {
  $flagged?: boolean;
}

const Container = styled(Stack)(() => ({
  backgroundColor: theme.palette.background.default,
  padding: theme.spacing(2),
  gap: theme.spacing(2),
}));

export const IconWrapper = styled(Box, {
  shouldForwardProp: (prop: string) => !prop.startsWith("$"),
})<IconWrapperBoxProps>(({ theme, $flagged }) => ({
  padding: theme.spacing(1),
  height: theme.spacing(4),
  borderRadius: "50%",
  backgroundColor: $flagged
    ? theme.palette.surface.error.main
    : theme.palette.surface?.mediumEmphasis,
}));

const LinkParser = styled(Typography)(() => ({
  "& a": {
    color: theme.palette.text.action.main,
    ...theme.typography.subtitle2,
  },
}));

interface Props {
  project: Project;
  handleSave: () => void;
}

const ProjectStatus = (props: Props): ReactElement => {
  const { canEdit } = useRoles(props.project);

  const [modalOpen, setModalOpen] = useState(false);
  const [editStatusModal, setEditStatusModal] = useState(false);
  const [manageCommentModal, setManageCommentModal] = useState(false);
  const [associatedModal, setAssociatedModal] = useState(false);

  const status = props.project.status;
  const onHoldDeadline = props.project.onHoldDeadline
    ? formatDate(new Date(props.project.onHoldDeadline))
    : null;
  const isOnHold = props.project.status === "on hold";
  const isArchived = props.project.status === "archived";
  const isOnHoldorArchived = isOnHold || isArchived;
  const reason =
    props.project.projectHistoryLatestChange[0]?.projectStatusChangeReason ||
    props.project.projectHistoryLatestChange[1]?.projectStatusChangeReason;

  const handleViewMore = () => {
    setModalOpen(true);
    setAssociatedModal(true);
  };

  const handleAddNewComment = () => {
    setManageCommentModal(true);
    setAssociatedModal(false);
  };

  const handleEditStatus = () => {
    if (!canEdit) return;
    setEditStatusModal(true);
    setAssociatedModal(false);
  };

  const renderHistoryItem = (history: ProjectHistory) => {
    if (!history)
      return (
        <Typography color={theme.palette.text.disabled}>
          No updates have been added yet.
        </Typography>
      );

    const isExternalUser = history.user.roleName === "Client User";
    switch (history.type) {
      case "Project Status Comment":
        return (
          <Stack gap={2} data-testid="status-comment-history">
            <ContactName
              isVentureAssociate={!isExternalUser}
              orientation="horizontal"
              name={history.user.name}
              title={formatDate(history.dateTriggered)}
            />
            <CustomExpendableText text={history.description} />
          </Stack>
        );

      case "Project Stage Change":
        return (
          <Container data-testid="stage-change-history">
            <Box display="flex" gap={2} alignItems="center">
              <IconWrapper>
                <East sx={{ color: "white", fontSize: "1rem" }} />
              </IconWrapper>
              <Typography>
                The project has been moved to{" "}
                <b>{capitalizeFirstLetter(history.newValue || "")}</b> stage.
              </Typography>
            </Box>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              gap={1}
            >
              <Box display="flex" gap={0.5}>
                <Typography variant="body2" color="text.mediumEmphasis">
                  By
                </Typography>
                <Typography variant="body2">{history.user.name}</Typography>
              </Box>
              <Typography
                variant="body2"
                color={theme.palette.text.mediumEmphasis}
              >
                {formatDate(history.dateTriggered)}
              </Typography>
            </Box>
          </Container>
        );

      case "Project Status Change":
        let icon;
        switch (history.newValue) {
          case "on hold":
            icon = (
              <IconWrapper>
                <Pause sx={{ color: "white", fontSize: "1rem" }} />
              </IconWrapper>
            );
            break;
          case "archived":
            icon = (
              <IconWrapper>
                <Stop sx={{ color: "white", fontSize: "1rem" }} />
              </IconWrapper>
            );
            break;
          case "adopted":
            icon = (
              <IconWrapper>
                <Check sx={{ color: "white", fontSize: "1rem" }} />
              </IconWrapper>
            );
            break;
          default:
            icon = (
              <IconWrapper>
                <PlayArrow sx={{ color: "white", fontSize: "1rem" }} />
              </IconWrapper>
            );
        }

        return (
          <Container data-testid="stage-change-history">
            <Box display="flex" gap={2} alignItems="center">
              {icon}
              <Typography>
                The project has been set to <b>{history.newValue}</b>.
              </Typography>
            </Box>

            {(isOnHoldorArchived || history.description) && (
              <Stack gap={1}>
                {isOnHoldorArchived && (
                  <Stack>
                    <Box display="flex" alignItems="start">
                      <Typography
                        variant="body2"
                        color={theme.palette.text.mediumEmphasis}
                      >
                        Reason
                      </Typography>
                      &nbsp;
                      <Typography variant="body2">
                        {reason?.description || ""}
                      </Typography>
                    </Box>
                    {isOnHold && (
                      <Box display="flex" alignItems="center">
                        <Typography
                          variant="body2"
                          color={theme.palette.text.mediumEmphasis}
                        >
                          On hold until
                        </Typography>
                        &nbsp;
                        <Typography variant="body2">
                          {onHoldDeadline}
                        </Typography>
                      </Box>
                    )}
                  </Stack>
                )}
                <CustomExpendableText text={history.description} />
              </Stack>
            )}
            <Box display="flex" justifyContent="space-between" gap={1}>
              <Box display="flex" gap={0.5}>
                <Typography variant="body2" color="text.mediumEmphasis">
                  By
                </Typography>
                <Typography variant="body2">{history.user.name}</Typography>
              </Box>
              <Typography
                variant="body2"
                color={theme.palette.text.mediumEmphasis}
              >
                {formatDate(history.dateTriggered)}
              </Typography>
            </Box>
          </Container>
        );

      case "Project Created":
        return (
          <Container data-testid="project-created-history">
            <Box display="flex" gap={2} alignItems="center">
              <IconWrapper>
                <Stream sx={{ color: "white", fontSize: "1rem" }} />
              </IconWrapper>
              <Typography>
                The project has been <b>launched</b>.
              </Typography>
            </Box>

            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              gap={1}
            >
              <Box display="flex" gap={0.5}>
                <Typography variant="body2" color="text.mediumEmphasis">
                  By
                </Typography>
                <Typography variant="body2">{history.user.name}</Typography>
              </Box>

              <Typography
                variant="body2"
                color={theme.palette.text.mediumEmphasis}
              >
                {formatDate(history.dateTriggered)}
              </Typography>
            </Box>
          </Container>
        );

      case "Project Split":
        return (
          <Container data-testid="stage-change-history">
            <Box display="flex" gap={2} alignItems="center">
              <IconWrapper>
                <CallSplit
                  sx={{
                    color: "white",
                    fontSize: "1rem",
                    transform: "rotate(90deg)",
                  }}
                />
              </IconWrapper>
              <LinkParser>{parse(history.description)}</LinkParser>
            </Box>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              gap={1}
            >
              <Box display="flex" gap={0.5}>
                <Typography variant="body2" color="text.mediumEmphasis">
                  By
                </Typography>
                <Typography variant="body2">{history.user.name}</Typography>
              </Box>
              <Typography
                variant="body2"
                color={theme.palette.text.mediumEmphasis}
              >
                {formatDate(history.dateTriggered)}
              </Typography>
            </Box>
          </Container>
        );

      case "Project Flagged":
        return (
          <Container data-testid="project-flagged-history">
            <Box display="flex" gap={2} alignItems="center">
              <IconWrapper
                $flagged={history.newValue === "true" ? true : false}
              >
                <Flag sx={{ color: "white", fontSize: "1rem" }} />
              </IconWrapper>
              <Typography>
                The project{" "}
                {history.newValue === "true"
                  ? "has been set to "
                  : "is no longer "}
                <b>off track</b>.
              </Typography>
            </Box>
            {history.description && (
              <CustomExpendableText text={history.description} />
            )}
            <Box display="flex" justifyContent="space-between" gap={1}>
              <Box display="flex" gap={0.5}>
                <Typography variant="body2" color="text.mediumEmphasis">
                  By
                </Typography>
                <Typography variant="body2">{history.user.name}</Typography>
              </Box>
              <Typography
                variant="body2"
                color={theme.palette.text.mediumEmphasis}
              >
                {formatDate(history.dateTriggered)}
              </Typography>
            </Box>
          </Container>
        );
      default:
        return null;
    }
  };

  return (
    <>
      <Paper
        component={Stack}
        sx={{ p: 3, gap: 3 }}
        data-testid="project-updates-section"
      >
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h6">Project Updates</Typography>
          <HoverableStatusChip
            status={status}
            onClick={handleEditStatus}
            canEdit={canEdit}
          />
        </Box>

        {renderHistoryItem(props.project.projectHistoryLatestChange[0])}
        <Stack gap={1}>
          {canEdit && (
            <Button onClick={handleAddNewComment} variant="contained">
              Add Comment
            </Button>
          )}
          <Button variant="text" onClick={handleViewMore}>
            View History
          </Button>
        </Stack>
      </Paper>
      {editStatusModal && (
        <EditProjectStatus
          projectId={props.project.id}
          statusComment={props.project.statusComment}
          status={props.project.status}
          statusReason={reason}
          onHoldDeadline={props.project.onHoldDeadline}
          modalOpen={editStatusModal}
          setModalOpen={setEditStatusModal}
          funnelStage={props.project.funnelStage}
          handleSave={props.handleSave}
          openAssociatedModal={associatedModal}
          setProjectStatusOverviewModal={setModalOpen}
          offTrack={props.project.offTrack}
          offTrackComment={props.project.offTrackComment}
        />
      )}
      {modalOpen && (
        <ProjectStatusOverview
          projectId={props.project.id}
          status={status}
          onHoldDeadline={props.project.onHoldDeadline}
          modalOpen={modalOpen}
          stage={props.project.funnelStage}
          setModalOpen={setModalOpen}
          handleSave={props.handleSave}
          setEditStatusModal={setEditStatusModal}
          setManageCommentModal={setManageCommentModal}
          offTrack={props.project.offTrack}
          canEdit={canEdit}
        />
      )}
      {manageCommentModal && (
        <ManageStatusComment
          modalOpen={manageCommentModal}
          setModalOpen={setManageCommentModal}
          projectId={props.project.id}
          handleSave={props.handleSave}
          openAssociatedModal={associatedModal}
          setProjectStatusOverviewModal={setModalOpen}
        />
      )}
    </>
  );
};

export default ProjectStatus;
