import { Box, Paper, Skeleton, Stack, Typography } from "@mui/material";
import { ReactElement } from "react";

export default function ProjectFilesSectionSkeleton(): ReactElement {
  return (
    <>
      <Paper component={Stack} p={3} gap={2}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography variant="h6">Files</Typography>
          <Box display="flex" gap={2}>
            <Skeleton height={20} width={20} />
            <Skeleton height={20} width={20} />
          </Box>
        </Box>
        <Skeleton width="auto" height={56} />
        <Skeleton width={144} height={20} />
        <Box display="flex" alignItems="center" gap={2}></Box>
        <Skeleton width={80} height={20} />
        <Skeleton width="auto" height={56} />
      </Paper>
    </>
  );
}
