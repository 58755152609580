import { ReactElement } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import { Project } from "../../../../Types/Project";
import theme from "../../../../theme";
import { findLogo } from "../../../../utils";
import LogoPlaceholder from "../LogoPlaceholder";

interface Props {
  setModalOpen: (args: boolean) => void;
  modalOpen: boolean;
  moveProject: () => void;
  projectData: Project;
}

function StageConfirmationModal(props: Props): ReactElement {
  const forPilotOpportunities = props.projectData?.opportunities.filter(
    (opportunity) => opportunity.isSelectedForPilot
  );

  const isMultipleOpportunities = forPilotOpportunities.length > 1;
  const selectedOpportunity = forPilotOpportunities[0];

  const title = isMultipleOpportunities
    ? "Proceeding with Multiple Startups"
    : `Proceeding with ${selectedOpportunity?.startup.name}`;

  const description = isMultipleOpportunities
    ? "You have selected multiple startups to proceed to Buy stage:"
    : "You have selected the following startup to proceed to Buy stage:";

  const confirmationMessage = isMultipleOpportunities ? (
    <>
      When proceeding with multiple startups, this project will be split into
      multiple independent projects.
      <br />
      Are you sure you want to move to Buy stage with multiple startups?
    </>
  ) : (
    "Are you sure you want to move to Buy stage with this startup?"
  );

  return (
    <Dialog
      open={props.modalOpen}
      onClose={() => props.setModalOpen(false)}
      id="proceed-to-buy-dialog"
      fullWidth
      maxWidth="xs"
    >
      <Stack gap={4}>
        <Stack gap={2}>
          <DialogTitle>{title}</DialogTitle>
          <DialogContent>
            <Stack gap={2}>
              <Typography variant="caption" color="text.mediumEmphasis">
                {description}
              </Typography>
              <Stack gap={1}>
                {forPilotOpportunities.map((opportunity) => (
                  <Stack
                    key={opportunity.id}
                    display="flex"
                    flexDirection="column"
                    p={1}
                    gap={2}
                    borderRadius={theme.shape.radius.minimal}
                    sx={{ background: theme.palette.surface.secondary.light }}
                  >
                    <Box display="flex" gap={2} alignItems="center">
                      <Box width={120}>
                        <LogoPlaceholder
                          logoUrl={findLogo(opportunity.startup?.files)}
                        />
                      </Box>

                      <Typography color="text.mediumEmphasis">
                        {opportunity.startup.name}
                      </Typography>
                    </Box>
                  </Stack>
                ))}
              </Stack>
              <Typography color="text.mediumEmphasis">
                {confirmationMessage}
              </Typography>
            </Stack>
          </DialogContent>
        </Stack>
        <DialogActions>
          <Button
            onClick={() => props.setModalOpen(false)}
            data-testid="proceed-to-buy-cancel"
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={() => (props.moveProject(), props.setModalOpen(false))}
            data-testid="proceed-to-buy-move"
          >
            Move to Buy
          </Button>
        </DialogActions>
      </Stack>
    </Dialog>
  );
}

export default StageConfirmationModal;
