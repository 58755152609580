import { FilterList } from "@mui/icons-material";
import { Badge, IconButton, styled } from "@mui/material";
import { ReactElement } from "react";
import useRoles from "../../Hooks/useRoles";
import { ProjectFilterableProperties } from "../../Types/Project";

const StyledBadge = styled(Badge)(() => ({
  "& .MuiBadge-badge": {
    color: "white",
    height: "18px",
    minWidth: "24px",
    top: "2px",
    right: "6px",
  },
}));

function countFilters(
  filters: ProjectFilterableProperties,
  isExternalUser: boolean
) {
  let count = 0;

  for (const key in filters) {
    if (key === "ventureClients" && isExternalUser) continue;

    if (filters.hasOwnProperty(key)) {
      const specificKey: keyof ProjectFilterableProperties =
        key as keyof ProjectFilterableProperties;
      const value = filters[specificKey];

      if (Array.isArray(value)) {
        count += value.length;
      }
      if (key === "bookmarked") count += 1;
    }
  }

  return count;
}

interface Props {
  onClick: () => void;
}

const CustomFilter = (props: Props): ReactElement => {
  const { isExternalUser } = useRoles();
  const filters = JSON.parse(localStorage.getItem("projectFilters") || "{}");
  const filtersCount = countFilters(filters, isExternalUser);

  return (
    <StyledBadge color="primary" badgeContent={filtersCount}>
      <IconButton color="secondary" onClick={props.onClick}>
        <FilterList />
      </IconButton>
    </StyledBadge>
  );
};

export default CustomFilter;
