import { InfoOutlined } from "@mui/icons-material";
import { Tooltip, Typography } from "@mui/material";
import { ReactElement } from "react";

interface CompetitorsInfoTooltipProps {
  isListInaccurate?: boolean;
}
export function CompetitorsInfoTooltip(
  props: CompetitorsInfoTooltipProps
): ReactElement {
  const tooltipMessage = (() => {
    const initialMessage =
      "Startup competitors are identified from available venture client activity data.";

    const complementaryMessage = props.isListInaccurate ? (
      <Typography component="span" fontWeight="bold">
        <br />
        Due to very limited available data, listed competitors for this startup
        may be inaccurate.
      </Typography>
    ) : (
      " Please note, that this list may not be exhaustive."
    );
    return (
      <Typography>
        {initialMessage}
        {complementaryMessage}
      </Typography>
    );
  })();

  return (
    <Tooltip data-testid="competitors-tooltip" title={tooltipMessage}>
      <InfoOutlined color={props.isListInaccurate ? "warning" : "disabled"} />
    </Tooltip>
  );
}
