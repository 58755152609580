import { Box, Paper, Skeleton, Stack, Typography } from "@mui/material";
import { ReactElement } from "react";

export default function ProjectTeamLeadersSectionSkeleton(): ReactElement {
  return (
    <>
      <Paper component={Stack} p={3} gap={2}>
        <Typography variant="h6">Main Stakeholders</Typography>
        <Stack gap={3}>
          <Stack gap={2}>
            <Box display="flex" alignItems="center" gap={2}>
              <Skeleton height={60} width={80} />
              <Stack gap={1}>
                <Stack>
                  <Skeleton height={16} width={112} />
                </Stack>
                <Stack gap={0.5}>
                  <Skeleton height={8} width={80} />
                  <Skeleton height={8} width={64} />
                </Stack>
              </Stack>
            </Box>
            <Box display="flex" alignItems="center" gap={2}>
              <Skeleton height={60} width={80} />
              <Stack gap={1}>
                <Stack>
                  <Skeleton height={16} width={112} />
                </Stack>
                <Stack gap={0.5}>
                  <Skeleton height={8} width={80} />
                  <Skeleton height={8} width={64} />
                </Stack>
              </Stack>
            </Box>
          </Stack>
          <Skeleton width="auto" height={36} />
        </Stack>
      </Paper>
    </>
  );
}
