import {
  Dialog,
  Button,
  DialogContent,
  DialogActions,
  DialogContentText,
  Stack,
  Typography,
} from "@mui/material";
import { ReactElement } from "react";
import theme from "../../../../theme";
import { normalizeUrl } from "../../../../utils";

interface Props {
  modalOpen: boolean;
  setModalOpen: (state: boolean) => void;
  url: string;
}

const RedirectWebsiteModal = ({
  modalOpen,
  setModalOpen,
  url,
}: Props): ReactElement => {
  const handleAction = () => {
    setModalOpen(false);
    window.open(normalizeUrl(url, false, false), "_blank");
  };

  return (
    <Dialog
      open={modalOpen}
      maxWidth="xs"
      PaperProps={{
        sx: {
          "&.MuiDialog-paper": {
            p: 0,
            gap: 0,
          },
        },
      }}
    >
      <Stack gap={2} p={theme.spacing(2, 3, 0)}>
        <Typography variant="subtitle1">
          You are leaving the Venture Client Platform
        </Typography>
        <DialogContent sx={{ p: 0 }}>
          <DialogContentText>
            You are being redirected to an external website: <br />
            <Typography
              color="text.action.main"
              sx={{ wordBreak: "break-word", cursor: "pointer" }}
              onClick={handleAction}
            >
              {url}
            </Typography>
            <br /> Would you like to visit this link?
          </DialogContentText>
        </DialogContent>
      </Stack>
      <DialogActions sx={{ p: theme.spacing(1) }}>
        <Button onClick={() => setModalOpen(false)}>Cancel</Button>
        <Button variant="contained" onClick={handleAction}>
          Visit Link
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RedirectWebsiteModal;
