import {
  InputLabel,
  MenuItem,
  Stack,
  TextFieldProps,
  Typography,
} from "@mui/material";
import { ReactElement, ReactNode } from "react";
import { SelectOption } from "../../../Types/Common";
import { TextField } from "@mui/material";
import CustomToolTip from "../CustomToolTip";

type SelectInputProps = TextFieldProps & {
  ignoreValidation?: boolean;
  deselectPossible?: boolean;
  selectValues: SelectOption[];
  disableSorting?: boolean;
  toolTipText?: string | ReactElement | null;
  editMode: boolean;
  children?: ReactNode;
  "data-testid"?: string;
};

export function SelectInput(props: SelectInputProps): ReactElement {
  const {
    ignoreValidation,
    deselectPossible,
    selectValues,
    disableSorting,
    toolTipText = null,
    editMode,
    ...defaultProps
  } = props;

  return (
    <>
      {props.editMode ? (
        <TextField
          {...defaultProps}
          select
          label={
            !!!toolTipText ? (
              defaultProps.label
            ) : (
              <>
                {defaultProps.label}
                <CustomToolTip
                  id={`${defaultProps.id}Tooltip`}
                  toolTipText={toolTipText || ""}
                />
              </>
            )
          }
          value={defaultProps.value || ""}
          InputLabelProps={{
            shrink: true,
            sx: {
              marginTop: editMode && !!toolTipText ? "-7px" : "0px",
              paddingRight: "8px",
            },
          }}
          error={
            editMode &&
            defaultProps.required &&
            !defaultProps.value &&
            !ignoreValidation
          }
        >
          {deselectPossible && <MenuItem value="">None</MenuItem>}
          {selectValues
            .sort(function (option1, option2) {
              if (disableSorting) {
                return 0;
              }
              return option1.name.localeCompare(option2.name, "en", {
                numeric: true,
              });
            })
            .map((selectOption) => (
              <MenuItem
                data-testid={"option-" + selectOption.name}
                key={selectOption.id}
                value={selectOption.id}
              >
                {selectOption.name}
              </MenuItem>
            ))}
        </TextField>
      ) : (
        <Stack gap={0.5}>
          <InputLabel>
            <Typography variant="caption">{defaultProps.label}</Typography>
          </InputLabel>
          <Typography
            variant="body1"
            id={props.id}
            data-testid={defaultProps.id}
          >
            {props.selectValues.find(
              (option) => option.id === defaultProps.value
            )?.name || "--"}
          </Typography>
        </Stack>
      )}
    </>
  );
}
