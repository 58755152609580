import axios from "axios";
import { PaginatedProblemDTO, ProblemsDTO } from "../../Types/Problem";

export class ProblemsHttpService {
  public static async getAllProblems(
    page: number,
    searchValue: string
  ): Promise<PaginatedProblemDTO> {
    const response = await axios.get<PaginatedProblemDTO>("/api/problems", {
      params: { page, searchValue },
    });
    return response.data;
  }

  public static async getProblemById(id: number): Promise<ProblemsDTO> {
    const response = await axios.get<ProblemsDTO>(`/api/problems/${id}`);
    return response.data;
  }
}
