import {
  LeadProject,
  LeadProjectDTO,
  LeadProjectHistoryLatestChangeDTO,
  LeadProjectLeader,
  LeadProjectUseCase,
} from "../../Types/LeadProject";
import ProjectMapper from "../Project/Project.mapper";

export default class LeadProjectMapper {
  public static map(leadProject: LeadProjectDTO): LeadProject {
    return {
      ...leadProject,
      dateCreated: new Date(leadProject.dateCreated),

      lastModifiedDate: leadProject.lastModifiedDate
        ? new Date(leadProject.lastModifiedDate)
        : null,
      ...(leadProject.useCases && {
        useCases: this.mapUseCases(leadProject.useCases),
      }),
      ...(leadProject.leaders && {
        leaders: this.mapleadProjectLeaders(leadProject.leaders),
      }),
      opportunities: leadProject.opportunities?.map((opportunity) => {
        opportunity.startup.dateFounded = opportunity.startup.dateFounded
          ? new Date(opportunity.startup.dateFounded)
          : null;
        opportunity.startup.lastQualityCheckDate = opportunity.startup
          .lastQualityCheckDate
          ? new Date(opportunity.startup.lastQualityCheckDate)
          : null;
        return opportunity;
      }),
      projects: leadProject.projects
        ?.map((project) => {
          return ProjectMapper.map(project);
        })
        .sort((a, b) => a.dateCreated.getTime() - b.dateCreated.getTime()),
      onHoldDeadline: leadProject.onHoldDeadline
        ? new Date(leadProject.onHoldDeadline).toLocaleDateString("fr-ca")
        : undefined,
      ...(leadProject.leadProjectHistoryLatestChange && {
        leadProjectHistoryLatestChange:
          leadProject.leadProjectHistoryLatestChange.map(
            (history: LeadProjectHistoryLatestChangeDTO) =>
              LeadProjectHistoryMapper.map(history)
          ),
      }),
      initiativeType: leadProject.initiativeName?.initiativeType,
      originThirdPartyCategory:
        leadProject.originThirdPartyCategoryName?.categoryType,
    };
  }

  public static mapUseCases(
    useCases: LeadProjectUseCase[]
  ): LeadProjectUseCase[] {
    return useCases.sort((a, b) => a.id - b.id);
  }

  public static mapleadProjectLeaders(
    leaders: LeadProjectLeader[]
  ): LeadProjectLeader[] {
    return leaders.sort((a, b) => a.id - b.id);
  }
}

class LeadProjectHistoryMapper {
  static map<T extends { dateTriggered: string }>(
    history: T
  ): T & { dateTriggered: Date } {
    return {
      ...history,
      dateTriggered: new Date(history.dateTriggered),
    };
  }
}
