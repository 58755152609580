/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import { File } from "../Types/File.d";
import { Kpi } from "../Types/Kpi";
import { ProductDemo } from "../Types/ProductDemo";
import { Project } from "../Types/Project.d";
import {
  FieldOptions,
  MissingInformation,
  MissingProjectInformation,
  ProjectFieldRules,
  ProjectFileRules,
} from "../Types/ProjectRules.d";

export default class ProjectValidator {
  public static getMissingProjectInformation(
    project: Project,
    funnelStage: string
  ): MissingProjectInformation {
    const projectFieldRules: ProjectFieldRules = JSON.parse(
      window.localStorage.getItem("projectFieldRules") || "{}"
    );

    const projectFileRules: ProjectFileRules[] = JSON.parse(
      window.localStorage.getItem("projectFileRules") || "[]"
    );

    const missingFields = this.getMissingStageFields(
      projectFieldRules,
      this.filterProjectInitialMeetings(project),
      funnelStage
    );

    const projectFiles = project.opportunities?.reduce(
      (files, opportunity) => (files = files.concat(opportunity.files || [])),
      project.files
    );

    const missingFiles = this.getMissingStageFiles(
      projectFiles || [],
      projectFileRules,
      project,
      funnelStage
    );

    return { fields: missingFields, files: missingFiles };
  }

  public static getMissingStageFields(
    projectFieldRules: ProjectFieldRules,
    project: Project,
    funnelStage: string
  ): MissingInformation[] {
    let missingFields: MissingInformation[] = [];
    Object.keys(projectFieldRules)
      .filter(
        (fieldId) =>
          projectFieldRules[fieldId].stage?.includes(funnelStage) &&
          (projectFieldRules[fieldId].required ||
            projectFieldRules[fieldId].type === "entity")
      )
      .forEach((fieldId) => {
        const fieldOptions: FieldOptions = projectFieldRules[fieldId];

        if (fieldOptions.type === "entity") {
          const missingFieldNames = this.getEntityEmptyFields(
            fieldOptions,
            project[fieldId as keyof Project] as any[],
            missingFields,
            funnelStage
          );
          missingFields = [...missingFieldNames];
        } else if (fieldOptions.name === "Pilot Project Evaluation Reason") {
          if (
            project.pilotOutcome === "Negative" &&
            project.pilotOutcomeReason === null
          ) {
            missingFields.push({
              name: fieldOptions.name,
              stageSpecific: fieldOptions.stageSpecific,
            });
          }
        } else {
          const isEmpty = this.isFieldEmpty(
            fieldOptions,
            project[fieldId as keyof Project]
          );

          if (isEmpty) {
            missingFields.push({
              name: fieldOptions.name,
              stageSpecific: fieldOptions.stageSpecific,
            });
          }
        }
      });

    // remove duplicates the array of object by their name
    return [
      ...new Map(missingFields.map((item) => [item["name"], item])).values(),
    ];
  }

  public static getMissingStageFiles(
    existingProjectFiles: File[],
    projectFileRules: ProjectFileRules[],
    project: Project,
    funnelStage: string
  ): MissingInformation[] {
    const missingFiles: MissingInformation[] = [];

    projectFileRules
      .filter(
        (fileRule) => fileRule.stage.includes(funnelStage) && fileRule.required
      )
      .forEach((fileOption) => {
        if (fileOption.scope === "project") {
          const fileFound = !!existingProjectFiles.find(
            (file) => file.type === fileOption.name
          );
          if (!fileFound) {
            missingFiles.push({
              name: fileOption.name,
              stageSpecific: fileOption.stageSpecific,
            });
          }
        } else if (fileOption.scope === "opportunity") {
          project.opportunities
            ?.filter((opp) =>
              funnelStage === "discover"
                ? 1
                : funnelStage === "assess" && opp.isQualified
                ? 1
                : opp.isSelectedForPilot
                ? 1
                : 0
            )
            .forEach((opportunity) => {
              const fileFound = !!existingProjectFiles.find(
                (file: File) =>
                  file.type === fileOption.name &&
                  file.linkableId === opportunity.id &&
                  file.linkableType === "opportunity"
              );
              if (!fileFound) {
                if (funnelStage === "discover" || funnelStage === "assess") {
                  missingFiles.push({
                    name: fileOption.name + " for " + opportunity.startup.name,
                    stageSpecific: fileOption.stageSpecific,
                  });
                } else {
                  missingFiles.push({
                    name: fileOption.name,
                    stageSpecific: fileOption.stageSpecific,
                  });
                }
              }
            });
        } else if (fileOption.scope === "productDemos") {
          project.opportunities
            ?.reduce((demos, opportunity) => {
              return demos.concat(opportunity.productDemos || []);
            }, [] as ProductDemo[])
            .forEach((demo) => {
              const fileFound = !!existingProjectFiles.find(
                (file) =>
                  file.type === fileOption.name &&
                  file.linkableId === demo.opportunityId &&
                  file.linkableType === "opportunity"
              );

              if (!fileFound) {
                const demoOpportunity = project.opportunities?.find(
                  (opp) => opp.id === demo.opportunityId
                );

                missingFiles.push({
                  name:
                    fileOption.name + " for " + demoOpportunity?.startup.name,
                  stageSpecific: fileOption.stageSpecific,
                });
              }
            });
        }
      });

    // remove duplicates the array of object by their name
    return [
      ...new Map(missingFiles.map((item) => [item["name"], item])).values(),
    ];
  }

  public static isFieldEmpty(fieldOptions: FieldOptions, value: any): boolean {
    if (fieldOptions.type === "list" && value && !!!value.length) {
      return true;
    } else if (fieldOptions?.type === "property" && !value) {
      return true;
    }
    return false;
  }

  public static getEntityEmptyFields(
    fieldOptions: FieldOptions,
    childEntries: any[],
    missingFields: MissingInformation[],
    funnelStage: string
  ): MissingInformation[] {
    Object.keys(fieldOptions.subFields || {})
      .filter(
        (subFieldId) =>
          fieldOptions.stage?.includes(funnelStage) &&
          (fieldOptions.subFields?.[subFieldId].required ||
            fieldOptions.subFields?.[subFieldId].type === "entity") &&
          (!fieldOptions.subFields?.[subFieldId].stage ||
            fieldOptions.subFields?.[subFieldId].stage?.includes(funnelStage))
      )
      .forEach((subFieldId) => {
        const subFieldOptions = fieldOptions.subFields?.[subFieldId];

        childEntries?.forEach((entry: any) => {
          if (subFieldOptions?.type === "entity") {
            // recursively call with children's data
            this.getEntityEmptyFields(
              subFieldOptions,
              entry[subFieldId],
              missingFields,
              funnelStage
            );
          } else {
            if (subFieldId === "assessmentDecision") {
              // if opportunity not qualified, then ignore the decision rationale required field
              if (!entry.isQualified) return;
              const isEmpty = this.isFieldEmpty(
                subFieldOptions as FieldOptions,
                entry[subFieldId]
              );
              if (isEmpty) {
                missingFields.push({
                  name: subFieldOptions?.name + " for " + entry.startup.name,
                  stageSpecific: subFieldOptions?.stageSpecific || false,
                });
              }
            } else {
              const isEmpty = this.isFieldEmpty(
                subFieldOptions as FieldOptions,
                entry[subFieldId]
              );

              // if the field is measurement and the kpi is fulfilled or is not measured, then ignore the missing field
              if (subFieldId === "measurement") {
                const isMeasurementSet = this.hasKpiMeasurement(isEmpty, entry);
                if (isMeasurementSet) return null;
              }

              if (isEmpty) {
                missingFields.push({
                  name: subFieldOptions?.name + " for " + fieldOptions.name,
                  stageSpecific: subFieldOptions?.stageSpecific || false,
                });
              }
            }
          }
        });
      });

    return missingFields;
  }

  public static hasKpiMeasurement(isEmpty: boolean, kpi: Kpi) {
    if (isEmpty && (kpi.fulfilled !== null || kpi.isNotMeasured === true)) {
      return true;
    }
    return false;
  }

  public static filterProjectInitialMeetings(project: Project) {
    return {
      ...project,
      meetings: project.meetings?.filter(
        (meeting) => meeting.isInitial === false
      ),
    };
  }
}
