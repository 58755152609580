import { Box, styled } from "@mui/material";
import theme from "../../theme";

const AuthenticationCard = styled(Box)(() => ({
  display: "flex",
  width: theme.spacing(41.25),
  padding: theme.spacing(3),
  height: "fit-content",
  alignSelf: "center",
  flexDirection: "column",
  gap: theme.spacing(2),
  color: theme.palette.text.primary,
  background: theme.palette.surface.secondary.main,
  borderRadius: theme.shape.radius.minimal,
}));

export default AuthenticationCard;
