import React, { ReactElement, useEffect } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  styled,
  Typography,
  useTheme,
  Box,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { LeadOpportunity } from "../../../Types/LeadProject";
import { File } from "../../../Types/File";
import MapFile from "../../../Http/File/File.mapper";
import { findLogo } from "../../../utils";
import { FilesListSection } from "../../ProjectDetails/SharedComponents/ProjectOverview/FilesComponent/FilesListSection";

interface LeadOpportunityFilesProps {
  localFiles: { [key: string | number]: File[] | undefined };
  deleteFile: (fileId: number) => void;
  editingFileId?: number;
  setEditingFileId: (id?: number) => void;
  handleFileRename: (
    index: number,
    name: string,
    opportunityId: number | string
  ) => void;
  opportunities: LeadOpportunity[];
  lastUploadFileId: number;
  handleSave: () => void;
}

const StyledOpportunityFiles = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(1),
  "& .MuiAccordion-root:before": {
    display: "none",
  },
  "& .MuiAccordion-root": {
    margin: "0",
  },
  "& .MuiAccordionSummary-root": {
    padding: "0",
    borderRight: "none",
    flexDirection: "row-reverse",
    justifyContent: "flex-end",
    gap: theme.spacing(1),
  },
  "& .MuiAccordionDetails-root": {
    padding: `${theme.spacing(0.5)} 0px`,
  },
}));

export function LeadOpportunityFilesSection(
  props: LeadOpportunityFilesProps
): ReactElement {
  const [expanded, setExpanded] = React.useState<string | false>(false);
  const theme = useTheme();
  const handleAccordionChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };

  useEffect(() => {
    if (props.lastUploadFileId) {
      setExpanded("panel-" + props.lastUploadFileId);
    }
  }, [props.lastUploadFileId]);

  const mappedOpportunities = props.opportunities
    .map((opportunity) => {
      let mappedFiles: File[] = [];
      mappedFiles = MapFile.map(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        props.localFiles[opportunity.id] as any
      );
      const startupLogo = opportunity.startup.files
        ? findLogo(opportunity.startup.files)
        : null;
      return {
        ...opportunity,
        mappedFiles,
        startupLogo,
      };
    })
    .filter((opportunity) => opportunity.mappedFiles);

  return (
    <StyledOpportunityFiles theme={theme}>
      {mappedOpportunities.map(
        (opportunity) =>
          opportunity.mappedFiles && (
            <Accordion
              expanded={expanded === "panel-" + opportunity.id}
              onChange={handleAccordionChange("panel-" + opportunity.id)}
              key={opportunity.id}
              data-testid="accordion-wrapper"
            >
              <AccordionSummary
                className="startup-name"
                expandIcon={<ExpandMoreIcon sx={{ color: "text.primary" }} />}
                aria-controls={"panel-" + opportunity.id + "-content"}
                id={"panel-" + opportunity.id}
              >
                <Box display="flex" alignItems="center" gap={theme.spacing(1)}>
                  {opportunity.startupLogo && (
                    <Box
                      maxHeight={theme.spacing(4)}
                      maxWidth={theme.spacing(14)}
                      component="img"
                      src={opportunity.startupLogo}
                    />
                  )}
                  <Typography variant="caption">
                    {opportunity.startup.name}
                  </Typography>
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                <FilesListSection
                  key={opportunity.id}
                  files={opportunity.mappedFiles}
                  mapId={opportunity.id}
                  deleteFile={props.deleteFile}
                  editingFileId={props.editingFileId}
                  setEditingFileId={props.setEditingFileId}
                  handleFileRename={props.handleFileRename}
                  projectId={opportunity.leadProjectId}
                  handleSave={props.handleSave}
                />
              </AccordionDetails>
            </Accordion>
          )
      )}
    </StyledOpportunityFiles>
  );
}
