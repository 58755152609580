import { DownloadOutlined } from "@mui/icons-material";
import { IconButton, Menu, MenuItem, Tooltip } from "@mui/material";
import { GridApiPremium } from "@mui/x-data-grid-premium/models/gridApiPremium";
import React, { ReactElement, useState } from "react";
import { useSnackbar } from "notistack";

interface ToolbarExportButtonProps {
  apiRef: React.MutableRefObject<GridApiPremium>;
}

export default function ToolbarExportButton({
  apiRef,
}: ToolbarExportButtonProps): ReactElement {
  const { enqueueSnackbar } = useSnackbar();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleExport = (type: string) => {
    type === "csv"
      ? apiRef.current.exportDataAsCsv()
      : apiRef.current.exportDataAsExcel();
    enqueueSnackbar(`Project List successfully exported`, {
      variant: "success",
    });
    setAnchorEl(null);
  };

  return (
    <>
      <Tooltip title="Export">
        <IconButton
          onClick={handleOpenMenu}
          data-testid="toolbar-export-button"
          color="primary"
        >
          <DownloadOutlined />
        </IconButton>
      </Tooltip>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        data-testid="export-options"
      >
        <MenuItem onClick={() => handleExport("csv")}>Download CSV</MenuItem>
        <MenuItem onClick={() => handleExport("excel")}>
          Download Excel
        </MenuItem>
      </Menu>
    </>
  );
}
