import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import { ReactElement, useEffect, useState } from "react";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import theme from "../../../theme";
import dayjs from "dayjs";

const ActionBarWrapper = styled(Box)(() => ({
  padding: theme.spacing(0, 3, 1),
  marginTop: theme.spacing(-2),
  display: "flex",
  flexDirection: "column",
}));

interface MeetingDatePickerProps {
  value: Date | null;
  onChange?: (value: Date | null, isPushMeetingsEnabled: boolean) => void;
  onSave?: (value: Date | null, isPushMeetingsEnabled: boolean) => void;
  isOpen?: boolean;
  setIsOpen?: (isOpen: boolean) => void;
  hasActionButton?: boolean;
  maxDate: Date | null;
  minDate: Date | null;
}

const MeetingDatePicker = (props: MeetingDatePickerProps): ReactElement => {
  const [isPushMeetingsEnabled, setIsPushMeetingsEnabled] = useState(false);
  const [dateValue, setDateValue] = useState<Date | null>(props.value);

  useEffect(() => {
    if (!isPushMeetingsEnabled && dateValue) {
      if (
        (props.minDate && dateValue < props.minDate) ||
        (props.maxDate && dateValue > props.maxDate)
      ) {
        setDateValue(props.value);
      }
    }
  }, [isPushMeetingsEnabled, dateValue, props.minDate, props.maxDate]);

  const minDateDayjs = props.minDate ? dayjs(props.minDate) : null;
  const maxDateDayjs = props.maxDate ? dayjs(props.maxDate) : null;

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en">
      <DatePicker
        open={props.isOpen}
        onClose={() => (props.setIsOpen ? props.setIsOpen(false) : undefined)}
        closeOnSelect={false}
        value={dateValue ? dayjs(dateValue) : null}
        label="Date"
        inputFormat="DD.MM.YYYY"
        onChange={(value) => {
          const newDateValue = value ? value.toDate() : null;
          setDateValue(newDateValue);
          props.onChange && props.onChange(newDateValue, isPushMeetingsEnabled);
        }}
        data-testid="meeting-date-picker"
        defaultCalendarMonth={minDateDayjs}
        components={{
          ActionBar: () => (
            <ActionBarComponent
              onToggle={(checked) => {
                setIsPushMeetingsEnabled(checked);
                props.onChange && props.onChange(dateValue, checked);
              }}
              isPushMeetingsEnabled={isPushMeetingsEnabled}
              hasActionButton={props.hasActionButton}
              onSave={() => {
                props.onSave && props.onSave(dateValue, isPushMeetingsEnabled);
              }}
              onCancel={() => {
                props.setIsOpen && props.setIsOpen(false);
              }}
            />
          ),
        }}
        renderInput={(params) => (
          <TextField data-testid="meeting-date-picker" {...params} />
        )}
        {...(!isPushMeetingsEnabled && {
          maxDate: maxDateDayjs,
          minDate: minDateDayjs,
        })}
      />
    </LocalizationProvider>
  );
};

export default MeetingDatePicker;

interface ActionBarProps {
  onToggle: (isChecked: boolean) => void;
  isPushMeetingsEnabled: boolean;
  onSave: () => void;
  onCancel: () => void;
  hasActionButton?: boolean;
}
const ActionBarComponent = (props: ActionBarProps) => {
  return (
    <>
      <ActionBarWrapper>
        {props.hasActionButton && (
          <Box alignSelf="end">
            <Button onClick={props.onCancel}>Cancel</Button>
            <Button onClick={props.onSave}>Save</Button>
          </Box>
        )}
        <FormControlLabel
          sx={{ ml: 0 }}
          control={
            <Checkbox
              edge="start"
              disableRipple
              checked={props.isPushMeetingsEnabled}
              onChange={(e) => props.onToggle(e.target.checked)}
              data-testid="push-meeting-enable-checkbox"
            />
          }
          label={
            <Typography variant="caption">
              Push following meetings accordingly
            </Typography>
          }
        />
      </ActionBarWrapper>
    </>
  );
};
