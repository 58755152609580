import { ReactElement, useContext, useState } from "react";
import { Opportunity } from "../../../../../Types/Opportunity";
import { GlobalProjectEditContext } from "../../../../../Context/ProjectDetailsContext";
import useSaveProject from "../../../../../Hooks/useSaveProject";
import { findLogo, normalizeUrl } from "../../../../../utils";
import {
  Box,
  Checkbox,
  IconButton,
  Link,
  styled,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import theme from "../../../../../theme";
import { Launch, RocketLaunchOutlined } from "@mui/icons-material";
import CustomTextField from "../../../../UI/InputFields/CustomTextField";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import LoupeOutlinedIcon from "@mui/icons-material/LoupeOutlined";
import { Project } from "../../../../../Types/Project";
import useRoles from "../../../../../Hooks/useRoles";
export const StyledTableRow = styled(TableRow, {
  shouldForwardProp: (prop: string) => !prop.startsWith("$"),
})(({ $isHighlighted = false }: { $isHighlighted?: boolean }) => ({
  display: "flex",
  cursor: "pointer",
  flexDirection: "column",
  width: theme.spacing(36),
  boxShadow: $isHighlighted ? theme.boxShadows[6] : "none",
  "&:first-of-type": {
    width: theme.spacing(42),
    "& > td": {
      "&:first-of-type": {
        borderTopLeftRadius: theme.shape.radius.cardSmall,
      },
    },
  },
  "&:last-of-type": {
    "& > td": {
      borderRight: "none",
      "&:first-of-type": {
        borderTopRightRadius: theme.shape.radius.cardSmall,
      },
    },
  },
  "& > td": {
    "&:last-of-type": {
      backgroundColor: theme.palette.surface.secondary.light,
      borderBottom: 0,
    },
  },
}));

const StyledTableCell = styled(TableCell, {
  shouldForwardProp: (prop: string) => !prop.startsWith("$"),
})(({ $isSelectedForPilot = false }: { $isSelectedForPilot?: boolean }) => ({
  border: 0,
  borderBottom: `1px solid ${theme.palette.borderOutline.soft}`,
  display: "block",
  borderRight: `1px solid ${theme.palette.borderOutline.soft}`,
  "&:first-of-type": {
    display: "flex",
    gap: theme.spacing(2),
    background: $isSelectedForPilot
      ? theme.palette.surface.action.main
      : theme.palette.surface.tertiary.dark,
    borderRight: `1px solid ${theme.palette.text.primaryInvert.main}`,
    color: theme.palette.text.primaryInvert.main,
    height: theme.spacing(7),
    ...theme.typography.subtitle2,
  },
}));

const DecisionRationaleCell = styled(StyledTableCell)(() => ({
  flexGrow: 1,
  display: "flex",
  gap: theme.spacing(2),
  width: "100%",
  "&:hover .decision-rationale-edit": {
    opacity: 1,
  },
}));

const StyledDecisionRationaleInput = styled(CustomTextField)(() => ({
  width: "100%",
  height: "100%",
  "& .MuiInputBase-root": {
    border: 0,
  },
  "& .MuiInputBase-input": {
    background: "transparent",
  },
}));

interface AssessmentConclusionRowProps {
  opportunity: Opportunity;
  currentOpportunityIndex: number | null;
  isSelectionDisabled: boolean;
  setCurrentOpportunityIndex: React.Dispatch<
    React.SetStateAction<number | null>
  >;
  handleOpportunityStatusChange: (opportunity: Opportunity) => void;
  handleUpdateDecisionRationale: (
    opportunityId: number,
    assessmentDecision: string
  ) => Promise<void>;
  index: number;
  project: Project;
}

const ASSESSMENT_DECISION_TEXT =
  "Please provide a brief explanation for selecting or not selecting this startup for pilot.";

export default function AssessmentConclusionRow({
  opportunity,
  handleOpportunityStatusChange,
  handleUpdateDecisionRationale,
  setCurrentOpportunityIndex,
  isSelectionDisabled,
  index,
  project,
}: AssessmentConclusionRowProps): ReactElement {
  const { canEdit } = useRoles(project);
  const { setGlobalEditMode } = useContext(GlobalProjectEditContext);
  const [editDecisionRationale, setEditDecisionRationale] = useState(false);
  const [currentValue, setCurrentValue] = useState(
    opportunity.assessmentDecision
  );

  const handleFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    const length = event.target.value.length;
    event.target.setSelectionRange(length, length);
  };

  const handleKeyDown = async (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      await handleSave();
    } else if (event.key === "Escape") {
      setEditDecisionRationale(false);
      setGlobalEditMode(false);
    }
  };

  const handleSave = async () => {
    if (opportunity.assessmentDecision !== currentValue) {
      await handleUpdateDecisionRationale(opportunity.id, currentValue);
    }
    setEditDecisionRationale(false);
    setGlobalEditMode(false);
  };

  const handleValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentValue(event.target.value);
  };

  const handleOpenQuickOverview = (index: number) => {
    setCurrentOpportunityIndex(index);
  };

  useSaveProject(handleSave);

  const { startup, isSelectedForPilot } = opportunity;
  const startupLogo = findLogo(startup?.files);

  return (
    <StyledTableRow
      key={opportunity.id}
      $isHighlighted={isSelectedForPilot}
      data-testid={`assessment-conclusion-row-${opportunity.id}`}
    >
      <StyledTableCell $isSelectedForPilot={isSelectedForPilot}>
        <Checkbox
          disabled={isSelectionDisabled || !canEdit}
          color="secondary"
          size="small"
          checked={isSelectedForPilot}
          data-testid={`select-for-pilot-${opportunity.id}`}
          onClick={() => handleOpportunityStatusChange(opportunity)}
        />
        <Link target="_blank" href={`/startups/${startup.id}`} color="inherit">
          {startup.name}
        </Link>
      </StyledTableCell>
      <StyledTableCell
        height={theme.spacing(8.5)}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Link
          target="_blank"
          href={`/startups/${startup.id}`}
          height="100%"
          display="flex"
          alignItems="center"
        >
          {startupLogo ? (
            <Box
              component="img"
              sx={{
                width: theme.spacing(8),
                height: theme.spacing(6),
                objectFit: "contain",
              }}
              src={startupLogo}
            />
          ) : (
            <RocketLaunchOutlined color="disabled" />
          )}
        </Link>

        <Box display="flex" gap={theme.spacing(1)} alignItems="center">
          <IconButton
            onClick={() => {
              handleOpenQuickOverview(index);
            }}
            sx={{ mb: 0.5 }}
          >
            <Tooltip title="Open Focus View">
              <LoupeOutlinedIcon
                data-testid="open-focus-view-btn"
                htmlColor={theme.palette.icon.action.main}
                sx={{ fontSize: "20px" }}
              />
            </Tooltip>
          </IconButton>
          {startup.website && (
            <Link
              onClick={(event) => event.stopPropagation()}
              target="_blank"
              href={normalizeUrl(startup.website)}
              height="20px"
            >
              <Launch
                fontSize="small"
                htmlColor={theme.palette.icon.action.main}
                data-testid="startup-link-icon"
              />
            </Link>
          )}
        </Box>
      </StyledTableCell>
      <StyledTableCell>
        {startup.dateFounded?.getFullYear() || "-"}
      </StyledTableCell>
      <StyledTableCell>{startup.billingCountry || "-"}</StyledTableCell>
      {canEdit ? (
        <DecisionRationaleCell
          data-testid={`decision-rationale-cell-${opportunity.id}`}
          onClick={() => {
            setEditDecisionRationale(true);
            setGlobalEditMode(true);
          }}
        >
          {editDecisionRationale ? (
            <StyledDecisionRationaleInput
              label="Decision Rationale"
              data-testid={`decision-rationale-${opportunity.id}`}
              editMode={true}
              autoFocus
              multiline
              value={currentValue}
              onBlur={() => {
                handleSave();
                setEditDecisionRationale(false);
              }}
              inputProps={{
                onFocus: handleFocus,
              }}
              onChange={handleValueChange}
              onKeyDown={handleKeyDown}
            />
          ) : (
            <>
              {!opportunity.assessmentDecision && (
                <ErrorOutlineIcon color="warning" />
              )}
              <Typography width="100%">
                {opportunity.assessmentDecision || ASSESSMENT_DECISION_TEXT}
              </Typography>
            </>
          )}
        </DecisionRationaleCell>
      ) : (
        <DecisionRationaleCell sx={{ cursor: "default" }}>
          {opportunity.assessmentDecision || "--"}
        </DecisionRationaleCell>
      )}
    </StyledTableRow>
  );
}
