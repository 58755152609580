import { Box, Paper, Typography } from "@mui/material";
import theme from "../../../../../theme";
import { ReactElement, useContext, useEffect, useState } from "react";
import { FunnelStage, Project } from "../../../../../Types/Project";
import {
  ProjectTasksCluster,
  ProjectTask as ProjectTaskType,
  ProjectTaskStatus,
} from "../../../../../Types/ProjectTask";
import ProjectTaskProgressBar from "./ProjectTaskProgressBar";
import ProjectTasksHttpService from "../../../../../Http/ProjectTasks/ProjectTasks.http.service";
import { useSnackbar } from "notistack";
import { GlobalLoaderContext } from "../../../../../Context/LoaderContext";
import ProjectTask from "./ProjectTask";
import { capitalizeFirstLetter, getErrorMessage } from "../../../../../utils";
import useRoles from "../../../../../Hooks/useRoles";

interface ProjectTasksSectionProps {
  funnelStage: FunnelStage;
  moveProjectToTheNewStage?: () => void;
  project: Project;
}

export default function ProjectTasksSection(
  props: ProjectTasksSectionProps
): ReactElement {
  const { enqueueSnackbar } = useSnackbar();
  const { setGlobalLoader } = useContext(GlobalLoaderContext);
  const { canEdit } = useRoles(props.project);
  const [projectTaskClusters, setProjectTaskClusters] = useState<
    ProjectTasksCluster[]
  >([]);

  const taskCount =
    projectTaskClusters?.reduce(
      (count, cluster) => count + cluster.tasks.length,
      0
    ) || 0;

  const { tasksToComplete, tasksCompleted } =
    getTasksToComplete(projectTaskClusters);

  useEffect(() => {
    getClusteredProjectTasks();
  }, [props.funnelStage]);

  const updateTask = async (
    status: ProjectTaskStatus,
    taskToUpdate?: ProjectTaskType
  ) => {
    if (!taskToUpdate) return;
    setGlobalLoader(true);
    try {
      await ProjectTasksHttpService.updateProjectTask(taskToUpdate.id, status);
    } catch (error) {
      // eslint-disable-next-line
      const errorMessage = getErrorMessage(error as any);
      return enqueueSnackbar(`Could not update Task: ${errorMessage}`, {
        variant: "error",
      });
    } finally {
      setGlobalLoader(false);
    }
  };

  const getClusteredProjectTasks = async () => {
    try {
      const clusters = await ProjectTasksHttpService.getClusteredProjectTasks(
        props.project.id,
        props.funnelStage
      );
      setProjectTaskClusters(clusters);
    } catch (error) {
      enqueueSnackbar(`Something went wrong with fetching tasks`, {
        variant: "error",
      });
    }
  };

  const currentTask = tasksToComplete[0];
  const previousTask = tasksCompleted[tasksCompleted.length - 1];
  const clusterName = projectTaskClusters.find((cluster) =>
    cluster.tasks.find((task) => task.id === currentTask?.id)
  )?.title;
  const hideBackButton =
    projectTaskClusters[0]?.tasks?.[0]?.id === tasksToComplete[0]?.id;

  return (
    <Paper sx={{ padding: theme.spacing(3) }}>
      <Box flexDirection="column" display="flex">
        <Box justifyContent="space-between" display="flex">
          <Typography variant="h6">Tasks</Typography>
          <Box display="flex">
            <Typography
              color="text.brand.accessibility"
              marginRight={theme.spacing(0.5)}
            >
              {taskCount - tasksToComplete.length}
            </Typography>
            <Typography color={"GrayText"} variant="body1">
              / {taskCount}
            </Typography>
          </Box>
        </Box>
        <Typography color="text.secondary" variant="caption">
          {capitalizeFirstLetter(props.funnelStage)} Phase
        </Typography>
        <ProjectTaskProgressBar
          projectTaskClusters={projectTaskClusters}
          taskCount={taskCount}
          currentTask={currentTask}
        />
        <ProjectTask
          currentTask={currentTask}
          previousTask={previousTask}
          updateTask={updateTask}
          hideBackButton={hideBackButton}
          onRefresh={getClusteredProjectTasks}
          clusterName={clusterName}
          funnelStage={props.funnelStage}
          projectFunnelStage={props.project.funnelStage}
          tasksToComplete={tasksToComplete}
          moveProjectToTheNewStage={props.moveProjectToTheNewStage}
          canEdit={canEdit}
        />
      </Box>
    </Paper>
  );
}

const getTasksToComplete = (taskClusters: ProjectTasksCluster[]) => {
  const tasksToComplete: ProjectTaskType[] = [];
  const tasksCompleted: ProjectTaskType[] = [];
  taskClusters.forEach((cluster) => {
    cluster.tasks.forEach((task) => {
      if (task.status === "NOT_COMPLETED") {
        tasksToComplete.push(task);
      } else {
        tasksCompleted.push(task);
      }
    });
  });
  return { tasksToComplete, tasksCompleted };
};
