import { Box, Button } from "@mui/material";
import { funnelStages, stageScores } from "../../../../Constants/FunnelStages";
import { Project, FunnelStage } from "../../../../Types/Project";
import { capitalizeFirstLetter } from "../../../../utils";
import { ReactElement } from "react";

interface Props {
  projectData: Project;
  activeStep: number;
  setFunnelStage: (funnelStage: FunnelStage) => Promise<void>;
  moveProjectToTheNewStage: () => Promise<void>;
  getNextFunnelStage: () => FunnelStage;
}

export default function FunnelButton(props: Props): ReactElement {
  const isFunnelStageDefined = props.projectData?.funnelStage;
  const isAdoptStage = props.projectData?.funnelStage === "adopt";
  const isCurrentStage =
    props.activeStep === stageScores[props.projectData.funnelStage] - 1;

  return (
    <Box display="flex" gap={2}>
      {isFunnelStageDefined && !isCurrentStage && (
        <Button
          variant="textInvert"
          onClick={() => props.setFunnelStage(funnelStages[props.activeStep])}
          disabled={props.projectData.status === "archived"}
        >
          Move to {capitalizeFirstLetter(funnelStages[props.activeStep])}
        </Button>
      )}
      {isFunnelStageDefined && !isAdoptStage && (
        <Button
          variant="contained"
          onClick={props.moveProjectToTheNewStage}
          disabled={props.projectData.status === "archived"}
        >
          Move to {capitalizeFirstLetter(props.getNextFunnelStage())}
        </Button>
      )}
    </Box>
  );
}
