import { VentureClient, VentureClientDTO } from "../../Types/VentureClient";

export default class VentureClientMapper {
  public static map(ventureClient: VentureClientDTO): VentureClient {
    return {
      ...ventureClient,
      dateCreated: new Date(ventureClient.dateCreated),
    };
  }
}
