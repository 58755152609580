import { Box, Paper, Skeleton, Stack, Typography } from "@mui/material";
import { ReactElement } from "react";
import ContactNameSkeleton from "../../../Contacts/ContactCard/ContactName/ContactNameSkeleton";

export default function VentureClientUnitOverviewSkeleton(): ReactElement {
  return (
    <Stack gap={3}>
      <Typography variant="h6">Venture Client Unit</Typography>
      <Paper component={Stack} p={3} gap={3}>
        <Stack gap={2} width="100%">
          <Box display="flex" justifyContent="space-between">
            <Skeleton width={80} height={40} />
            <Skeleton width={20} height={20} />
          </Box>
          <Stack gap={1}>
            <Skeleton width={64} height={20} />
            <Skeleton width={128} height={12} />
          </Stack>
        </Stack>
        <ContactNameSkeleton />
        <Box display="flex" gap={2} alignItems="center">
          <Skeleton width={64} height={16} />
          <Skeleton width={80} height={12} />
        </Box>
      </Paper>
    </Stack>
  );
}
