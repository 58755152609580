import { IconButton, Menu, MenuItem } from "@mui/material";
import { ReactElement, useState } from "react";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import theme from "../../../../theme";

interface Props {
  archiveFocusArea?: () => void;
  unarchiveFocusArea?: () => void;
  setEditModalOpen: (state: boolean) => void;
  setDeleteModalOpen: (state: boolean) => void;
}

const FocusAreaMenu = (props: Props): ReactElement => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const editOpen = Boolean(anchorEl);

  const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  // Dynamically determine archive/unarchive action
  const isArchived = Boolean(props.unarchiveFocusArea);
  const archiveActionLabel = isArchived ? "Unarchive" : "Archive";
  const archiveActionFunction = isArchived
    ? props.unarchiveFocusArea
    : props.archiveFocusArea;

  return (
    <>
      <IconButton onClick={handleMenuOpen} data-testid="focus-area-menu-button">
        <MoreVertOutlinedIcon
          fontSize="inherit"
          htmlColor={theme.palette.icon.mediumEmphasis}
        />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={editOpen}
        onClose={handleMenuClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        sx={{
          "& ul": {
            minWidth: "100px",
          },
        }}
      >
        <MenuItem
          onClick={(event) => {
            event.stopPropagation();
            props.setEditModalOpen(true);
            setAnchorEl(null);
          }}
          data-testid="rename-button"
          sx={{
            paddingInline: 0.7,
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          Rename
        </MenuItem>

        {archiveActionFunction && (
          <MenuItem
            onClick={(event) => {
              event.stopPropagation();
              setAnchorEl(null);
              archiveActionFunction();
            }}
            data-testid="archive-button"
            sx={{
              paddingInline: 0.7,
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            {archiveActionLabel}
          </MenuItem>
        )}

        <MenuItem
          onClick={(event) => {
            event.stopPropagation();
            props.setDeleteModalOpen(true);
            setAnchorEl(null);
          }}
          data-testid="delete-button"
          sx={{
            paddingInline: 0.7,
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          Delete
        </MenuItem>
      </Menu>
    </>
  );
};

export default FocusAreaMenu;
