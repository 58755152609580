import {
  Box,
  Button,
  MenuItem,
  Stack,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import { Fragment, ReactElement, useState } from "react";
import { LeadProject } from "../../../Types/LeadProject";
import { useSnackbar } from "notistack";
import { FileHttpService } from "../../../Http/File/File.http.service";
import theme from "../../../theme";
import { isProjectBmwCheck } from "../../../utils";
import { FileDropzone } from "../../ProjectDetails/SharedComponents/ProjectOverview/FilesComponent/Dropzone";
import { InsertDriveFileOutlined, Link } from "@mui/icons-material";

interface LeadProjectFileUploadSectionProps {
  leadProject: LeadProject;
  projectFileTypes: string[];
  opportunityFileTypes: string[];
  handleSave: () => void;
  setLastUploadFile: (id: number) => void;
}

export function LeadProjectFileUploadSection(
  props: LeadProjectFileUploadSectionProps
): ReactElement {
  const { enqueueSnackbar } = useSnackbar();
  const [fileType, setFileType] = useState<string>("");
  const [selectedOpportunityId, setSelectedOpportunityId] = useState(0);
  const [toggleValue, setToggleValue] = useState<"upload" | "link">("upload");
  const [fileLink, setFileLink] = useState<string>("");

  const getFileType = (): string => {
    if (fileType === "Other") {
      return "Other";
    } else {
      return fileType;
    }
  };

  const isOpportunityFile = (): boolean => {
    return !!props.opportunityFileTypes?.includes(fileType);
  };

  const showOpportunityDropdown = (): boolean => {
    return !!props.opportunityFileTypes?.includes(fileType);
  };

  const getLinkableId = (): number => {
    if (isOpportunityFile()) {
      return selectedOpportunityId;
    } else {
      return props.leadProject.id;
    }
  };

  const uploadFile = async (file: File): Promise<void> => {
    try {
      await FileHttpService.upload(
        file,
        getLinkableId(),
        isOpportunityFile() ? "leadOpportunity" : "leadProject",
        getFileType()
      ).catch(() => {
        enqueueSnackbar("File can not be uploaded", {
          variant: "error",
        });
      });

      if (isOpportunityFile()) {
        props.setLastUploadFile(selectedOpportunityId);
      }

      resetAddFile();
      props.handleSave();
    } catch (error) {
      enqueueSnackbar("File can not be uploaded", {
        variant: "error",
      });
    }
  };

  const createFileAsLink = async (): Promise<void> => {
    try {
      await FileHttpService.createFileAsLink(
        isOpportunityFile() ? "leadOpportunity" : "leadProject",
        getLinkableId(),
        getFileType(),
        fileLink
      );
      resetAddFile();
      props.handleSave();
    } catch (error) {
      enqueueSnackbar("File can not be uploaded", {
        variant: "error",
      });
    }
  };

  const handleToggleTypeChange = (
    _: React.MouseEvent<HTMLElement>,
    type: "upload" | "link"
  ) => {
    if (type) setToggleValue(type);
  };

  const resetAddFile = () => {
    setFileType("");
    setFileLink("");
    setSelectedOpportunityId(0);
    setToggleValue("upload");
  };

  const showToggleButtons = (): boolean => {
    if (!fileType) {
      return false;
    } else if (!showOpportunityDropdown()) {
      return true;
    }
    return !!(showOpportunityDropdown() && selectedOpportunityId);
  };

  const showDropZone = showToggleButtons() && toggleValue === "upload";
  const showAddLink = showToggleButtons() && toggleValue === "link";

  return (
    <Fragment>
      <Typography variant="subtitle1">Add File</Typography>
      <TextField
        label="File Type to Add"
        name="File Type to Add"
        id="fileType"
        variant="outlined"
        select
        fullWidth
        value={fileType}
        onChange={(e) => setFileType(e.target.value as string)}
      >
        {props.opportunityFileTypes.map((option) => (
          <MenuItem value={option} key={option}>
            {option}
          </MenuItem>
        ))}
        {props.projectFileTypes.map((option) => (
          <MenuItem value={option} key={option}>
            {option}
          </MenuItem>
        ))}
      </TextField>
      {showOpportunityDropdown() && (
        <TextField
          label="Select Startup"
          id="startupSelect"
          variant="outlined"
          select
          fullWidth
          value={selectedOpportunityId || ""}
          sx={{ marginTop: theme.spacing(2) }}
          onChange={(e) => setSelectedOpportunityId(parseInt(e.target.value))}
        >
          {props.leadProject.opportunities.map((opportunity) => (
            <MenuItem
              value={opportunity.id}
              key={opportunity.id}
              data-cy={
                "MenuItem-" + opportunity.startup.name.replaceAll(" ", "_")
              }
            >
              {opportunity.startup.name}
            </MenuItem>
          ))}
        </TextField>
      )}
      {showToggleButtons() && (
        <ToggleButtonGroup
          value={toggleValue}
          onChange={handleToggleTypeChange}
          color="secondary"
          exclusive
          sx={{ width: "100%" }}
        >
          <ToggleButton
            sx={{ gap: theme.spacing(0.5) }}
            fullWidth
            value="upload"
          >
            <InsertDriveFileOutlined sx={{ fontSize: "16px" }} /> Upload File
          </ToggleButton>
          <ToggleButton sx={{ gap: theme.spacing(0.5) }} fullWidth value="link">
            <Link sx={{ fontSize: "16px" }} /> Add Link
          </ToggleButton>
        </ToggleButtonGroup>
      )}
      {showDropZone && (
        <FileDropzone
          addFile={uploadFile}
          isBmwProject={isProjectBmwCheck(props.leadProject.ventureClient.id)}
        />
      )}
      {showAddLink && (
        <Stack gap={2}>
          <TextField
            label="Add Link"
            value={fileLink}
            inputProps={{ "data-testid": "add-link" }}
            onChange={(e) => setFileLink(e.target.value)}
          />
          <Box display="flex" gap={2}>
            <Button fullWidth onClick={resetAddFile}>
              Cancel
            </Button>
            <Button
              fullWidth
              onClick={createFileAsLink}
              variant="contained"
              disabled={!fileLink}
            >
              Save
            </Button>
          </Box>
        </Stack>
      )}
    </Fragment>
  );
}
