import { NewReleasesOutlined, Verified } from "@mui/icons-material";
import { Box, Stack, Typography } from "@mui/material";
import { ReactElement } from "react";
import theme from "../../../../theme";

interface QualityVerificationProgressProps {
  progress: number;
  failed: number;
  target: number;
}

export default function QualityVerificationProgress({
  progress,
  failed,
  target,
}: QualityVerificationProgressProps): ReactElement {
  const noInputProvided = progress === 0 && failed === 0;
  const allChecked = progress + failed === target;
  const isVerified = progress === target && failed === 0;
  const hasFailures = failed > 0;

  const lastIndex = allChecked ? target - 1 : progress + failed - 1;

  const message = noInputProvided
    ? "Select criteria to begin assessment."
    : isVerified
    ? "Startup is ready for Venture Client projects."
    : hasFailures
    ? "Startup not suitable for Venture Client projects."
    : "Venture Client readiness not yet determined.";

  return (
    <Stack alignItems="center" gap={0.5} marginTop={2}>
      <Typography variant="caption" color="text.mediumEmphasis">
        {message}
      </Typography>
      <Box display="flex" width="100%" gap={0.25}>
        {[...Array(target)].map((_, index) => (
          <Stack key={index} width="100%" gap={0.5}>
            <Box
              height="2px"
              bgcolor={
                index < progress
                  ? theme.palette.success.main
                  : index < progress + failed
                  ? theme.palette.warning.main
                  : theme.palette.surface.cool
              }
              borderRadius={theme.shape.radius.full}
            />
            <Box
              ml="auto"
              position="relative"
              right={theme.spacing(-1)}
              fontSize="16px"
              display="flex"
              height="16px"
            >
              {index === lastIndex ? (
                <>
                  {isVerified ? (
                    <Verified color="success" fontSize="inherit" />
                  ) : (
                    <NewReleasesOutlined
                      color={hasFailures ? "warning" : "disabled"}
                      fontSize="inherit"
                    />
                  )}
                </>
              ) : null}
            </Box>
          </Stack>
        ))}
      </Box>
    </Stack>
  );
}
