import { Box } from "@mui/material";
import { LeadProject } from "../../Types/LeadProject";
import ProjectLeadKanbanCard from "./LeadProjectKanbanCard";
import { memo } from "react";

const LeadProjectCards = ({
  leadProjects,
  refreshLeadProjectData,
}: {
  leadProjects: LeadProject[];
  refreshLeadProjectData: () => void;
}) => {
  return (
    <>
      {leadProjects.map((leadProject) => (
        <Box key={leadProject.id} width="100%">
          <ProjectLeadKanbanCard
            leadProject={leadProject}
            refreshLeadProjectData={refreshLeadProjectData}
          />
        </Box>
      ))}
    </>
  );
};

export const MemoizedLeadProjectCards = memo(LeadProjectCards);
