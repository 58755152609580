import { Box, Chip, Stack, styled, Typography } from "@mui/material";
import { ReactElement } from "react";
import { Objective } from "../../../../Types/Objective";
import theme from "../../../../theme";
import { Cancel, CheckCircle } from "@mui/icons-material";

const ObjectiveCardWrapper = styled(Stack)({
  gap: theme.spacing(0.5),
  backgroundColor: theme.palette.background.default,
  padding: theme.spacing(2),
  borderRadius: theme.shape.radius.minimal,
  width: "100%",
});

interface ObjectivesOverviewProps {
  objectives: Objective[];
}

export default function ObjectivesOverview({
  objectives,
}: ObjectivesOverviewProps): ReactElement {
  const fulfilledObjectives = objectives.filter((o) => o.fulfilled);
  const notFulfilledObjectives = objectives.filter(
    (o) => o.fulfilled === false
  );
  const objectivesInEvaluation = objectives.filter((o) => o.fulfilled === null);

  const successIcon = <CheckCircle fontSize="small" color="success" />;
  const failedIcon = <Cancel fontSize="small" color="error" />;

  return (
    <Stack gap={2}>
      <Box display="flex" gap={1} alignItems="center">
        <Typography variant="subtitle1">Objectives</Typography>
        <Chip
          label={objectives.length}
          variant="counter"
          color="info"
          data-testid="objectives-total"
        />
      </Box>
      <Box display="flex" gap={2}>
        <ObjectiveCard
          label="In Evaluation"
          value={objectivesInEvaluation.length}
          data-testid="objectives-evaluation"
        />
        <ObjectiveCard
          label="Achieved"
          value={fulfilledObjectives.length}
          icon={successIcon}
          data-testid="objectives-achieved"
        />
        <ObjectiveCard
          label="Not Achieved"
          value={notFulfilledObjectives.length}
          icon={failedIcon}
          data-testid="objectives-not-achieved"
        />
      </Box>
    </Stack>
  );
}

function ObjectiveCard({
  value,
  label,
  icon,
  "data-testid": dataTestId,
}: {
  value: number;
  label: string;
  icon?: ReactElement;
  "data-testid"?: string;
}) {
  return (
    <ObjectiveCardWrapper data-testid={dataTestId}>
      <Box gap={0.5} display="flex" alignItems="center">
        <Typography variant="caption" color="text.mediumEmphasis">
          {label}
        </Typography>
        {icon}
      </Box>
      <Typography variant="subtitle2">{value}</Typography>
    </ObjectiveCardWrapper>
  );
}
