import { Box, IconButton, styled, Typography } from "@mui/material";
import { MutableRefObject, ReactElement, useState } from "react";
import { ClientContactDTO } from "../../../Types/ClientContact";
import ManageClientContactModal from "./ManageClientContactModal";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import CustomDataGrid from "../../ProjectList/CustomDataGrid";
import { GridApiPremium } from "@mui/x-data-grid-premium/models/gridApiPremium";
import { DataGridPremiumProps, GridColDef } from "@mui/x-data-grid-premium";
import theme from "../../../theme";
import { useSnackbar } from "notistack";
import { EditOutlined } from "@mui/icons-material";

interface ClientsDataGridProps extends Omit<DataGridPremiumProps, "columns"> {
  refreshClientContact: () => void;
  apiRef: MutableRefObject<GridApiPremium>;
  ventureClientId: number;
  canEdit: boolean;
}

type GridColumn = GridColDef & {
  hidden?: boolean;
};

const StyledButton = styled(IconButton)(() => ({
  display: "none",
  color: theme.palette.icon.action.main,
  padding: theme.spacing(0.5),
}));

const GridActionItem = styled(Box)({
  display: "flex",
  alignItems: "center",
  overflow: "hidden",
  width: "100%",
});

export default function ClientsDataGrid({
  apiRef,
  refreshClientContact,
  ventureClientId,
  canEdit,
  ...defaultProps
}: ClientsDataGridProps): ReactElement {
  const { enqueueSnackbar } = useSnackbar();

  const [editModalOpen, setEditModalOpen] = useState<boolean>(false);

  const [selectedClientContact, setSelectedClientContact] =
    useState<ClientContactDTO>();

  const handleEditModalOpen = (clientContact: ClientContactDTO) => {
    setEditModalOpen(true);
    setSelectedClientContact(clientContact);
  };

  const handleClientContactRefresh = () => {
    refreshClientContact();
  };

  const copyToClipboard = (
    e: React.MouseEvent<HTMLElement>,
    value: string,
    entity: string
  ) => {
    e.stopPropagation();
    navigator.clipboard.writeText(value);
    enqueueSnackbar(entity + " successfully copied to clipboard", {
      variant: "success",
    });
  };

  const baseColumns: GridColumn[] = [
    {
      flex: 1,
      field: "name",
      headerName: "Name",
    },
    {
      flex: 1,
      field: "departmentCode",
      headerName: "Department Code",
    },
    {
      flex: 1,
      field: "title",
      headerName: "Title",
    },
    {
      flex: 1,
      field: "businessUnit.name",
      headerName: "Organizational Unit",
      valueGetter: (_value, row) => {
        if (!row.businessUnit) {
          return "";
        }
        return `${row.businessUnit.name}`;
      },
    },
    {
      flex: 1,
      field: "email",
      headerName: "Email",
      renderCell: (params) => (
        <>
          {params.row.email && (
            <GridActionItem
              onClick={(e) => {
                copyToClipboard(e, params.row.email, "Email address");
              }}
            >
              <StyledButton className="copy-icon">
                <ContentCopyOutlinedIcon sx={{ fontSize: "1rem" }} />
              </StyledButton>
              <Typography
                overflow="hidden"
                textOverflow="ellipsis"
                color="text.action.main"
                noWrap
              >
                {params.row.email}
              </Typography>
            </GridActionItem>
          )}
        </>
      ),
    },
    {
      flex: 1,
      field: "phone",
      headerName: "Phone",
      renderCell: (params) => (
        <>
          {params.row.phone && (
            <GridActionItem
              onClick={(e) => {
                copyToClipboard(e, params.row.phone, "Phone");
              }}
            >
              <StyledButton className="copy-icon">
                <ContentCopyOutlinedIcon sx={{ fontSize: "1rem" }} />
              </StyledButton>
              <Typography
                overflow="hidden"
                textOverflow="ellipsis"
                color="text.action.main"
                noWrap
              >
                {params.row.phone}
              </Typography>
            </GridActionItem>
          )}
        </>
      ),
    },
    {
      flex: 0.1,
      field: "edit",
      headerName: "",
      hideable: false,
      type: "actions",
      hidden: !canEdit,
      renderCell: (params) => (
        <>
          {params.row.id && (
            <GridActionItem
              onClick={() => {
                handleEditModalOpen(params.row);
              }}
            >
              <IconButton size="small">
                <EditOutlined fontSize="small" />
              </IconButton>
            </GridActionItem>
          )}
        </>
      ),
    },
  ];

  const columns = baseColumns.filter((column) => !column.hidden);

  return (
    <>
      <CustomDataGrid
        {...defaultProps}
        apiRef={apiRef}
        columns={columns}
        noRowsText="No Contact Found"
      />
      {selectedClientContact && editModalOpen && (
        <ManageClientContactModal
          contact={selectedClientContact}
          handleSave={handleClientContactRefresh}
          setModalOpen={setEditModalOpen}
          modalOpen={editModalOpen}
          ventureClientId={ventureClientId}
        />
      )}
    </>
  );
}
