import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  TextField,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { ReactElement, useState } from "react";

interface EditCalculationModalProps {
  currentName: string;
  handleModalClose: () => void;
  handleSave: (name: string) => void;
  modalOpen: boolean;
}

export default function EditCalculationModal({
  currentName,
  handleModalClose,
  handleSave,
  modalOpen,
}: EditCalculationModalProps): ReactElement {
  const { enqueueSnackbar } = useSnackbar();
  const [name, setName] = useState(currentName);

  const handleSaveButton = () => {
    if (!name) {
      enqueueSnackbar("Please Enter Name", {
        variant: "error",
      });
      return;
    }

    handleSave(name);
    handleModalClose();
  };
  return (
    <Dialog open={modalOpen} fullWidth>
      <DialogTitle>Edit Calculation Name</DialogTitle>
      <DialogContent>
        <FormControl fullWidth>
          <TextField
            label="Name"
            required
            value={name}
            error={!name}
            onChange={(e) => setName(e.target.value)}
          ></TextField>
          <FormHelperText>
            Provide a descriptive name for this calculation.
          </FormHelperText>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleModalClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleSaveButton} variant="contained">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}
