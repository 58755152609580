import { Box, Typography, styled } from "@mui/material";
import { ReactElement, useState } from "react";
import theme from "../../../../../theme";
import { ClientContactDTO } from "../../../../../Types/ClientContact";
import DetailsClientContactModal from "../../../../Contacts/ClientComponents/DetailsClientcontactModal/DetailsClientContactModal";
import { StartupContactDTO } from "../../../../../Types/StartupContact";
import DetailsStartupContactModal from "../../../../Contacts/StartupComponents/DetailsStartupContactModal/DetailsStartupContactModal";
import { useHistory } from "react-router-dom";

interface ImageContainerProps {
  src: string;
}

const ImageContainer = styled(Box)<ImageContainerProps>(() => ({
  border: `1px solid ${theme.palette.borderOutline.main}`,
  borderRadius: theme.shape.radius.minimal,
  objectFit: "contain",
  height: 62,
  width: 77.5,
  padding: theme.spacing(1),
}));

type MatchCardProps = {
  title: string;
  subtitle1?: string;
  subtitle2?: string;
  src?: string;
  handleSave: () => void;
} & (
  | {
      isStartup?: false;
      contact?: ClientContactDTO;
    }
  | {
      isStartup: true;
      id: number;
      contact?: StartupContactDTO;
    }
);

export default function MatchCard(props: MatchCardProps): ReactElement {
  const history = useHistory();
  const [openContactCard, setOpenContactCard] = useState(false);

  const handleEditSave = () => {
    props.handleSave();
  };

  const handleBoxClick = () => {
    if (props.contact) {
      setOpenContactCard(true);
    } else if (!props.contact && props.isStartup && props.id) {
      history.push(`/startups/${props.id}`);
    }
  };

  const handleLogoClick = (e: React.MouseEvent<HTMLElement>) => {
    if (props.isStartup && props.id) {
      e.stopPropagation();
      history.push(`/startups/${props.id}`);
    }
  };

  const handleContactClick = (e: React.MouseEvent<HTMLElement>) => {
    if (props.contact) {
      e.stopPropagation();
      setOpenContactCard(true);
    }
  };

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        gap={theme.spacing(2)}
        onClick={handleBoxClick}
        sx={{ cursor: "pointer" }}
      >
        <ImageContainer
          component="img"
          src={props.src || "/images/logo_placeholder.svg"}
          onClick={handleLogoClick}
        />
        <Box onClick={handleContactClick}>
          <Typography variant="subtitle2">{props.title}</Typography>
          {props.subtitle1 && (
            <Typography variant="body2" color="mediumEmphasis">
              {props.subtitle1}
            </Typography>
          )}
          {props.subtitle2 && (
            <Typography variant="body2" color="mediumEmphasis">
              {props.subtitle2}
            </Typography>
          )}
        </Box>
      </Box>
      {openContactCard && props.contact && !props.isStartup && (
        <DetailsClientContactModal
          setModalOpen={setOpenContactCard}
          modalOpen={openContactCard}
          contact={props.contact}
          handleSave={handleEditSave}
          mustFetch
        />
      )}
      {openContactCard && props.contact && props.isStartup === true && (
        <DetailsStartupContactModal
          setModalOpen={setOpenContactCard}
          modalOpen={openContactCard}
          contact={props.contact}
          handleSave={handleEditSave}
          mustFetch
        />
      )}
    </>
  );
}
