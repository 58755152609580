import { Box, List, MenuItem, TextField, Typography } from "@mui/material";
import { ReactElement, useEffect, useState } from "react";
import { FileHttpService } from "../../../../../Http/File/File.http.service";
import { FileDropzone } from "./Dropzone";
import { File as DocumentFile } from "../../../../../Types/File";
import { Startup } from "../../../../../Types/Startup";
import { FilesListSection } from "./FilesListSection";
import { useSnackbar } from "notistack";
import useRoles from "../../../../../Hooks/useRoles";

interface FileSectionProps {
  startup: Startup;
  fileTypes: string[];
  handleSave: () => void;
  imageFileTypes: string[];
}

export function StartupFileSection(props: FileSectionProps): ReactElement {
  const { isViewer } = useRoles();
  const { enqueueSnackbar } = useSnackbar();
  const [fileType, setFileType] = useState<string>("");
  const [editingFileId, setEditingFileId] = useState<number>();
  const [filesToDelete, setFilesToDelete] = useState<number[]>([]);
  const [localFiles, setLocalFiles] = useState<DocumentFile[]>([]);

  useEffect(() => {
    setLocalFiles(props.startup.files);
  }, [props.startup.files]);

  const saveChanges = async (): Promise<void> => {
    for (const fileId of filesToDelete) {
      await FileHttpService.delete(fileId);
    }
    for (const file of localFiles) {
      await FileHttpService.update(file);
    }
    setFilesToDelete([]);
    setEditingFileId(undefined);
    props.handleSave();
  };

  const deleteFile = async (fileId: number) => {
    setLocalFiles((prevState) =>
      prevState.filter((file) => file.id !== fileId)
    );
    await FileHttpService.delete(fileId);
    props.handleSave();
  };

  const handleFileRename = (id: number, name: string) => {
    const files = JSON.parse(JSON.stringify(localFiles));

    const changedFile = files.find((file: DocumentFile) => file.id === id);
    changedFile.name = name;
    setLocalFiles(files);
  };

  const uploadFile = async (file: File) => {
    try {
      if (fileType !== "") {
        await FileHttpService.upload(
          file,
          props.startup.id,
          "startup",
          fileType
        );
        setFileType("");
        props.handleSave();
      }
    } catch (error) {
      enqueueSnackbar("File can not be uploaded", {
        variant: "error",
      });
    }
  };
  return (
    <Box display="flex" flexDirection="column" gap={2}>
      <Typography variant={"h6"}>Files</Typography>
      <Box display="flex" flexDirection="column" gap={4}>
        <List dense>
          <FilesListSection
            files={localFiles}
            mapId={props.startup.id}
            deleteFile={deleteFile}
            editingFileId={editingFileId}
            setEditingFileId={setEditingFileId}
            handleFileRename={handleFileRename}
            handleSave={saveChanges}
          />
        </List>
        {!editingFileId && !isViewer && (
          <Box key="1" display="flex" flexDirection="column" gap={2}>
            <Typography variant="subtitle1">Upload a file</Typography>
            <TextField
              label="File Type to Upload"
              id="fileType"
              variant="outlined"
              key="2"
              select
              fullWidth
              value={fileType}
              onChange={(e) => setFileType(e.target.value as string)}
            >
              {props.fileTypes.map((option) => (
                <MenuItem value={option} key={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>
            {fileType !== "" && (
              <FileDropzone
                key="3"
                addFile={uploadFile}
                isImage={props.imageFileTypes.includes(fileType)}
              />
            )}
          </Box>
        )}
      </Box>
    </Box>
  );
}
