import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  TextField,
  DialogActions,
  Button,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { ReactElement, useState } from "react";
import { useHistory } from "react-router-dom";
import { StartupHttpService } from "../../../Http/Startup/Startup.http.service";
import theme from "../../../theme";

interface DeleteConfirmationModalProps {
  modalOpen: boolean;
  setModalOpen: (state: boolean) => void;
  startupId: number;
  startupName: string;
}

const DeleteConfirmationModal = (
  props: DeleteConfirmationModalProps
): ReactElement => {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [confirmationText, setConfirmationText] = useState("");

  const handleDelete = async () => {
    try {
      await StartupHttpService.deleteStartup(props.startupId);
      history.push("/startups");
      // eslint-disable-next-line
    } catch (error: any) {
      enqueueSnackbar(error.response.data.message, {
        variant: "error",
      });
    }
  };
  return (
    <Dialog
      fullWidth
      open={props.modalOpen}
      onClose={() => props.setModalOpen(false)}
      data-testid="delete-startup-confirmation-modal"
      PaperProps={{
        sx: {
          gap: theme.spacing(4),
        },
      }}
    >
      <DialogTitle>Are you sure you want to delete this startup?</DialogTitle>
      <DialogContent>
        <Typography>You are about to delete:</Typography>
        <Typography py={1} fontWeight="bold">
          {props.startupName}
        </Typography>
        <Typography>
          This will permanently delete this company from the startup database.
        </Typography>

        <TextField
          data-testid="confirmation-text-field"
          placeholder="Type startup name to confirm"
          variant="outlined"
          value={confirmationText}
          sx={{ mt: 2, width: "400px" }}
          onChange={(event) => {
            setConfirmationText(event.target.value);
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button
          data-testid="confirmation-cancel-button"
          color="inherit"
          onClick={() => props.setModalOpen(false)}
        >
          Cancel
        </Button>
        <Button
          data-testid="confirmation-delete-button"
          variant="contained"
          color="error"
          disabled={confirmationText !== props.startupName}
          onClick={handleDelete}
        >
          Delete Startup
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteConfirmationModal;
