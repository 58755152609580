import { ReactElement, useContext } from "react";
import { ResponsiveBar } from "@nivo/bar";
import theme from "../../../theme";
import { Box, Typography, styled, Chip, Stack } from "@mui/material";
import { BarItemProps } from "@nivo/bar";
import { ProjectCount, ProjectCountByStage } from "../../../Types/Analytics";
import { animated, useSpring } from "@react-spring/web";
import SkeletonWrapper from "../../UI/SkeletonWrapper";
import { GlobalLoaderContext } from "../../../Context/LoaderContext";
import WidgetWrapper from "./WidgetWrapper";

const ChartWrapper = styled(Box)(() => ({
  width: "100%",
  height: 160,
  marginBottom: theme.spacing(1),
  display: "flex",
  scale: "1.16",
  justifyContent: "center",
  alignItems: "center",
}));

const Header = styled(Box)(() => ({
  display: "flex",
  gap: theme.spacing(1),
  alignItems: "center",
}));

const CustomBarComponent = (props: BarItemProps<ProjectCount>) => {
  const {
    bar,
    borderRadius,
    borderWidth,
    isFocusable,
    ariaLabel,
    ariaLabelledBy,
    ariaDescribedBy,
  } = props;

  const springs = useSpring({
    from: { width: 0, height: bar.height },
    to: {
      width: bar.width,
      height: bar.height,
    },
  });

  const borderColors = [
    theme.palette.chart.light.primary,
    theme.palette.chart.medium.secondary,
    theme.palette.chart.medium.primary,
    theme.palette.chart.dark.secondary,
    theme.palette.chart.dark.primary,
  ];

  return (
    <animated.g id={`bar-${bar.data.data.id}`}>
      <animated.rect
        style={{ ...springs }}
        clipPath="inset(0 0 8px 0)"
        transform="translate(0,8)"
        rx={borderRadius}
        ry={borderRadius}
        x={bar.x}
        y={bar.y}
        width={bar.width}
        height={bar.height}
        fill={bar.data.fill ?? bar.color}
        strokeWidth={borderWidth}
        stroke={borderColors[bar.data.index]}
        strokeOpacity={0.2}
        tabIndex={isFocusable ? 0 : undefined}
        aria-label={ariaLabel ? ariaLabel(bar.data) : undefined}
        aria-labelledby={ariaLabelledBy ? ariaLabelledBy(bar.data) : undefined}
        aria-describedby={
          ariaDescribedBy ? ariaDescribedBy(bar.data) : undefined
        }
      />
      <animated.text
        x={bar.x + bar.width / 2}
        y={bar.y - 6}
        textAnchor="middle"
        style={{ ...theme.typography.overline, fontWeight: 600 }}
      >
        {bar.data.value}
      </animated.text>
      <animated.text
        x={bar.x + bar.width / 2}
        y={bar.y + bar.height + 20}
        dominantBaseline="central"
        textAnchor="middle"
        style={{ ...theme.typography.overline, fontSize: "0.55rem" }}
      >
        {bar.data.data.id}
      </animated.text>
    </animated.g>
  );
};

interface TotalProjectsProps {
  projectCount: ProjectCountByStage;
}

function TotalProjects(props: TotalProjectsProps): ReactElement {
  const { globalLoader } = useContext(GlobalLoaderContext);
  const stages = ["Discover", "Assess", "Buy", "Pilot", "Adopt"];

  const totalProjectsCount =
    props.projectCount.discover.total +
    props.projectCount.assess.total +
    props.projectCount.buy.total +
    props.projectCount.pilot.total +
    props.projectCount.adopt.total;

  return (
    <WidgetWrapper data-testid="total-projects" filename="Total Projects">
      <SkeletonWrapper isLoading={globalLoader}>
        <Header>
          <Typography variant="h6">Total Projects</Typography>
          <Chip variant="counter" color="info" label={totalProjectsCount} />
        </Header>
      </SkeletonWrapper>
      <ChartWrapper data-testid="total-projects-chart">
        {globalLoader ? (
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-evenly"
            alignItems="center"
            width="100%"
          >
            {stages.map((label) => (
              <Stack key={label} gap={1}>
                {[100, 8].map((height, index) => (
                  <SkeletonWrapper
                    key={index}
                    isLoading={globalLoader}
                    width={56}
                    height={height}
                  />
                ))}
              </Stack>
            ))}
          </Box>
        ) : (
          <ResponsiveBar
            animate
            padding={0.42}
            enableGridY={false}
            enableGridX={false}
            borderRadius={theme.shape.borderRadius}
            borderWidth={8}
            enableLabel={false}
            colorBy={"indexValue"}
            colors={[
              theme.palette.chart.light.primary,
              theme.palette.chart.medium.secondary,
              theme.palette.chart.medium.primary,
              theme.palette.chart.dark.secondary,
              theme.palette.chart.dark.primary,
            ]}
            barComponent={(barProps) => <CustomBarComponent {...barProps} />}
            axisLeft={null}
            axisBottom={null}
            keys={["value"]}
            data={[
              {
                label: theme.palette.chart.light.primary,
                id: "Discover",
                value: props.projectCount.discover.total,
              },
              {
                label: theme.palette.chart.medium.secondary,
                id: "Assess",
                value: props.projectCount.assess.total,
              },
              {
                label: theme.palette.chart.medium.primary,
                id: "Buy",
                value: props.projectCount.buy.total,
              },
              {
                label: theme.palette.chart.dark.secondary,
                id: "Pilot",
                value: props.projectCount.pilot.total,
              },
              {
                label: theme.palette.chart.dark.primary,
                id: "Adopt",
                value: props.projectCount.adopt.total,
              },
            ]}
            margin={{
              left: 0,
              bottom: 30,
              top: 24,
            }}
          />
        )}
      </ChartWrapper>
    </WidgetWrapper>
  );
}

export default TotalProjects;
