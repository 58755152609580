import { useEffect, useState } from "react";

interface ReturnType {
  remainingSessionTime: number;
  setRemainingSessionTime: (value: number) => void;
}

interface Props {
  signedIn: boolean;
  handleSignOut: () => void;
}

const SESSION_TIMEOUT_THRESHOLD = 480; // set in AWS Cognito

function useSessionCheck(props: Props): ReturnType {
  const [remainingSessionTime, setRemainingSessionTime] = useState(
    SESSION_TIMEOUT_THRESHOLD
  );

  const checkRefreshTokenExpiracy = async () => {
    const sessionStartTime = window.localStorage.getItem("sessionStartTime");
    if (!sessionStartTime) return;

    const currentTime = new Date().getTime();
    const elapsedTokenTime = Math.floor(
      (currentTime - parseInt(sessionStartTime)) / 60000
    );

    if (elapsedTokenTime >= 450) {
      setRemainingSessionTime(SESSION_TIMEOUT_THRESHOLD - elapsedTokenTime);
    }

    if (elapsedTokenTime >= SESSION_TIMEOUT_THRESHOLD) {
      props.handleSignOut();
    }
  };

  useEffect(() => {
    const tokenIssueTime = window.localStorage.getItem("sessionStartTime");

    if (tokenIssueTime) {
      const interval = setInterval(checkRefreshTokenExpiracy, 1000);
      return () => clearInterval(interval);
    }
  }, [props.signedIn]);

  return {
    remainingSessionTime,
    setRemainingSessionTime,
  };
}

export default useSessionCheck;
