import React, { Fragment, useState, useEffect, ChangeEvent } from "react";
import PageHeader from "../UI/PageHeader";
import { Box, Container, Pagination } from "@mui/material";
import theme from "../../theme";
import ProblemCard from "./ProblemCard";
import { ProblemsHttpService } from "../../Http/Problems/Problems.http.service";
import mockImage from "../../assets/problem-discovery-mock-img.png";
import { Link } from "react-router-dom";
import { getErrorMessage } from "../../utils";
import { AxiosError } from "axios";
import SearchBar from "../UI/SearchBar";
import { Problem } from "../../Types/Problem";

const baseS3Url =
  "https://vclos-frontend.s3.eu-central-1.amazonaws.com/problems/";

const ProblemDiscovery: React.FC = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [problems, setProblems] = useState<Problem[]>([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    document.title = `Problem Discovery - Venture Client Platform`;
  }, []);

  useEffect(() => {
    fetchProblems(searchQuery, page);
  }, [searchQuery, page]);

  const fetchProblems = async (query: string, pageNumber: number) => {
    try {
      const response = await ProblemsHttpService.getAllProblems(
        pageNumber,
        query
      );

      setProblems(response.data);
      setTotalPages(response.totalPages);

      window.scrollTo({ behavior: "smooth", left: 0, top: 0 });
    } catch (error) {
      getErrorMessage(error as AxiosError);
    }
  };

  const handleSearchChange = (value: string) => {
    setSearchQuery(value);
    setPage(1);
  };

  const handlePageChange = (_event: ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  return (
    <Fragment>
      <PageHeader
        title="Which problem do you want to solve next?"
        subtitle="Identify new problems to tackle in Venture Client projects."
      />

      <Container>
        <Box width="40%" m={theme.spacing(6, "auto", 5)}>
          <SearchBar
            searchValue={searchQuery}
            handleValueChange={handleSearchChange}
            entity="problem"
          />
        </Box>

        <Box
          display="grid"
          gap={theme.spacing(3)}
          gridTemplateColumns="repeat(auto-fill, minmax(260px, 1fr))"
          justifyContent="center"
          alignItems="start"
        >
          {problems.map((problem) => (
            <Link
              target="_blank"
              to={`problem-discovery/${problem.id}`}
              key={problem.id}
              style={{ textDecoration: "none" }}
            >
              <ProblemCard
                name={problem.name}
                shortDescription={problem.shortDescription}
                image={
                  problem.imageUrl != null
                    ? baseS3Url + problem.imageUrl
                    : mockImage
                }
              />
            </Link>
          ))}
        </Box>

        <Box mt={5} display="flex" justifyContent="flex-end">
          <Pagination
            sx={{
              marginBlock: 5,
              display: "flex",
              justifyContent: "flex-end",
              "& .MuiPaginationItem-root": {
                "&.Mui-selected": {
                  color: theme.palette.background.paper,
                  background: theme.palette.brand.accessibility,
                },
              },
            }}
            size="medium"
            shape="rounded"
            count={totalPages}
            page={page}
            onChange={handlePageChange}
          />
        </Box>
      </Container>
    </Fragment>
  );
};

export default ProblemDiscovery;
