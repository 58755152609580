import {
  Autocomplete,
  Box,
  CircularProgress,
  IconButton,
  TextField,
  Tooltip,
  styled,
} from "@mui/material";
import { SelectOption } from "../../../Types/Common";
import { ReactElement } from "react";
import AddIcon from "@mui/icons-material/Add";

const StyledInput = styled(TextField)(({ theme }) => ({
  "& .MuiInputLabel-root.Mui-error, .MuiInputLabel-asterisk.Mui-error": {
    color: theme.palette.warning.main,
  },
  "& .Mui-error .MuiOutlinedInput-notchedOutline": {
    borderColor: theme.palette.warning.main,
  },
  "& .MuiOutlinedInput-root.MuiInputBase-root.MuiAutocomplete-inputRoot": {
    paddingRight: "75px",
  },
  "& .MuiOutlinedInput-root .MuiAutocomplete-endAdornment": {
    right: 0,
  },
}));

const EndAdornmentWrapper = styled(Box)(() => ({
  position: "absolute",
  right: "9px",
  display: "flex",
  alignItems: "center",

  "& .MuiAutocomplete-endAdornment": {
    position: "relative",
    translate: "0 50%",
  },
}));

export interface SelectInputProps {
  selectValues: SelectOption[];
  labelText: string;
  fieldId: string;
  value?: SelectOption | null;
  onChange: (value: SelectOption | null) => void;
  isLoading?: boolean;
  addIcon: boolean;
  setModalOpen?: () => void;
  onInputChange?: (event: string) => void;
  required?: boolean;
  fullWidth?: boolean;
  disabled?: boolean;
}

const SingleSelectInput = (props: SelectInputProps): ReactElement => {
  const handleInputChange = (
    event: React.SyntheticEvent<Element, Event>,
    newValue: string
  ) => {
    if (event === null) return;
    if (props.selectValues.some((option) => option.name === newValue)) return;

    props.onInputChange && props.onInputChange(newValue);
  };

  return (
    <Autocomplete
      fullWidth={props.fullWidth}
      id={props.fieldId}
      disabled={props.disabled}
      forcePopupIcon={props.addIcon ? false : true}
      options={props.selectValues.sort((option1, option2) =>
        option1.name.localeCompare(option2.name)
      )}
      isOptionEqualToValue={(option: SelectOption, value: SelectOption) =>
        option.id === value.id
      }
      getOptionLabel={(option: SelectOption) => option.name}
      value={props.value || null}
      filterSelectedOptions
      onChange={(_, selectedOption) => {
        props.onChange(selectedOption ? selectedOption : null);
      }}
      onInputChange={handleInputChange}
      renderInput={(params) => (
        <StyledInput
          {...params}
          label={props.labelText}
          error={props.required && !props.value}
          InputProps={{
            ...params.InputProps,
            readOnly: true,
            endAdornment: props.addIcon ? (
              <EndAdornmentWrapper>
                {props.isLoading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : (
                  <>
                    {params.InputProps.endAdornment}
                    <Tooltip
                      title={`Create New ${props.labelText}`}
                      placement="right-end"
                    >
                      <span>
                        <IconButton
                          data-testid={"addNew-" + props.fieldId}
                          onClick={props.setModalOpen}
                          disabled={props.disabled}
                        >
                          <AddIcon fontSize="small" />
                        </IconButton>
                      </span>
                    </Tooltip>
                  </>
                )}
              </EndAdornmentWrapper>
            ) : (
              params.InputProps.endAdornment
            ),
          }}
          InputLabelProps={{ shrink: true }}
        />
      )}
    />
  );
};

export default SingleSelectInput;
