import { Box, styled } from "@mui/material";
import {
  CorporateFareOutlined,
  RocketLaunchOutlined,
} from "@mui/icons-material";
import theme from "../../../theme";
import { ReactElement } from "react";

const LogoPlaceholderBox = styled(Box)({
  display: "flex",
  width: "88px",
  height: "100%",
  padding: theme.spacing(0.75, 2),
  justifyContent: "center",
  alignItems: "center",
  borderRadius: theme.shape.radius.minimal,
  backgroundColor: theme.palette.surface.medium,
});

const Logo = styled("img")({
  width: "100%",
  height: "100%",
  objectFit: "contain",
  objectPosition: "left",
});

interface LogoPlaceholderProps {
  logoUrl?: string;
  type?: "startup" | "client";
}

const LogoPlaceholder = ({
  logoUrl,
  type = "startup",
}: LogoPlaceholderProps): ReactElement => {
  return (
    <Box display="flex" maxWidth={120} height={40} alignItems="center">
      {logoUrl ? (
        <Logo src={logoUrl} />
      ) : (
        <LogoPlaceholderBox>
          {type === "client" ? (
            <CorporateFareOutlined fontSize="small" color="disabled" />
          ) : (
            <RocketLaunchOutlined fontSize="small" color="disabled" />
          )}
        </LogoPlaceholderBox>
      )}
    </Box>
  );
};

export default LogoPlaceholder;
