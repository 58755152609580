import { Paper, Stack, Box, Typography, Skeleton } from "@mui/material";
import { ReactElement } from "react";

export default function LeadProjectBackgroundInformationSkeleton(): ReactElement {
  return (
    <Paper component={Stack} p={3} gap={3}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="h6">Background Information</Typography>
        <Skeleton width={128} height={36} />
      </Box>
      <Stack gap={1}>
        <Skeleton width={80} height={16} />
        <Skeleton width="100%" height={12} />
        <Skeleton width="100%" height={12} />
        <Skeleton width="100%" height={12} />
        <Skeleton width="100%" height={12} />
        <Skeleton width={80} height={16} />
      </Stack>
    </Paper>
  );
}
