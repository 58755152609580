import {
  Box,
  Chip,
  Typography,
  styled,
  IconButton,
  Stack,
} from "@mui/material";
import { ReactElement, useState } from "react";
import EditPhaseModal from "./EditPhaseModal";
import ViewPhaseModal from "./ViewPhaseModal";
import Phase from "../../../../../Types/Phase";
import theme from "../../../../../theme";
import { getApproximateDuration } from "./ProjectTimeline";
import { EditOutlined } from "@mui/icons-material";

const PhaseColumnWrapper = styled(Box)(() => ({
  display: "flex",
  height: "56px",
  position: "relative",
  alignItems: "center",
}));

const PhaseColumn = styled(Stack)(() => ({
  width: "45%",
  alignItems: "center",
  gap: theme.spacing(1),
  position: "relative",
  padding: theme.spacing(2),
  cursor: "pointer",
}));

const PhaseHeader = styled(Box)(() => ({
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
}));

const PhaseBody = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  columnGap: theme.spacing(1),
  width: "100%",
  flexShrink: 1,
  flexWrap: "wrap",
}));

const ProgressBarWrapper = styled("span")(() => ({
  width: "10%",
  display: "flex",
  height: "100%",
  justifyContent: "center",
  alignItems: "center",
}));

const ProgressBar = styled("span")(({ theme }) => ({
  width: "1px",
  height: "150%",
  backgroundColor: theme.palette.borderOutline.main,
}));

const DurationDisplay = styled(Chip)(() => ({
  border: `1px solid ${theme.palette.borderOutline.main}`,
  borderRadius: theme.shape.radius.minimal,
  backgroundColor: "transparent",
  height: "24px",
  color: theme.palette.grey[600],
  fontSize: theme.typography.caption.fontSize,
  pointerEvents: "none",
}));

const TruncatedText = styled(Typography)(
  ({ maxLines }: { maxLines: number }) => ({
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    WebkitLineClamp: maxLines,
    WebkitBoxOrient: "vertical",
    flexGrow: 1,
  })
);

const hasPhaseInfo = (obj: Phase) => {
  const keysToCheck = ["name", "description"] as const;
  return keysToCheck.some((key) => !!obj[key]);
};

interface Props {
  phase: Phase;
  index: number;
  handlePhaseEdit: (phase: Phase) => void;
  canEdit: boolean;
}

const PhaseRow = (props: Props): ReactElement => {
  const [isEditPhaseModalOpen, setIsEditPhaseModalOpen] = useState(false);
  const [isViewPhaseModalOpen, setIsViewPhaseModalOpen] = useState(false);

  const handleManageModalOpen = () => {
    if (!props.canEdit) return;
    if (hasPhaseInfo(props.phase)) {
      setIsViewPhaseModalOpen(true);
    } else {
      setIsEditPhaseModalOpen(true);
    }
  };

  return (
    <>
      <PhaseColumnWrapper>
        <PhaseColumn sx={{ cursor: "default" }}></PhaseColumn>
        <ProgressBarWrapper>
          <ProgressBar />
        </ProgressBarWrapper>
        <PhaseColumn
          onClick={handleManageModalOpen}
          data-testid={`phase-row-${props.phase.id}`}
        >
          <PhaseHeader>
            <DurationDisplay
              size="medium"
              label={
                <Typography
                  variant="caption"
                  color={theme.palette.text.mediumEmphasis}
                >
                  {getApproximateDuration(props.phase.duration)}
                </Typography>
              }
            />
            {props.canEdit && (
              <IconButton color="inherit" sx={{ ml: "auto" }}>
                <EditOutlined fontSize="small" />
              </IconButton>
            )}
          </PhaseHeader>

          <PhaseBody>
            <Typography
              variant="subtitle2"
              flexShrink={0}
              color={theme.palette.text.mediumEmphasis}
            >
              Phase {props.index}:
            </Typography>

            <TruncatedText maxLines={2}>{props.phase.name}</TruncatedText>
          </PhaseBody>
        </PhaseColumn>
      </PhaseColumnWrapper>
      {isEditPhaseModalOpen && (
        <EditPhaseModal
          setModalOpen={setIsEditPhaseModalOpen}
          modalOpen={isEditPhaseModalOpen}
          phase={props.phase}
          handlePhaseEdit={props.handlePhaseEdit}
          index={props.index}
        />
      )}
      {isViewPhaseModalOpen && (
        <ViewPhaseModal
          index={props.index}
          setModalOpen={setIsViewPhaseModalOpen}
          modalOpen={isViewPhaseModalOpen}
          phase={props.phase}
          handlePhaseEdit={props.handlePhaseEdit}
        />
      )}
    </>
  );
};

export default PhaseRow;
