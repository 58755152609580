import { Autocomplete, TextField } from "@mui/material";
import { ReactElement } from "react";
import { Address } from "../../../Types/Startup";
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";

interface AddressAutocompleteProps {
  defaultAddress: string;
  label?: string;
  setAddress(address: Address): void;
}
type AddressOption = google.maps.places.AutocompletePrediction;
type AddressComponent = google.maps.places.PlaceResult["address_components"];

const emptyAddress: Address = {
  streetName: "",
  streetNumber: "",
  fullStreet: "",
  city: "",
  state: "",
  country: "",
  postalCode: "",
};

export const AddressAutocomplete = ({
  defaultAddress,
  setAddress,
  label = "Address",
}: AddressAutocompleteProps): ReactElement => {
  const {
    placesService,
    placePredictions,
    getPlacePredictions,
    isPlacePredictionsLoading,
  } = usePlacesService({
    apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
    debounce: 500,
    options: {
      types: ["geocode"],
      input: "",
    },
    language: "en",
  });

  const extractAddress = (address_components: AddressComponent) => {
    const address = emptyAddress;

    if (address_components) {
      address_components.forEach((component) => {
        const types = component.types;
        const value = component.long_name;

        if (types.includes("route")) {
          address.streetName = value;
        }
        if (types.includes("street_number")) {
          address.streetNumber = value;
        }
        if (types.includes("locality") || types.includes("postal_town")) {
          address.city = value;
        }
        if (types.includes("administrative_area_level_1")) {
          address.state = value;
        }
        if (types.includes("country")) {
          address.country = value;
        }
        if (types.includes("postal_code")) {
          address.postalCode = value;
        }
        address.fullStreet =
          `${address.streetName} ${address.streetNumber}`.trim();
      });
    }

    setAddress(address);
  };

  const handleAddressChange = (
    _: any,
    selectedAddress: AddressOption | null
  ) => {
    if (!placesService) return;

    if (selectedAddress?.place_id)
      placesService.getDetails(
        {
          fields: ["address_components"],
          placeId: selectedAddress.place_id,
        },
        (detailedAddress) => extractAddress(detailedAddress?.address_components)
      );
    else setAddress(emptyAddress);
  };

  return (
    <Autocomplete
      forcePopupIcon={false}
      fullWidth
      isOptionEqualToValue={() => true === true}
      onInputChange={(_, newValue: string) => {
        getPlacePredictions({ input: newValue });
      }}
      onChange={handleAddressChange}
      filterOptions={(options) => options}
      getOptionLabel={(option) => option.description}
      options={placePredictions}
      noOptionsText="No address found"
      loading={isPlacePredictionsLoading}
      loadingText="Loading..."
      defaultValue={{ description: defaultAddress } as AddressOption}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          InputProps={{
            ...params.InputProps,
          }}
          InputLabelProps={{
            shrink: true,
          }}
        />
      )}
    />
  );
};
