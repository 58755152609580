import { Box, Paper, Skeleton, Stack, Typography } from "@mui/material";
import { ReactElement } from "react";
import theme from "../../../../../theme";
import ContactNameSkeleton from "../../../../Contacts/ContactCard/ContactName/ContactNameSkeleton";

export default function ProjectStatusSkeleton(): ReactElement {
  return (
    <>
      <Paper component={Stack} p={3} gap={3}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography variant="h6">Project Updates</Typography>
          <Skeleton
            height={24}
            width={60}
            sx={{ borderRadius: theme.shape.radius.full }}
          />
        </Box>
        <Box display="flex" alignItems="center" gap={2}>
          <Box display="flex" justifyContent="space-between">
            <Box display="flex" gap={4} alignItems="center">
              <Box display="flex" gap={1} alignItems="center">
                <ContactNameSkeleton />
              </Box>
            </Box>
          </Box>
        </Box>
        <Stack gap={1}>
          <Skeleton width="auto" height={12} />
          <Skeleton width="auto" height={12} />
          <Skeleton width="auto" height={12} />
          <Skeleton width="auto" height={12} />
          <Skeleton width="auto" height={12} />
          <Skeleton width={72} height={16} />
        </Stack>
        <Stack gap={1}>
          <Skeleton width="auto" height={36} />
          <Skeleton width="auto" height={36} />
        </Stack>
      </Paper>
    </>
  );
}
