import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import { RequirementHttpService } from "../Http/Requirement/Requirement.http.service";
import { Requirement } from "../Types/Requirement";
import { DropResult } from "react-beautiful-dnd";

export interface useRequirementsReturns {
  requirements: Requirement[];
  currentEditRequirement: number | null;
  setCurrentEditRequirement: React.Dispatch<
    React.SetStateAction<number | null>
  >;
  addRequirement: () => void;
  deleteRequirement: (id: number) => void;
  changeRequirement: (updatedRequirement: Requirement) => Promise<void>;
  saveRequirement: (
    newRequirement: Requirement,
    isEnterPressed: boolean
  ) => Promise<void>;
  handleDragEnd: (result: DropResult) => Promise<void>;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  isLoading: boolean;
}

export interface UseRequirementsProps {
  initialRequirements: Requirement[];
  projectId: number;
  handleSaveNoScroll: () => void;
}

function useRequirements(props: UseRequirementsProps): useRequirementsReturns {
  const [requirements, setRequirements] = useState(
    props.initialRequirements.sort((a, b) => a.priority - b.priority)
  );
  const [currentEditRequirement, setCurrentEditRequirement] = useState<
    number | null
  >(null);
  const [addEmptyItem, setAddEmptyItem] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    const _requirements = [
      ...props.initialRequirements.sort((a, b) => a.priority - b.priority),
      ...(addEmptyItem
        ? [
            {
              id: -1,
              description: "",
              projectId: props.projectId,
              priority:
                props.initialRequirements.reduce(
                  (max, requirement) => Math.max(max, requirement.priority),
                  -1
                ) + 1,
              dateCreated: new Date(),
            },
          ]
        : []),
    ];
    setRequirements(_requirements);
    if (addEmptyItem) {
      setCurrentEditRequirement(-1);
      setAddEmptyItem(false);
    }

    setIsLoading(false);
  }, [props.initialRequirements]);

  const handleDelayedSave = () => {
    if (timeoutRef?.current) {
      clearTimeout(timeoutRef.current);
    }

    const timeoutId = setTimeout(() => {
      props.handleSaveNoScroll();
      timeoutRef.current = null;
    }, 500);

    timeoutRef.current = timeoutId;
  };

  const changeRequirement = async (updatedRequirement: Requirement) => {
    const isNewRequirement = updatedRequirement.id === -1;

    if (!isNewRequirement) {
      await RequirementHttpService.updateRequirement(updatedRequirement);
    }
    const orderedRequirements = requirements.map((req) =>
      req.id !== updatedRequirement.id ? req : updatedRequirement
    );
    setRequirements(orderedRequirements);
    handleDelayedSave();
  };

  const deleteRequirement = async (id: number) => {
    const isNewRequirement = id === -1;
    if (isNewRequirement) {
      setRequirements([
        ...requirements.filter((requirement) => requirement.id !== id),
      ]);
    } else {
      await RequirementHttpService.deleteRequirement(id);
      handleDelayedSave();
    }
    setCurrentEditRequirement(null);
  };

  const addRequirement = () => {
    const highestPriority = props.initialRequirements.reduce(
      (max, requirement) => Math.max(max, requirement.priority),
      -1
    );

    setCurrentEditRequirement(-1);
    setRequirements([
      ...requirements,
      {
        id: -1,
        description: "",
        projectId: props.projectId,
        priority: highestPriority + 1,
        dateCreated: new Date(),
      },
    ]);
  };

  const saveRequirement = async (
    newRequirement: Requirement,
    isEnterPressed: boolean
  ) => {
    if (newRequirement?.description) {
      setIsLoading(true);
      const { description, projectId, priority } = newRequirement;
      const requirement = await RequirementHttpService.createRequirement({
        description,
        projectId,
        priority,
      });
      if (isEnterPressed) setAddEmptyItem(true);

      setRequirements(
        requirements.map((_requirement) => {
          if (_requirement.id == -1) return requirement;
          return _requirement;
        })
      );
    } else {
      setRequirements(
        requirements.filter((requirement) => requirement.id !== -1)
      );
    }
    handleDelayedSave();
  };

  useEffect(() => {
    setRequirements(requirements.sort((a, b) => a.priority - b.priority));
  }, [requirements]);

  const handleDragEnd = async (result: DropResult) => {
    if (!result.destination) {
      return;
    }
    const orderedRequirements = [...requirements];
    const [removed] = orderedRequirements.splice(result.source.index, 1);
    orderedRequirements.splice(result.destination.index, 0, removed);

    orderedRequirements.forEach((requirement, index) => {
      requirement.priority = index;
    });

    setRequirements(orderedRequirements);

    await Promise.all(
      orderedRequirements.map((requirement) =>
        RequirementHttpService.updateRequirement(requirement)
      )
    );

    handleDelayedSave();
  };

  return {
    requirements,
    currentEditRequirement,
    setCurrentEditRequirement,
    addRequirement,
    deleteRequirement,
    changeRequirement,
    saveRequirement,
    handleDragEnd,
    isLoading,
    setIsLoading,
  };
}

export default useRequirements;
