import {
  Box,
  Button,
  Chip,
  Typography,
  styled,
  Accordion,
  AccordionProps,
  AccordionDetails,
} from "@mui/material";
import theme from "../../../../../theme";
import { ReactElement, useContext, useEffect, useState } from "react";
import { Project } from "../../../../../Types/Project";
import { Meeting, Status } from "../../../../../Types/Meeting";
import Phase from "../../../../../Types/Phase";
import PhaseHttpService from "../../../../../Http/Phase/Phase.Http.service";
import RocketIcon from "@mui/icons-material/Rocket";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import EditMeetingModal from "./EditMeetingModal";
import { compareMeetings, getErrorMessage } from "../../../../../utils";
import { useSnackbar } from "notistack";
import { GlobalProjectEditContext } from "../../../../../Context/ProjectDetailsContext";
import MeetingRow from "./MeetingRow";
import PhaseRow from "./PhaseRow";
import { MeetingHttpService } from "../../../../../Http/Meeting/Meeting.Http.service";
import { GlobalLoaderContext } from "../../../../../Context/LoaderContext";
import ProjectDetailsAccordionSummary from "../../../SharedComponents/ProjectDetailsAccordionSummary";
import useElementHeight from "../../../../../Hooks/useElementHeight";
import useRoles from "../../../../../Hooks/useRoles";

type MilestoneOrPhase = Meeting | Phase;

const MILLISECONDS_IN_A_DAY = 1000 * 60 * 60 * 24;

const TimePlanContainer = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",
}));

const ProgressBarContainer = styled(Box)(() => ({
  padding: theme.spacing(2),
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(3),
  overflow: "hidden",
  position: "relative",
}));

const ProgressBarWrapper = styled(Box, {
  shouldForwardProp: (prop: string) => !prop.startsWith("$"),
})<{
  $firstElementHeight: number;
  $lastElementHeight: number;
}>(({ $firstElementHeight, $lastElementHeight }) => ({
  position: "absolute",
  left: "50%",
  width: "1px",
  transform: `translate(-50%, ${$firstElementHeight / 2}px)`,
  zIndex: 8,
  height: `calc(100% - 32px - ${
    ($firstElementHeight + $lastElementHeight) / 2
  }px)`,
}));

const IconWrapper = styled("span")(() => ({
  position: "absolute",
  left: "50%",
  width: "32px",
  aspectRatio: "1/1",
  borderRadius: "50%",
  display: "grid",
  placeItems: "center",
}));

const RocketIconWrapper = styled(IconWrapper, {
  shouldForwardProp: (prop: string) => !prop.startsWith("$"),
})(
  ({
    $rocketPosition,
    $isBuyStage,
  }: {
    $rocketPosition: number;
    $isBuyStage: boolean;
  }) => {
    return {
      border: `1px solid ${
        $isBuyStage ? theme.palette.borderOutline.main : "transparent"
      }`,
      transform: "translateX(-50%)",
      backgroundColor: $isBuyStage ? "white" : theme.palette.brand.main,
      top: `${$rocketPosition}%`,
    };
  }
);

const RocketIndicator = styled(RocketIcon, {
  shouldForwardProp: (prop: string) => !prop.startsWith("$"),
})(({ $color }: { $color: string }) => ({
  fontSize: "18px",
  stroke: $color,
  strokeWidth: "2px",
  fill: "transparent",
}));

const ConclusionIconWrapper = styled(IconWrapper, {
  shouldForwardProp: (prop: string) => !prop.startsWith("$"),
})<{
  $isConcluded: boolean;
  $lastElementHeight: number;
}>(({ $lastElementHeight, $isConcluded }) => ({
  // padding of the parent + half of the row height
  bottom: `calc(${$lastElementHeight / 2 + 16}px)`,
  transform: "translate(-50%, 50%)",
  fill: theme.palette.secondary.main,
  color: $isConcluded
    ? theme.palette.common.white
    : theme.palette.borderOutline.main,
  backgroundColor: $isConcluded
    ? theme.palette.primary.main
    : theme.palette.background.default,
  zIndex: 10,
}));

const ConclusionIcon = styled(CheckCircleOutlineIcon)(() => ({
  fontSize: "18px",
}));

const TotalDurationWrapper = styled(Box)(() => ({
  display: "flex",
  gap: theme.spacing(2),
  alignItems: "center",
  padding: theme.spacing(4, 3),
  justifyContent: "end",
}));

const TotalDurationDisplay = styled(Chip, {
  shouldForwardProp: (prop: string) => !prop.startsWith("$"),
})(({ $totalDuration }: { $totalDuration: number }) => ({
  border: `1px solid ${
    $totalDuration
      ? theme.palette.surface.brand.accessibility
      : theme.palette.borderOutline.main
  }`,
  borderRadius: theme.shape.radius.minimal,
  backgroundColor: "transparent",
  height: "24px",
  color: $totalDuration
    ? theme.palette.text.brand.accessibility
    : theme.palette.text.disabled,
}));

const compareById = (a: Meeting | Phase, b: Meeting | Phase) => {
  return a.id - b.id;
};

const mergeMeetingAndPhase = (meetings: Meeting[], phases: Phase[]) => {
  const sortedMeetings = meetings.sort(compareMeetings);
  const sortedPhases = phases.sort(compareById);

  const merged = [];
  for (
    let i = 0;
    i < Math.max(sortedMeetings.length, sortedPhases.length);
    i++
  ) {
    if (sortedMeetings[i]) merged.push(meetings[i]);
    if (sortedPhases[i]) merged.push(phases[i]);
  }
  return merged;
};

const calculateTotalDuration = (sortedMeetings: Meeting[]): number => {
  return sortedMeetings.reduce((accumulatedDuration, currentMeeting, index) => {
    if (index === 0) return accumulatedDuration;

    const previousMeeting = sortedMeetings[index - 1];

    if (!previousMeeting.dateStart || !currentMeeting.dateStart)
      return accumulatedDuration;

    const previousDate = new Date(previousMeeting.dateStart ?? "");
    const currentDate = new Date(currentMeeting.dateStart ?? "");

    let duration = (+currentDate - +previousDate) / MILLISECONDS_IN_A_DAY;
    duration = Math.max(0, Math.ceil(duration));

    return accumulatedDuration + duration;
  }, 0);
};

const computePhaseDurations = (
  meetings: Meeting[],
  phases: Phase[]
): Phase[] => {
  return phases.map((phase, index) => {
    if (index < meetings.length - 1) {
      const startDate = new Date(meetings[index].dateStart ?? "");
      const endDate = new Date(meetings[index + 1].dateStart ?? "");
      const duration = (+endDate - +startDate) / MILLISECONDS_IN_A_DAY;
      phase.duration = Math.max(0, Math.ceil(duration));
    }
    return phase;
  });
};

const isMeeting = (obj: Meeting | Phase): obj is Meeting => {
  return "type" in obj;
};

export const adjustWeekendDate = (date: Date): Date => {
  if (date.getDay() === 6) {
    date.setDate(date.getDate() + 2);
  } else if (date.getDay() === 0) {
    date.setDate(date.getDate() + 1);
  }
  return date;
};

export const getSubsequentMeetingsToUpdate = (
  meetings: Meeting[],
  startIndex: number,
  dateDifference: number
): Meeting[] => {
  return meetings
    .slice(startIndex + 1)
    .filter((meeting) => meeting.dateStart)
    .map((meeting) => {
      if (meeting.dateStart) {
        const newDate = meeting.dateStart.getTime() + dateDifference;
        meeting.dateStart = new Date(newDate);
        meeting.dateStart = adjustWeekendDate(meeting.dateStart);
      }

      return {
        ...meeting,
        dateStart: meeting.dateStart,
      };
    });
};

export const getApproximateDuration = (
  duration: number | undefined
): string => {
  if (!duration) return "- days";

  if (duration <= 10) return `${duration} days`;
  else if (duration <= 17) return "2 weeks";
  else if (duration <= 24) return "3 weeks";
  else if (duration <= 37) return "1 month";
  else if (duration <= 49) return "1.5 months";
  else if (duration <= 75) return "2 months";
  else {
    return `${Math.ceil((duration - 75) / 31) + 2} months`;
  }
};

const getMeetingDate = (meetings: Meeting[], type: string): Date | null => {
  const meeting = meetings.find((meeting) => meeting.type === type);
  return meeting?.dateStart || null;
};

interface Props extends Omit<AccordionProps, "children"> {
  project: Project;
  handleSave: (withScroll?: boolean) => void;
}

const ProjectTimeline = (props: Props): ReactElement => {
  const { enqueueSnackbar } = useSnackbar();
  const { setGlobalLoader } = useContext(GlobalLoaderContext);
  const { canEdit } = useRoles(props.project);
  const { activeStep, globalEditMode } = useContext(GlobalProjectEditContext);
  const meetingsInitial = props.project.meetings.sort(compareMeetings);
  const phasesInitial = props.project.phases.sort(compareById);
  const [meetings, setMeetings] = useState(meetingsInitial);
  const [phases, setPhases] = useState(phasesInitial);
  const phasesWithDuration = computePhaseDurations(meetings, phases);
  const totalDuration = calculateTotalDuration(meetings);
  const [mergedData, setMergedData] = useState<MilestoneOrPhase[]>(
    mergeMeetingAndPhase(meetings, phasesWithDuration)
  );
  const [isEditMeetingModalOpen, setIsEditMeetingModalOpen] = useState(false);
  const [meetingStatuses, setMeetingStatuses] = useState<Status[]>([]);
  const [rocketPosition, setRocketPosition] = useState(0);

  const [timelineHeight, timelineRef] = useElementHeight();
  const [, containerRef] = useElementHeight();
  const [firstElementHeight, firstElementRef] = useElementHeight();
  const [lastElementHeight, lastElementRef] = useElementHeight();

  const isBuyStage = activeStep === 2;
  const isPilotStage = activeStep === 3;

  let meetingIndex = 0;
  let phaseIndex = 0;

  const shouldShowIcon = mergedData.length > 0;
  const isConcluded = rocketPosition === 100;

  useEffect(() => {
    setMergedData(mergeMeetingAndPhase(meetings, phasesWithDuration));
  }, [meetings, phases]);

  useEffect(() => {
    if (isPilotStage) {
      const getMeetingStatus = (meetingDate: Date, today: Date) => {
        if (meetingDate < today) return "completed";
        if (meetingDate > today || isNaN(meetingDate.getTime())) {
          return "upcoming";
        }
        return "today";
      };

      const updateMeetingStatuses = (meetings: Meeting[], today: Date) => {
        const statuses: Status[] = meetings.map((meeting) => {
          const meetingDate = new Date(meeting.dateStart || "");
          meetingDate.setHours(0, 0, 0, 0);
          return getMeetingStatus(meetingDate, today);
        });

        for (let i = 0; i < statuses.length - 1; i++) {
          if (
            statuses[i] === "completed" &&
            (statuses[i + 1] === "completed" || statuses[i + 1] === "today")
          ) {
            statuses[i] = "done";
          }
        }

        setMeetingStatuses(statuses);
      };

      const today = new Date();
      today.setHours(0, 0, 0, 0);

      if (meetings.length) {
        updateMeetingStatuses(meetings, today);
        updateRocketPosition(meetings, today);
      }
    } else setRocketPosition((-16 / timelineHeight) * 100);
  }, [meetings, mergedData, timelineHeight]);

  const updateRocketPosition = (meetings: Meeting[], today: Date) => {
    const resetDateToMidnight = (date: Date) => {
      const newDate = new Date(date);
      newDate.setHours(0, 0, 0, 0);
      return newDate;
    };
    const startPosition = (-16 / timelineHeight) * 100;

    const firstMeetingDate = meetings[0].dateStart
      ? resetDateToMidnight(new Date(meetings[0].dateStart))
      : null;
    const lastMeetingDate = meetings[meetings.length - 1].dateStart
      ? resetDateToMidnight(
          new Date(meetings[meetings.length - 1].dateStart || "")
        )
      : null;

    if (firstMeetingDate && today < firstMeetingDate)
      return setRocketPosition(startPosition);

    if (lastMeetingDate && today > lastMeetingDate) {
      setRocketPosition(100);
      return;
    }

    const meetingRows = containerRef.current?.querySelectorAll(".meeting");

    const getMeetingPosition = (meeting: HTMLDivElement | null) => {
      if (!meeting || !meetingRows) return 0;

      const { offsetTop = 0, offsetHeight = 0 } = meeting;
      const firstMeeting = meetingRows[0] as HTMLDivElement;
      if (!firstMeeting) return 0;

      const { offsetTop: firstTop = 0, offsetHeight: firstHeight = 0 } =
        firstMeeting;
      const firstMeetingPosition = firstTop + firstHeight / 2;

      return offsetTop + offsetHeight / 2 - firstMeetingPosition;
    };

    for (let i = 1; i < meetings.length; i++) {
      const prevMeetingDate = resetDateToMidnight(
        new Date(meetings[i - 1].dateStart || "")
      );
      const currentMeetingDate = resetDateToMidnight(
        new Date(meetings[i].dateStart || "")
      );

      if (today >= prevMeetingDate && today <= currentMeetingDate) {
        if (!meetingRows) return;
        const totalDays =
          (currentMeetingDate.getTime() - prevMeetingDate.getTime()) /
          MILLISECONDS_IN_A_DAY;
        const passedDays =
          (today.getTime() - prevMeetingDate.getTime()) / MILLISECONDS_IN_A_DAY;
        const percentagePassed = passedDays / totalDays;

        const prevPosition = getMeetingPosition(
          meetingRows[i - 1] as HTMLDivElement
        );
        const currentPosition = getMeetingPosition(
          meetingRows[i] as HTMLDivElement
        );

        const rocketIconHeight = (16 / timelineHeight) * 100;
        const newRocketPosition =
          ((prevPosition +
            percentagePassed * (currentPosition - prevPosition)) /
            timelineHeight) *
          100;

        setRocketPosition(newRocketPosition - rocketIconHeight);
        return;
      }

      setRocketPosition(startPosition);
    }
  };

  const getBoundaryDate = (
    meetingIndex: number,
    direction: "min" | "max"
  ): Date | null => {
    let filteredMeetings: Meeting[];

    if (direction === "min") {
      filteredMeetings = mergedData
        .slice(0, meetingIndex)
        .filter(
          (meeting) => isMeeting(meeting) && meeting.dateStart
        ) as Meeting[];
    } else {
      filteredMeetings = mergedData
        .slice(meetingIndex + 1)
        .filter(
          (meeting) => isMeeting(meeting) && meeting.dateStart
        ) as Meeting[];
    }

    if (filteredMeetings.length === 0) return null;

    const boundaryMeeting = filteredMeetings.reduce((prev, current) => {
      const prevDate = prev.dateStart ? new Date(prev.dateStart) : null;
      const currentDate = current.dateStart
        ? new Date(current.dateStart)
        : null;

      if (!prevDate) return current;
      if (!currentDate) return prev;

      return (direction === "min" && prevDate > currentDate) ||
        (direction === "max" && prevDate < currentDate)
        ? prev
        : current;
    });

    const boundaryDate = boundaryMeeting.dateStart
      ? new Date(boundaryMeeting.dateStart)
      : null;

    if (boundaryDate) {
      if (direction === "min") {
        boundaryDate.setDate(boundaryDate.getDate() + 1);
      } else if (direction === "max") {
        boundaryDate.setDate(boundaryDate.getDate() - 1);
      }
    }

    return boundaryDate;
  };

  const handleMeetingCreate = async (createdMeeting: Meeting) => {
    const initialPhase = {
      projectId: props.project.id,
      name: "",
      description: "",
      isInitial: isBuyStage ? true : false,
    };
    await PhaseHttpService.createPhase(initialPhase)
      .then((createdPhase: Phase) => {
        props.handleSave(false);
        setPhases((prevPhases) => [...prevPhases, createdPhase]);
        setMeetings((prevMeetings) => {
          return [...prevMeetings, createdMeeting];
        });
      })
      .catch((error) => {
        const errorMessage = getErrorMessage(error);
        return enqueueSnackbar(`Could not create phase: ${errorMessage}`, {
          variant: "error",
        });
      });
  };

  const handleMeetingEdit = async (
    updatedMeeting: Meeting,
    isPushMeetingsEnabled: boolean
  ) => {
    const meetingIndex = meetings.findIndex(
      (meeting) => meeting.id === updatedMeeting.id
    );
    if (meetingIndex === -1) return;

    const originalMeeting: Meeting = meetings[meetingIndex];
    const meetingsToUpdate: Meeting[] = [updatedMeeting];

    if (
      originalMeeting.dateStart !== updatedMeeting.dateStart &&
      isPushMeetingsEnabled
    ) {
      const dateDifference =
        +(updatedMeeting.dateStart || "") - +(originalMeeting.dateStart || "");

      if (dateDifference) {
        const subsequentMeetingsToUpdate = getSubsequentMeetingsToUpdate(
          meetings,
          meetingIndex,
          dateDifference
        );
        meetingsToUpdate.push(...subsequentMeetingsToUpdate);
      }
    }

    try {
      setGlobalLoader(true);
      await Promise.all(
        meetingsToUpdate.map((meeting) =>
          MeetingHttpService.updateMeeting(meeting)
        )
      );
      setMeetings((prevMeetings) =>
        prevMeetings.map((meeting) =>
          meeting.id === updatedMeeting.id ? updatedMeeting : meeting
        )
      );
      props.handleSave();
      // eslint-disable-next-line
    } catch (error: any) {
      const errorMessage = getErrorMessage(error);
      enqueueSnackbar(`Could not update meetings: ${errorMessage}`, {
        variant: "error",
      });
    } finally {
      setGlobalLoader(false);
    }
  };

  const getPhaseToDelete = (milestoneId: number) => {
    const milestoneIndex = mergedData.findIndex(
      (item) => item.id === milestoneId && isMeeting(item)
    );
    let phaseToDelete: Phase | null = null;
    if (!isMeeting(mergedData[milestoneIndex + 1])) {
      phaseToDelete = mergedData[milestoneIndex + 1] as Phase;
    }
    return phaseToDelete?.id;
  };

  const handleDeleteMilestone = async (milestoneId: number): Promise<void> => {
    const phaseIdToDelete = getPhaseToDelete(milestoneId);

    await MeetingHttpService.deleteMeeting(milestoneId)
      .then(() => {
        setMeetings((prevMeetings) =>
          prevMeetings.filter(
            (meeting) =>
              meeting.id !== milestoneId && meeting.id !== phaseIdToDelete
          )
        );
      })
      .catch((error) => {
        const errorMessage = getErrorMessage(error);
        enqueueSnackbar(`Could not delete milestone: ${errorMessage}`, {
          variant: "error",
        });
      });

    if (phaseIdToDelete) {
      await PhaseHttpService.deletePhase(phaseIdToDelete)
        .then(() => {
          setPhases((prevPhases) =>
            prevPhases.filter((phase) => phase.id !== phaseIdToDelete)
          );
        })
        .catch((error) => {
          const errorMessage = getErrorMessage(error);
          enqueueSnackbar(`Could not delete phase: ${errorMessage}`, {
            variant: "error",
          });
        });
    }

    props.handleSave(false);
  };

  const handlePhaseEdit = (updatedPhase: Phase) => {
    setPhases((prevPhases) =>
      prevPhases.map((phase) =>
        phase.id === updatedPhase.id ? updatedPhase : phase
      )
    );
    props.handleSave();
  };

  return (
    <Accordion expanded={props.expanded} onChange={props.onChange}>
      <ProjectDetailsAccordionSummary
        actionButton={
          props.expanded &&
          canEdit && (
            <Button
              variant="contained"
              onClick={() => setIsEditMeetingModalOpen(true)}
              disabled={globalEditMode}
            >
              Add Milestone
            </Button>
          )
        }
      >
        Project Timeline
      </ProjectDetailsAccordionSummary>
      <AccordionDetails
        sx={{ display: "flex", justifyContent: "space-between" }}
      >
        <TimePlanContainer>
          <ProgressBarContainer ref={containerRef}>
            <ProgressBarWrapper
              ref={timelineRef}
              sx={{
                background: `linear-gradient(to bottom, ${theme.palette.primary.main} ${rocketPosition}%, transparent ${rocketPosition}%)`,
              }}
              $firstElementHeight={firstElementHeight}
              $lastElementHeight={lastElementHeight}
            />
            {shouldShowIcon && !isConcluded && (
              <ProgressBarWrapper
                sx={{ zIndex: 11 }}
                $firstElementHeight={firstElementHeight}
                $lastElementHeight={lastElementHeight}
              >
                <RocketIconWrapper
                  id="rocket"
                  $isBuyStage={isBuyStage}
                  $rocketPosition={rocketPosition}
                >
                  <RocketIndicator
                    $color={
                      isBuyStage
                        ? theme.palette.icon.disabled
                        : theme.palette.icon.primaryInvert.main
                    }
                  />
                </RocketIconWrapper>
              </ProgressBarWrapper>
            )}
            {mergedData.map((item, index) => {
              const isMilestone = isMeeting(item) && item.type === "Milestone";
              if (isMilestone) {
                meetingIndex++;
              }

              if (isMeeting(item)) {
                const minDate = getBoundaryDate(index, "min");
                const maxDate = getBoundaryDate(index, "max");

                return (
                  <Box
                    ref={
                      index === 0
                        ? firstElementRef
                        : index === mergedData.length - 1
                        ? lastElementRef
                        : null
                    }
                    className="meeting"
                    key={item.id}
                  >
                    <MeetingRow
                      key={item.id}
                      index={meetingIndex}
                      isFirst={index === 0}
                      isLast={index === mergedData.length - 1}
                      meeting={item}
                      status={meetingStatuses[index / 2]}
                      handleMeetingEdit={handleMeetingEdit}
                      handleDelete={handleDeleteMilestone}
                      startupId={
                        props.project.opportunities?.filter(
                          (opp) => opp.isSelectedForPilot
                        )[0]?.startupId
                      }
                      ventureClientId={
                        props.project.businessUnit.ventureClient.id
                      }
                      maxDate={maxDate}
                      minDate={minDate}
                      canEdit={canEdit}
                    />
                  </Box>
                );
              } else {
                return (
                  <PhaseRow
                    index={++phaseIndex}
                    key={item.id}
                    phase={item}
                    handlePhaseEdit={handlePhaseEdit}
                    canEdit={canEdit}
                  />
                );
              }
            })}
            {shouldShowIcon && (
              <ConclusionIconWrapper
                $isConcluded={isConcluded}
                $lastElementHeight={lastElementHeight}
              >
                <ConclusionIcon />
              </ConclusionIconWrapper>
            )}
          </ProgressBarContainer>
          <TotalDurationWrapper>
            <Typography variant="caption" color="text.mediumEmphasis">
              Total duration of the project
            </Typography>
            <TotalDurationDisplay
              $totalDuration={totalDuration}
              size="medium"
              label={
                <Typography variant="caption">
                  {totalDuration
                    ? getApproximateDuration(totalDuration)
                    : "N/A"}
                </Typography>
              }
            />
          </TotalDurationWrapper>
        </TimePlanContainer>
      </AccordionDetails>
      {isEditMeetingModalOpen && (
        <EditMeetingModal
          modalOpen={isEditMeetingModalOpen}
          setModalOpen={setIsEditMeetingModalOpen}
          handleMeetingCreate={handleMeetingCreate}
          project={props.project}
          startupId={
            props.project.opportunities?.filter(
              (opp) => opp.isSelectedForPilot
            )[0]?.startupId
          }
          ventureClientId={props.project.businessUnit.ventureClient.id}
          minDate={getMeetingDate(meetings, "Kick-off")}
          maxDate={getMeetingDate(meetings, "Conclusion")}
        />
      )}
    </Accordion>
  );
};

export default ProjectTimeline;
