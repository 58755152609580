import {
  Box,
  Button,
  Paper,
  Stack,
  Tooltip,
  styled,
  Skeleton,
} from "@mui/material";
import { ReactElement, useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { StartupHttpService } from "../../../Http/Startup/Startup.http.service";
import { Startup } from "../../../Types/Startup";
import { StartupFileSection } from "../../ProjectDetails/SharedComponents/ProjectOverview/FilesComponent/StartupFileSection";
import ProjectSection from "./ProjectSection/ProjectSection";
import DeleteConfirmationModal from "./DeleteConfirmationModal";
import { getErrorMessage, isBmwUserCheck } from "../../../utils";
import AddStartupToProjectModal from "./AddStartupToProjectModal";
import { UserContext } from "../../../Context/UserContext";
import ContentWrapper from "../../ProjectDetails/ContentWrapper";
import theme from "../../../theme";
import FundingSection from "./FundingSection/FundingSection";
import { GlobalStartupEditContext } from "../../../Context/StartupDetailsContext";
import StartupOverview from "./Overview/StartupOverview";
import useRoles from "../../../Hooks/useRoles";
import SolutionAndClients from "./SolutionAndClients/SolutionAndClients";
import BmwSalesforce from "./BmwSalesforce";
import StartupIncompleteInfo from "./StartupIncompleteInfo";
import CompetitorsSection from "./CompetitorsSection/CompetitorsSection";
import { useSnackbar } from "notistack";
import QualityVerification from "./QualityVerification/QualityVerification";
import StartupTeam from "./StartupTeam";

const CardContainer = styled(Paper)({
  padding: theme.spacing(3),
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(3),
});

export default function StartupDetailsPage(): ReactElement {
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const user = useContext(UserContext);
  const isBmwUser = isBmwUserCheck(user);
  const { isExternalUser } = useRoles();

  const [globalEditMode, setGlobalEditMode] = useState(false);
  const [startup, setStartup] = useState<Startup>();
  const [competitors, setCompetitors] = useState<Startup[]>([]);
  const [refresh, setRefresh] = useState<boolean>();

  const [deleteConfirmationModalOpen, setDeleteConfirmationModalOpen] =
    useState(false);
  const [addStartupToProjectModalOpen, setAddStartupToProjectModalOpen] =
    useState(false);

  const hasAssignedToBmwProject = startup?.opportunities.some(
    (opp) => opp.project.businessUnit.ventureClient.id === 7
  );
  const hasAssignedToBmwLead = startup?.leadOpportunities?.some(
    (opp) => opp.leadProject.ventureClient.id === 7
  );

  useEffect(() => {
    Promise.all([
      StartupHttpService.getStartupById(id),
      StartupHttpService.getSimilarStartups([Number(id)]),
    ])
      .then(([startupData, competitorsData]) => {
        setStartup(startupData);
        setCompetitors(competitorsData);
      })
      .catch((error) => {
        const errorMessage = getErrorMessage(error);
        enqueueSnackbar(`Could not fetch startup details: ${errorMessage}`, {
          variant: "error",
        });
        history.push("/startups");
      });
  }, [refresh]);

  useEffect(() => {
    if (startup?.name) {
      document.title = `${startup?.name} - Startup`;
    } else {
      document.title = `Startup`;
    }
  }, [startup?.name]);

  const handleSave = (withScroll = true) => {
    setRefresh((prevState) => !prevState);
    if (withScroll) window.scrollTo({ behavior: "smooth", top: 0 });
  };

  const handleStartupAddedToProject = () => {
    handleSave(false);
  };

  return (
    <GlobalStartupEditContext.Provider
      value={{ globalEditMode, setGlobalEditMode }}
    >
      <ContentWrapper display="flex" flexDirection="column" gap={7}>
        <Stack gap={7}>
          {startup?.isMissingValidation && <StartupIncompleteInfo />}
          <Box
            display="grid"
            gridTemplateColumns="minmax(0, 1fr) minmax(0, 3fr)"
            gap={3}
          >
            <Stack gap={3}>
              {startup ? (
                <>
                  <CardContainer>
                    <StartupOverview
                      handleSave={handleSave}
                      startup={startup}
                      setAddStartupToProjectModalOpen={
                        setAddStartupToProjectModalOpen
                      }
                    />
                  </CardContainer>
                  <CardContainer>
                    {startup && (
                      <QualityVerification
                        startupId={startup.id}
                        handleSave={() => handleSave(false)}
                        qualityChecks={startup.qualityChecks}
                        lastQualityCheckBy={startup.lastQualityCheckBy}
                        lastQualityCheckDate={startup.lastQualityCheckDate}
                        startup={startup}
                      />
                    )}
                  </CardContainer>
                  <StartupTeam startupId={startup.id} />
                  <CardContainer>
                    <StartupFileSection
                      startup={startup}
                      handleSave={handleSave}
                      fileTypes={[
                        "Strategic Image",
                        "Product Deck",
                        "Logo",
                        "Other",
                      ]}
                      imageFileTypes={["Strategic Image", "Logo"]}
                    />
                  </CardContainer>
                  {isBmwUser &&
                    !isExternalUser &&
                    (hasAssignedToBmwProject || hasAssignedToBmwLead) && (
                      <CardContainer>
                        <BmwSalesforce startupId={id} />
                      </CardContainer>
                    )}
                  {!isExternalUser && (
                    <Tooltip
                      title={
                        startup?.opportunities.length
                          ? "Deletion is not possible due to associated projects"
                          : ""
                      }
                    >
                      <Box>
                        <Button
                          onClick={() => setDeleteConfirmationModalOpen(true)}
                          variant="contained"
                          color="error"
                          data-testid="delete-startup"
                          disabled={
                            !!startup?.opportunities.length ||
                            !!startup?.leadOpportunities.length
                          }
                          fullWidth
                        >
                          Delete Startup
                        </Button>
                      </Box>
                    </Tooltip>
                  )}
                </>
              ) : (
                <>
                  <Skeleton height={600} />
                  <Skeleton height={260} />
                  <Skeleton height={260} />
                  <Skeleton height={500} />
                </>
              )}
            </Stack>
            <Stack gap={3}>
              {startup ? (
                <>
                  <CardContainer>
                    <FundingSection startup={startup} handleSave={handleSave} />
                  </CardContainer>
                  <CardContainer>
                    <SolutionAndClients
                      startup={startup}
                      handleSave={handleSave}
                    />
                  </CardContainer>

                  <CardContainer sx={{ padding: 2.25 }}>
                    <CompetitorsSection
                      competitors={competitors}
                      onCompetitorAddedToProject={handleStartupAddedToProject}
                      startup={startup}
                    />
                  </CardContainer>

                  <CardContainer>
                    {startup.opportunities && (
                      <ProjectSection
                        opportunities={startup.opportunities}
                        startupName={startup.name}
                        leadOpportunities={startup.leadOpportunities}
                      />
                    )}
                  </CardContainer>
                </>
              ) : (
                <>
                  <Skeleton height={360} />
                  <Skeleton height={600} />
                  <Skeleton height={600} />
                </>
              )}
            </Stack>
          </Box>
        </Stack>
      </ContentWrapper>
      {deleteConfirmationModalOpen && startup && (
        <DeleteConfirmationModal
          modalOpen={deleteConfirmationModalOpen}
          setModalOpen={setDeleteConfirmationModalOpen}
          startupId={startup.id}
          startupName={startup.name}
        />
      )}
      {addStartupToProjectModalOpen && startup && (
        <AddStartupToProjectModal
          modalOpen={addStartupToProjectModalOpen}
          setModalOpen={setAddStartupToProjectModalOpen}
          startupId={startup?.id}
          startupName={startup.name}
          handleSave={handleSave}
        />
      )}
    </GlobalStartupEditContext.Provider>
  );
}
