import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  debounce,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  MenuItem,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/styles";
import React, {
  ReactElement,
  useReducer,
  Reducer,
  useState,
  Fragment,
} from "react";
import { CreateStartupContactDTO } from "../../../Types/StartupContact";
import StartupContactHttpService from "../../../Http/StartupContact/StartupContact.http.service";
import { StartupHttpService } from "../../../Http/Startup/Startup.http.service";
import { Startup } from "../../../Types/Startup";
import { Gender } from "../../../Types/Common";
interface CreateContactModalProps {
  setModalOpen: (state: boolean) => void;
  modalOpen: boolean;
  handleSave: () => void;
  startupId?: number;
}

const genders = ["male", "female", "non-binary"];

export default function CreateStartupContactModal(
  props: CreateContactModalProps
): ReactElement {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const [isLoading, setIsLoading] = useState(false);
  const [startups, setStartups] = useState<Startup[]>([]);

  const [newContact, setNewContact] = useReducer<
    Reducer<CreateStartupContactDTO, Partial<CreateStartupContactDTO>>
  >((state, newState) => ({ ...state, ...newState }), {
    ...(props.startupId && { startupId: props.startupId }),
  } as CreateStartupContactDTO);

  const handleCreateContact = async () => {
    if (newContact.name != null && newContact.startupId != null) {
      await StartupContactHttpService.createContact(newContact);
      props.handleSave();
      props.setModalOpen(false);
    }
  };

  const handleFounderChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = e.target.checked;
    const currentTitle = newContact.title || "";

    if (isChecked) {
      setNewContact({
        founder: true,
        title: currentTitle.startsWith("(Co-)Founder")
          ? currentTitle
          : `(Co-)Founder ${currentTitle}`.trim(),
      });
    } else {
      setNewContact({
        founder: false,
        title: currentTitle.replace(/^\(Co-\)Founder\s*/, ""),
      });
    }
  };

  const searchForStartups = async (searchValue: string): Promise<void> => {
    setIsLoading(true);
    if (searchValue.trim().length > 0)
      setStartups(await StartupHttpService.getStartupsByName(searchValue));
    setIsLoading(false);
  };

  const debouncedSearchForStartups = debounce(searchForStartups, 500);

  return (
    <Dialog
      fullWidth
      fullScreen={fullScreen}
      open={props.modalOpen}
      id="startup-create-contact-view"
      PaperProps={{
        sx: {
          gap: theme.spacing(4),
        },
      }}
    >
      <DialogTitle>Add Startup Contact</DialogTitle>
      <DialogContent>
        <Stack noValidate autoComplete="off" component="form" gap={3}>
          <Stack gap={2}>
            <Typography variant="subtitle1">Personal Information</Typography>

            <Box display="flex" gap={2}>
              <TextField
                label="Name"
                id="contact-name"
                variant="outlined"
                required
                fullWidth
                defaultValue=""
                onChange={(event) =>
                  setNewContact({ name: event.target.value })
                }
              />
              <TextField
                label="Gender"
                id="contact-gender"
                variant="outlined"
                fullWidth
                select
                defaultValue=""
                onChange={(event) =>
                  setNewContact({ gender: event.target.value as Gender })
                }
              >
                {genders.map((gender) => (
                  <MenuItem key={gender} value={gender}>
                    {gender}
                  </MenuItem>
                ))}
              </TextField>
            </Box>
          </Stack>

          <Stack gap={2}>
            <Typography variant="subtitle1">Company</Typography>
            <FormControlLabel
              control={
                <Checkbox
                  data-cy="founder"
                  checked={newContact.founder || false}
                  onChange={handleFounderChange}
                />
              }
              label="(Co-)Founder"
            />
            <TextField
              label="Title"
              id="contact-title"
              variant="outlined"
              fullWidth
              value={newContact.title || ""}
              onChange={(event) => setNewContact({ title: event.target.value })}
            />

            {!props.startupId && (
              <Autocomplete
                sx={{ width: "100%" }}
                isOptionEqualToValue={(option: Startup, value: Startup) =>
                  option.name === value.name
                }
                onInputChange={(_, newValue: string) =>
                  debouncedSearchForStartups(newValue)
                }
                onChange={(_, selectedStartup) =>
                  setNewContact({ startupId: selectedStartup?.id || undefined })
                }
                getOptionLabel={(option) => option.name}
                options={startups}
                noOptionsText="No startup found"
                loading={isLoading}
                defaultValue={null}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Startup"
                    required
                    placeholder="Type the startup's name"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <Fragment>
                          {isLoading ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </Fragment>
                      ),
                    }}
                  />
                )}
              />
            )}
          </Stack>
          <Stack gap={2}>
            <Typography variant="subtitle1">Contact</Typography>
            <Box display="flex" gap={2}>
              <TextField
                label="Phone Number"
                id="contact-phone"
                variant="outlined"
                fullWidth
                defaultValue=""
                onChange={(event) =>
                  setNewContact({ phone: event.target.value })
                }
              />
              <TextField
                label="Email"
                id="contact-email"
                variant="outlined"
                fullWidth
                defaultValue=""
                onChange={(event) =>
                  setNewContact({ email: event.target.value })
                }
              />
            </Box>
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button
          autoFocus
          onClick={() => props.setModalOpen(false)}
          color="primary"
          id="close-startup-create-contact-view"
        >
          Cancel
        </Button>
        <Button
          disabled={!newContact.name || !newContact.startupId}
          onClick={handleCreateContact}
          variant="contained"
          id="save-startup-create-contact-view"
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}
