import { ReactElement, Reducer, useContext, useReducer, useState } from "react";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import { Avatar, Box, Chip, styled } from "@mui/material";
import { NavLink } from "react-router-dom";
import { Project, FunnelStage } from "../../Types/Project";
import RocketLaunchOutlinedIcon from "@mui/icons-material/RocketLaunchOutlined";
import TvOutlinedIcon from "@mui/icons-material/TvOutlined";
import {
  capitalizeFirstLetter,
  daysBetweenDates,
  formatStatusDate,
  getMeetingTimes,
  nameToInitials,
} from "../../utils";
import {
  CheckCircle,
  PauseCircleOutlined,
  PlayCircleOutline,
  StopCircleOutlined,
} from "@mui/icons-material";
import theme from "../../theme";
import { stageScores } from "../../Constants/FunnelStages";
import { ProjectHttpService } from "../../Http/Project/Project.http.service";
import { GlobalLoaderContext } from "../../Context/LoaderContext";
import { enqueueSnackbar } from "notistack";
import ProjectDetailsModal from "../UI/Modals/ProjectDetailsModal/ProjectDetailsModal";
import useRoles from "../../Hooks/useRoles";
import { formatDate } from "../../utils";
import Bookmark from "../UI/Bookmark";
import { Meeting } from "../../Types/Meeting";

interface Props {
  project: Project;
  refreshProjectData: (preventLoading?: boolean) => void;
}

interface StyledProjectCardProps {
  $isArchivedOrOnhold: boolean;
  $isAdopted: boolean;
  $offtrack?: boolean;
}

export const StyledProjectCard = styled(Card, {
  shouldForwardProp: (prop: string) => !prop.startsWith("$"),
})<StyledProjectCardProps>(
  ({ theme, $isArchivedOrOnhold, $isAdopted, $offtrack }) => ({
    flexDirection: "column",
    padding: `${theme.spacing(2)}`,
    gap: theme.spacing(2),
    position: "relative",
    ...(($isArchivedOrOnhold || $isAdopted) && {
      backgroundColor: theme.palette.surface.secondary.light,
    }),
    borderRadius: theme.shape.radius.cardSmall,
    display: "flex",
    elevation: 11,
    "&:hover .bookmark": {
      maxWidth: "24px",
      opacity: 1,
      transition: "opacity 0.5s, max-width 0.5s;",
    },
    ...($offtrack && {
      borderBottom: `4px solid ${theme.palette.surface.error.main}`,
    }),
  })
);

const StyledFocusAreaWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  overflowY: "auto",
  gap: theme.spacing(1),
  flexDirection: "row",
  "&::-webkit-scrollbar": {
    display: "none",
  },
}));

export const VentureClientNameWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: theme.spacing(1),
  padding: theme.spacing(0.5, 1),
  backgroundColor: theme.palette.surface.secondary.main,
  flexWrap: "wrap",
  alignItems: "center",
}));

export const StageProgressWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  width: "100%",
  gap: theme.spacing(0.5),
  backgroundColor: theme.palette.surface.secondary.main,
  padding: theme.spacing(0.5, 1),
}));

export const ProjectOwnerAvatar = styled(Avatar)(({ theme }) => ({
  width: theme.spacing(3),
  height: theme.spacing(3),
  backgroundColor: theme.palette.surface.brand.main,
}));

const StartupPlaceHolderLogo = styled(RocketLaunchOutlinedIcon)(
  ({ theme }) => ({
    height: theme.spacing(2),
    width: theme.spacing(2),
    color: theme.palette.icon.disabled,
  })
);

const StartupLogoBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  gap: theme.spacing(1),
}));

const PlaceHolderLogoBox = styled(Box)(({ theme }) => ({
  width: "48px",
  height: "28px",
  backgroundColor: theme.palette.surface.secondary.main,
  borderRadius: theme.shape.radius.minimal,
  placeItems: "center",
  display: "grid",
}));

export const ProjectStatusIcon = ({
  status,
}: {
  status: string;
}): ReactElement => {
  switch (status) {
    case "on hold":
      return <PauseCircleOutlined color="disabled" />;
    case "archived":
      return <StopCircleOutlined color="error" />;
    case "adopted":
      return <CheckCircle color="success" />;
    default:
      return <PlayCircleOutline htmlColor={theme.palette.icon.success} />;
  }
};

const ProgressText = ({ days, text }: { days: number; text: string }) => (
  <Typography>
    <Typography component="span" variant="subtitle2">
      {`${days ?? "--"} ${days === 1 ? "day " : "days "}`}
    </Typography>
    {text}
  </Typography>
);

const StageProgress = ({
  meetings,
  funnelStage,
  funnelStageAge,
}: {
  meetings: Meeting[];
  funnelStage: FunnelStage;
  funnelStageAge: number;
}): ReactElement => {
  const { kickOff, conclusion, today } = getMeetingTimes(meetings);

  if (!conclusion || !kickOff || !today || funnelStage !== "pilot") {
    return (
      <ProgressText
        days={funnelStageAge}
        text={`in ${capitalizeFirstLetter(funnelStage)}`}
      />
    );
  }

  if (today < kickOff) {
    const daysUntilKickOff = daysBetweenDates(
      new Date(today),
      new Date(kickOff)
    );
    return <ProgressText days={daysUntilKickOff} text="until Kick-off" />;
  }

  if (today === kickOff) {
    return <Typography variant="subtitle2">Kick-off today!</Typography>;
  }

  if (today < conclusion) {
    const daysUntilConclusion = daysBetweenDates(
      new Date(today),
      new Date(conclusion)
    );
    return <ProgressText days={daysUntilConclusion} text="until Conclusion" />;
  }

  if (today === conclusion) {
    return <Typography variant="subtitle2">Conclusion today!</Typography>;
  }

  if (today > conclusion) {
    const daysSinceConclusion = daysBetweenDates(
      new Date(conclusion),
      new Date(today)
    );

    return <ProgressText days={daysSinceConclusion} text="since Conclusion" />;
  }

  return (
    <ProgressText
      days={funnelStageAge}
      text={`in ${capitalizeFirstLetter(funnelStage)}`}
    />
  );
};

function ProjectKanbanCard(props: Props): ReactElement {
  const { setGlobalLoader } = useContext(GlobalLoaderContext);
  const [isLogoUrlBroken, setIsLogoUrlBroken] = useState(false);
  const [project, setProject] = useReducer<Reducer<Project, Partial<Project>>>(
    (state, newState) => ({ ...state, ...newState }),
    props.project
  );

  const selectedStartup = props.project.opportunities?.find(
    (opportunity) => opportunity.isSelectedForPilot === true
  );
  const selectedStartupName = selectedStartup?.startup?.name;
  const selectedStartupLogoUrl = selectedStartup?.startup?.files[0]?.url;
  const projectOwner = props.project.projectOwner?.name;
  const formattedProjectCreatedDate = formatDate(
    new Date(props.project.dateCreated)
  );

  const numberOfOpportunities =
    props.project.opportunities?.filter(
      (opportunity) =>
        opportunity.isQualified === true ||
        props.project.funnelStage === "discover"
    ).length || 0;
  const isEarlyStage =
    stageScores[props.project.funnelStage] <= stageScores["assess"];
  const isArchivedOrOnhold =
    props.project.status === "archived" || props.project.status === "on hold";
  const isDiscover = props.project.funnelStage === "discover";

  const numberOfDemos = props.project.opportunities?.reduce(
    (previousOpportunity, currentOpportunity) => {
      if (currentOpportunity.productDemos) {
        return (previousOpportunity += currentOpportunity.productDemos.length);
      }
      return previousOpportunity;
    },
    0
  );

  const isAdopted = props.project.status === "adopted";
  const isPassive = isAdopted || isArchivedOrOnhold;

  const updateProject = () => {
    try {
      setGlobalLoader(true);
      ProjectHttpService.updateProject({
        id: project.id,
        bookmarked: !project.bookmarked,
      }).then(() => {
        props.refreshProjectData(true);
      });
    } catch (error) {
      enqueueSnackbar("Something went wrong with updating project", {
        variant: "error",
      });
      setProject({ bookmarked: project.bookmarked });
    } finally {
      setGlobalLoader(false);
    }
  };

  const handleBookmark = () => {
    setProject({ bookmarked: !project.bookmarked });
    updateProject();
  };

  return (
    <>
      <Box display="flex" alignItems="center">
        <Bookmark
          bookmarked={props.project.bookmarked}
          handleBookmark={handleBookmark}
        />
        <Box display="flex" alignItems="center" gap={1}>
          <ProjectOwnerAvatar>
            <Typography variant="overline">
              {nameToInitials(projectOwner)}
            </Typography>
          </ProjectOwnerAvatar>
          <Typography variant="body2" color="text.mediumEmphasis">
            {projectOwner}
          </Typography>
        </Box>
      </Box>
      <VentureClientNameWrapper>
        <Typography variant="subtitle2" color="text.mediumEmphasis">
          {props.project.businessUnit.ventureClient.name}
        </Typography>
        <Typography variant="body2" color="text.mediumEmphasis">
          {props.project.businessUnit.name}
        </Typography>
      </VentureClientNameWrapper>
      {isEarlyStage ? (
        <Box>
          <Typography
            variant="subtitle2"
            color={isPassive ? "text.mediumEmphasis" : "text.primary"}
          >
            {props.project.name}
          </Typography>
          <Typography
            variant="body2"
            color={isPassive ? "text.disabled" : "text.mediumEmphasis"}
          >
            {formattedProjectCreatedDate}
          </Typography>
        </Box>
      ) : (
        <StartupLogoBox>
          {selectedStartupLogoUrl && !isLogoUrlBroken ? (
            <Box
              component="img"
              src={selectedStartupLogoUrl}
              onError={() => {
                setIsLogoUrlBroken(true);
              }}
              alt={`${selectedStartupName} logo`}
              sx={{
                maxWidth: "80px",
                backgroundColor: "surface.primary.main",
                maxHeight: "25px",
                objectFit: "contain",
              }}
            />
          ) : (
            <PlaceHolderLogoBox>
              <StartupPlaceHolderLogo />
            </PlaceHolderLogoBox>
          )}
          <Typography
            variant="subtitle2"
            color={isPassive ? "text.mediumEmphasis" : "text.primary"}
          >
            {selectedStartupName}
          </Typography>
        </StartupLogoBox>
      )}
      {isEarlyStage ? (
        <Box
          display="flex"
          gap={1}
          height="24px"
          color={isPassive ? "text.disabled" : "text.primary"}
        >
          <Box gap={0.5} alignItems="center" display="flex">
            <RocketLaunchOutlinedIcon sx={{ fontSize: "13px" }} />
            <Typography
              variant="body2"
              data-testid={`nrOfStartups-${props.project.id}`}
            >
              {numberOfOpportunities}
            </Typography>
          </Box>
          {!isDiscover && (
            <Box gap={0.5} alignItems="center" display="flex">
              <TvOutlinedIcon sx={{ fontSize: "13px" }} />
              <Typography
                variant="body2"
                data-testid={`nrOfDemos-${props.project.id}`}
              >
                {numberOfDemos}
              </Typography>
            </Box>
          )}
        </Box>
      ) : (
        <Box>
          <Typography
            variant="body1"
            color={isPassive ? "text.mediumEmphasis" : "text.primary"}
          >
            {props.project.name}
          </Typography>
          <Typography
            variant="body2"
            color={isPassive ? "text.disabled" : "text.mediumEmphasis"}
          >
            {formattedProjectCreatedDate}
          </Typography>
        </Box>
      )}

      {props.project.focusAreas?.length != 0 && (
        <StyledFocusAreaWrapper>
          {props.project.focusAreas?.map((focusArea, index) => (
            <Chip
              variant="outlined"
              key={focusArea.id}
              label={focusArea.name}
              data-testid={`focusArea-${index + 1}`}
              color="secondary"
              size="small"
            />
          ))}
        </StyledFocusAreaWrapper>
      )}
      <Box display="flex" gap={1} alignItems="center">
        <ProjectStatusIcon status={props.project.status} />
        {!isPassive ? (
          <StageProgressWrapper>
            <StageProgress
              meetings={project.meetings}
              funnelStage={project.funnelStage}
              funnelStageAge={project.funnelStageAge}
            />
          </StageProgressWrapper>
        ) : (
          <Typography
            bgcolor={theme.palette.background.default}
            color="text.mediumEmphasis"
            p={theme.spacing(0.5, 1)}
            width="100%"
          >
            <Typography component="span" textTransform="capitalize">
              {props.project.status}
            </Typography>
            {props.project.status === "on hold" &&
              formatStatusDate(
                props.project.onHoldDeadline,
                props.project.status
              )}
            {props.project.status === "archived" &&
              formatStatusDate(
                props.project.projectHistoryStatusChange[0]?.dateTriggered,
                props.project.status
              )}
          </Typography>
        )}
      </Box>
    </>
  );
}

export default function ProjectKanbanCardUserBased(props: Props): ReactElement {
  const { isExternalUser } = useRoles();
  const [modalOpen, setModalOpen] = useState(false);
  const isArchivedOrOnhold =
    props.project.status === "archived" || props.project.status === "on hold";
  const isAdopted = props.project.status === "adopted";
  const isOffTrack = props.project.offTrack;

  return (
    <>
      {isExternalUser ? (
        <StyledProjectCard
          $isArchivedOrOnhold={isArchivedOrOnhold}
          $isAdopted={isAdopted}
          $offtrack={isOffTrack}
          data-testid={`project-card-${props.project.id}`}
          onClick={() => setModalOpen(true)}
          sx={{
            cursor: "pointer",
          }}
        >
          <ProjectKanbanCard
            project={props.project}
            refreshProjectData={props.refreshProjectData}
          />
        </StyledProjectCard>
      ) : (
        <NavLink to={`/projects/${props.project.id}`}>
          <StyledProjectCard
            $isArchivedOrOnhold={isArchivedOrOnhold}
            $isAdopted={isAdopted}
            $offtrack={isOffTrack}
            data-testid={`project-card-${props.project.id}`}
          >
            <ProjectKanbanCard
              project={props.project}
              refreshProjectData={props.refreshProjectData}
            />
          </StyledProjectCard>
        </NavLink>
      )}
      {modalOpen && (
        <ProjectDetailsModal
          modalOpen={modalOpen}
          setModalOpen={setModalOpen}
          projectId={props.project.id}
        />
      )}
    </>
  );
}
