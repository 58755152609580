import { Stack, styled, Typography } from "@mui/material";
import theme from "../../theme";

const StyledCardContainer = styled(Stack)(() => ({
  width: "auto",
  height: "265px",
  padding: theme.spacing(2),
  borderRadius: theme.shape.radius.minimal,
  backgroundColor: theme.palette.background.paper,
}));

const StyledImage = styled("img")(() => ({
  width: "100%",
  height: "120px",
  borderRadius: theme.shape.radius.minimal,
  objectFit: "cover",
  marginBottom: theme.spacing(2),
}));

const TruncatedText = styled(Typography, {
  shouldForwardProp: (prop) => prop !== "$maxLines",
})<{ $maxLines: number }>(({ $maxLines }) => ({
  overflow: "hidden",
  textOverflow: "ellipsis",
  display: "-webkit-box",
  WebkitLineClamp: $maxLines,
  WebkitBoxOrient: "vertical",
}));

interface ProblemCardProps {
  name: string;
  shortDescription: string;
  image: string;
}

const ProblemCard = ({
  name,
  shortDescription,
  image,
}: ProblemCardProps): JSX.Element => {
  return (
    <StyledCardContainer data-testid="problem-card">
      <StyledImage src={image} alt={name} />
      <Stack spacing={1}>
        <TruncatedText
          $maxLines={2}
          variant="subtitle1"
          data-testid="problem-name"
        >
          {name}
        </TruncatedText>
        <TruncatedText
          $maxLines={3}
          variant="body2"
          color="text.secondary"
          data-testid="problem-short-description"
        >
          {shortDescription}
        </TruncatedText>
      </Stack>
    </StyledCardContainer>
  );
};

export default ProblemCard;
