import {
  useTheme,
  useMediaQuery,
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  Typography,
  Box,
  styled,
  TextField,
  Stack,
} from "@mui/material";
import {
  ChangeEvent,
  ReactElement,
  Reducer,
  useEffect,
  useReducer,
  useState,
} from "react";
import { ProjectHttpService } from "../../../Http/Project/Project.http.service";
import { Project } from "../../../Types/Project";
import { VentureClientHttpService } from "../../../Http/VentureClient/VentureClient.http.service";
import UserSelectInput from "../../UI/InputFields/UserSelect";
import { FocusArea, VentureClient } from "../../../Types/VentureClient";
import BusinessUnitSelect from "../../UI/InputFields/BusinessUnitSelect";
import {
  AUTO_GENERATE_SHORT_DESCRIPTION,
  NAME_TOOLTIP,
  SHORT_DESCRIPTION_TOOLTIP,
} from "../../../Constants/TooltipText";
import { SelectInput } from "../../UI/InputFields/SelectInput";
import { useSnackbar } from "notistack";
import { MultiSelectInput } from "../../UI/InputFields/MultiSelectInput";
import { SelectOption } from "../../../Types/Common";
import CreateEntityByName from "../../UI/Modals/CreateEntityByName/CreateEntityByName";
import AutoGenerateTextButton from "../../UI/AutoGenerateTextButton";
import { AiHttpService } from "../../../Http/Ai/Ai.http.service";
import TextGenerationRevertModal from "../../UI/Modals/TextGenerationRevertModal/TextGenerationRevertModal";
import AutoGenerateBlurBackground from "../../UI/AutoGenerateBlurBackground";
import { getErrorMessage } from "../../../utils";
import theme from "../../../theme";
import CustomToolTip from "../../UI/CustomToolTip";
import useRoles from "../../../Hooks/useRoles";
import ScrollableDialogContent from "../../UI/Modals/ScrollableDialogContent";

interface Props {
  handleModalClose: () => void;
  handleSave: () => void;
  modalOpen: boolean;
  projectData: Project;
}

const Container = styled(Box)(() => ({
  width: "100%",
  position: "relative",
  "& .auto-generate-text-button": {
    position: "absolute",
    right: theme.spacing(2),
    top: theme.spacing(-1.5),
  },
}));

interface editProjectRequiredFields {
  Name: string;
  "Short Description": string;
  "Organizational Unit": number | null;
  "Impact Type": string;
}

const impactTypeOptions = ["Cost", "Revenue", "Cost & Revenue"];

function EditProjectModal(props: Props): ReactElement {
  const theme = useTheme();
  const { isExternalUser, ventureClientId, canManage } = useRoles(
    props.projectData
  );

  const { enqueueSnackbar } = useSnackbar();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [project, setProject] = useReducer<Reducer<Project, Partial<Project>>>(
    (state, newState) => ({ ...state, ...newState }),
    props.projectData
  );
  const [ventureClientSelectOptions, setVentureClientSelectOptions] = useState<
    VentureClient[]
  >([]);
  const [selectedVentureClient, setSelectedVentureClient] =
    useState<VentureClient>();
  const [focusAreas, setFocusAreas] = useState<FocusArea[]>([]);
  const [createFocusAreaModalOpen, setCreateFocusAreaModalOpen] =
    useState<boolean>(false);
  const [isGenerated, setIsGenerated] = useState(false);
  const [isGenerating, setIsGenerating] = useState(false);
  const [isRevertModalOpen, setIsRevertModalOpen] = useState(false);
  const [initialShortDescription, setInitialShortDescription] = useState("");

  useEffect(() => {
    setProject(props.projectData);
    if (isExternalUser && ventureClientId) {
      VentureClientHttpService.getVentureClientById(ventureClientId).then(
        (_ventureClient) => {
          setSelectedVentureClient(_ventureClient);
          setFocusAreas(_ventureClient.focusAreas);
        }
      );
    } else {
      VentureClientHttpService.getVentureClients().then((ventureClients) => {
        setVentureClientSelectOptions(ventureClients);
        const initialVentureClient = ventureClients.find(
          (ventureClient) =>
            ventureClient.id === props.projectData.businessUnit.ventureClientId
        );

        if (initialVentureClient) {
          setSelectedVentureClient(initialVentureClient);
          setFocusAreas(initialVentureClient.focusAreas);
        }
      });
    }
  }, []);

  const handleSelectVentureClient = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const ventureClient = ventureClientSelectOptions.find(
      (ventureClient) => ventureClient.id === parseInt(event.target.value)
    );
    setProject({
      focusAreas: [],
      businessUnitId: undefined,
    });

    if (ventureClient) {
      setFocusAreas(ventureClient.focusAreas);
      setSelectedVentureClient(ventureClient);
    }
  };

  const editProjectRequiredFields: editProjectRequiredFields = {
    Name: project.name,
    "Short Description": project.shortDescription,
    "Organizational Unit": project.businessUnitId,
    "Impact Type": project.impactType,
  };

  const checkRequiredFields = () => {
    const emptyRequiredFieldsArray: string[] = [];
    for (const [key, value] of Object.entries(editProjectRequiredFields)) {
      if (("" + value).trim().length === 0 || !value) {
        emptyRequiredFieldsArray.push(key);
      }
    }
    return emptyRequiredFieldsArray;
  };

  const showError = (value: string) => {
    enqueueSnackbar(`Please Enter ${value}`, {
      variant: "error",
    });
  };

  const saveProject = async () => {
    const emptyRequiredFields = checkRequiredFields();
    if (emptyRequiredFields.length > 0) {
      return showError(emptyRequiredFields[0]);
    }

    await ProjectHttpService.updateProject(project as Project);
    props.handleModalClose();
    props.handleSave();
  };

  const handleSelectBusinessUnit = (unitId: number) => {
    setProject({ businessUnitId: unitId });
  };

  const handleAddFocusArea = (focusArea: FocusArea) => {
    setProject({
      focusAreas: [...project.focusAreas, focusArea],
    });

    getUpdatedVentureClient();
  };

  const getUpdatedVentureClient = async () => {
    if (selectedVentureClient) {
      const updatedVentureClient =
        await VentureClientHttpService.getVentureClientById(
          selectedVentureClient.id
        );
      setFocusAreas(updatedVentureClient.focusAreas);
    }
  };

  const generateShortDescription = async () => {
    setIsGenerating(true);
    setInitialShortDescription(project.shortDescription);

    try {
      if (project.description) {
        const generatedShortDescription = await AiHttpService.autoGenerateText(
          project.description,
          "short-description"
        );
        setProject({ shortDescription: generatedShortDescription });
        setIsGenerated(true);
      } else {
        enqueueSnackbar(`Please Enter Problem Description`, {
          variant: "error",
        });
      }
      // eslint-disable-next-line
    } catch (error: any) {
      const errorMessage = getErrorMessage(error);
      enqueueSnackbar(`Could not auto generate: ${errorMessage}`, {
        variant: "error",
      });
    } finally {
      setIsGenerating(false);
    }
  };

  const handleRevertModal = (open: boolean, isReverted?: boolean) => {
    setIsRevertModalOpen(open);
    if (isReverted) {
      setIsGenerated(false);
      setProject({ shortDescription: initialShortDescription });
    }
  };

  return (
    <>
      <Dialog
        fullScreen={fullScreen}
        open={props.modalOpen}
        data-testid="edit-project-modal"
        fullWidth
        PaperProps={{
          sx: {
            gap: theme.spacing(4),
          },
        }}
      >
        <DialogTitle id="edit-project-modal">
          Edit Basic Information
        </DialogTitle>
        <ScrollableDialogContent>
          {project && (
            <Stack
              component="form"
              noValidate
              autoComplete="off"
              gap={4}
              pt={1.25}
            >
              <TextField
                id="name"
                value={project.name}
                label={
                  <>
                    {"Name"}
                    <CustomToolTip
                      id="nameTooltip"
                      key="nameTooltip"
                      toolTipText={NAME_TOOLTIP}
                    />
                  </>
                }
                InputLabelProps={{
                  sx: {
                    marginTop: theme.spacing(-0.875),
                  },
                  shrink: true,
                }}
                onChange={(e) => setProject({ name: e.target.value })}
                fullWidth
                required
              />
              <Container>
                <TextField
                  id="shortDescription"
                  label={
                    <>
                      {"Short Description"}
                      <CustomToolTip
                        id="shortDescriptionTooltip"
                        key="shortDescriptionTooltip"
                        toolTipText={SHORT_DESCRIPTION_TOOLTIP}
                      />
                    </>
                  }
                  value={project.shortDescription}
                  InputLabelProps={{
                    sx: {
                      marginTop: theme.spacing(-0.875),
                    },
                    shrink: true,
                  }}
                  onChange={(e) =>
                    setProject({ shortDescription: e.target.value })
                  }
                  fullWidth
                  required
                  multiline
                  minRows={3}
                ></TextField>
                {props.projectData.businessUnit.ventureClient.genAiEnabled && (
                  <AutoGenerateTextButton
                    isGenerated={isGenerated}
                    isGenerating={isGenerating}
                    handleRevertModal={handleRevertModal}
                    generateText={generateShortDescription}
                    toolTipText={AUTO_GENERATE_SHORT_DESCRIPTION}
                  />
                )}
                {isGenerating && <AutoGenerateBlurBackground />}
              </Container>
              <Box display="flex" gap={2}>
                {!isExternalUser && (
                  <SelectInput
                    id="ventureClient"
                    label="Company"
                    value={selectedVentureClient?.id}
                    onChange={handleSelectVentureClient}
                    selectValues={ventureClientSelectOptions.map((vc) => {
                      return {
                        id: vc.id,
                        name: vc.name,
                      };
                    })}
                    editMode
                    required
                    fullWidth
                  />
                )}
                <BusinessUnitSelect
                  selectedVentureClient={selectedVentureClient}
                  defaultBusinessUnitId={project.businessUnitId}
                  handleSelectBusinessUnit={handleSelectBusinessUnit}
                />
              </Box>
              <Box display="flex" gap={2}>
                <MultiSelectInput
                  fieldId="focusArea"
                  labelText="Focus Area"
                  selectValues={focusAreas as FocusArea[]}
                  value={(project.focusAreas as SelectOption[]) || []}
                  onChange={(focusAreas) => {
                    setProject({ focusAreas: focusAreas as FocusArea[] });
                  }}
                  addIcon={canManage}
                  setModalOpen={() => setCreateFocusAreaModalOpen(true)}
                  disabled={selectedVentureClient?.id === undefined}
                  fullWidth
                />
                <SelectInput
                  id="impactType"
                  label="Business Impact Type"
                  value={project.impactType}
                  onChange={(e) => setProject({ impactType: e.target.value })}
                  selectValues={
                    impactTypeOptions.map((type) => {
                      return {
                        id: type,
                        name: type,
                      };
                    }) || []
                  }
                  editMode
                  required
                  fullWidth
                />
              </Box>
              <Typography variant="subtitle1">
                Project Administrators
              </Typography>

              <UserSelectInput
                onChange={(user) =>
                  setProject({
                    projectOwnerId: user ? user.id : undefined,
                  })
                }
                defaultUserId={project.projectOwnerId}
                label="Project Owner"
                sourcing
              />

              <UserSelectInput
                onChange={(user) => {
                  setProject({
                    startupIntelligenceId: user ? user.id : null,
                  });
                }}
                defaultUserId={project.startupIntelligenceId}
                label="Startup Sourcing"
                sourcing
              />
            </Stack>
          )}
        </ScrollableDialogContent>
        <DialogActions>
          <Button
            onClick={props.handleModalClose}
            color="primary"
            data-testid="cancel-project-button"
          >
            Cancel
          </Button>
          <Button
            onClick={saveProject}
            variant="contained"
            data-testid="save-project-button"
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
      {createFocusAreaModalOpen && (
        <CreateEntityByName
          name="focusArea"
          label="Focus Area"
          modalOpen={createFocusAreaModalOpen}
          setModalOpen={() => setCreateFocusAreaModalOpen(false)}
          handleAdd={handleAddFocusArea}
          ventureClientId={props.projectData.businessUnit.ventureClientId}
        />
      )}
      {isRevertModalOpen && (
        <TextGenerationRevertModal
          isRevertModalOpen={isRevertModalOpen}
          handleRevertModal={handleRevertModal}
          setIsGenerated={setIsGenerated}
        />
      )}
    </>
  );
}
export default EditProjectModal;
