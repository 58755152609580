import { Opportunity } from "../../Types/Opportunity";

export default class OpportunityMapper {
  public static map(opportunity: Opportunity): Opportunity {
    return {
      ...opportunity,
      startup: {
        ...opportunity.startup,
        dateFounded: opportunity.startup?.dateFounded
          ? new Date(opportunity.startup.dateFounded)
          : null,
        lastQualityCheckDate: opportunity.startup?.lastQualityCheckDate
          ? new Date(opportunity.startup.lastQualityCheckDate)
          : null,
        qualityChecks:
          opportunity.startup?.qualityChecks?.map((qualityCheck) => ({
            id: qualityCheck.id,
            sortOrder: qualityCheck.sortOrder,
            name: qualityCheck.name,
            description: qualityCheck.description,
            status:
              qualityCheck.startupQualityChecks?.status !== undefined
                ? qualityCheck.startupQualityChecks.status
                : qualityCheck.status,
          })) || opportunity.startup?.qualityChecks,
      },
    };
  }
}
