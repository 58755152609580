import {
  CircularProgress,
  InputLabel,
  Stack,
  styled,
  Typography,
} from "@mui/material";
import { ReactElement, useState } from "react";
import { useDropzone } from "react-dropzone";
import theme from "../../../../theme";
import UploadOutlinedIcon from "@mui/icons-material/UploadOutlined";
import FileDownloadDoneOutlinedIcon from "@mui/icons-material/FileDownloadDoneOutlined";

interface ImageUploadProps {
  label: string;
  maxSize?: string;
  uploadImage: (file: File) => Promise<void>;
}

const StyledUploadBox = styled(Stack)(() => ({
  width: "100%",
  height: "100%",
  alignItems: "center",
  justifyContent: "center",
  border: `1px solid ${theme.palette.borderOutline.main}`,
  borderRadius: theme.shape.radius.minimal,
  cursor: "pointer",
}));

const StyledInputLabel = styled(InputLabel)(({ theme }) => ({
  position: "absolute",
  top: "-8px",
  left: "12px",
  backgroundColor: "white",
  padding: "0 4px",
  color: theme.palette.text.secondary,
}));

export default function ImageUploadField({
  label,
  maxSize = "5MB",
  uploadImage,
}: ImageUploadProps): ReactElement {
  const [isUploading, setIsUploading] = useState(false);
  const [isUploaded, setIsUploaded] = useState(false);

  const onDrop = async (acceptedFiles: File[]) => {
    setIsUploaded(false);

    if (acceptedFiles.length > 0) {
      const file = acceptedFiles[0];
      setIsUploading(true);

      await uploadImage(file);
      setIsUploaded(true);

      setIsUploading(false);
    } else {
      setIsUploaded(false);
      setIsUploading(false);
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: "image/*",
    multiple: false,
  });

  return (
    <Stack {...getRootProps()} position="relative" height="100%">
      <StyledInputLabel shrink>{label}</StyledInputLabel>
      <StyledUploadBox>
        {isUploaded ? (
          <FileDownloadDoneOutlinedIcon
            sx={{ fontSize: "4rem" }}
            color="primary"
          />
        ) : isUploading ? (
          <CircularProgress color="primary" />
        ) : (
          <Stack direction="column" alignItems="center" gap={1}>
            <UploadOutlinedIcon
              fontSize="large"
              sx={{ color: theme.palette.surface.action.main }}
            />
            <Typography color={theme.palette.text.disabled} textAlign="center">
              Max: {maxSize} <br />
              (ideally 1:1 images)
            </Typography>
          </Stack>
        )}
      </StyledUploadBox>
      <input {...getInputProps()} />
    </Stack>
  );
}
