import { ReactElement } from "react";
import { Box, Typography, styled } from "@mui/material";
import TopicOutlinedIcon from "@mui/icons-material/TopicOutlined";
import theme from "../../theme";

const EmptyStageBox = styled(Box)(({ theme }) => ({
  borderRadius: theme.shape.radius.cardSmall,
  border: `1px solid ${theme.palette.borderOutline.main}`,
  padding: theme.spacing(3, 4, 5, 4),
  display: "flex",
  flexDirection: "column",
}));

export function EmptyStageKanbanCard(): ReactElement {
  return (
    <EmptyStageBox>
      <TopicOutlinedIcon
        fontSize="medium"
        color="disabled"
        sx={{ margin: theme.spacing(0, 0, 0.5, -0.25) }}
      />
      <Typography variant="subtitle2" color="text.disabled">
        No projects here.
      </Typography>
      <Typography variant="body2" color="text.disabled">
        It might be your filters, or there are no current projects in this
        phase.
      </Typography>
    </EmptyStageBox>
  );
}
