import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  styled,
  tableCellClasses,
} from "@mui/material";
import { Dispatch, Fragment, ReactElement, SetStateAction } from "react";
import { ProductDemo } from "../../../../../Types/ProductDemo";
import { Project } from "../../../../../Types/Project";
import theme from "../../../../../theme";
import ProductDemosTableRow from "./DemosTableRow";

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.surface.tertiary.dark,
    color: theme.palette.text.primaryInvert.main,
  },
  [`&.${tableCellClasses.body}`]: {
    borderColor: theme.palette.borderOutline.soft,
  },
}));

const StyledTableRow = styled(TableRow)(() => ({
  "&:first-of-type": {
    border: 4,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

interface ProductDemosTableProps {
  project: Project;
  productDemos: ProductDemo[];
  setProductDemos: Dispatch<SetStateAction<ProductDemo[]>>;
  onEditClick: () => void;
  onCancelClick: () => void;
  onSaveClick: () => void;
  onDemosDelete: (index: number) => void;
}

export default function ProductDemosTable(
  props: ProductDemosTableProps
): ReactElement {
  const handleEdit = (productDemo: ProductDemo, index: number) => {
    const newProductDemos = [...props.productDemos];

    newProductDemos[index] = {
      ...productDemo,
    };
    props.setProductDemos(newProductDemos);
  };

  return (
    <Fragment>
      <TableContainer
        sx={{
          borderTopLeftRadius: theme.shape.radius.minimal,
          borderTopRightRadius: theme.shape.radius.minimal,
        }}
      >
        <Table aria-label="Product Demos">
          <TableHead>
            <StyledTableRow>
              <StyledTableCell sx={{ width: theme.spacing(5) }} />
              <StyledTableCell width="50%">Startup</StyledTableCell>
              <StyledTableCell width="30%">Date</StyledTableCell>
              <StyledTableCell width="20%">Participants</StyledTableCell>
              <StyledTableCell></StyledTableCell>
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {props.productDemos.map((productDemo, index: number) => (
              <ProductDemosTableRow
                index={index}
                key={index}
                project={props.project}
                productDemo={productDemo}
                onEditClick={props.onEditClick}
                onEdit={(productDemo) => handleEdit(productDemo, index)}
                onDelete={() => props.onDemosDelete(index)}
                onCancelClick={props.onCancelClick}
                onSaveClick={props.onSaveClick}
              />
            ))}
            {props.productDemos.length < 1 && (
              <StyledTableRow>
                <StyledTableCell colSpan={5}>
                  <Typography color="text.disabled">
                    No Product Demo Added
                  </Typography>
                </StyledTableCell>
              </StyledTableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Fragment>
  );
}
