import { ReactElement } from "react";
import useRoles from "../../../Hooks/useRoles";
import { Box, Stack, Typography } from "@mui/material";
import { MiscellaneousServices, WarningAmber } from "@mui/icons-material";

function StartupIncompleteInfo(): ReactElement {
  const { isExternalUser } = useRoles();

  const infoObject = !isExternalUser
    ? {
        icon: <WarningAmber color="warning" />,
        title: "Startup Profile needs your Attention",
        description: (
          <>
            This Startup was added by a client and currently shows limited
            information. <br />
            Please fill in the necessary information to update the profile.
          </>
        ),
      }
    : {
        icon: <MiscellaneousServices />,
        title: "We’re Updating this Profile",
        description:
          "This startup currently shows limited information. Details will be added by the 27pilots team soon.",
      };

  return (
    <Box display="flex" gap={2}>
      {infoObject.icon}
      <Stack>
        <Typography variant="h6">{infoObject.title}</Typography>
        <Typography variant="body1">{infoObject.description}</Typography>
      </Stack>
    </Box>
  );
}

export default StartupIncompleteInfo;
