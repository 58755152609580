import {
  useTheme,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Stack,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { ReactElement, Reducer, useReducer, Fragment } from "react";
import BusinessUnitSelect from "../../UI/InputFields/BusinessUnitSelect";
import { getErrorMessage } from "../../../utils";
import { LeadProject, LeadProjectLeader } from "../../../Types/LeadProject";
import { SelectInput } from "../../UI/InputFields/SelectInput";
import ClientContactSelect from "../../UI/InputFields/ClientContactSelect";
import { LeadProjectsHttpService } from "../../../Http/LeadProjects/LeadProjects.http.service";

interface ManageProjectLeadLeaderModalProps {
  setModalOpen: (state: boolean) => void;
  modalOpen: boolean;
  leadProject: LeadProject;
  onRefresh: () => void;
  LeadProjectLeader?: LeadProjectLeader;
}
function ManageLeadProjectLeaderModal(
  props: ManageProjectLeadLeaderModalProps
): ReactElement {
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const [newProjectLeadLeader, setNewProjectLeadLeader] = useReducer<
    Reducer<LeadProjectLeader, Partial<LeadProjectLeader>>
  >(
    (state, newState) => ({ ...state, ...newState }),
    props.LeadProjectLeader ||
      ({
        leadProjectId: props.leadProject.id,
      } as LeadProjectLeader)
  );

  const isCreateMode = !props.LeadProjectLeader;

  const addLeadProjectLeader = async () => {
    const canAddProjectLeadLeader =
      newProjectLeadLeader.projectLeaderId ||
      newProjectLeadLeader.useCaseId ||
      newProjectLeadLeader.businessUnitId;

    if (!canAddProjectLeadLeader) {
      return enqueueSnackbar("Please provide Project Leader information", {
        variant: "error",
      });
    }

    await LeadProjectsHttpService.addLeadProjectLeader(newProjectLeadLeader)
      .then(() => {
        props.onRefresh();
      })
      .catch((error) => {
        const errorMessage = getErrorMessage(error);
        return enqueueSnackbar(
          `Could not add project lead leader: ${errorMessage}`,
          { variant: "error" }
        );
      })
      .finally(() => {
        props.setModalOpen(false);
      });
  };

  const updateLeadProjectLeader = () => {
    LeadProjectsHttpService.updateLeadProjectLeader(newProjectLeadLeader)
      .then(() => {
        props.onRefresh();
      })
      .catch((error) => {
        const errorMessage = getErrorMessage(error);
        return enqueueSnackbar(
          `Could not update project lead leader: ${errorMessage}`,
          { variant: "error" }
        );
      })
      .finally(() => {
        props.setModalOpen(false);
      });
  };

  const handleBusinessUnitChange = (selectedUnitId: number) => {
    setNewProjectLeadLeader({ businessUnitId: selectedUnitId });
  };

  const handleBusinessUnitChangeOnProjectLeaderSelected =
    (projectLeaderSelected: {
      projectLeaderId: number;
      projectLeader: LeadProjectLeader;
    }) => {
      if (!projectLeaderSelected.projectLeader) return;

      if (newProjectLeadLeader.businessUnitId) {
        setNewProjectLeadLeader({
          projectLeaderId: projectLeaderSelected.projectLeaderId,
        });
      } else
        setNewProjectLeadLeader({
          projectLeaderId: projectLeaderSelected.projectLeaderId,
          businessUnitId: projectLeaderSelected.projectLeader.businessUnitId,
        });
    };

  const handleUseCaseChange = (selectedUseCaseId: number) => {
    setNewProjectLeadLeader({ useCaseId: selectedUseCaseId });
  };

  return (
    <Fragment>
      <Dialog
        fullWidth
        open={props.modalOpen}
        data-testid="manage-lead-project-leader-modal"
        PaperProps={{
          sx: {
            gap: theme.spacing(4),
          },
        }}
      >
        <DialogTitle>
          {isCreateMode ? "Add" : "Edit"} Project Leader
        </DialogTitle>
        <DialogContent>
          <Stack direction="column" gap={3}>
            <ClientContactSelect
              editMode
              labelText="Project Leader"
              ventureClientId={props.leadProject.ventureClient.id}
              onChange={handleBusinessUnitChangeOnProjectLeaderSelected}
              contactData={props.LeadProjectLeader?.projectLeader}
              fieldId="projectLeaderId"
              field="projectLeader"
            />

            <BusinessUnitSelect
              handleSelectBusinessUnit={handleBusinessUnitChange}
              defaultBusinessUnitId={newProjectLeadLeader.businessUnitId}
              selectedVentureClient={props.leadProject.ventureClient}
              required={false}
            />

            <SelectInput
              id="use-cases"
              fullWidth
              label="Use Cases"
              selectValues={props.leadProject.useCases}
              onChange={(event) =>
                handleUseCaseChange(parseInt(event.target.value))
              }
              editMode
              value={newProjectLeadLeader.useCaseId}
            />
          </Stack>
        </DialogContent>
        <DialogActions sx={{ mt: 0 }}>
          <Button onClick={() => props.setModalOpen(false)}>Cancel</Button>
          <Button
            onClick={
              isCreateMode ? addLeadProjectLeader : updateLeadProjectLeader
            }
            variant="contained"
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}
export default ManageLeadProjectLeaderModal;
