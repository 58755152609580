import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Stack,
  TextField,
} from "@mui/material";
import { Box } from "@mui/system";
import { enqueueSnackbar } from "notistack";
import { ReactElement, Reducer, useReducer } from "react";
import { FileHttpService } from "../../../../Http/File/File.http.service";
import { VentureClientHttpService } from "../../../../Http/VentureClient/VentureClient.http.service";
import theme from "../../../../theme";
import { Address } from "../../../../Types/Startup";
import {
  EditVentureClientDTO,
  VentureClient,
} from "../../../../Types/VentureClient";
import { getErrorMessage } from "../../../../utils";
import { AddressAutocomplete } from "../../../UI/InputFields/AddressAutocomplete";
import ClientContactSelect from "../../../UI/InputFields/ClientContactSelect";
import CustomTextField from "../../../UI/InputFields/CustomTextField";
import LogoUploadDropbox from "../../../UI/LogoUploadDropbox";
import ScrollableDialogContent from "../../../UI/Modals/ScrollableDialogContent";

const requiredFields: (keyof EditVentureClientDTO)[] = ["name"];

interface EditVentureClientModalProps {
  ventureClient: VentureClient;
  open: boolean;
  onClose(): void;
  onSave(): void;
}

export default function EditVentureClientModal({
  open,
  onClose,
  onSave,
  ...props
}: EditVentureClientModalProps): ReactElement {
  const [ventureClient, setVentureClient] = useReducer<
    Reducer<EditVentureClientDTO, Partial<EditVentureClientDTO>>
  >((state, newState) => ({ ...state, ...newState }), {
    id: props.ventureClient.id,
    name: props.ventureClient.name,
    industry: props.ventureClient.industry,
    website: props.ventureClient.website,
    country: props.ventureClient.country,
    city: props.ventureClient.city,
    state: props.ventureClient.state,
    street: props.ventureClient.street,
    postalCode: props.ventureClient.postalCode,
    files: props.ventureClient.files,
    mainContactId: props.ventureClient.mainContactId,
    mainContact: props.ventureClient.mainContact,
  });

  const addressInfo = [
    props.ventureClient.street,
    props.ventureClient.city,
    props.ventureClient.state,
    props.ventureClient.country,
    props.ventureClient.postalCode,
  ];

  const address = addressInfo.filter(Boolean).join(", ");

  const setAddress = (address: Address) => {
    setVentureClient({
      street: address.fullStreet,
      city: address.city,
      state: address.state,
      country: address.country,
      postalCode: address.postalCode,
    });
  };

  const isMissingRequiredField = () => {
    return requiredFields.some((field) => !ventureClient[field]);
  };

  const saveVentureClient = async () => {
    if (isMissingRequiredField()) {
      enqueueSnackbar("Please fill out required fields", {
        variant: "error",
      });
      return;
    }

    await VentureClientHttpService.updateVentureClient(
      ventureClient as VentureClient
    )
      .then(() => {
        onSave();
        enqueueSnackbar("Company saved successfully.", {
          variant: "success",
        });
        onClose();
      })
      .catch((error) => {
        const errorMessage = getErrorMessage(error);
        enqueueSnackbar(`Could not update company details: ${errorMessage}`, {
          variant: "error",
        });
      });
  };

  const uploadLogo = async (file: File): Promise<void> => {
    try {
      await FileHttpService.upload(
        file,
        ventureClient.id,
        "ventureClient",
        "companyLogo"
      );
    } catch (error) {
      enqueueSnackbar("File can not be uploaded", {
        variant: "error",
      });
    }
  };

  return (
    <Dialog
      open={open}
      fullWidth
      PaperProps={{
        sx: {
          "&.MuiDialog-paper": {
            gap: theme.spacing(3),
          },
        },
      }}
      data-testid="edit-venture-client-modal"
    >
      <DialogTitle sx={{ pb: 0 }}>Edit Company Information</DialogTitle>
      <ScrollableDialogContent>
        <Stack gap={4}>
          <Box display="flex" gap={2} pt={1}>
            <Stack gap={4} width="100%">
              <CustomTextField
                editMode
                label="Name"
                id="name"
                fullWidth
                required={requiredFields.includes("name")}
                variant="outlined"
                value={ventureClient.name}
                onChange={(e) => setVentureClient({ name: e.target.value })}
              />
              <TextField
                data-testid="website"
                id="website"
                label="Website"
                required={requiredFields.includes("website")}
                fullWidth
                variant="outlined"
                onChange={(e) => setVentureClient({ website: e.target.value })}
                value={ventureClient.website}
              />
            </Stack>
            <LogoUploadDropbox addLogo={(file) => uploadLogo(file)} />
          </Box>
          <CustomTextField
            editMode
            label="Industry"
            id="industry"
            fullWidth
            required={requiredFields.includes("industry")}
            variant="outlined"
            value={ventureClient.industry}
            onChange={(e) => setVentureClient({ industry: e.target.value })}
          />
          <ClientContactSelect
            editMode={true}
            labelText="Main Contact"
            fieldId="mainContactId"
            field="mainContact"
            ventureClientId={ventureClient.id}
            onChange={setVentureClient}
            contactData={ventureClient.mainContact}
            required={requiredFields.includes("mainContactId")}
          />
          <AddressAutocomplete
            label="Location"
            defaultAddress={address}
            setAddress={setAddress}
          />
        </Stack>
      </ScrollableDialogContent>
      <DialogActions>
        <Button variant="text" onClick={onClose}>
          Cancel
        </Button>
        <Button variant="contained" onClick={saveVentureClient}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}
