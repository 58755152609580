import {
  AppBar,
  Tabs,
  Tab,
  Box,
  styled,
  Stack,
  Typography,
  Chip,
} from "@mui/material";
import React, { Fragment, ReactElement, useEffect, useState } from "react";
import BusinessUnitsList from "./BusinessUnitsView/BusinessUnitsList";
import { TabPanel, a11yProps } from "../../UI/TabPanel";
import { useHistory, useParams } from "react-router-dom";
import { VentureClientHttpService } from "../../../Http/VentureClient/VentureClient.http.service";
import { VentureClient } from "../../../Types/VentureClient";
import ContentWrapper from "../../ProjectDetails/ContentWrapper";
import theme from "../../../theme";
import PageHeader from "../../UI/PageHeader";
import { extractClientPrefixFromUrl } from "../../../utils";
import { clientSpecifications } from "../../../Constants/ClientSpecifications";
import VentureClientOverview from "./VentureClientOverview/VentureClientOverview";
import VentureClientUnitOverview from "./VentureClientUnitOverview/VentureClientUnitOverview";
import VentureClientDetailsPageSkeleton from "./VentureClientDetailsPageSkeleton";
import CompanyFocusAreas from "./FocusAreasView/CompanyFocusAreas";
import { ClientContactDTO } from "../../../Types/ClientContact";
import ClientContactHttpService from "../../../Http/ClientContact/ClientContact.http.service";
import { enqueueSnackbar } from "notistack";
import ContactsList from "./ContactsView/ContactsList";

export const StyledAppBar = styled(AppBar)(() => ({
  boxShadow: "none",
  backgroundColor: theme.palette.background.default,
}));

export const StyledTabsPanel = styled(Tabs)(() => ({
  "& .MuiTabs-flexContainer": {
    borderBottom: `1px solid ${theme.palette.borderOutline.main}`,
  },
}));

function getClientBackground(domain?: string): string {
  const { hero, background } =
    clientSpecifications[extractClientPrefixFromUrl(domain || "")];
  const isDevelopment = process.env.NODE_ENV === "development";
  const baseS3Url = isDevelopment
    ? "https://vclms-frontend.s3.eu-central-1.amazonaws.com"
    : "https://vclms-frontend-prod.s3.eu-central-1.amazonaws.com";
  const heroUrl = hero ? `${baseS3Url}/hero/${hero}` : "";
  const backgroundUrl = background ? `${baseS3Url}/${background}` : "";

  return heroUrl || backgroundUrl || baseS3Url + "/hero_banner.png";
}

export default function VentureClientDetailsPage(): ReactElement {
  const { id } = useParams<{ id: string }>();
  const history = useHistory();

  const [ventureClientData, setVentureClientData] = useState<VentureClient>();
  const [contacts, setContacts] = useState<ClientContactDTO[]>([]);
  const [refresh, setRefresh] = useState<boolean>(false);
  const [refreshContacts, setRefreshContacts] = useState<boolean>(false);

  const [value, setValue] = useState(0);
  const [isLoadingContacts, setIsLoadingContacts] = useState(false);

  const clientBackground = getClientBackground(ventureClientData?.domain);
  const ventureClientUnitLogo = ventureClientData?.files.find(
    (file) => file.type === "Logo"
  );
  const companyLogo = ventureClientData?.files.find(
    (file) => file.type === "companyLogo"
  );

  const handleChange = (
    _event: React.ChangeEvent<unknown>,
    newValue: number
  ) => {
    setValue(newValue);
  };

  useEffect(() => {
    VentureClientHttpService.getVentureClientById(parseInt(id))
      .then((data) => {
        setVentureClientData(data);
      })
      .catch(() => {
        history.push(`/companies/${id}`);
      });
  }, [id, refresh]);

  useEffect(() => {
    getClientContacts();
  }, [ventureClientData?.id, refreshContacts]);

  useEffect(() => {
    if (ventureClientData?.name) {
      document.title = `${ventureClientData?.name} - Venture Client Platform`;
    } else {
      document.title = `Client`;
    }
  }, [ventureClientData?.name]);

  const getClientContacts = async () => {
    if (!ventureClientData) return;

    try {
      setIsLoadingContacts(true);
      await ClientContactHttpService.getContactsByName(
        ventureClientData?.id,
        ""
      ).then((res) => {
        setContacts(res);
        setIsLoadingContacts(false);
      });
    } catch (error) {
      enqueueSnackbar("Something went wrong with fetching contacts", {
        variant: "error",
      });
      setIsLoadingContacts(false);
    }
  };

  const handleSave = () => {
    setRefresh(!refresh);
  };

  const handleSaveContacts = () => {
    setRefreshContacts(!refreshContacts);
  };

  if (!ventureClientData) {
    return <VentureClientDetailsPageSkeleton />;
  }

  return (
    <Fragment>
      <PageHeader
        title={ventureClientData?.name || ""}
        subtitle={`View and manage key information related to ${ventureClientData?.name}.`}
        dynamicBgImage={clientBackground}
      />
      <ContentWrapper zIndex={3} sx={{ paddingTop: theme.spacing(5) }}>
        <Box
          display="grid"
          gridTemplateColumns={`minmax(0, 312px) minmax(0,100%)`}
          gap={3}
        >
          <Stack gap={5}>
            {ventureClientData && (
              <VentureClientOverview
                ventureClient={ventureClientData}
                companyLogo={companyLogo}
                handleSave={handleSave}
              />
            )}
            {ventureClientData && (
              <VentureClientUnitOverview
                ventureClient={ventureClientData}
                logo={ventureClientUnitLogo}
                handleSave={handleSave}
              />
            )}
          </Stack>
          <Stack gap={3}>
            <StyledAppBar position="static">
              <StyledTabsPanel value={value} onChange={handleChange}>
                <Tab
                  label={
                    <Box display="flex" alignItems="center" gap={1}>
                      <Typography variant="subtitle1">
                        Organizational Units
                      </Typography>
                      <Chip
                        variant="counter"
                        color="info"
                        label={ventureClientData.businessUnits.length}
                      />
                    </Box>
                  }
                  {...a11yProps(0)}
                />
                <Tab
                  label={
                    <Box display="flex" alignItems="center" gap={1}>
                      <Typography variant="subtitle1">Contacts</Typography>
                      <Chip
                        variant="counter"
                        color="info"
                        label={contacts.length}
                      />
                    </Box>
                  }
                  {...a11yProps(1)}
                />
                <Tab label="Setup" {...a11yProps(2)} />
              </StyledTabsPanel>
            </StyledAppBar>
            <TabPanel value={value} index={0}>
              <BusinessUnitsList ventureClient={ventureClientData} />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <ContactsList
                ventureClient={ventureClientData}
                contacts={contacts}
                onContactSave={handleSaveContacts}
                loading={isLoadingContacts}
              />
            </TabPanel>
            <TabPanel value={value} index={2}>
              <CompanyFocusAreas
                ventureClient={ventureClientData}
                setRefresh={setRefresh}
                refresh={refresh}
              />
            </TabPanel>
          </Stack>
        </Box>
      </ContentWrapper>
    </Fragment>
  );
}
