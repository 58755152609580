import { Box, Chip, Typography, styled } from "@mui/material";
import theme from "../../../theme";
import React from "react";

interface LeadsMetricsProps {
  totalLeads: number;
}

const LeadMetricsContainer = styled(Box)({
  padding: theme.spacing(3),
  backgroundColor: theme.palette.surface.primary.main,
  alignItems: "center",
  gap: theme.spacing(1),
  width: "fit-content",
  display: "flex",
  borderRadius: theme.shape.radius.minimal,
});

const LeadsMetrics: React.FC<LeadsMetricsProps> = ({ totalLeads }) => {
  return (
    <LeadMetricsContainer>
      <Typography variant="h6">Total Leads</Typography>
      <Chip variant="counter" color="info" label={totalLeads} />
    </LeadMetricsContainer>
  );
};

export default LeadsMetrics;
