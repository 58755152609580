import { ReactElement } from "react";
import { styled, Typography, Box } from "@mui/material";
import ReferenceClient from "../../../../../../Types/Company";
import Investor from "../../../../../../Types/Investor";

interface LimitedNameListProps {
  entityNames: ReferenceClient[] | Investor[];
}

const LimitedNameListContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  columnGap: theme.spacing(1.25),
  alignItems: "center",
  overflowY: "hidden",
}));

const LimitedNameListText = styled(Typography)(() => ({
  overflow: "hidden",
  textOverflow: "ellipsis",
  display: "-webkit-box",
  WebkitLineClamp: 3,
  WebkitBoxOrient: "vertical",
}));

const EntitiesCount = styled(Box)(({ theme }) => ({
  minWidth: theme.spacing(4),
  height: theme.spacing(4),
  border: `1px solid ${theme.palette.grey[200]}`,
  borderRadius: "100%",
  justifyContent: "center",
  alignItems: "center",
  display: "grid",
}));

export default function LimitedNameList({
  entityNames,
}: LimitedNameListProps): ReactElement {
  if (!entityNames || entityNames.length === 0) {
    return <>-</>;
  }

  const displayedNames = entityNames
    .slice(0, 3)
    .map((entity) => entity.name)
    .join(", ");

  const remainingCount = entityNames.length > 3 ? entityNames.length - 3 : null;

  return (
    <LimitedNameListContainer>
      <LimitedNameListText variant="caption">
        {displayedNames}
      </LimitedNameListText>
      {remainingCount && (
        <EntitiesCount>
          <Typography data-testid="entities-count-indicator" variant="caption">
            +{remainingCount}
          </Typography>
        </EntitiesCount>
      )}
    </LimitedNameListContainer>
  );
}
