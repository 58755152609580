import { OpenInNew, RocketLaunchOutlined } from "@mui/icons-material";
import {
  Stack,
  Box,
  Typography,
  styled,
  Paper,
  Link,
  Button,
} from "@mui/material";
import { ReactElement, useState } from "react";
import theme from "../../theme";
import { Startup } from "../../Types/Startup";
import {
  findLogo,
  getDateMonthsAfter,
  normalizeUrl,
  trimStageName,
  formatFunding,
} from "../../utils";
import ProjectsCounter from "../UI/ProjectsCounter";
import AddStartupToProjectModal from "./DetailsPage/AddStartupToProjectModal";
import QualityVerificationIcon from "./DetailsPage/QualityVerification/QualityVerificationIcon";
import useRoles from "../../Hooks/useRoles";

const Website = styled(Typography)({
  color: "text.action.main",
  overflow: "hidden",
  textOverflow: "ellipsis",
  WebkitLineClamp: 1,
  WebkitBoxOrient: "vertical",
  display: "-webkit-box",
});

const ShortDescription = styled(Typography)({
  color: theme.palette.text.mediumEmphasis,
  display: "-webkit-box",
  overflow: "hidden",
  textOverflow: "ellipsis",
  WebkitLineClamp: 3 /* number of lines to show */,
  WebkitBoxOrient: "vertical",
  ...theme.typography.body2,
});

const StartupName = styled(Typography)({
  display: "-webkit-box",
  overflow: "hidden",
  textOverflow: "ellipsis",
  WebkitLineClamp: 2 /* number of lines to show */,
  WebkitBoxOrient: "vertical",
  ...theme.typography.subtitle2,
});

const StartupCardContainer = styled(Paper)({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  gap: theme.spacing(2),
  height: "100%",
  minWidth: "197px",
  padding: theme.spacing(2),
  borderRadius: theme.shape.radius.cardSmall,
});

const StartupProfileLink = styled(Link)({
  height: "100%",
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(2),
  color: "inherit",
  "&:hover": {
    color: "inherit",
  },
});

interface Props {
  startup: Startup;
  hasShadow?: boolean;
  showWebsite?: boolean;
  showProjectCounter?: boolean;
  onStartupAddedToProject?: () => void;
  showAddStartupToProject?: boolean;
  greyBgColor?: boolean;
}

const StartupCard = (props: Props): ReactElement => {
  const {
    startup,
    hasShadow,
    showWebsite,
    showProjectCounter,
    showAddStartupToProject = true,
    onStartupAddedToProject,
    greyBgColor,
  } = props;

  const { isViewer } = useRoles();

  const [addStartupToProjectModalOpen, setAddStartupToProjectModalOpen] =
    useState(false);

  const logo = findLogo(startup.files);

  const totalProjectsCount =
    (startup.opportunities?.length ?? 0) +
    (startup.leadOpportunities?.length ?? 0);

  const isQualityChecked = startup.isQualityChecked;
  const expiryDate = startup.lastQualityCheckDate
    ? getDateMonthsAfter(startup.lastQualityCheckDate, 12)
    : new Date();
  const hasExpired =
    (startup.lastQualityCheckDate &&
      isQualityChecked &&
      expiryDate < new Date()) ??
    false;

  return (
    <StartupCardContainer
      data-testid="startupTile"
      sx={{
        boxShadow: hasShadow ? theme.shadows[2] : 0,
        backgroundColor: greyBgColor
          ? theme.palette.surface.secondary.light
          : theme.palette.background.paper,
      }}
    >
      <StartupProfileLink
        href={`/startups/${startup.id}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        <Box
          minHeight={40}
          height={40}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          {logo ? (
            <img
              src={logo}
              alt="startup logo"
              style={{
                objectFit: "contain",
                maxHeight: "100%",
                maxWidth: "110px",
              }}
            />
          ) : (
            <RocketLaunchOutlined color="disabled" fontSize="large" />
          )}
          <QualityVerificationIcon
            lastQualityCheckBy={startup.lastQualityCheckBy}
            lastQualityCheckDate={startup.lastQualityCheckDate}
            isQualityChecked={isQualityChecked}
            hasExpired={hasExpired}
          />
        </Box>
        <Stack height="100%" justifyContent="space-between">
          <Stack>
            <Box display="flex" gap={1} justifyContent="space-between">
              <StartupName>{startup.name}</StartupName>
              {startup.dateFounded !== null && (
                <Typography
                  variant="caption"
                  color="brand.accessibility"
                  pt={0.25}
                >
                  {new Date(startup.dateFounded).getFullYear()}
                </Typography>
              )}
            </Box>
            <ShortDescription>{startup.shortDescription}</ShortDescription>
            <Stack gap={0.5} pt={0.5}>
              <Box display="flex" alignItems="center">
                <Typography variant="caption">
                  {`${startup.billingCountry ? startup.billingCountry : ""} ${
                    startup.billingCity ? `- ${startup.billingCity}` : ""
                  }`}
                </Typography>
              </Box>
              {showWebsite && startup.website && (
                <Link
                  href={normalizeUrl(startup.website)}
                  target="_blank"
                  rel="noopener noreferrer"
                  display="flex"
                  alignItems="center"
                  gap={0.5}
                  width="fit-content"
                >
                  <OpenInNew sx={{ fontSize: 16 }} />
                  <Website>Website</Website>
                </Link>
              )}
            </Stack>
          </Stack>
          <Stack gap={2} mt={4}>
            <Stack>
              <Box display="flex" justifyContent="space-between" gap={0.5}>
                <Typography variant="caption">Funding</Typography>
                <Typography variant="caption" color="text.brand.accessibility">
                  {startup.fundingIsUndisclosed
                    ? "Undisclosed"
                    : startup.totalFunding === null
                    ? "--"
                    : `$${formatFunding(startup.totalFunding)}`}
                </Typography>
              </Box>
              <Box display="flex" justifyContent="space-between" gap={0.5}>
                <Typography variant="caption">Stage</Typography>
                <Typography variant="caption" color="text.brand.accessibility">
                  {trimStageName(startup.currentInvestmentStage) || "--"}
                </Typography>
              </Box>
            </Stack>
            {showProjectCounter && (
              <ProjectsCounter
                value={totalProjectsCount}
                dataTestId={`projects-count-${startup.id}`}
                sx={{
                  backgroundColor: greyBgColor
                    ? theme.palette.surface.medium
                    : theme.palette.surface.secondary.main,
                }}
              />
            )}
          </Stack>
        </Stack>
      </StartupProfileLink>
      {showAddStartupToProject && !isViewer && (
        <Button onClick={() => setAddStartupToProjectModalOpen(true)}>
          + Add
        </Button>
      )}

      {addStartupToProjectModalOpen && onStartupAddedToProject && (
        <AddStartupToProjectModal
          modalOpen={addStartupToProjectModalOpen}
          setModalOpen={setAddStartupToProjectModalOpen}
          startupId={startup.id}
          startupName={startup.name}
          handleSave={onStartupAddedToProject}
        />
      )}
    </StartupCardContainer>
  );
};

export default StartupCard;
