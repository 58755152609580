import { Box, Stack, Typography, styled } from "@mui/material";
import { ReactElement } from "react";
import theme from "../../../../../theme";
import { Project } from "../../../../../Types/Project";
import CustomExpendableText from "../../../../UI/CustomExpendableText";
import RichTextEditor from "../../../../UI/InputFields/RichTextEditor/RichTextEditor";
import parse from "html-react-parser";

const StyledAgendaContainer = styled(Stack)(() => ({
  display: "flex",
  background: theme.palette.surface.secondary.light,
  borderRadius: theme.shape.radius.cardLarge,
  padding: theme.spacing(3),
  gap: theme.spacing(5),
  width: "100%",
}));

interface ProductDemosAgendaProps {
  project: Project;
  editMode: boolean;
  setDemoUseCases: (value: string) => void;
  setDemoQuestions: (value: string) => void;
}

export default function ProductDemosAgenda(
  props: ProductDemosAgendaProps
): ReactElement {
  return (
    <StyledAgendaContainer>
      {!props.editMode ? (
        <>
          <Box display="flex" flexDirection="column" gap={theme.spacing(0.5)}>
            <Typography variant="caption" color="text.mediumEmphasis">
              Use Cases
            </Typography>
            <CustomExpendableText
              text={parse(props.project.demoUseCases || "--")}
            />
          </Box>

          <Box display="flex" flexDirection="column" gap={theme.spacing(0.5)}>
            <Typography variant="caption" color="text.mediumEmphasis">
              General Questions for All Startups
            </Typography>
            <CustomExpendableText
              text={parse(props.project.demoQuestions || "--")}
            />
          </Box>
        </>
      ) : (
        <>
          <Box height={128}>
            <RichTextEditor
              fieldId="demoUseCases"
              labelText="Use Cases"
              editMode={props.editMode}
              isListsDisabled
              fieldValue={props.project.demoUseCases}
              onChange={(value) => props.setDemoUseCases(value)}
              demoAgendaToolBarBg={true}
            />
          </Box>

          <Box height={128}>
            <RichTextEditor
              fieldId="demoQuestions"
              labelText="General Questions for All Startups"
              editMode={props.editMode}
              isListsDisabled
              fieldValue={props.project.demoQuestions}
              onChange={(value) => props.setDemoQuestions(value)}
              demoAgendaToolBarBg={true}
            />
          </Box>
        </>
      )}
    </StyledAgendaContainer>
  );
}
