import { Box, Stack, styled, Tab, Tabs, Typography } from "@mui/material";
import { ReactElement, useEffect, useRef, useState } from "react";
import { StartupMainTabs } from "../../../../Types/Startup";
import theme from "../../../../theme";

const StyledBox = styled(Box)({
  transition: "all 0.3s",
  width: "100%",
  "&.stickied": {
    width: `calc(100vw - ${theme.spacing(9)})`,
    marginLeft: `calc((100vw - 100% - ${theme.spacing(9)})/-2)`,
    paddingLeft: theme.spacing(3),
    boxShadow: theme.boxShadows[3],
  },
  display: "flex",
});

interface Props {
  activeTab: number;
  tabs: StartupMainTabs;
  handleChangeTab: (index: number) => void;
  headerHeight: number;
  fullWidth: boolean;
}

function PrimaryTab(props: Props): ReactElement {
  const [isStickied, setIsStickied] = useState(false);

  const navigationRef = useRef<Element>(null);

  const navigationHeight = navigationRef.current
    ? navigationRef.current.getBoundingClientRect().height
    : 0;

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([e]) => {
        setIsStickied(
          e.boundingClientRect.bottom <= props.headerHeight + navigationHeight
        );
      },
      {
        root: null,
        threshold: 0,
        rootMargin: `-${
          props.headerHeight + navigationHeight + 1
        }px 0px 0px 0px`,
      }
    );

    navigationRef.current && observer.observe(navigationRef.current);

    return () => {
      navigationRef.current && observer.unobserve(navigationRef.current);
    };
  }, [navigationRef, navigationHeight, props.headerHeight]);
  return (
    <>
      <StyledBox
        className={isStickied && props.fullWidth ? "stickied" : ""}
        ref={navigationRef}
      >
        <Tabs
          value={props.activeTab}
          sx={{
            "& .MuiTabs-flexContainer": {
              borderBottom: "none",
              gap: 3,
            },
          }}
        >
          {props.tabs.map((type, index) => {
            return (
              <Tab
                sx={{ pl: 0 }}
                key={index}
                onClick={() => props.handleChangeTab(index)}
                label={
                  <Stack textAlign="start">
                    <Typography variant="subtitle1">{type.title}</Typography>
                    <Typography variant="overline" color="text.mediumEmphasis">
                      {type.description}
                    </Typography>
                  </Stack>
                }
              />
            );
          })}
        </Tabs>
      </StyledBox>
    </>
  );
}

export default PrimaryTab;
