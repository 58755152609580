import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogTitle,
  TextField,
  Typography,
  styled,
  DialogActions,
  Stack,
  Chip,
} from "@mui/material";
import { ReactElement, Reducer, useContext, useReducer, useState } from "react";
import { useSnackbar } from "notistack";
import { GlobalProjectEditContext } from "../../../../../Context/ProjectDetailsContext";
import { getErrorMessage } from "../../../../../utils";
import PhaseHttpService from "../../../../../Http/Phase/Phase.Http.service";
import Phase from "../../../../../Types/Phase";
import theme from "../../../../../theme";
import ScrollableDialogContent from "../../../../UI/Modals/ScrollableDialogContent";

const FormContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: theme.spacing(4),
  marginBlock: theme.spacing(4),
  flexWrap: "wrap",
}));

const DateDisplay = styled(Chip)(() => ({
  borderRadius: theme.shape.radius.minimal,
  color: theme.palette.text.mediumEmphasis,
  border: `1px solid ${theme.palette.borderOutline.main}`,
}));

interface Props {
  setModalOpen: (state: boolean) => void;
  modalOpen: boolean;
  phase?: Phase;
  projectId?: number;
  handlePhaseEdit: (phase: Phase) => void;
  index: number;
}

const EditPhaseModal = (props: Props): ReactElement => {
  const { activeStep } = useContext(GlobalProjectEditContext);
  const { enqueueSnackbar } = useSnackbar();
  const isBuyStage = activeStep === 2;
  const [isLoading, setIsLoading] = useState(false);
  const [newPhase, setNewPhase] = useReducer<Reducer<Phase, Partial<Phase>>>(
    (state, newState) => ({ ...state, ...newState }),
    props.phase ||
      ({
        projectId: props.projectId,
        isInitial: isBuyStage ? true : false,
      } as Phase)
  );

  const editPhase = async () => {
    setIsLoading(true);
    await PhaseHttpService.updatePhase(newPhase)
      .then(() => {
        props.handlePhaseEdit(newPhase);
      })
      .catch((error) => {
        const errorMessage = getErrorMessage(error);
        return enqueueSnackbar(`Could not update phase: ${errorMessage}`, {
          variant: "error",
        });
      })
      .finally(() => {
        setIsLoading(false);
        props.setModalOpen(false);
      });
  };

  return (
    <Dialog open={props.modalOpen} fullWidth data-testid="edit-phase-modal">
      <DialogTitle data-testid="edit-phase-header">Edit Phase</DialogTitle>
      <ScrollableDialogContent>
        <FormContainer data-testid="edit-phase-form">
          <TextField
            label="Name"
            value={newPhase.name || ""}
            variant="outlined"
            onChange={(event) => setNewPhase({ name: event.target.value })}
            fullWidth
            inputProps={{ "data-testid": "phase-name-input" }}
          />
          <Stack alignItems="start" gap={0.5}>
            <Typography variant="caption" color="text.mediumEmphasis">
              Duration
            </Typography>
            {props.phase?.duration ? (
              <DateDisplay
                variant="outlined"
                label={props.phase.duration + " days"}
              />
            ) : (
              <Typography variant="caption">--</Typography>
            )}
          </Stack>
          <TextField
            label="Description"
            multiline
            value={newPhase.description || ""}
            variant="outlined"
            onChange={(event) =>
              setNewPhase({ description: event.target.value })
            }
            inputProps={{ "data-testid": "phase-description-input" }}
            fullWidth
            minRows={4}
          />
        </FormContainer>
      </ScrollableDialogContent>
      <DialogActions>
        {isLoading ? (
          <CircularProgress size={24} />
        ) : (
          <>
            <Button onClick={() => props.setModalOpen(false)}>Cancel</Button>
            <Button variant="contained" onClick={editPhase}>
              Save
            </Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default EditPhaseModal;
