import { ProjectTasksCluster } from "../../Types/ProjectTask";

export default class ProjectTasksMapper {
  public static sort(tasks: ProjectTasksCluster[]): ProjectTasksCluster[] {
    return tasks
      .map((cluster) => ({
        ...cluster,
        tasks: [...cluster.tasks].sort((a, b) => a.priority - b.priority),
      }))
      .sort((a, b) => a.priority - b.priority);
  }
}
