import axios from "axios";
import { Preset, CreatePresetDto, EditPresetDto } from "../../Types/Preset";

export class PresetsHttpService {
  public static async getPresets(): Promise<Preset[]> {
    const response = await axios.get<Preset[]>("/api/presets");
    return response.data;
  }

  public static async createPreset(preset: CreatePresetDto): Promise<Preset> {
    const response = await axios.post<Preset>("/api/presets", preset);
    return response.data;
  }

  public static async deletePreset(id: number): Promise<void> {
    const response = await axios.delete(`/api/presets/${id}`);
    return response.data;
  }

  public static async updatePreset(
    id: number,
    preset: EditPresetDto
  ): Promise<void> {
    await axios.put(`/api/presets/${id}`, preset);
  }
}
