import { Box, Skeleton, Paper, Stack, Typography } from "@mui/material";
import { ReactElement } from "react";

function LeadSourceAttributeSkeleton(): ReactElement {
  return (
    <Box gap={2} display="flex" width="100%">
      <Skeleton width={80} height={13} />
      <Skeleton width={168} height={13} />
    </Box>
  );
}

export default function LeadProjectSourceSkeleton(): ReactElement {
  return (
    <Paper component={Stack} p={3} gap={2}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="h6">Lead Source</Typography>
        <Skeleton width={20} height={20} />
      </Box>
      <Stack gap={3}>
        <Stack gap={1}>
          <Skeleton width={48} height={16} />
          <Stack gap={0.5}>
            <LeadSourceAttributeSkeleton />
            <LeadSourceAttributeSkeleton />
          </Stack>
        </Stack>
        <Stack gap={1}>
          <Skeleton width={48} height={16} />
          <Stack gap={0.5}>
            <LeadSourceAttributeSkeleton />
            <LeadSourceAttributeSkeleton />
          </Stack>
        </Stack>
      </Stack>
    </Paper>
  );
}
