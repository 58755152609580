import axios from "axios";
import {
  EditLeadProjectHistoryDTO,
  LeadProjectHistory,
  LeadProjectHistoryDTO,
} from "../../Types/LeadProjectHistory";
import LeadProjectHistoryMapper from "./LeadProjectHistory.mapper";

export default class LeadProjectHistoryHttpService {
  public static async getLeadProjectHistory(
    LeadProjectId: number
  ): Promise<LeadProjectHistory[]> {
    const response = await axios.get<LeadProjectHistoryDTO[]>(
      `/api/lead-project/${LeadProjectId}/history`
    );
    return LeadProjectHistoryMapper.map(response.data);
  }

  public static async createLeadProjectHistory(
    history: Partial<LeadProjectHistory>
  ): Promise<void> {
    await axios.post<void>("/api/lead-project-history", history);
  }

  public static async updateLeadProjectHistory(
    history: EditLeadProjectHistoryDTO
  ): Promise<void> {
    await axios.put<void>(`/api/lead-project-history/${history.id}`, history);
  }

  public static async deleteLeadProjectHistory(
    LeadProjectHistoryId: number
  ): Promise<void> {
    await axios.delete<void>(
      `/api/lead-project-history/${LeadProjectHistoryId}`
    );
  }
}
