import {
  Box,
  BoxProps,
  Chip,
  ChipOwnProps,
  styled,
  Typography,
} from "@mui/material";
import { ReactElement } from "react";
import theme from "../../theme";

const ProjectsCounterContainer = styled(Box)({
  backgroundColor: theme.palette.surface.secondary.main,
  padding: theme.spacing(1, 2),
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  borderRadius: theme.shape.radius.minimal,
});

interface ProjectsCounterProps extends BoxProps {
  value: number;
  color?: ChipOwnProps["color"];
  dataTestId?: string;
}

export default function ProjectsCounter({
  value,
  color = "info",
  dataTestId,
  ...props
}: ProjectsCounterProps): ReactElement {
  return (
    <ProjectsCounterContainer {...props}>
      <Typography variant="body2">Projects</Typography>
      <Chip
        variant="counter"
        color={color}
        label={value}
        data-testid={dataTestId}
      />
    </ProjectsCounterContainer>
  );
}
