import {
  IconButton,
  styled,
  Tooltip,
  tooltipClasses,
  TooltipProps,
} from "@mui/material";
import { ReactElement } from "react";
import { useSnackbar } from "notistack";

interface Props {
  toolTipText: string;
  toBeCopied: string;
  icon: ReactElement;
  successText: string;
}

const CopyToClipboardButton = (props: Props): ReactElement => {
  const { enqueueSnackbar } = useSnackbar();

  const copy = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    navigator.clipboard.writeText(props.toBeCopied);
    enqueueSnackbar(props.successText, {
      variant: "success",
    });
  };

  const CopyToClipBoardTooltip = styled(
    ({ className, ...props }: TooltipProps) => (
      <Tooltip {...props} classes={{ popper: className }} />
    )
  )(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "white",
      color: "black",
      translate: "0 -15px",
      padding: theme.spacing(1),
      boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.3)",
    },
  }));

  const StyledButton = styled(IconButton)(({ theme }) => ({
    transition: "all .2s ease-in-out",
    ":hover": {
      color: theme.palette.primary.main,
      backgroundColor: theme.palette.primary.main + "0a",
    },
  }));

  return (
    <CopyToClipBoardTooltip title={props.toolTipText}>
      <StyledButton onClick={copy} data-testid="copyToClipboard">
        {props.icon}
      </StyledButton>
    </CopyToClipBoardTooltip>
  );
};

export default CopyToClipboardButton;
