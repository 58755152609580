import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Link,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { ReactElement, useEffect, useState } from "react";
import { StartupContactDTO } from "../../../../Types/StartupContact";
import CopyToClipboardButton from "../../../UI/CopyToClipBoardButton";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import StartupContactHttpService from "../../../../Http/StartupContact/StartupContact.http.service";
import EditStartupContactModal from "../EditStartupContactModal";
import { useSnackbar } from "notistack";
import CloseIcon from "@mui/icons-material/Close";
import ContactName from "../../ContactCard/ContactName/ContactName";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import PhonelinkRingIcon from "@mui/icons-material/PhonelinkRing";
import MailOutlineOutlinedIcon from "@mui/icons-material/MailOutlineOutlined";
import theme from "../../../../theme";
import useRoles from "../../../../Hooks/useRoles";

interface ContactDetailsModalProps {
  contact: StartupContactDTO;
  setModalOpen: (state: boolean) => void;
  handleSave: () => void;
  modalOpen: boolean;
  mustFetch?: boolean;
}

function DetailsStartupContactModal(
  props: ContactDetailsModalProps
): ReactElement {
  const { enqueueSnackbar } = useSnackbar();
  const { isViewer } = useRoles();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [contact, setContact] = useState<StartupContactDTO>(props.contact);
  const [editModalOpen, setEditModalOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [refresh, setRefresh] = useState(!!props.mustFetch);

  useEffect(() => {
    if (refresh) {
      StartupContactHttpService.getContactById(props.contact.id)
        .then((res) => setContact(res))
        .catch(() => {
          props.setModalOpen(false);
          enqueueSnackbar("Contact not found", {
            variant: "error",
          });
        })
        .finally(() => {
          setLoading(false);
          setRefresh(false);
        });
    }
  }, [refresh]);

  if (props.mustFetch && loading) {
    return (
      <Dialog fullScreen={fullScreen} open={loading}>
        <Box sx={{ p: 3 }}>
          <CircularProgress data-testid="startup-contact-loader" />
        </Box>
      </Dialog>
    );
  }
  return (
    <>
      <Dialog
        fullScreen={fullScreen}
        open={props.modalOpen}
        onClose={() => props.setModalOpen(false)}
        id="startup-contact-details-view"
        data-testid="startup-contact-details-view"
        fullWidth
      >
        <Stack gap={4}>
          <DialogTitle>
            Startup Contact
            <IconButton
              sx={{ color: "icon.primary" }}
              onClick={() => props.setModalOpen(false)}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <Stack gap={3}>
              <ContactName
                name={contact.name}
                title={contact.title}
                orientation="horizontal"
                gender={contact.gender}
                founder={contact.founder}
              />
              <Link
                target="_blank"
                href={"/startups/" + contact.startupId}
                display="flex"
                alignItems="center"
                gap={1}
                variant="body2"
                sx={{ color: theme.palette.text.action.main }}
              >
                <OpenInNewIcon />
                {contact.startup.name}
              </Link>
              <Box display="flex" alignItems="center" gap={4}>
                <Box display="flex" alignItems="center" gap={1}>
                  <PhonelinkRingIcon sx={{ fontSize: "1rem" }} />
                  <Typography variant="body2">
                    {contact.phone || "--"}
                    {contact.phone && (
                      <CopyToClipboardButton
                        toolTipText="Copy phone to clipboard"
                        toBeCopied={contact.phone}
                        icon={
                          <ContentCopyOutlinedIcon
                            sx={{ fontSize: "1rem" }}
                            color="info"
                          />
                        }
                        successText="Phone number successfully copied to clipboard"
                      />
                    )}
                  </Typography>
                </Box>

                <Box display="flex" alignItems="center" gap={1}>
                  <MailOutlineOutlinedIcon sx={{ fontSize: "1rem" }} />
                  <Typography variant="body2">
                    {contact.email || "--"}
                    {contact.email && (
                      <CopyToClipboardButton
                        toolTipText="Copy email to clipboard"
                        toBeCopied={contact.email}
                        icon={
                          <ContentCopyOutlinedIcon
                            sx={{ fontSize: "1rem" }}
                            color="info"
                          />
                        }
                        successText="Email address successfully copied to clipboard"
                      />
                    )}
                  </Typography>
                </Box>
              </Box>
            </Stack>
          </DialogContent>
          {!isViewer && (
            <DialogActions>
              <Button
                variant="contained"
                onClick={() => setEditModalOpen(true)}
                data-testid="edit-startup-contact-details-view"
              >
                Edit
              </Button>
            </DialogActions>
          )}
        </Stack>
      </Dialog>
      {editModalOpen && (
        <EditStartupContactModal
          contact={contact}
          setModalOpen={setEditModalOpen}
          modalOpen={editModalOpen}
          handleSave={() => {
            setRefresh(true);
            props.handleSave();
          }}
        />
      )}
    </>
  );
}

export default DetailsStartupContactModal;
