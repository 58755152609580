import {
  Box,
  Button,
  styled,
  AccordionProps,
  Accordion,
  AccordionDetails,
  Typography,
} from "@mui/material";
import { ReactElement, useContext, useEffect, useState } from "react";
import ObjectiveCard from "./ObjectiveCard";
import ManageObjectiveModal from "./ManageObjectiveModal";
import { GlobalProjectEditContext } from "../../../../Context/ProjectDetailsContext";
import { Objective } from "../../../../Types/Objective";
import { Kpi } from "../../../../Types/Kpi";
import ProjectDetailsAccordionSummary from "../ProjectDetailsAccordionSummary";
import { KpiHttpService } from "../../../../Http/Kpi/Kpi.http.service";
import { getErrorMessage } from "../../../../utils";
import { useSnackbar } from "notistack";
import ObjectiveHttpService from "../../../../Http/Objective/Objective.Http.service";
import { Project } from "../../../../Types/Project";
import useRoles from "../../../../Hooks/useRoles";

interface Props extends Omit<AccordionProps, "children"> {
  project: Project;
  handleSave: () => void;
}

const ObjectivesContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(4),
  width: "100%",
}));

export default function ObjectivesSection(props: Props): ReactElement {
  const { enqueueSnackbar } = useSnackbar();
  const { activeStep, globalEditMode } = useContext(GlobalProjectEditContext);
  const { canEdit } = useRoles(props.project);

  const objectivesUpdated = props.project.objectives || [];
  const [objectives, setObjectives] = useState(objectivesUpdated);
  const [createObjectiveModalOpen, setCreateObjectiveModalOpen] =
    useState(false);

  const isBuyStageActive = activeStep === 2;

  useEffect(() => {
    setObjectives(props.project.objectives || []);
  }, [props.project.objectives]);

  const createObjective = (newObjective: Objective) => {
    setObjectives((prevObjectives) => [
      ...prevObjectives,
      { ...newObjective, kpis: [], id: -1 },
    ]);
    props.handleSave();
  };

  const updateObjective = (updatedObjective: Objective) => {
    setObjectives((prevObjectives) =>
      prevObjectives.map((obj) =>
        obj.id === updatedObjective.id ? updatedObjective : obj
      )
    );
    props.handleSave();
  };

  const deleteObjective = async (id: number) => {
    await ObjectiveHttpService.deleteObjective(id)
      .then(() => {
        setObjectives((prevObjectives) =>
          prevObjectives.filter((obj) => obj.id !== id)
        );
        props.handleSave();
      })
      .catch((error) => {
        const errorMessage = getErrorMessage(error);
        return enqueueSnackbar(
          `Could not delete the objective: ${errorMessage}`,
          {
            variant: "error",
          }
        );
      });
  };

  const createKpi = (newKpi: Kpi) => {
    setObjectives((prevObjectives) =>
      prevObjectives.map((obj) =>
        obj.id === newKpi.objectiveId
          ? { ...obj, kpis: [...obj.kpis, newKpi] }
          : obj
      )
    );
    props.handleSave();
  };

  const updateKpi = (objectiveId: number, updatedKpi: Kpi) => {
    setObjectives((prevObjectives) =>
      prevObjectives.map((obj) =>
        obj.id === objectiveId
          ? {
              ...obj,
              kpis: obj.kpis.map((kpi: Kpi) =>
                kpi.id === updatedKpi.id ? updatedKpi : kpi
              ),
            }
          : obj
      )
    );
    props.handleSave();
  };

  const deleteKpi = async (id: number) => {
    await KpiHttpService.deleteKpi(id)
      .then(() => {
        setObjectives((prevObjectives) =>
          prevObjectives.map((obj) => ({
            ...obj,
            kpis: obj.kpis.filter((kpi: Kpi) => kpi.id !== id),
          }))
        );
        props.handleSave();
      })
      .catch((error) => {
        const errorMessage = getErrorMessage(error);
        return enqueueSnackbar(`Could not delete the kpi: ${errorMessage}`, {
          variant: "error",
        });
      });
  };

  return (
    <Accordion expanded={props.expanded} onChange={props.onChange}>
      <ProjectDetailsAccordionSummary
        actionButton={
          props.expanded &&
          isBuyStageActive &&
          canEdit && (
            <Button
              variant="contained"
              onClick={() => setCreateObjectiveModalOpen(true)}
              disabled={globalEditMode}
              data-testid="add-objective-button"
            >
              Add Objective
            </Button>
          )
        }
      >
        Objectives & KPIs
      </ProjectDetailsAccordionSummary>
      <AccordionDetails
        sx={{ display: "flex", justifyContent: "space-between" }}
      >
        <ObjectivesContainer>
          {objectives.length > 0 ? (
            objectives.map((objective) => {
              return (
                <ObjectiveCard
                  key={objective.id}
                  objective={objective}
                  createObjective={createObjective}
                  updateObjective={updateObjective}
                  deleteObjective={deleteObjective}
                  createKpi={createKpi}
                  updateKpi={updateKpi}
                  deleteKpi={deleteKpi}
                  project={props.project}
                />
              );
            })
          ) : (
            <Typography variant="body1" color="text.disabled">
              No Objectives or KPIs have been added yet.
            </Typography>
          )}
        </ObjectivesContainer>
      </AccordionDetails>

      {createObjectiveModalOpen && (
        <ManageObjectiveModal
          projectId={props.project.id}
          modalOpen={createObjectiveModalOpen}
          setModalOpen={setCreateObjectiveModalOpen}
          createObjective={createObjective}
        />
      )}
    </Accordion>
  );
}
