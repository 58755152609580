import { ErrorOutline } from "@mui/icons-material";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Stack,
  DialogContentText,
} from "@mui/material";
import { ReactElement, useContext } from "react";
import theme from "../../theme";
import { GlobalProjectEditContext } from "../../Context/ProjectDetailsContext";

interface UnsavedChangesModalProps {
  modalOpen: boolean;
  setModalOpen: (state: boolean) => void;
}

const UnsavedChangesModal = (props: UnsavedChangesModalProps): ReactElement => {
  const { setShouldSaveProject } = useContext(GlobalProjectEditContext);

  const handleSaveButton = () => {
    props.setModalOpen(false);
    setShouldSaveProject(true);
  };

  return (
    <Dialog
      fullWidth
      maxWidth="xs"
      open={props.modalOpen}
      onClose={() => props.setModalOpen(false)}
      data-testid="unsaved-changes-modal"
      PaperProps={{
        sx: {
          "&.MuiDialog-paper": {
            p: 0,
            gap: 0,
          },
        },
      }}
    >
      <Stack p={theme.spacing(2, 2, 0, 2)}>
        <DialogTitle
          sx={{
            ...theme.typography.subtitle1,
            justifyContent: "flex-start",
            px: 1,
          }}
        >
          <ErrorOutline color="warning" /> Unsaved Changes
        </DialogTitle>
        <DialogContent sx={{ display: "flex", gap: theme.spacing(2), px: 1 }}>
          <DialogContentText>
            You have unsaved changes. Would you like to save before leaving this
            section?
          </DialogContentText>
        </DialogContent>
      </Stack>
      <DialogActions sx={{ p: theme.spacing(1), margin: 0 }}>
        <Button
          data-testid="discard-button"
          color="inherit"
          onClick={() => {
            props.setModalOpen(false);
          }}
        >
          Continue Editing
        </Button>
        <Button
          data-testid="save-button"
          variant="contained"
          onClick={handleSaveButton}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UnsavedChangesModal;
