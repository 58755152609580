import React, { ReactElement, useState } from "react";
import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Auth } from "aws-amplify";
import { useSnackbar } from "notistack";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import theme from "../../theme";
import AuthenticationCard from "./AuthenticationCard";
import PasswordRequirements from "./PasswordRequirements";

interface Props {
  setFormType: (x: string) => void;
}

export default function ResetPassword(props: Props): ReactElement {
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [token, setToken] = useState<string>("");
  const { enqueueSnackbar } = useSnackbar();

  const textFieldForPasswordRef = React.useRef<HTMLInputElement>(null);
  const textFieldForTokenRef = React.useRef<HTMLInputElement>(null);

  const [showToken, setShowToken] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);

  const handleResetPassword = async () => {
    try {
      await Auth.forgotPasswordSubmit(email, token, password);
      props.setFormType("signIn");
      // eslint-disable-next-line
    } catch (error: any) {
      enqueueSnackbar(error.message, {
        variant: "error",
      });
    }
  };

  return (
    <AuthenticationCard>
      <Typography variant="subtitle1" align="center">
        Reset Your Password
      </Typography>
      <Stack gap={theme.spacing(3)}>
        <TextField
          id="email"
          label="Username"
          autoComplete="off"
          value={email}
          onChange={(e) => setEmail(e.target.value as string)}
          inputProps={{
            onKeyPress: (event) => {
              if (event.key === "Enter" && textFieldForPasswordRef.current) {
                textFieldForPasswordRef.current.focus();
              }
            },
          }}
        />
        <Stack gap={theme.spacing(1)}>
          <TextField
            id="token"
            label="Token"
            value={token}
            type={showToken ? "text" : "password"}
            inputRef={textFieldForTokenRef}
            onChange={(e) => setToken(e.target.value as string)}
            inputProps={{
              onKeyPress: (event) => {
                if (event.key === "Enter" && textFieldForPasswordRef.current) {
                  textFieldForPasswordRef.current.focus();
                }
              },
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    data-testid="token-visibility-toggle"
                    aria-label="toggle token visibility"
                    onClick={() => setShowToken(!showToken)}
                  >
                    {showToken ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <Box display="flex" justifyContent="end" gap={1}>
            <Typography variant="caption">Did not Receive a Token?</Typography>
            <Typography
              variant="button"
              color="text.action.main"
              sx={{ cursor: "pointer" }}
              onClick={() => props.setFormType("resetPasswordToken")}
            >
              Click Here
            </Typography>
          </Box>
        </Stack>
        <TextField
          id="password"
          label="New Password"
          autoComplete="new-password"
          value={password}
          type={showNewPassword ? "text" : "password"}
          inputRef={textFieldForPasswordRef}
          onChange={(e) => setPassword(e.target.value as string)}
          inputProps={{
            onKeyPress: (event) => {
              if (event.key === "Enter") {
                handleResetPassword();
              }
            },
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  data-testid="new-password-visibility-toggle"
                  aria-label="toggle new password visibility"
                  onClick={() => setShowNewPassword(!showNewPassword)}
                >
                  {showNewPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <PasswordRequirements />
        <Button
          type="submit"
          onClick={handleResetPassword}
          variant="contained"
          id="reset-password-button"
        >
          Reset Password
        </Button>
      </Stack>
    </AuthenticationCard>
  );
}
