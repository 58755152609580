import { StyledEngineProvider, Theme, ThemeProvider } from "@mui/material";
import { SnackbarProvider } from "notistack";
import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import RequestInterceptors from "./Http/RequestInterceptors";
import reportWebVitals from "./reportWebVitals";
import theme from "./theme";
import CustomSnackbar from "./Components/UI/CustomSnackbar";
import "./index.css";

declare module "@mui/styles/defaultTheme" {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

RequestInterceptors.addAuthHeaderInterceptor();

const container = document.getElementById("root");

if (container) {
  const root = createRoot(container);
  root.render(
    <React.StrictMode>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <SnackbarProvider
            maxSnack={5}
            dense
            Components={{
              default: CustomSnackbar,
              error: CustomSnackbar,
              success: CustomSnackbar,
              warning: CustomSnackbar,
            }}
          >
            <App />
          </SnackbarProvider>
        </ThemeProvider>
      </StyledEngineProvider>
    </React.StrictMode>
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
