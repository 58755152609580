import { Paper, Skeleton, Stack, Typography } from "@mui/material";
import { ReactElement } from "react";

export default function LeadSectionSkeleton(): ReactElement {
  return (
    <>
      <Paper component={Stack} p={3} gap={2}>
        <Typography variant="h6">Launched from Lead</Typography>
        <Skeleton width="auto" height={160} />
      </Paper>
    </>
  );
}
