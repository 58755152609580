import { Box, Stack, Typography } from "@mui/material";
import { enqueueSnackbar } from "notistack";
import { ReactElement, useEffect, useState } from "react";
import ProjectTasksHttpService from "../../../../Http/ProjectTasks/ProjectTasks.http.service";
import { FunnelStage } from "../../../../Types/Project";
import {
  ProjectTasksCluster,
  ProjectTask,
} from "../../../../Types/ProjectTask";
import ProjectTaskProgressBar from "../../../ProjectDetails/SharedComponents/ProjectOverview/ProjectTasks/ProjectTaskProgressBar";

interface TaskStepperSectionProps {
  projectId: number;
  funnelStage: FunnelStage;
}

export default function TaskStepperSection({
  projectId,
  funnelStage,
}: TaskStepperSectionProps): ReactElement {
  const [projectTaskClusters, setProjectTaskClusters] = useState<
    ProjectTasksCluster[]
  >([]);

  const taskCount =
    projectTaskClusters?.reduce(
      (count, cluster) => count + cluster.tasks.length,
      0
    ) || 0;

  const { tasksToComplete } = getTasksToComplete(projectTaskClusters);

  const currentTask = tasksToComplete[0];

  useEffect(() => {
    getClusteredProjectTasks();
  }, [funnelStage]);

  const getClusteredProjectTasks = async () => {
    try {
      const clusters = await ProjectTasksHttpService.getClusteredProjectTasks(
        projectId,
        funnelStage
      );
      setProjectTaskClusters(clusters);
    } catch (error) {
      enqueueSnackbar(`Something went wrong with fetching tasks`, {
        variant: "error",
      });
    }
  };

  return (
    <Stack gap={1}>
      <Box
        display="flex"
        justifyContent="space-between"
        data-testid="task-counter"
      >
        <Typography color="text.mediumEmphasis">
          {tasksToComplete.length === 0 ? "Completed" : currentTask.description}
        </Typography>
        <Box display="flex" gap={0.25} alignItems="flex-end">
          <Typography variant="h6" color="text.disabled">
            {taskCount - tasksToComplete.length}
          </Typography>
          <Typography color="text.disabled" variant="body2" pb={0.25}>
            /{taskCount}
          </Typography>
        </Box>
      </Box>
      <ProjectTaskProgressBar
        projectTaskClusters={projectTaskClusters}
        currentTask={currentTask}
        taskCount={taskCount}
        sx={{ gap: 0.5, paddingBlock: 0 }}
      />
    </Stack>
  );
}

const getTasksToComplete = (taskClusters: ProjectTasksCluster[]) => {
  const tasksToComplete: ProjectTask[] = [];
  const tasksCompleted: ProjectTask[] = [];
  taskClusters.forEach((cluster) => {
    cluster.tasks.forEach((task) => {
      if (task.status === "NOT_COMPLETED") {
        tasksToComplete.push(task);
      } else {
        tasksCompleted.push(task);
      }
    });
  });
  return { tasksToComplete, tasksCompleted };
};
