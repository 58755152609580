import { ReactElement, useContext, useState } from "react";
import { Opportunity } from "../../../../../../Types/Opportunity";
import { styled, Select, MenuItem, Typography } from "@mui/material";
import theme from "../../../../../../theme";
import { SolutionCluster } from "../../../../../../Types/SolutionCluster";
import { GlobalLoaderContext } from "../../../../../../Context/LoaderContext";
import ManageSolutionClusterModal from "../../../../FunnelStages/Discover/CuratedList/OverviewSection/SolutionCluster/ManageSolutionClusterModal";
import { Project } from "../../../../../../Types/Project";
import useRoles from "../../../../../../Hooks/useRoles";

const SolutionClusterSelectInput = styled(Select)(() => ({
  width: "Calc(100% - 18px)",
  height: "Calc(100% - 18px)",
  margin: "9px",
}));

const StyledClusterCell = styled(Typography)(() => ({
  cursor: "pointer",
  padding: `${theme.spacing(2.5)} ${theme.spacing(2)}`,
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  overflow: "hidden",
  ...theme.typography.body2,
}));

interface AssignClusterProps {
  opportunity: Opportunity;
  clusters: SolutionCluster[];
  onChangeCluster: (clusterId: number) => void;
  editingOpportunity: string | null;
  setEditingOpportunity: (id: string | null) => void;
  isAddingStartup: boolean;
  project: Project;
}

export default function AssignCluster(props: AssignClusterProps): ReactElement {
  const { canEdit } = useRoles(props.project);
  const { globalLoader } = useContext(GlobalLoaderContext);

  const [editingClusters, setEditingClusters] = useState(false);
  const [currentClusterId, setCurrentClusterId] = useState(
    props.opportunity.solutionClusterId
  );
  const [createClusterModalOpen, setCreateClusterModalOpen] = useState(false);

  const clusterName = props.clusters.find(
    (cluster) => cluster.id === currentClusterId
  )?.name;

  const handleClickEditingCluster = (event: React.MouseEvent<HTMLElement>) => {
    if (!globalLoader && !props.isAddingStartup) {
      event.stopPropagation();
      setEditingClusters(!editingClusters);
      props.setEditingOpportunity(`cluster-${props.opportunity.id}`);
    }
  };

  const handleChange = (clusterId: number) => {
    props.onChangeCluster(clusterId);
    setCurrentClusterId(clusterId);
    props.setEditingOpportunity(`cluster-${props.opportunity.id}`);
  };

  const handleClose = (e: React.SyntheticEvent<Element, Event>) => {
    e.stopPropagation();
    setEditingClusters(false);
    props.setEditingOpportunity(null);
  };

  return (
    <>
      {editingClusters &&
      props.editingOpportunity === `cluster-${props.opportunity.id}` ? (
        <SolutionClusterSelectInput
          displayEmpty
          value={currentClusterId || ""}
          onChange={(e) => {
            if (!e.target.value) return;
            handleChange(e.target.value as number);
          }}
          onClose={handleClose}
          defaultOpen={editingClusters}
          data-testid="assign-cluster-input"
          renderValue={(selected) => {
            if (!selected) {
              return "Assign Cluster";
            }
            return clusterName;
          }}
        >
          {props.clusters.map((cluster) => {
            return (
              <MenuItem
                key={cluster.id}
                value={cluster.id}
                data-testid={`cluster-input-${cluster.name}`}
                onClick={handleClickEditingCluster}
              >
                {cluster.name}
              </MenuItem>
            );
          })}
          <MenuItem
            onClick={() => {
              setCreateClusterModalOpen(true);
            }}
          >
            + Add New Solution Cluster
          </MenuItem>
        </SolutionClusterSelectInput>
      ) : currentClusterId && clusterName ? (
        <StyledClusterCell onClick={handleClickEditingCluster}>
          {clusterName}
        </StyledClusterCell>
      ) : canEdit ? (
        <StyledClusterCell
          onClick={handleClickEditingCluster}
          data-testid="no-cluster-placeholder"
          color="text.action.main"
        >
          Assign Cluster
        </StyledClusterCell>
      ) : (
        <StyledClusterCell justifySelf="center">--</StyledClusterCell>
      )}
      {createClusterModalOpen && (
        <ManageSolutionClusterModal
          modalOpen={createClusterModalOpen}
          setModalOpen={setCreateClusterModalOpen}
          handleSave={() => null}
          handleNewCluster={handleChange}
        />
      )}
    </>
  );
}
