import { Box, Container, Skeleton, Stack } from "@mui/material";
import { ReactElement } from "react";
import theme from "../../../../theme";
import ContactNameSkeleton from "../../../Contacts/ContactCard/ContactName/ContactNameSkeleton";

export default function ProjectDetailsHeaderSkeleton(): ReactElement {
  return (
    <>
      <Box pt={13.5} pb={19.5} zIndex={4} bgcolor={theme.palette.surface.cool}>
        <Container>
          <Box display="flex" justifyContent="space-between" gap={5}>
            <Stack gap={1}>
              <Skeleton height={32} width={560} />
              <Skeleton height={32} width={160} />
            </Stack>
            <Box
              display="flex"
              gap={5}
              alignItems="center"
              height="fit-content"
            >
              <Skeleton width={40} height={40} variant="circular" />
              <Box display="flex" gap={5}>
                <Skeleton width={128} height={36} />
                <Skeleton width={128} height={36} />
              </Box>
            </Box>
          </Box>
        </Container>
      </Box>
      <Box pb={3} bgcolor={theme.palette.surface.cool}>
        <Container>
          <Box display="flex" gap={4} alignItems="center">
            <Box display="flex" gap={1} alignItems="center">
              <ContactNameSkeleton />
            </Box>
            <Stack gap={1}>
              <Skeleton width={80} height={12} />
              <Skeleton width={64} height={8} />
            </Stack>
            <Stack gap={1}>
              <Skeleton width={120} height={12} />
              <Skeleton width={64} height={8} />
            </Stack>
          </Box>
        </Container>
      </Box>
    </>
  );
}
