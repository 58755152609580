import { Box, Container, Stack, Skeleton } from "@mui/material";
import { ReactElement } from "react";
import theme from "../../../theme";

export default function LeadProjectHeaderSkeleton(): ReactElement {
  return (
    <>
      <Box pt={13.5} pb={19.5} zIndex={4} bgcolor={theme.palette.surface.cool}>
        <Container>
          <Box display="flex" justifyContent="space-between" gap={5}>
            <Stack gap={1}>
              <Skeleton height={32} width={560} />
              <Skeleton height={32} width={160} />
            </Stack>
            <Box
              display="flex"
              gap={5}
              alignItems="center"
              height="fit-content"
            >
              <Skeleton width={40} height={40} variant="circular" />
              <Skeleton width={128} height={36} />
            </Box>
          </Box>
        </Container>
      </Box>
      <Box pb={3} bgcolor={theme.palette.surface.cool}>
        <Container>
          <Box display="flex" justifyContent="space-between">
            <Box display="flex" gap={4} alignItems="center">
              <Box display="flex" gap={1} alignItems="center">
                <Skeleton width={32} height={32} variant="circular" />
                <Stack gap={1}>
                  <Skeleton width={112} height={12} />
                  <Skeleton width={64} height={8} />
                </Stack>
              </Box>
              <Stack gap={1}>
                <Skeleton width={80} height={12} />
                <Skeleton width={64} height={8} />
              </Stack>
              <Stack gap={1}>
                <Skeleton width={120} height={12} />
                <Skeleton width={64} height={8} />
              </Stack>
            </Box>
          </Box>
        </Container>
      </Box>
    </>
  );
}
