import { Box, IconButton, Paper, Stack, Typography } from "@mui/material";
import { ReactElement, useState } from "react";
import { File } from "../../../../Types/File";
import LogoPlaceholder from "../../../ProjectDetails/SharedComponents/LogoPlaceholder";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import ContactName from "../../../Contacts/ContactCard/ContactName/ContactName";
import EditVentureClientUnitModal from "../VentureClientOverview/EditVentureClientUnitModal";
import { VentureClient } from "../../../../Types/VentureClient";
import { OpenInNew } from "@mui/icons-material";
import { normalizeUrl } from "../../../../utils";
import theme from "../../../../theme";
import {
  Logo,
  Website,
  WebsiteLink,
} from "../VentureClientOverview/VentureClientOverview";
import useRoles from "../../../../Hooks/useRoles";

interface VentureClientUnitOverviewProps {
  ventureClient: VentureClient;
  logo?: File;
  handleSave(): void;
}

export default function VentureClientUnitOverview({
  ventureClient,
  logo,
  handleSave,
}: VentureClientUnitOverviewProps): ReactElement {
  const { canManage } = useRoles(undefined, undefined, ventureClient);

  const [editModalOpen, setEditModalOpen] = useState(false);

  const { unitName, unitContact, unitWebsite } = ventureClient;
  return (
    <>
      <Stack gap={3} data-testid="venture-client-unit-overview">
        <Typography variant="h6">Venture Client Unit</Typography>
        <Paper component={Stack} gap={3} p={3}>
          <Stack gap={2}>
            <Box display="flex" justifyContent="space-between" gap={3}>
              {logo ? (
                <Logo src={logo.url} />
              ) : (
                <Box width={64}>
                  <LogoPlaceholder type="client" />
                </Box>
              )}
              {canManage && (
                <IconButton
                  onClick={() => {
                    setEditModalOpen(true);
                  }}
                  data-testid="edit-venture-client-unit-button"
                  sx={{
                    color: theme.palette.icon.action.main,
                  }}
                >
                  <EditOutlinedIcon fontSize="small" />
                </IconButton>
              )}
            </Box>
            <Typography variant="h6">{unitName}</Typography>
          </Stack>
          <ContactName
            name={unitContact?.name}
            title={unitContact?.title || "Main Contact"}
            orientation="horizontal"
          />
          <Box display="flex" gap={2}>
            <Typography variant="subtitle2">Website</Typography>
            {unitWebsite ? (
              <WebsiteLink
                href={normalizeUrl(unitWebsite)}
                target="_blank"
                rel="noopener noreferrer"
              >
                <OpenInNew sx={{ fontSize: 16 }} />
                <Website>{normalizeUrl(unitWebsite, true)}</Website>
              </WebsiteLink>
            ) : (
              <Typography>--</Typography>
            )}
          </Box>
        </Paper>
      </Stack>
      {editModalOpen && (
        <EditVentureClientUnitModal
          open={editModalOpen}
          onClose={() => {
            setEditModalOpen(false);
          }}
          onSave={handleSave}
          ventureClient={ventureClient}
        />
      )}
    </>
  );
}
