import { Box, Paper, Typography, styled } from "@mui/material";
import { ReactElement } from "react";
import theme from "../../../../../theme";
import { Project } from "../../../../../Types/Project";
import MatchCard from "./MatchCard";
import { findLogo } from "../../../../../utils";

const CardsContainer = styled(Box)(() => ({
  padding: theme.spacing(3),
  paddingTop: 0,
  gap: theme.spacing(2),
  display: "flex",
  flexDirection: "column",
}));

interface ProjectTeamLeadersSectionProps {
  project: Project;
  handleSave: () => void;
}

export default function ProjectTeamLeadersSection(
  props: ProjectTeamLeadersSectionProps
): ReactElement {
  const projectLeaderObject = {
    title: props.project.projectLeader?.name,
    subtitle1: props.project.projectLeader?.departmentCode,
    subtitle2: props.project.projectLeader?.businessUnit?.name,
    src: props.project.businessUnit.ventureClient.files
      ? findLogo(props.project.businessUnit.ventureClient.files, "companyLogo")
      : "",
  };

  const selectedStartup = props.project.opportunities.find(
    (opportunity) => opportunity.isSelectedForPilot === true
  )?.startup;

  const programManagerMatchOject = (() => {
    if (!props.project.programManager) return null;
    return {
      id: props.project.programManager.id,
      title: props.project.programManager?.name,
      subtitle1: props.project.programManager?.departmentCode,
      subtitle2: props.project.programManager?.businessUnit?.name,
      src: props.project.businessUnit.ventureClient.files
        ? findLogo(props.project.businessUnit.ventureClient.files, "Logo")
        : "",
    };
  })();

  const startupMatchObject = (() => {
    if (!selectedStartup) return null;

    if (props.project.projectLeadStartup) {
      return {
        id: selectedStartup.id,
        title: props.project.projectLeadStartup.name,
        subtitle1: props.project.projectLeadStartup.title,
        subtitle2: selectedStartup.name,
        src: selectedStartup.files ? findLogo(selectedStartup.files) : "",
      };
    } else {
      return {
        id: selectedStartup.id,
        title: selectedStartup.name,
        src: selectedStartup.files ? findLogo(selectedStartup.files) : "",
      };
    }
  })();

  return (
    <Paper>
      <Typography variant="h6" padding={theme.spacing(3)}>
        Main Stakeholders
      </Typography>
      <CardsContainer>
        <MatchCard
          {...projectLeaderObject}
          contact={props.project.projectLeader}
          handleSave={props.handleSave}
        />
        {programManagerMatchOject && (
          <MatchCard
            {...programManagerMatchOject}
            contact={props.project.programManager ?? undefined}
            handleSave={props.handleSave}
          />
        )}
        {startupMatchObject && (
          <MatchCard
            {...startupMatchObject}
            contact={props.project.projectLeadStartup ?? undefined}
            isStartup={true}
            handleSave={props.handleSave}
          />
        )}
      </CardsContainer>
    </Paper>
  );
}
