import { useSnackbar } from "notistack";
import { StartupHttpService } from "../Http/Startup/Startup.http.service";
import { Startup } from "../Types/Startup";

interface usePitchbookSyncReturns {
  syncStartup: (startupId: number, pitchbookId?: string) => Promise<void>;
  removeLink: (startupId: number) => Promise<void>;
}

function usePitchbookSync(): usePitchbookSyncReturns {
  const { enqueueSnackbar } = useSnackbar();

  const hasFundingChanged = (
    currentStartup: Startup,
    updatedStartup: Startup
  ): boolean => {
    const fields: Array<keyof Startup> = [
      "totalFunding",
      "lastFunding",
      "currentInvestmentStage",
    ];

    const hasFieldChanged = fields.some(
      (field) => updatedStartup[field] != currentStartup[field]
    );
    const hasDateChanged = areDatesDifferent(
      updatedStartup.lastFundingDate,
      currentStartup.lastFundingDate
    );

    return hasFieldChanged || hasDateChanged;
  };

  const getSnackbarMessage = (
    currentStartup: Startup,
    updatedStartup: Startup
  ) => {
    if (!currentStartup.pitchbookId) {
      return "Startup Profile Successfully Linked & Funding Data Updated";
    }
    const isUpdated = hasFundingChanged(currentStartup, updatedStartup);
    return `Funding data updated${isUpdated ? "" : " - no changes"}`;
  };

  const syncStartup = async (
    startupId: number,
    selectedPitchbookId?: string
  ) => {
    try {
      const currentStartup = await StartupHttpService.getStartupById(startupId);

      const pitchbookId = selectedPitchbookId ?? currentStartup.pitchbookId;
      if (!pitchbookId) return;

      const updatedStartup = await StartupHttpService.syncStartup(
        startupId,
        pitchbookId
      );
      enqueueSnackbar(getSnackbarMessage(currentStartup, updatedStartup), {
        variant: "success",
      });
    } catch (err) {
      enqueueSnackbar(`Could not sync startup`, {
        variant: "error",
      });
    }
  };

  const removeLink = async (startupId: number) => {
    try {
      await StartupHttpService.updateStartup({
        id: startupId,
        pitchbookId: null,
      });
    } catch (err) {
      enqueueSnackbar(
        `Something went wrong while while removing the PitchBook link`,
        {
          variant: "error",
        }
      );
    }
  };

  return { syncStartup, removeLink };
}

export default usePitchbookSync;

function areDatesDifferent(date1: Date | null, date2: Date | null): boolean {
  return date1?.toDateString() !== date2?.toDateString();
}
