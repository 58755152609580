import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Stack,
} from "@mui/material";
import { Box } from "@mui/system";
import { enqueueSnackbar } from "notistack";
import { ReactElement, Reducer, useReducer } from "react";
import { FileHttpService } from "../../../../Http/File/File.http.service";
import { VentureClientHttpService } from "../../../../Http/VentureClient/VentureClient.http.service";
import theme from "../../../../theme";
import {
  EditVentureClientUnitDTO,
  VentureClient,
} from "../../../../Types/VentureClient";
import { getErrorMessage } from "../../../../utils";
import ClientContactSelect from "../../../UI/InputFields/ClientContactSelect";
import CustomTextField from "../../../UI/InputFields/CustomTextField";
import LogoUploadDropbox from "../../../UI/LogoUploadDropbox";
import ScrollableDialogContent from "../../../UI/Modals/ScrollableDialogContent";

const requiredFields: (keyof EditVentureClientUnitDTO)[] = ["unitName"];

interface EditVentureClientModalProps {
  ventureClient: VentureClient;
  open: boolean;
  onClose(): void;
  onSave(): void;
}

export default function EditVentureClientUnitModal({
  open,
  onClose,
  onSave,
  ...props
}: EditVentureClientModalProps): ReactElement {
  const [ventureClient, setVentureClient] = useReducer<
    Reducer<EditVentureClientUnitDTO, Partial<EditVentureClientUnitDTO>>
  >((state, newState) => ({ ...state, ...newState }), {
    id: props.ventureClient.id,
    unitName: props.ventureClient.unitName,
    unitWebsite: props.ventureClient.unitWebsite,
    files: props.ventureClient.files,
    unitContactId: props.ventureClient.unitContactId,
    unitContact: props.ventureClient.unitContact,
  });

  const saveVentureClient = async () => {
    await VentureClientHttpService.updateVentureClient(
      ventureClient as VentureClient
    )
      .then(() => {
        onSave();
        enqueueSnackbar("Venture Client Unit saved successfully.", {
          variant: "success",
        });
      })
      .catch((error) => {
        const errorMessage = getErrorMessage(error);
        enqueueSnackbar(
          `Could not update Venture Client Unit details: ${errorMessage}`,
          {
            variant: "error",
          }
        );
      });

    onClose();
  };

  const uploadLogo = async (file: File): Promise<void> => {
    try {
      await FileHttpService.upload(
        file,
        ventureClient.id,
        "ventureClient",
        "Logo"
      );
    } catch (error) {
      enqueueSnackbar("File can not be uploaded", {
        variant: "error",
      });
    }
  };

  return (
    <Dialog
      open={open}
      fullWidth
      PaperProps={{
        sx: {
          "&.MuiDialog-paper": {
            gap: theme.spacing(3),
          },
        },
      }}
      data-testid="edit-venture-client-unit-modal"
    >
      <DialogTitle sx={{ pb: 0 }}>
        Edit Venture Client Unit Information
      </DialogTitle>
      <ScrollableDialogContent>
        <Stack gap={4}>
          <Box display="flex" gap={2} pt={1}>
            <Stack gap={4} width="100%">
              <CustomTextField
                editMode
                label="Name"
                fullWidth
                required={requiredFields.includes("unitName")}
                variant="outlined"
                value={ventureClient.unitName}
                onChange={(e) => setVentureClient({ unitName: e.target.value })}
              />
              <CustomTextField
                editMode
                data-testid="website"
                label="Website"
                fullWidth
                required={requiredFields.includes("unitWebsite")}
                variant="outlined"
                onChange={(e) =>
                  setVentureClient({ unitWebsite: e.target.value })
                }
                value={ventureClient.unitWebsite}
              />
            </Stack>
            <LogoUploadDropbox addLogo={(file) => uploadLogo(file)} />
          </Box>
          <ClientContactSelect
            editMode={true}
            labelText="Main Contact"
            fieldId="unitContactId"
            field="unitContact"
            ventureClientId={ventureClient.id}
            onChange={setVentureClient}
            contactData={ventureClient.unitContact}
            required={requiredFields.includes("unitContactId")}
          />
        </Stack>
      </ScrollableDialogContent>
      <DialogActions>
        <Button variant="text" onClick={onClose}>
          Cancel
        </Button>
        <Button variant="contained" onClick={saveVentureClient}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}
