import {
  Box,
  Button,
  Container,
  Paper,
  Stack,
  styled,
  Typography,
} from "@mui/material";
import { Fragment, ReactElement, useEffect, useState } from "react";
import CreateVentureClientModal from "./CreateVentureClientModal";
import { VentureClientHttpService } from "../../Http/VentureClient/VentureClient.http.service";
import { VentureClient } from "../../Types/VentureClient";
import { useHistory } from "react-router-dom";
import ContentWrapper from "../ProjectDetails/ContentWrapper";
import useRoles from "../../Hooks/useRoles";
import PageHeader from "../UI/PageHeader";
import theme from "../../theme";
import { CorporateFare } from "@mui/icons-material";
import { useSnackbar } from "notistack";
import { getErrorMessage } from "../../utils";
import { AxiosError } from "axios";
import VentureClientListSkeleton from "./VentureClientListSkeleton";

const ClientsGrid = styled(Box)({
  display: "grid",
  gap: theme.spacing(3),
  gridTemplateColumns: "repeat(auto-fill,minmax(max(200px,(100%)/7), 1fr))",
});
export const ClientCardContainer = styled(Paper)({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  gap: theme.spacing(1),
  height: "100%",
  minWidth: "200px",
  padding: theme.spacing(3),
  borderRadius: theme.shape.radius.cardSmall,
  cursor: "pointer",
});
const PlaceHolderCotainer = styled(Container)({
  display: "flex",
  justifyContent: "center",
  margin: 0,
  alignItems: "center",
  width: "56px",
  height: "40px",
  borderRadius: theme.shape.radius.minimal,
  backgroundColor: theme.palette.surface.secondary.main,
});
const LogoCotainer = styled(Paper)({
  minHeight: 60,
  display: "flex",
  alignItems: "center",
});

export default function VentureClientList(): ReactElement {
  const history = useHistory();
  const { isAdmin, ventureClientId } = useRoles();
  const { enqueueSnackbar } = useSnackbar();

  const [ventureClients, setVentureClients] = useState<VentureClient[]>([]);
  const [toggleContentLoad, setToggleContentLoad] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    document.title = `Companies - Venture Client Platform`;
  }, []);

  useEffect(() => {
    setIsLoading(true);

    const fetchData = async () => {
      try {
        const ventureClients =
          await VentureClientHttpService.getVentureClients();
        setVentureClients(ventureClients);
      } catch (error) {
        const errorMessage = getErrorMessage(error as AxiosError);

        if (errorMessage === "Forbidden resource") {
          return history.push(`/companies/${ventureClientId}`);
        }

        enqueueSnackbar(`Could not get companies: ${errorMessage}`, {
          variant: "error",
        });
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [toggleContentLoad]);

  const [createVentureClientModalOpen, setCreateVentureClientModalOpen] =
    useState(false);

  const handleCreateVentureClientModalOpen = () => {
    setCreateVentureClientModalOpen(true);
  };

  const handleCreateVentureClientModalClose = () => {
    setCreateVentureClientModalOpen(false);
  };

  const handleCreateNewVentureClient = () => {
    setToggleContentLoad((prevState) => !prevState);
  };

  const redirect = (id: number) => {
    history.push(`/companies/${id}`);
  };

  return (
    <Fragment>
      <PageHeader
        title="Know your Venture Client."
        subtitle="These are your Venture Clients."
        actions={
          isAdmin && (
            <Button
              variant="contained"
              onClick={handleCreateVentureClientModalOpen}
            >
              Add Company
            </Button>
          )
        }
      />
      <ContentWrapper>
        <ClientsGrid>
          {isLoading && !ventureClients.length ? (
            <VentureClientListSkeleton />
          ) : (
            ventureClients
              .sort((option1, option2) =>
                option1.name.localeCompare(option2.name)
              )
              .map((ventureClient) => {
                const companyLogo = ventureClient?.files[0];
                return (
                  <ClientCardContainer
                    key={ventureClient.id}
                    onClick={() => redirect(ventureClient.id)}
                  >
                    <LogoCotainer>
                      {companyLogo ? (
                        <img
                          src={companyLogo.url}
                          alt="client logo"
                          style={{
                            objectFit: "contain",
                            maxHeight: 30,
                            maxWidth: 90,
                          }}
                        />
                      ) : (
                        <PlaceHolderCotainer>
                          <CorporateFare color="disabled" />
                        </PlaceHolderCotainer>
                      )}
                    </LogoCotainer>
                    <Stack>
                      <Typography variant="subtitle1">
                        {ventureClient.name}
                      </Typography>
                      <Typography variant="body2" color="text.mediumEmphasis">
                        {ventureClient.industry}
                      </Typography>
                    </Stack>
                  </ClientCardContainer>
                );
              })
          )}
        </ClientsGrid>
      </ContentWrapper>
      <CreateVentureClientModal
        modalOpen={createVentureClientModalOpen}
        handleModalClose={handleCreateVentureClientModalClose}
        handleCreateNewVentureClient={handleCreateNewVentureClient}
      />
    </Fragment>
  );
}
