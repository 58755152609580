import { ReactElement, useContext, useState } from "react";
import {
  Opportunity,
  OpportunityRating as OpportunityRatingType,
} from "../../../../../../Types/Opportunity";
import { styled, Typography } from "@mui/material";
import theme from "../../../../../../theme";
import { opportunityRatings } from "../../../../../../Constants/Opportunity";
import { GlobalLoaderContext } from "../../../../../../Context/LoaderContext";
import RatingContent from "../../../../RatingContent";
import RatingButton from "../../../../RatingButton";
import { Project } from "../../../../../../Types/Project";
import useRoles from "../../../../../../Hooks/useRoles";

const StyledFormControl = styled("span")(() => ({
  display: "flex",
  position: "relative",
  justifyContent: "center",
  columnGap: theme.spacing(0.5),
  "&:has(.MuiTypography-root)": {
    justifySelf: "left",
  },
}));

const SelectedIndicator = styled("span")(() => ({
  width: theme.spacing(0.75),
  aspectRatio: "1/1",
  backgroundColor: theme.palette.grey[300],
  borderRadius: "50%",
  position: "absolute",
  bottom: theme.spacing(-1),
}));

const RateStartupText = styled(Typography)(({ theme }) => ({
  cursor: "pointer",
  padding: theme.spacing(2),
  color: theme.palette.text.action.main,
  ...theme.typography.body2,
}));

interface OpportunityRatingProps {
  opportunity: Opportunity;
  onChangeRating: (option: OpportunityRatingType) => void;
  editingOpportunity: string | null;
  setEditingOpportunity: (id: string | null) => void;
  isAddingStartup: boolean;
  project: Project;
}

export default function OpportunityRating(
  props: OpportunityRatingProps
): ReactElement {
  const { canEdit } = useRoles(props.project);
  const { globalLoader } = useContext(GlobalLoaderContext);

  const [editingRating, setEditingRating] = useState(false);
  const [currentRating, setCurrentRating] = useState(props.opportunity.rating);

  const handleRatingClick = (event: React.MouseEvent<HTMLElement>) => {
    if (!globalLoader && canEdit) {
      event.stopPropagation();
      setEditingRating(true);
      props.setEditingOpportunity(`rating-${props.opportunity.id}`);
    }
  };

  return (
    <StyledFormControl>
      {editingRating &&
      !props.isAddingStartup &&
      props.editingOpportunity === `rating-${props.opportunity.id}` ? (
        opportunityRatings.map((rating) => (
          <RatingButton
            key={rating}
            onClick={(e) => {
              e.stopPropagation();
              if (!globalLoader) {
                setCurrentRating(rating);
                props.onChangeRating(rating);
                setEditingRating(false);
              }
            }}
            data-testid={`rating-button-${rating}`}
          >
            <RatingContent className={`${rating}`} rating={currentRating}>
              {rating}
            </RatingContent>
            {rating === currentRating && (
              <SelectedIndicator data-testid="select-indicator" />
            )}
          </RatingButton>
        ))
      ) : currentRating ? (
        <RatingButton
          data-testid={`current-rating-button-${currentRating}`}
          onClick={handleRatingClick}
        >
          <RatingContent className={currentRating} rating={currentRating}>
            {currentRating}
          </RatingContent>
        </RatingButton>
      ) : canEdit ? (
        <RateStartupText
          onClick={handleRatingClick}
          data-testid="rate-startup-button"
        >
          Rate Startup
        </RateStartupText>
      ) : (
        "--"
      )}
    </StyledFormControl>
  );
}
