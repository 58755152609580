export const ThirdPartyCategories = [
  "Startup Investor",
  "Tech / Startup Scouting Unit",
  "Service Providers",
  "Other",
] as const;

export const ThirPartyCategoryValues = ThirdPartyCategories.map((value) => ({
  id: value,
  name: value,
}));
