import { Box, Stack, Typography, styled, useMediaQuery } from "@mui/material";
import { ReactElement, useMemo } from "react";
import { StartupOpportunity } from "../../../../Types/Opportunity";
import ProjectCard from "./ProjectCard";
import ProjectStats from "./ProjectStats";
import {
  sortOpportunitiesByRelevance,
  capitalizeFirstLetter,
} from "../../../../utils";
import { LeadOpportunity } from "../../../../Types/LeadProject";
import LeadCard from "./LeadCard";

interface ProjectSectionProps {
  opportunities: StartupOpportunity[];
  leadOpportunities: LeadOpportunity[];
  startupName: string;
}

const ProjectSectionWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(3),
}));

interface OpportunityListProps {
  opportunities: Array<LeadOpportunity | StartupOpportunity>;
  CardComponent: React.FC<any>;
  type: "lead" | "project";
}

const OpportunityList = (props: OpportunityListProps) => {
  const { opportunities, CardComponent, type } = props;

  return (
    <Stack width="100%">
      <Typography variant="subtitle1">{capitalizeFirstLetter(type)}</Typography>
      {opportunities.length > 0 ? (
        opportunities
          .sort(sortOpportunitiesByRelevance)
          .map((opportunity) => (
            <CardComponent key={opportunity.id} opportunity={opportunity} />
          ))
      ) : (
        <Typography color="text.mediumEmphasis" py={2}>
          {`This startup has not been assigned to any ${type}.`}
        </Typography>
      )}
    </Stack>
  );
};

export default function ProjectSection(
  props: ProjectSectionProps
): ReactElement {
  const { opportunities, leadOpportunities } = props;

  const narrowResolution = useMediaQuery("(max-width:1300px)");

  const groupedOpportunities: Array<LeadOpportunity | StartupOpportunity> =
    useMemo(() => {
      return [
        ...(leadOpportunities ? leadOpportunities : []),
        ...(opportunities ? opportunities : []),
      ];
    }, [leadOpportunities, opportunities]);

  return (
    <ProjectSectionWrapper data-testid="relatedProjectsSection">
      <Typography variant="h6">Venture Client Activities</Typography>
      {groupedOpportunities.length > 0 && (
        <ProjectStats
          opportunities={props.opportunities}
          leadOpportunities={props.leadOpportunities}
        />
      )}
      <Box
        display="flex"
        gap={6}
        justifyContent="space-between"
        flexDirection={narrowResolution ? "column" : "row"}
      >
        <OpportunityList
          type="lead"
          CardComponent={LeadCard}
          opportunities={leadOpportunities}
        />
        <OpportunityList
          type="project"
          CardComponent={ProjectCard}
          opportunities={opportunities}
        />
      </Box>
    </ProjectSectionWrapper>
  );
}
