import {
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  styled,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  OutlinedInput,
  Checkbox,
  ListItemText,
  Typography,
  DialogActions,
  Stack,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { ReactElement, useEffect, useState } from "react";
import CustomTextField from "../../../../../../UI/InputFields/CustomTextField";
import {
  Opportunity,
  RejectionReason,
} from "../../../../../../../Types/Opportunity";
import theme from "../../../../../../../theme";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { findLogo } from "../../../../../../../utils";
import { RejectionReasonHttpService } from "../../../../../../../Http/RejectionReasons/RejectionReasons.http.service";
import { RocketLaunchOutlined } from "@mui/icons-material";

interface AddSelectionReasonsModalProps {
  opportunity: Opportunity;
  modalOpen: boolean;
  setModalOpen: (isOpen: boolean) => void;
  handleRejectionReasonChange: (
    updatedRejectionReasons: RejectionReason[],
    updatedDescription: string
  ) => void;
  ventureClientId: number;
}

const SelectionRationaleInfo = styled("div")(() => ({
  display: "grid",
}));

const AddSelectionReasonsModal = (
  props: AddSelectionReasonsModalProps
): ReactElement => {
  const { enqueueSnackbar } = useSnackbar();

  const [description, setDescription] = useState(
    props.opportunity.rejectionDescription
  );

  const [projectRejectionReasons, setProjectRejectionReasons] = useState<
    RejectionReason[]
  >([]);

  const [editMode, setEditMode] = useState(
    !props.opportunity.rejectionReasons?.length
  );
  const [selectedReasonsIds, setSelectedReasonsIds] = useState<number[]>(
    props.opportunity.rejectionReasons
      ? props.opportunity.rejectionReasons?.map((reason) => reason.id)
      : []
  );

  useEffect(() => {
    RejectionReasonHttpService.getRejectionReasonsByVentureClientName().then(
      (response) => {
        setProjectRejectionReasons(response);
      }
    );
  }, []);

  const updateReasonsIdList = (id: number) => {
    let updatedReasonsIdList: number[] = selectedReasonsIds;

    if (selectedReasonsIds?.length && selectedReasonsIds.includes(id)) {
      updatedReasonsIdList = updatedReasonsIdList.filter(
        (selectedid) => selectedid !== id
      );
    } else {
      updatedReasonsIdList = [...selectedReasonsIds, id];
    }
    return updatedReasonsIdList;
  };

  const handleChange = (id: number) => {
    const updatedReasonsIdList = updateReasonsIdList(id);
    setSelectedReasonsIds(updatedReasonsIdList);
  };

  const getUpdatedReasons = () => {
    const updated = projectRejectionReasons.filter((reason) =>
      selectedReasonsIds.includes(reason.id)
    );
    return updated;
  };

  const handleSave = () => {
    if (!selectedReasonsIds.length) {
      enqueueSnackbar("Please provide a reason", {
        variant: "error",
      });
    } else {
      props.handleRejectionReasonChange(getUpdatedReasons(), description);
      setEditMode(false);
      props.setModalOpen(false);
    }
  };

  const opportunityLogo = props.opportunity.startup.files
    ? findLogo(props.opportunity.startup.files) || null
    : null;

  const isFundingUndisclosed =
    props.opportunity.startup.fundingIsUndisclosed ||
    !props.opportunity.startup.totalFunding;

  return (
    <Dialog
      fullWidth
      open={props.modalOpen}
      data-testid={`${props.opportunity.startup.name}-selection-reasons-modal`}
      PaperProps={{
        sx: {
          gap: theme.spacing(4),
        },
      }}
    >
      <DialogTitle>Selection Rationale</DialogTitle>
      <DialogContent>
        <Stack gap={4}>
          <Box display="flex" gap={1}>
            <InfoOutlinedIcon />
            <Typography variant="caption">
              It is recommended to assess A-rated startups based on the specific
              criteria and requirements of the use case in order to find the
              best fit.
            </Typography>
          </Box>
          <Stack gap={1} p={2} boxShadow={theme.boxShadows[0]}>
            <Box width={theme.spacing(8)} height={theme.spacing(3)}>
              {opportunityLogo ? (
                <img
                  style={{ width: "100%", height: "100%", objectFit: "cover" }}
                  src={opportunityLogo}
                  alt="logo associated to the startup"
                />
              ) : (
                <RocketLaunchOutlined color="disabled" />
              )}
            </Box>
            <Typography
              variant="caption"
              color={theme.palette.action.secondary}
            >
              {props.opportunity.startup.name}
            </Typography>
            <Box display="flex">
              <Typography variant="caption">
                {props.opportunity.startup.billingCountry}
              </Typography>
              <Typography variant="caption" ml={2}>
                {props.opportunity.startup.dateFounded?.getFullYear()}
              </Typography>
              <Typography
                variant="caption"
                ml="auto"
                color="text.brand.accessibility"
              >
                {props.opportunity.startup.currentInvestmentStage}
              </Typography>
              <Typography
                variant="caption"
                ml={2}
                color="text.brand.accessibility"
              >
                {isFundingUndisclosed
                  ? "Not Disclosed"
                  : `$${props.opportunity.startup.totalFunding}M`}
              </Typography>
            </Box>
          </Stack>
          {!editMode && (
            <SelectionRationaleInfo>
              {props.opportunity.rejectionReasons?.map((reason) => {
                return (
                  <Typography
                    key={`list-of-reasons-${props.opportunity.id}-${reason.id}`}
                    variant="caption"
                    fontWeight={"bold"}
                  >
                    • {reason.name}
                  </Typography>
                );
              })}
              <Typography
                sx={{ paddingBlock: theme.spacing(1) }}
                variant="caption"
              >
                {props.opportunity.rejectionDescription}
              </Typography>
            </SelectionRationaleInfo>
          )}
          {editMode && (
            <>
              <FormControl sx={{ width: "-webkit-fill-available" }}>
                <InputLabel id="selection-rationale-label">
                  Select Reason(s)
                </InputLabel>
                <Select
                  labelId="selection-rationale-label"
                  id="selection-rationale-checkbox"
                  data-testid="rejection-reasons-dropdown"
                  value={selectedReasonsIds}
                  multiple
                  onClick={(event) => {
                    event.stopPropagation();
                  }}
                  input={<OutlinedInput label="Select Reason(s)" />}
                  renderValue={() => {
                    const value = selectedReasonsIds?.map(
                      (selectedReasonId) => {
                        return projectRejectionReasons.find(
                          (reason) => reason.id === selectedReasonId
                        )?.name;
                      }
                    );
                    return value ? value.join(", ") : "";
                  }}
                >
                  {projectRejectionReasons.map((reason) => (
                    <MenuItem
                      key={reason.id}
                      value={reason.id}
                      data-testid={`list-of-reasons-${reason.id}`}
                      onClick={(event) => {
                        event.stopPropagation();
                        handleChange(reason.id);
                      }}
                    >
                      <Checkbox
                        checked={selectedReasonsIds?.includes(reason.id)}
                      />
                      <ListItemText primary={reason.name} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <CustomTextField
                id="reson-description"
                label="Description"
                fullWidth
                editMode={true}
                value={description}
                onClick={(event) => {
                  event.stopPropagation();
                }}
                onChange={(event) => {
                  setDescription(event.target.value);
                }}
              />
            </>
          )}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            props.setModalOpen(false);
            setSelectedReasonsIds([]);
            setDescription("");
          }}
        >
          Cancel
        </Button>
        {editMode ? (
          <Button onClick={() => handleSave()} variant="contained">
            Save
          </Button>
        ) : (
          <Button onClick={() => setEditMode(true)} variant="contained">
            Edit
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default AddSelectionReasonsModal;
