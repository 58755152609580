import axios from "axios";
import {
  CreateOpportunityDTO,
  Opportunity,
  UpdateOpportunityDto,
} from "../../Types/Opportunity";

export default class OpportunityHttpService {
  public static async createOpportunity(
    opportunity: CreateOpportunityDTO
  ): Promise<Opportunity> {
    const res = await axios.post<Opportunity>(`/api/opportunity`, opportunity);
    return res.data;
  }

  public static async deleteOpportunity(opportunityId: number): Promise<void> {
    await axios.delete<void>(`/api/opportunity/${opportunityId}`);
  }

  public static async updateOpportunity(
    opportunity: Partial<UpdateOpportunityDto>,
    id: number
  ): Promise<void> {
    await axios.put<void>(`/api/opportunity/${id}`, opportunity);
  }
}
