import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/styles";
import { enqueueSnackbar } from "notistack";
import { ReactElement, useReducer, Reducer } from "react";
import { VentureClientHttpService } from "../../Http/VentureClient/VentureClient.http.service";
import { CreateVentureClientDTO } from "../../Types/VentureClient";
import CustomTextField from "../UI/InputFields/CustomTextField";
interface CreateVentureClientModalProps {
  handleModalClose: () => void;
  modalOpen: boolean;
  handleCreateNewVentureClient: () => void;
}

const requiredFields: (keyof CreateVentureClientDTO)[] = [
  "name",
  "unitName",
  "industry",
];

export default function CreateVentureClientModal(
  props: CreateVentureClientModalProps
): ReactElement {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const [newVentureClient, setNewVentureClient] = useReducer<
    Reducer<CreateVentureClientDTO, Partial<CreateVentureClientDTO>>
  >(
    (state, newState) => ({ ...state, ...newState }),
    {} as CreateVentureClientDTO
  );

  const isMissingRequiredField = () => {
    return requiredFields.some((field) => !newVentureClient[field]);
  };

  const handleCreateVentureClient = async () => {
    if (isMissingRequiredField()) {
      enqueueSnackbar("Please fill out required fields", {
        variant: "error",
      });
      return;
    }

    await VentureClientHttpService.createVentureClient(newVentureClient);
    props.handleCreateNewVentureClient();
    props.handleModalClose();
  };

  return (
    <Dialog
      fullWidth
      maxWidth="xs"
      fullScreen={fullScreen}
      open={props.modalOpen}
      aria-labelledby="responsive-dialog-title"
      PaperProps={{
        sx: {
          gap: theme.spacing(4),
        },
      }}
    >
      <DialogTitle id="responsive-dialog-title">Add Company</DialogTitle>
      <DialogContent>
        <Stack noValidate autoComplete="off" component="form" gap={4}>
          <CustomTextField
            editMode
            label="Name"
            data-testid="client-name"
            variant="outlined"
            fullWidth
            required={requiredFields.includes("name")}
            onChange={(event) =>
              setNewVentureClient({ name: event.target.value })
            }
          />
          <CustomTextField
            editMode
            label="Venture Client Unit Name"
            data-testid="client-unit-name"
            variant="outlined"
            fullWidth
            required={requiredFields.includes("unitName")}
            onChange={(event) =>
              setNewVentureClient({ unitName: event.target.value })
            }
          />
          <CustomTextField
            editMode
            label="Industry"
            data-testid="industry-name"
            variant="outlined"
            fullWidth
            required={requiredFields.includes("industry")}
            onChange={(event) =>
              setNewVentureClient({ industry: event.target.value })
            }
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={props.handleModalClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleCreateVentureClient} variant="contained">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}
