import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { ReactElement, Reducer, useReducer } from "react";
import { FocusAreasHttpService } from "../../../../Http/FocusArea/FocusAreas.http.service";
import theme from "../../../../theme";
import { FocusArea } from "../../../../Types/VentureClient";
import { getErrorMessage } from "../../../../utils";

interface ManageFocusAreModalOpenProps {
  focusArea: FocusArea | null;
  manageFocusAreModalOpen: boolean;
  closeModal: () => void;
  ventureClientId?: number;
  updateFocusArea: (updatedFocusArea: FocusArea) => void;
  addFocusArea: (newFocusArea: FocusArea) => void;
}

const ManageFocusAreModalOpen = (
  props: ManageFocusAreModalOpenProps
): ReactElement => {
  const { enqueueSnackbar } = useSnackbar();
  const [newFocusArea, setNewFocusArea] = useReducer<
    Reducer<FocusArea, Partial<FocusArea>>
  >(
    (state, newState) => ({ ...state, ...newState }),
    props.focusArea ||
      ({ ventureClientId: props.ventureClientId, name: "" } as FocusArea)
  );

  const isCreateMode = !props.focusArea;
  const title = isCreateMode ? "Add Focus Area" : "Rename Focus Area";

  const createFocusArea = async () => {
    if (!newFocusArea.name)
      return enqueueSnackbar("No input provided", { variant: "error" });

    try {
      const createdFocusArea = await FocusAreasHttpService.createFocusArea({
        ...newFocusArea,
        ventureClientId: props.ventureClientId,
      });

      if (createdFocusArea) {
        props.addFocusArea(createdFocusArea);
      }
      // eslint-disable-next-line
    } catch (error: any) {
      enqueueSnackbar(
        `Could not create the focus area: ${error.response.data.message}`,
        {
          variant: "error",
        }
      );
    }
  };

  const editFocusArea = async () => {
    await FocusAreasHttpService.updateFocusArea(newFocusArea)
      .then(() => {
        props.updateFocusArea(newFocusArea);
      })
      .catch((error) => {
        const errorMessage = getErrorMessage(error);
        return enqueueSnackbar(
          `Could not update the focus area: ${errorMessage}`,
          {
            variant: "error",
          }
        );
      });
  };

  return (
    <Dialog
      fullWidth
      open={props.manageFocusAreModalOpen}
      data-testid="manage-focus-area-modal"
      PaperProps={{
        sx: {
          gap: theme.spacing(4),
        },
      }}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <TextField
          id="focus-area"
          label="Focus Area"
          fullWidth
          value={newFocusArea.name}
          onChange={(event) => {
            setNewFocusArea({ name: event.target.value });
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button
          autoFocus
          onClick={() => props.closeModal()}
          data-testid="cancel-button"
        >
          Cancel
        </Button>
        <Button
          onClick={isCreateMode ? createFocusArea : editFocusArea}
          variant="contained"
          data-testid="create-button"
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ManageFocusAreModalOpen;
