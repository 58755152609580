import { useEffect, useState } from "react";
import { ThirdPartyCategoryType } from "../Types/LeadProject";
import { ThirdPartyCategoryName } from "../Types/ThirdPartyCategoryName";
import { ThirdPartyCategoryNamesHttpService } from "../Http/ThirdPartyCategoryNames/ThirdPartyCategoryNames.http.service";
import { getErrorMessage } from "../utils";
import { AxiosError } from "axios";
import { enqueueSnackbar } from "notistack";

export interface useCategoryNamesReturns {
  categoryNames: ThirdPartyCategoryName[];
  createCategoryName: (
    categoryName: string,
    callback: (newCategoryName: ThirdPartyCategoryName) => void
  ) => void;
}

function useCategoryNames(
  ventureClientId: number,
  categoryType?: ThirdPartyCategoryType
): useCategoryNamesReturns {
  const [categoryNames, setCategoryNames] = useState<ThirdPartyCategoryName[]>(
    []
  );

  useEffect(() => {
    if (ventureClientId && categoryType) {
      getCategoryNamesByVentureClient();
    }
  }, [ventureClientId, categoryType]);

  const getCategoryNamesByVentureClient = async () => {
    try {
      const categoryNames =
        await ThirdPartyCategoryNamesHttpService.getThirdPartyCategoryNames(
          ventureClientId,
          categoryType
        );
      setCategoryNames(categoryNames);
    } catch (error) {
      const message = getErrorMessage(error as AxiosError);
      enqueueSnackbar(`Could not fetch category names: ${message}`, {
        variant: "error",
      });
    }
  };

  const createCategoryName = async (
    categoryName: string,
    callback: (newCategoryName: ThirdPartyCategoryName) => void
  ) => {
    if (!ventureClientId || !categoryType) return;

    if (!categoryName) {
      enqueueSnackbar("Category Name cannot be empty", {
        variant: "error",
      });
      return;
    }

    try {
      const newCategoryName =
        await ThirdPartyCategoryNamesHttpService.createThirdPartyCategoryName({
          value: categoryName,
          ventureClientId: ventureClientId,
          categoryType: categoryType,
        });
      setCategoryNames([...categoryNames, newCategoryName]);
      callback(newCategoryName);
    } catch (error) {
      const message = getErrorMessage(error as AxiosError);
      enqueueSnackbar(`Could not create category name: ${message}`, {
        variant: "error",
      });
    }
  };

  return {
    categoryNames,
    createCategoryName,
  };
}

export default useCategoryNames;
