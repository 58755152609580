import { Close } from "@mui/icons-material";
import {
  ClickAwayListener,
  Box,
  IconButton,
  InputBase,
  InputAdornment,
  styled,
} from "@mui/material";
import { ReactElement, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import theme from "../../theme";

const SearchWrapper = styled(Box, {
  shouldForwardProp: (prop: string) => !prop.startsWith("$"),
})(({ $searchOpen }: { $searchOpen: boolean }) => ({
  display: "flex",
  alignItems: "center",
  width: $searchOpen ? 280 : 40,
  height: 40,
  transition: "width 0.5s",
  borderRadius: theme.shape.radius.full,
  backgroundColor: $searchOpen
    ? theme.palette.surface.primary.main
    : theme.palette.surface.tertiary.light,
}));

interface Props {
  placeholder: string;
  onSearchChange: (value: string) => void;
  searchValue: string;
}

const CustomSearch = ({
  placeholder,
  onSearchChange,
  searchValue,
}: Props): ReactElement => {
  const [searchOpen, setSearchOpen] = useState(false);

  const handleSearchClick = () => {
    setSearchOpen(true);
  };

  const handleClickAway = () => {
    if (searchValue === "") {
      setSearchOpen(false);
    }
  };

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <SearchWrapper $searchOpen={searchOpen}>
        {searchOpen && (
          <InputBase
            sx={{ mx: 1, width: "100%" }}
            placeholder={placeholder}
            autoFocus
            onChange={(e) => onSearchChange(e.target.value)}
            value={searchValue}
            startAdornment={
              <InputAdornment position="start">
                <SearchIcon sx={{ color: "icon.primary" }} />
              </InputAdornment>
            }
            endAdornment={
              searchValue && (
                <InputAdornment position="end">
                  <IconButton
                    sx={{ color: "icon.disabled" }}
                    size="small"
                    onClick={() => onSearchChange("")}
                  >
                    <Close fontSize="inherit" />
                  </IconButton>
                </InputAdornment>
              )
            }
          />
        )}

        {!searchOpen && (
          <IconButton
            onClick={handleSearchClick}
            color="secondary"
            data-testid="custom-search-button"
          >
            <SearchIcon />
          </IconButton>
        )}
      </SearchWrapper>
    </ClickAwayListener>
  );
};

export default CustomSearch;
