import { Box, Button, Stack } from "@mui/material";
import { useGridApiRef } from "@mui/x-data-grid-premium";
import { GridApiPremium } from "@mui/x-data-grid-premium/models/gridApiPremium";
import { ReactElement, useState } from "react";
import { ClientContactDTO } from "../../../../Types/ClientContact";
import ClientsDataGrid from "../../../Contacts/ClientComponents/ClientContactList";
import ContactsDataGridToolbar from "../../../Contacts/ContactsDataGridToolbar";
import SearchBar from "../../../UI/SearchBar";
import Fuse from "fuse.js";
import ManageClientContactModal from "../../../Contacts/ClientComponents/ManageClientContactModal";
import { VentureClient } from "../../../../Types/VentureClient";
import useRoles from "../../../../Hooks/useRoles";

interface ContactsListProps {
  ventureClient: VentureClient;
  contacts: ClientContactDTO[];
  onContactSave(): void;
  loading: boolean;
}

export default function ContactsList({
  ventureClient,
  contacts,
  onContactSave,
  loading,
}: ContactsListProps): ReactElement {
  const { canEdit } = useRoles(undefined, undefined, ventureClient);
  const apiRef = useGridApiRef() as React.MutableRefObject<GridApiPremium>;
  const [createContactModalOpen, setCreateContactModalOpen] = useState(false);
  const [searchValue, setSearchValue] = useState("");

  const fuse = new Fuse(contacts, {
    includeScore: true,
    threshold: 0.2,
    keys: ["name"],
  });

  const contactsToDisplay =
    searchValue !== ""
      ? [...fuse.search(searchValue).map((result) => result.item)]
      : [...contacts.sort((a, b) => a.name.localeCompare(b.name))];

  const handleAddContact = () => {
    setCreateContactModalOpen(true);
  };

  const handleSearch = (searchValue: string) => {
    setSearchValue(searchValue);
  };

  return (
    <>
      <Stack gap={2}>
        <Box
          gap={1}
          display="flex"
          width="100%"
          alignItems="center"
          justifyContent="space-between"
        >
          <Box maxWidth={500} width="100%">
            <SearchBar
              searchValue={searchValue}
              handleValueChange={handleSearch}
              entity="contact"
            />
          </Box>
          <Box display="flex" gap={1}>
            <ContactsDataGridToolbar apiRef={apiRef} />
            {canEdit && (
              <Button
                onClick={handleAddContact}
                variant="contained"
                data-testid="add-contact"
              >
                Add Contact
              </Button>
            )}
          </Box>
        </Box>
        <Stack
          gap={2}
          bgcolor="transparent"
          id="contacts-list"
          height="calc(100vh - 252px)"
        >
          <ClientsDataGrid
            rows={contactsToDisplay}
            apiRef={apiRef}
            loading={loading}
            refreshClientContact={onContactSave}
            ventureClientId={ventureClient.id}
            canEdit={canEdit}
          />
        </Stack>
      </Stack>
      {createContactModalOpen && (
        <ManageClientContactModal
          ventureClientId={ventureClient.id}
          setModalOpen={setCreateContactModalOpen}
          modalOpen={createContactModalOpen}
          handleSave={onContactSave}
        />
      )}
    </>
  );
}
