import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { ReactElement, useEffect, useState } from "react";
import {
  ClientContactDTO,
  ProjectLeadMatch,
} from "../../../../Types/ClientContact";
import { NavLink } from "react-router-dom";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import CopyToClipboardButton from "../../../UI/CopyToClipBoardButton";
import ClientContactHttpService from "../../../../Http/ClientContact/ClientContact.http.service";
import ManageClientContactModal from "../ManageClientContactModal";
import { useSnackbar } from "notistack";
import CloseIcon from "@mui/icons-material/Close";
import ContactName from "../../ContactCard/ContactName/ContactName";
import PhonelinkRingIcon from "@mui/icons-material/PhonelinkRing";
import MailOutlineOutlinedIcon from "@mui/icons-material/MailOutlineOutlined";
import useRoles from "../../../../Hooks/useRoles";

interface ContactDetailsModalProps {
  contact: ClientContactDTO | ProjectLeadMatch;
  setModalOpen: (state: boolean) => void;
  handleSave: () => void;
  modalOpen: boolean;
  mustFetch?: boolean;
}
function DetailsClientContactModal(
  props: ContactDetailsModalProps
): ReactElement {
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const { isViewer } = useRoles();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [contact, setContact] = useState(props.contact);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [refresh, setRefresh] = useState(!!props.mustFetch);

  useEffect(() => {
    if (refresh) {
      ClientContactHttpService.getContactById(props.contact.id)
        .then((res) => setContact(res))
        .catch(() => {
          props.setModalOpen(false);
          enqueueSnackbar("Contact not found", {
            variant: "error",
          });
        })
        .finally(() => {
          setLoading(false);
          setRefresh(false);
        });
    }
  }, [refresh]);

  if (props.mustFetch && loading) {
    return (
      <Dialog fullScreen={fullScreen} open={loading}>
        <Box sx={{ p: 3 }}>
          <CircularProgress data-testid="client-contact-loader" />
        </Box>
      </Dialog>
    );
  }

  return (
    <>
      <Dialog
        fullScreen={fullScreen}
        open={props.modalOpen}
        onClose={() => props.setModalOpen(false)}
        data-testid="client-details-view"
        maxWidth="sm"
        fullWidth
      >
        <Stack gap={4}>
          <DialogTitle>
            Contact
            <IconButton
              sx={{ color: "icon.primary" }}
              onClick={() => props.setModalOpen(false)}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <Stack gap={3}>
              <ContactName
                name={contact.name}
                title={contact.title}
                orientation="horizontal"
                gender={contact.gender}
              />
              <Box display="grid" gap={3} gridTemplateColumns="repeat(3, 1fr)">
                <Stack gap={0.5}>
                  <Typography variant="caption" color="text.mediumEmphasis">
                    Department Code
                  </Typography>
                  <Typography variant="body1" color="text.highEmphasis">
                    {contact.departmentCode || "--"}
                  </Typography>
                </Stack>
                <Stack gap={0.5}>
                  <Typography variant="caption" color="text.mediumEmphasis">
                    Organizational Unit
                  </Typography>
                  <Typography variant="body1" color="text.highEmphasis">
                    {contact.businessUnit.name || "--"}
                  </Typography>
                </Stack>
                <Stack gap={0.5}>
                  <Typography variant="caption" color="text.mediumEmphasis">
                    Company
                  </Typography>
                  <Typography variant="body1" color="text.highEmphasis">
                    <NavLink
                      to={"/companies/" + contact.businessUnit.ventureClient.id}
                    >
                      {contact.businessUnit.ventureClient.name}
                    </NavLink>
                  </Typography>
                </Stack>
              </Box>
              <Box display="flex" alignItems="center" gap={4}>
                <Box display="flex" alignItems="center" gap={1}>
                  <PhonelinkRingIcon sx={{ fontSize: "1rem" }} />
                  <Typography variant="body2">
                    {contact.phone || "--"}
                    {contact.phone && (
                      <CopyToClipboardButton
                        toolTipText="Copy phone to clipboard"
                        toBeCopied={contact.phone}
                        icon={
                          <ContentCopyOutlinedIcon
                            sx={{ fontSize: "1rem" }}
                            color="info"
                          />
                        }
                        successText="Phone number successfully copied to clipboard"
                      />
                    )}
                  </Typography>
                </Box>

                <Box display="flex" alignItems="center" gap={1}>
                  <MailOutlineOutlinedIcon sx={{ fontSize: "1rem" }} />
                  <Typography variant="body2">
                    {contact.email || "--"}
                    {contact.email && (
                      <CopyToClipboardButton
                        toolTipText="Copy email to clipboard"
                        toBeCopied={contact.email}
                        icon={
                          <ContentCopyOutlinedIcon
                            sx={{ fontSize: "1rem" }}
                            color="info"
                          />
                        }
                        successText="Email address successfully copied to clipboard"
                      />
                    )}
                  </Typography>
                </Box>
              </Box>
            </Stack>
          </DialogContent>
          {!isViewer && (
            <DialogActions>
              <Button
                variant="contained"
                onClick={() => setEditModalOpen(true)}
                data-testid="edit-client-details-view"
              >
                Edit
              </Button>
            </DialogActions>
          )}
        </Stack>
      </Dialog>
      {editModalOpen && (
        <ManageClientContactModal
          ventureClientId={contact.businessUnit.ventureClient.id}
          contact={contact}
          setModalOpen={setEditModalOpen}
          modalOpen={editModalOpen}
          handleSave={() => {
            setRefresh(true);
            props.handleSave();
          }}
        />
      )}
    </>
  );
}

export default DetailsClientContactModal;
