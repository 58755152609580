import { Box, Chip, styled, Typography } from "@mui/material";
import { ReactElement } from "react";
import theme from "../../../../theme";
import { Meeting } from "../../../../Types/Meeting";
import { formatDate } from "../../../../utils";

const DateDisplay = styled(Chip, {
  shouldForwardProp: (prop: string) => !prop.startsWith("$"),
})(({ $dateStart }: { $dateStart: Date | null }) => ({
  borderRadius: theme.shape.radius.minimal,
  height: "24px",
  pointerEvents: "none",
  borderColor: $dateStart
    ? theme.palette.surface.brand.accessibility
    : theme.palette.borderOutline.main,
  color: $dateStart
    ? theme.palette.text.brand.accessibility
    : theme.palette.text.mediumEmphasis,
}));

const BarFromMeeting = styled("span", {
  shouldForwardProp: (prop: string) => !prop.startsWith("$"),
})<{ $barColor: string }>(({ $barColor }: { $barColor: string }) => ({
  position: "relative",
  minWidth: 58,
  width: 58,
  height: 1,
  backgroundColor: $barColor,
  "&::after": {
    content: "''",
    position: "absolute",
    right: "0",
    transform: "translate(0%,-50%)",
    width: "16px",
    aspectRatio: "1/1",
    borderRadius: "50%",
    border: `1px solid ${$barColor}`,
    backgroundColor: $barColor,
    zIndex: 9,
  },
}));

interface OverviewMeetingRowProps {
  meeting: Meeting;
}
export default function OverviewMeetingRow({
  meeting,
}: OverviewMeetingRowProps): ReactElement {
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  const meetingDate = new Date(meeting.dateStart || "");
  meetingDate.setHours(0, 0, 0, 0);

  const barColor =
    meetingDate <= today
      ? theme.palette.primary.main
      : theme.palette.borderOutline.main;

  return (
    <Box
      display="flex"
      width="100%"
      alignItems="center"
      padding={theme.spacing(1, 2, 1, 0)}
      position="relative"
    >
      <Box
        display="flex"
        gap={2}
        alignItems="center"
        p={2}
        bgcolor="background.default"
        borderRadius={theme.shape.radius.minimal}
        width="100%"
      >
        {meetingDate && (
          <DateDisplay
            variant="outlined"
            size="medium"
            label={
              <Typography variant="caption">
                {meeting.dateStart ? formatDate(meetingDate) : "No Date Set"}
              </Typography>
            }
            $dateStart={meeting.dateStart}
          />
        )}
        <Typography variant="subtitle2">
          {meeting.type}
          {meeting.title && (
            <Typography component="span"> - {meeting.title}</Typography>
          )}
        </Typography>
      </Box>
      <BarFromMeeting $barColor={barColor} />
    </Box>
  );
}
