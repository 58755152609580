import { ReactElement, useEffect, useState } from "react";
import { Box, Button, styled, Typography } from "@mui/material";
import theme from "../../theme";
import useRoles from "../../Hooks/useRoles";
import { ReportProblemOutlined } from "@mui/icons-material";

const AirbusAmberBannerContainer = styled(Box)(() => ({
  zIndex: 999,
  width: "100%",
  padding: theme.spacing(1),
  marginLeft: theme.spacing(7),
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: theme.spacing(3),
  position: "fixed",
  bottom: theme.spacing(0),
  backgroundColor: theme.palette.surface.alert.light,
}));

const STORAGE_KEY = "bannerDismissedDate";

const AirbusAmberBanner = (): ReactElement | null => {
  const { isExternalUser, ventureClientId } = useRoles();

  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    if (isExternalUser && ventureClientId === 14) {
      const dismissedDate = localStorage.getItem(STORAGE_KEY);
      const today = new Date().toISOString().split("T")[0];

      if (dismissedDate !== today) {
        setIsVisible(true);
      }
    }
  }, [isExternalUser, ventureClientId]);

  const handleDismiss = () => {
    const today = new Date().toISOString().split("T")[0];
    localStorage.setItem(STORAGE_KEY, today);
    setIsVisible(false);
  };

  if (!isVisible) return null;

  return (
    <AirbusAmberBannerContainer>
      <Box display="flex" alignItems="center" gap={1}>
        <ReportProblemOutlined color="warning" />
        <Typography variant="subtitle2">Airbus Amber</Typography>
      </Box>
      <Typography>
        By using this application, you agree to follow the associated security
        guidelines.
      </Typography>
      <Button onClick={handleDismiss}>Dismiss</Button>
    </AirbusAmberBannerContainer>
  );
};

export default AirbusAmberBanner;
