import { Typography, styled } from "@mui/material";
import GrainIcon from "@mui/icons-material/Grain";
import { ReactElement } from "react";

const BlurryGeneratingBackground = styled("div")(({ theme }) => ({
  position: "absolute",
  inset: 0,
  zIndex: 2,
  background:
    "linear-gradient(180deg, transparent 0%, rgba(238,238,238,1) 25%)",
  opacity: ".95",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: theme.shape.borderRadius,

  "& p": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "1rem",
  },
}));

const AutoGenerateBlurBackground = (): ReactElement => {
  return (
    <BlurryGeneratingBackground>
      <Typography variant="body1">
        <GrainIcon sx={{ fontSize: "2rem" }} /> Generating...
      </Typography>
    </BlurryGeneratingBackground>
  );
};

export default AutoGenerateBlurBackground;
