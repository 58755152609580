import {
  Box,
  Chip,
  ChipProps,
  IconButton,
  Stack,
  Typography,
  styled,
  Container,
} from "@mui/material";
import FunnelButton from "../FunnelStageComponents/FunnelButton";
import { FunnelStage, Project } from "../../../../Types/Project";
import { ReactElement, useContext, useState } from "react";
import { GlobalProjectEditContext } from "../../../../Context/ProjectDetailsContext";
import theme from "../../../../theme";
import ContactName from "../../../Contacts/ContactCard/ContactName/ContactName";
import { EditOutlined } from "@mui/icons-material";
import EditProjectModal from "../EditProjectModal";
import useClient from "../../../../Hooks/useClient";
import { capitalizeFirstLetter, getStatusColor } from "../../../../utils";
import useElementHeight from "../../../../Hooks/useElementHeight";

import { formatDate } from "../../../../utils";
import useRoles from "../../../../Hooks/useRoles";

const StyledContactName = styled(ContactName)({
  gap: theme.spacing(1),
  flex: "initial",
  "& .contact-name": {
    ...theme.typography.body2,
  },
  "& .contact-title": {
    ...theme.typography.overline,
  },
});

const TruncatedText = styled(Typography)(
  ({ maxLines }: { maxLines: number }) => ({
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    WebkitLineClamp: maxLines,
    WebkitBoxOrient: "vertical",
  })
);

const StatusChip = styled(Chip, {
  shouldForwardProp: (prop: string) => !prop.startsWith("$"),
})<{ $statusColor?: string }>(({ $statusColor }) => ({
  color: $statusColor,
  borderColor: $statusColor,
  backgroundColor: theme.palette.icon.primaryInvert.disabled,
}));

const FocusAreaChip = styled((props: ChipProps) => (
  <Chip variant="outlined" {...props} />
))({
  borderColor: theme.palette.borderOutline.main,
  color: theme.palette.text.primaryInvert.main,
  backgroundColor: "transparent",
});

interface ProjectDetailsHeaderProps {
  projectData: Project;
  setFunnelStage: (funnelStage: FunnelStage) => Promise<void>;
  moveProjectToTheNewStage: () => Promise<void>;
  getNextFunnelStage: () => FunnelStage;
  projectNameRef: React.RefObject<HTMLElement>;
  bottomHeaderRef: React.RefObject<HTMLElement>;
  handleSave: (withScroll?: boolean) => void;
}

const ProjectDetailsHeader = ({
  projectData,
  setFunnelStage,
  moveProjectToTheNewStage,
  getNextFunnelStage,
  projectNameRef,
  bottomHeaderRef,
  handleSave,
}: ProjectDetailsHeaderProps): ReactElement => {
  const { globalEditMode, activeStep } = useContext(GlobalProjectEditContext);
  const { background, hero } = useClient();
  const { canEdit } = useRoles(projectData);
  const [modalOpen, setModalOpen] = useState(false);

  const lastModifiedDate = projectData.lastModifiedDate
    ? formatDate(projectData.lastModifiedDate)
    : "";
  const dateCreated = projectData.lastModifiedDate
    ? formatDate(projectData.dateCreated)
    : "";

  const [topheaderHeight, topHeaderRef] = useElementHeight();

  const isDevelopment = process.env.NODE_ENV === "development";
  const baseS3Url = isDevelopment
    ? "https://vclos-frontend.s3.eu-central-1.amazonaws.com"
    : "https://vclos-frontend-prod.s3.eu-central-1.amazonaws.com";

  const backgroundUrl =
    hero || background || baseS3Url + "/project-details-header-bg.png";

  const handleModalOpen = () => {
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
    window.scrollTo({ behavior: "smooth", top: 0 });
  };

  return (
    <>
      <Box
        py={3}
        position="sticky"
        id="page-header"
        top={theme.spacing(-3)}
        zIndex={4}
        ref={topHeaderRef}
        sx={{
          background: `linear-gradient(to right, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0.5)), url(${backgroundUrl}) no-repeat top center fixed`,
          backgroundSize: "100% auto",
        }}
      >
        <Container>
          <Box
            pt={10.5}
            display="flex"
            position="relative"
            justifyContent="space-between"
            alignItems="center"
            color="text.primaryInvert.main"
            gap={5}
          >
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              color="text.primaryInvert.main"
              maxWidth="60%"
              gap={2}
            >
              <TruncatedText
                ref={projectNameRef}
                variant="h3"
                maxLines={2}
                data-testid="project-name"
              >
                {projectData.name}
              </TruncatedText>
              <Box>
                {projectData.status == "archived" && (
                  <StatusChip
                    data-testid="project-status-chip"
                    label={capitalizeFirstLetter(projectData.status)}
                    variant="outlined"
                    size="medium"
                    $statusColor={getStatusColor("archived white")}
                  />
                )}
              </Box>
            </Box>
            <Box display="flex" gap={5} alignItems="center">
              {canEdit && (
                <IconButton
                  id="edit-project-button"
                  color="secondary"
                  onClick={handleModalOpen}
                >
                  <EditOutlined />
                </IconButton>
              )}
              {!globalEditMode &&
                projectData &&
                activeStep !== null &&
                canEdit && (
                  <FunnelButton
                    projectData={projectData}
                    activeStep={activeStep}
                    setFunnelStage={setFunnelStage}
                    moveProjectToTheNewStage={moveProjectToTheNewStage}
                    getNextFunnelStage={getNextFunnelStage}
                  />
                )}
            </Box>
          </Box>
        </Container>
      </Box>
      <Box
        pb={3}
        position="sticky"
        top={topheaderHeight - parseInt(theme.spacing(3))}
        sx={{
          background: `linear-gradient(to right, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0.5)), url(${backgroundUrl}) no-repeat top center fixed`,
          backgroundSize: "100% auto",
        }}
        ref={bottomHeaderRef}
      >
        <Container>
          <Box
            display="flex"
            position="relative"
            justifyContent="space-between"
            color="text.primaryInvert.main"
          >
            <Stack gap={2} width={800}>
              <Box display="flex" gap={2} alignItems="center">
                <Box display="flex" gap={1} alignItems="center">
                  <Typography
                    variant="subtitle2"
                    data-testid="project-venture-client"
                  >
                    {projectData.businessUnit.ventureClient.name}
                  </Typography>
                  <Typography
                    variant="body2"
                    data-testid="project-business-unit"
                  >
                    {projectData.businessUnit.name}
                  </Typography>
                </Box>
                {projectData.focusAreas.map((focusArea) => {
                  return (
                    <FocusAreaChip
                      key={focusArea.id}
                      label={focusArea.name}
                      variant="outlined"
                    />
                  );
                })}
              </Box>
              <TruncatedText
                maxLines={5}
                data-testid="project-short-description"
              >
                {projectData.shortDescription}
              </TruncatedText>
              <Box display="flex" gap={4} sx={{ pointerEvents: "none" }}>
                {projectData.projectOwner && (
                  <StyledContactName
                    name={projectData.projectOwner.name}
                    title="Project Owner"
                    orientation="horizontal"
                    isVentureAssociate
                    invertColors
                  />
                )}
                {projectData.startupIntelligence && (
                  <StyledContactName
                    name={projectData.startupIntelligence.name}
                    title="Startup Sourcing"
                    orientation="horizontal"
                    isVentureAssociate
                    invertColors
                  />
                )}
                <Box display="flex" flexDirection="column">
                  <Typography
                    variant="body2"
                    color="text.primaryInvert.highEmphasis"
                  >
                    {dateCreated}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="text.primaryInvert.mediumEmphasis"
                  >
                    Created
                  </Typography>
                </Box>
                {lastModifiedDate && (
                  <Box display="flex" flexDirection="column">
                    <Typography
                      variant="body2"
                      color="text.primaryInvert.highEmphasis"
                    >
                      {lastModifiedDate}
                      {projectData.lastModifiedBy
                        ? ` by ${projectData.lastModifiedBy.name}`
                        : ""}
                    </Typography>
                    <Typography
                      variant="body2"
                      color="text.primaryInvert.mediumEmphasis"
                    >
                      Last Edited
                    </Typography>
                  </Box>
                )}
              </Box>
            </Stack>
          </Box>
        </Container>
      </Box>
      {modalOpen && (
        <EditProjectModal
          handleModalClose={handleModalClose}
          modalOpen={modalOpen}
          projectData={projectData}
          handleSave={handleSave}
        />
      )}
    </>
  );
};

export default ProjectDetailsHeader;
