import { Stack } from "@mui/material";
import ContactCopy from "./ContactCopy";
import { ClientContactDTO } from "../../../Types/ClientContact";
import { StartupContactDTO } from "../../../Types/StartupContact";
import { ReactElement } from "react";

interface ContactCardContactsProps {
  contact: ClientContactDTO | StartupContactDTO;
}

const ContactCardContacts = ({
  contact,
}: ContactCardContactsProps): ReactElement => {
  return (
    <Stack gap={1} columnGap={4} flex="1 1 0" flexWrap="wrap">
      <ContactCopy value={contact.phone} variant="phone" />
      <ContactCopy value={contact.email} variant="email" />
    </Stack>
  );
};

export default ContactCardContacts;
