import { Box, IconButton, Stack, styled, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import { ReactElement, useState } from "react";
import theme from "../../../theme";
import { LeadProjectHistory } from "../../../Types/LeadProjectHistory";
import { Project } from "../../../Types/Project";
import { formatDate, getErrorMessage } from "../../../utils";
import ContactName from "../../Contacts/ContactCard/ContactName/ContactName";
import CustomExpendableText from "../../UI/CustomExpendableText";
import LeadProjectHistoryHttpService from "../../../Http/LeadProjectHistory/LeadProjectHistory.http.service";
import { AxiosError } from "axios";
import EditDeleteMenu from "../../UI/EditDeleteMenu";
import DeleteOrRemoveModal from "../../UI/Modals/DeleteOrRemoveModal/DeleteOrRemoveModal";
import ManageLeadStatusComment from "./ManageLeadStatusComment";
import { Pause, PlayArrow, Stop, Stream } from "@mui/icons-material";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import parse from "html-react-parser";
import EditLeadProjectStatus from "./EditLeadProjectStatus";

const Container = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  backgroundColor: theme.palette.background.default,
  padding: theme.spacing(2),
  gap: theme.spacing(2),
}));

const IconWrapper = styled(Box)(() => ({
  padding: theme.spacing(1),
  height: theme.spacing(4),
  borderRadius: "50%",
  backgroundColor: theme.palette.surface.mediumEmphasis,
}));

interface LeadProjectHistoryItemProps {
  history: LeadProjectHistory;
  projectsLaunched: Project[];
  onHoldDeadline: string | null;
  handleSave: () => void;
  refreshHistory: () => void;
  index: number;
  lastOnHoldHistoryIndex: number;
  canEdit: boolean;
}
const LeadProjectHistoryItem = (
  props: LeadProjectHistoryItemProps
): ReactElement => {
  const { history, refreshHistory, handleSave } = props;
  const isExternalUser = history.user.roleName === "Client User";
  const [manageCommentModal, setManageCommentModal] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [editStatusModal, setEditStatusModal] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const reason = history.statusChangeReason?.description;

  const handleDeleteComment = async () => {
    await LeadProjectHistoryHttpService.deleteLeadProjectHistory(history.id)
      .then(() => {
        enqueueSnackbar("Status Comment successfully deleted", {
          variant: "success",
        });
        refreshHistory();
        handleSave();
      })
      .catch((error) => {
        const errorMessage = getErrorMessage(error as AxiosError);
        enqueueSnackbar(`Could not delete the comment: ${errorMessage}`, {
          variant: "error",
        });
      });
  };

  switch (history.type) {
    case "Lead Project Status Comment":
      return (
        <>
          <Stack px={2} gap={2} data-testid="status-comment-history">
            <Box display="flex" justifyContent="space-between">
              <ContactName
                isVentureAssociate={!isExternalUser}
                orientation="horizontal"
                name={history.user.name}
                title={formatDate(history.dateTriggered)}
              />
              {props.canEdit && (
                <EditDeleteMenu
                  entity="lead-status-comment"
                  setEditModalOpen={setManageCommentModal}
                  setDeleteModalOpen={setIsDeleteModalOpen}
                  sx={{ fontSize: 18 }}
                />
              )}
            </Box>
            <Typography whiteSpace="pre-wrap">
              <CustomExpendableText text={history.description} />
            </Typography>
          </Stack>
          {isDeleteModalOpen && (
            <DeleteOrRemoveModal
              id={history.leadProjectId}
              modalOpen={isDeleteModalOpen}
              setModalOpen={setIsDeleteModalOpen}
              entity="Comment"
              handleDelete={handleDeleteComment}
              actionType="delete"
            />
          )}

          {manageCommentModal && (
            <ManageLeadStatusComment
              modalOpen={manageCommentModal}
              setModalOpen={setManageCommentModal}
              leadProjectId={history.leadProjectId}
              handleSave={props.handleSave}
              openAssociatedModal={false}
              setLeadProjectStatusOverviewModal={() => false}
              refreshHistory={props.refreshHistory}
              history={history}
            />
          )}
        </>
      );

    case "Lead Project Status Change":
      let icon;
      switch (history.newValue) {
        case "on hold":
          icon = (
            <IconWrapper>
              <Pause sx={{ color: "white", fontSize: "1rem" }} />
            </IconWrapper>
          );
          break;
        case "archived":
          icon = (
            <IconWrapper>
              <Stop sx={{ color: "white", fontSize: "1rem" }} />
            </IconWrapper>
          );
          break;
        default:
          icon = (
            <IconWrapper>
              <PlayArrow sx={{ color: "white", fontSize: "1rem" }} />
            </IconWrapper>
          );
      }

      const isOnHold = history.newValue === "on hold";
      const isArchived = history.newValue === "archived";
      const isOnHoldOrArchived = isOnHold || isArchived;
      const onHoldDeadline = props.onHoldDeadline
        ? formatDate(new Date(props.onHoldDeadline))
        : null;

      return (
        <>
          <Stack
            bgcolor="background.default"
            p={2}
            gap={2}
            data-testid="status-change-history"
          >
            {" "}
            <Box display="flex" alignItems="center" gap={2}>
              {icon}
              <Typography>
                The lead has been set to <b>{history.newValue}</b>.
              </Typography>
              <Box ml="auto" display="flex" alignItems="center" gap={1}>
                <Typography variant="body2">{history.user.name}</Typography>
                <Typography
                  variant="body2"
                  color={theme.palette.text.mediumEmphasis}
                >
                  {formatDate(history.dateTriggered)}
                </Typography>
              </Box>
              {props.canEdit && (
                <IconButton
                  sx={{ color: "icon.primary" }}
                  onClick={() => {
                    setEditStatusModal(true);
                  }}
                  data-testid="edit-history-item"
                >
                  <EditOutlinedIcon
                    sx={{
                      fontSize: "18px",
                    }}
                  />
                </IconButton>
              )}
            </Box>
            {(isOnHoldOrArchived || history.description) && (
              <Stack gap={1}>
                {isArchived && (
                  <>
                    <Box display="flex" gap={3}>
                      <Box display="flex" alignItems="center">
                        <Typography
                          variant="body2"
                          color={theme.palette.text.mediumEmphasis}
                        >
                          Reason:
                        </Typography>
                        &nbsp;
                        <Typography variant="body2">{reason}</Typography>
                      </Box>
                    </Box>
                    {isOnHold &&
                      props.index === props.lastOnHoldHistoryIndex && (
                        <Box display="flex" alignItems="center">
                          <Typography
                            variant="body2"
                            color={theme.palette.text.mediumEmphasis}
                          >
                            On hold until:
                          </Typography>
                          &nbsp;
                          <Typography variant="body2">
                            {onHoldDeadline}
                          </Typography>
                        </Box>
                      )}
                  </>
                )}
                {history.description && (
                  <Typography whiteSpace="pre-wrap">
                    {history.description}
                  </Typography>
                )}
              </Stack>
            )}
          </Stack>
          {editStatusModal && (
            <EditLeadProjectStatus
              leadProjectId={history.leadProjectId}
              historyId={history.id}
              statusComment={history.description}
              status={history.newValue}
              onHoldDeadline={props.onHoldDeadline}
              modalOpen={editStatusModal}
              setModalOpen={setEditStatusModal}
              handleSave={() => {
                refreshHistory();
                handleSave();
              }}
              openAssociatedModal={false}
              setLeadProjectStatusOverviewModal={() => false}
              preventStatusChange
            />
          )}
        </>
      );

    case "Lead Project Created":
      return (
        <Container data-testid="lead-created-history">
          <IconWrapper>
            <Stream sx={{ color: "white", fontSize: "1rem" }} />
          </IconWrapper>
          <Typography>
            The lead has been <b>created</b>.
          </Typography>
          <Box ml="auto" display="flex" alignItems="center" gap={1}>
            <Typography variant="body2">{history.user.name}</Typography>
            <Typography
              variant="body2"
              color={theme.palette.text.mediumEmphasis}
            >
              {formatDate(history.dateTriggered)}
            </Typography>
          </Box>
        </Container>
      );

    case "Project Launched":
      return (
        <Container data-testid="lead-created-history">
          <IconWrapper>
            <Stream sx={{ color: "white", fontSize: "1rem" }} />
          </IconWrapper>
          <Typography
            sx={{
              "& a": {
                color: theme.palette.text.action.main,
                ...theme.typography.subtitle2,
              },
            }}
          >
            {parse(history.description)}
          </Typography>
          <Box ml="auto" display="flex" alignItems="center" gap={1}>
            <Typography variant="body2">{history.user.name}</Typography>
            <Typography
              variant="body2"
              color={theme.palette.text.mediumEmphasis}
            >
              {formatDate(history.dateTriggered)}
            </Typography>
          </Box>
        </Container>
      );
    default:
      return <></>;
  }
};

export default LeadProjectHistoryItem;
