import axios from "axios";
import { FocusArea } from "../../Types/VentureClient";

export class FocusAreasHttpService {
  public static async getAll(): Promise<FocusArea[]> {
    return (await axios.get<FocusArea[]>(`/api/focus-areas`)).data;
  }

  public static async createFocusArea(
    focusArea: Omit<FocusArea, "id">
  ): Promise<FocusArea> {
    const response = await axios.post<FocusArea>(`/api/focus-areas`, focusArea);
    return response.data;
  }

  public static async updateFocusArea(
    focusArea: FocusArea
  ): Promise<FocusArea> {
    const response = await axios.put<FocusArea>(`/api/focus-areas`, focusArea);
    return response.data;
  }

  public static async deleteFocusArea(id: number): Promise<void> {
    await axios.delete(`/api/focus-areas/${id}`);
  }

  public static async archiveFocusArea(id: number): Promise<void> {
    await axios.put(`/api/focus-areas/${id}/archive`);
  }

  public static async unarchiveFocusArea(id: number): Promise<void> {
    await axios.put(`/api/focus-areas/${id}/unarchive`);
  }
}
