import { Skeleton, Box, styled } from "@mui/material";
import { ReactElement } from "react";
import theme from "../../../../theme";
import ContactNameSkeleton from "../../../Contacts/ContactCard/ContactName/ContactNameSkeleton";

const LeadProjectLeaderSummaryContainer = styled(Box)({
  backgroundColor: theme.palette.surface.secondary.main,
  display: "grid",
  alignItems: "center",
  padding: theme.spacing(2),
  borderRadius: theme.shape.radius.minimal,
  gridTemplateColumns: "1fr 1fr 1fr auto",
});

export function LeadProjectLeaderSummarySkeleton(): ReactElement {
  return (
    <LeadProjectLeaderSummaryContainer>
      <ContactNameSkeleton />
      <Skeleton width={64} height={16} />
      <Skeleton width={180} height={16} />
      <Box display="flex" gap={2}>
        <Skeleton width={24} height={24} />
        <Skeleton width={24} height={24} />
      </Box>
    </LeadProjectLeaderSummaryContainer>
  );
}
