import { IconButton, Paper, Tooltip, Typography } from "@mui/material";
import { ReactElement } from "react";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import parse from "html-react-parser";

interface CustomToolTipProps {
  id: string;
  toolTipText: string | ReactElement;
  isForHeader?: boolean;
  warning?: boolean;
}

const CustomToolTip = (props: CustomToolTipProps): ReactElement => {
  const { id, toolTipText, warning } = props;

  return (
    <Tooltip
      id={id}
      title={
        <Paper sx={{ m: -2, width: "fit-content" }} elevation={3}>
          {typeof toolTipText === "string" ? (
            <Typography
              variant="body2"
              sx={{
                p: 2,
                color: "black",
                background: "none",
              }}
            >
              {parse(toolTipText)}
            </Typography>
          ) : (
            toolTipText
          )}
        </Paper>
      }
    >
      <IconButton
        id={`${id}-help-button`}
        sx={{ marginRight: "-8px" }}
        color={warning ? "warning" : "inherit"}
      >
        <HelpOutlineIcon
          sx={{ fontSize: `${props.isForHeader ? "0.9rem" : "1.2rem"}` }}
        />
      </IconButton>
    </Tooltip>
  );
};

export default CustomToolTip;
