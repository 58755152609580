import { Grid } from "@mui/material";
import { ReactElement } from "react";
import { GenericProject } from "../../../Types/Project";
import TotalProjects from "./TotalProjects";
import BusinessImpact from "./BusinessImpact";
import Startups from "./Startups";
import {
  BusinessImpactValues,
  ProjectCountByStage,
  ProjectSpeed,
  StartupCountByAssessment,
} from "../../../Types/Analytics";
import { calculateMedian, getImpactValueByFunnelStage } from "../../../utils";
import ConversionRate from "./ConversionRate";
import { stageScores } from "../../../Constants/FunnelStages";
import Speed from "./Speed";

interface MetricsProps {
  projects: GenericProject[];
}

function Metrics(props: MetricsProps): ReactElement {
  const projectCountByStage: ProjectCountByStage = {
    discover: { total: 0, status: [] },
    assess: { total: 0, status: [] },
    buy: { total: 0, status: [] },
    pilot: { total: 0, status: [] },
    adopt: { total: 0, status: [] },
  };

  const impactValuesByImpactType: BusinessImpactValues = {
    Cost: 0,
    Revenue: 0,
    "Cost & Revenue": 0,
  };

  const startupCountByAssessment: StartupCountByAssessment = {
    curated: 0,
    qualified: 0,
  };

  let prePilotDeaths = 0;
  let pilotDeaths = 0;

  const projectSpeed: ProjectSpeed = {
    minimumDiscoverToPilotDuration: 0,
    medianDiscoverToPilotDuration: 0,
    maximumDiscoverToPilotDuration: 0,
    minimumPilotToAdoptionDuration: 0,
    medianPilotToAdoptionDuration: 0,
    maximumPilotToAdoptionDuration: 0,
  };

  const movedToPilotsDurations: number[] = [];
  const movedToAdoptsDurations: number[] = [];
  // Array to collect all impact values
  const allImpactValues: number[] = [];

  props.projects.forEach((project) => {
    const { funnelStage, impactType } = project;

    // Project count
    projectCountByStage[funnelStage].total += 1;

    const statusIndex = projectCountByStage[funnelStage].status.findIndex(
      (statusCount) => statusCount.name === project.status
    );

    if (statusIndex === -1) {
      projectCountByStage[funnelStage].status.push({
        name: project.status,
        count: 1,
      });
    } else {
      projectCountByStage[funnelStage].status[statusIndex].count++;
    }

    if (project.status === "archived") {
      if (stageScores[project.funnelStage] < stageScores.pilot)
        prePilotDeaths++;
      else if (stageScores[project.funnelStage] === stageScores.pilot)
        pilotDeaths++;
    }

    // Business impact
    const impactValue = getImpactValueByFunnelStage(
      funnelStage,
      project.impactValues
    );

    if (impactValue && Number(impactValue.value)) {
      impactValuesByImpactType[impactType] += Number(impactValue.value);
      allImpactValues.push(Number(impactValue.value));
    }

    // project speed
    if (project.daysInStagesPeriods?.discoverToPilot) {
      const totalDaysToPilot = project.daysInStagesPeriods.discoverToPilot;
      if (totalDaysToPilot > 0) movedToPilotsDurations.push(totalDaysToPilot);
    }

    if (project.daysInStagesPeriods?.pilotToAdoption) {
      const totalDaysToAdopt = project.daysInStagesPeriods.pilotToAdoption;
      if (totalDaysToAdopt > 0) movedToAdoptsDurations.push(totalDaysToAdopt);
    }

    // Startup count
    project.opportunities.forEach((opportunity) => {
      startupCountByAssessment.curated += 1;
      if (opportunity.isQualified) {
        startupCountByAssessment.qualified += 1;
      }
    });
  });

  movedToPilotsDurations.sort((a, b) => a - b);
  movedToAdoptsDurations.sort((a, b) => a - b);

  // Min, Max and median for project speed
  projectSpeed.minimumDiscoverToPilotDuration = movedToPilotsDurations[0];
  projectSpeed.maximumDiscoverToPilotDuration =
    movedToPilotsDurations[movedToPilotsDurations.length - 1];

  projectSpeed.minimumPilotToAdoptionDuration = movedToAdoptsDurations[0];
  projectSpeed.maximumPilotToAdoptionDuration =
    movedToAdoptsDurations[movedToAdoptsDurations.length - 1];

  projectSpeed.medianDiscoverToPilotDuration = calculateMedian(
    movedToPilotsDurations
  );

  projectSpeed.medianPilotToAdoptionDuration = calculateMedian(
    movedToAdoptsDurations
  );
  // Business Impact Median Calculation
  const businessImpactMedian = calculateMedian(allImpactValues);

  return (
    <Grid
      container
      spacing={3}
      justifyContent={{ md: "center", lg: "flex-start" }}
    >
      <Grid item lg={5} md={12} sm={12} xs={12} order={{ md: -1, lg: 0 }}>
        <TotalProjects projectCount={projectCountByStage} />
      </Grid>
      <Grid item lg={5} md={8} sm={12} xs={12}>
        <BusinessImpact
          impactValuesByImpactType={impactValuesByImpactType}
          median={businessImpactMedian}
        />
      </Grid>

      <Grid item lg={2} md={4} sm={12} xs={12}>
        <Startups startupCount={startupCountByAssessment} />
      </Grid>
      <Grid item lg={5} md={8} sm={12} xs={12}>
        <ConversionRate
          projectCount={projectCountByStage}
          prePilotDeaths={prePilotDeaths}
          pilotDeaths={pilotDeaths}
        />
      </Grid>
      <Grid item lg={5} md={4} xs={12}>
        <Speed projectSpeed={projectSpeed} />
      </Grid>
    </Grid>
  );
}

export default Metrics;
