import { ReactElement } from "react";
import {
  Project,
  ProjectStartupOpportunityAssociation,
} from "../../../../Types/Project";
import { Box, Stack, Typography } from "@mui/material";
import { formatDate, capitalizeFirstLetter } from "../../../../utils";
import useRoles from "../../../../Hooks/useRoles";

interface ProjectMainInformationProps {
  project: Project | ProjectStartupOpportunityAssociation;
}

export default function ProjectMainInformation(
  props: ProjectMainInformationProps
): ReactElement {
  const { isExternalUser } = useRoles();
  return (
    <Box display="flex" gap={4}>
      {!isExternalUser && (
        <InfoItem
          label="Company"
          value={props.project.businessUnit.ventureClient.name}
        />
      )}
      <InfoItem
        label="Organizational Unit"
        value={props.project.businessUnit.name}
      />
      <InfoItem label="Created" value={formatDate(props.project.dateCreated)} />
      <InfoItem label="Project Owner" value={props.project.projectOwner.name} />
      <InfoItem
        label="Stage"
        value={capitalizeFirstLetter(props.project.funnelStage)}
      />
    </Box>
  );
}

interface InfoItemProps {
  value: string;
  label: string;
}

export function InfoItem(props: InfoItemProps): ReactElement {
  return (
    <Stack>
      <Typography variant="body2">{props.value}</Typography>
      <Typography variant="overline" color="text.mediumEmphasis">
        {props.label}
      </Typography>
    </Stack>
  );
}
