import { ReactElement, useState } from "react";
import {
  Dialog,
  DialogTitle,
  Typography,
  IconButton,
  Button,
  Chip,
  styled,
  Stack,
  DialogActions,
} from "@mui/material";
import EditPhaseModal from "./EditPhaseModal";
import theme from "../../../../../theme";
import Phase from "../../../../../Types/Phase";
import CloseIcon from "@mui/icons-material/Close";
import ScrollableDialogContent from "../../../../UI/Modals/ScrollableDialogContent";

const DateDisplay = styled(Chip)(() => ({
  borderRadius: theme.shape.radius.minimal,
  color: theme.palette.text.brand.accessibility,
  border: `1px solid ${theme.palette.surface.brand.accessibility}`,
}));

interface Props {
  index: number;
  setModalOpen: (state: boolean) => void;
  modalOpen: boolean;
  phase: Phase;
  handlePhaseEdit: (phase: Phase) => void;
}

const ViewPhaseModal = (props: Props): ReactElement => {
  const [isEditPhaseModalOpen, setIsEditPhaseModalOpen] = useState(false);

  return (
    <>
      <Dialog
        open={props.modalOpen}
        onClose={() => props.setModalOpen(false)}
        fullWidth
        data-testid="view-phase-modal"
        PaperProps={{
          sx: {
            gap: theme.spacing(4),
          },
        }}
      >
        <DialogTitle>
          Phase {props.index}
          <IconButton
            sx={{ color: "icon.primary" }}
            onClick={() => props.setModalOpen(false)}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </DialogTitle>

        <ScrollableDialogContent>
          <Stack gap={3}>
            {props.phase.name && (
              <Typography variant="subtitle1" data-testid="title">
                {props.phase.name}
              </Typography>
            )}
            <Stack alignItems="start" gap={0.5}>
              <Typography variant="caption" color="text.mediumEmphasis">
                Duration
              </Typography>
              {props.phase.duration ? (
                <DateDisplay
                  variant="outlined"
                  label={props.phase.duration + " days"}
                />
              ) : (
                <Typography variant="caption">--</Typography>
              )}
            </Stack>
            {props.phase.description && (
              <Typography
                data-testid="phase-description"
                sx={{
                  padding: theme.spacing(3),
                  backgroundColor: theme.palette.surface.secondary.main,
                  borderRadius: theme.shape.radius.minimal,
                }}
              >
                {props.phase.description}
              </Typography>
            )}
          </Stack>
        </ScrollableDialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={() => setIsEditPhaseModalOpen(true)}
          >
            Edit
          </Button>
        </DialogActions>
      </Dialog>
      {isEditPhaseModalOpen && (
        <EditPhaseModal
          modalOpen={isEditPhaseModalOpen}
          setModalOpen={setIsEditPhaseModalOpen}
          phase={props.phase}
          handlePhaseEdit={props.handlePhaseEdit}
          index={props.index}
        />
      )}
    </>
  );
};

export default ViewPhaseModal;
