import { Box, Button, Chip, IconButton, Stack, Tooltip } from "@mui/material";
import { ReactElement, useState, useEffect, useRef, useCallback } from "react";
import { GridApiPremium } from "@mui/x-data-grid-premium/models/gridApiPremium";
import { FilterListOutlined, ViewColumnOutlined } from "@mui/icons-material";
import ToolbarExportButton from "./ToolbarExportButton";
import CollectionsBookmarkOutlinedIcon from "@mui/icons-material/CollectionsBookmarkOutlined";
import LibraryAddOutlinedIcon from "@mui/icons-material/LibraryAddOutlined";
import {
  GridInitialState,
  GridPreferencePanelsValue,
} from "@mui/x-data-grid-premium";
import { Preset } from "../../../Types/Preset";
import LoadPresetModal from "./LoadPresetModal";
import AddPresetModal from "./AddPresetModal";
import { PresetsHttpService } from "../../../Http/Preset/Preset.http.service";
import { LeadsPresetsHttpService } from "../../../Http/LeadsPreset/LeadsPreset.http.service";
import theme from "../../../theme";
import { getErrorMessage } from "../../../utils";
import { enqueueSnackbar } from "notistack";

interface ProjectsDataGridToolbarProps {
  apiRef: React.MutableRefObject<GridApiPremium>;
  syncState: (stateToSave: GridInitialState) => void;
  currentTab: "project" | "lead";
}

const debounce = (func: () => void, delay: number) => {
  let timer: NodeJS.Timeout;
  return () => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func();
    }, delay);
  };
};

export default function ProjectsDataGridToolbar({
  apiRef,
  syncState,
  currentTab,
}: ProjectsDataGridToolbarProps): ReactElement {
  const [presetList, setPresetList] = useState<Preset[]>([]);
  const [loadPresetModalOpen, setLoadPresetModalOpen] = useState(false);
  const [addPresetModalOpen, setAddPresetModalOpen] = useState(false);
  const [appliedProjectPreset, setAppliedProjectPreset] =
    useState<null | Preset>(null);
  const [appliedLeadPreset, setAppliedLeadPreset] = useState<null | Preset>(
    null
  );
  const [, setSelectedPreset] = useState<Preset | null>(null);
  const [applyingPreset, setApplyingPreset] = useState(false);

  const initialProjectStateRef = useRef<GridInitialState | null>(null);
  const initialLeadStateRef = useRef<GridInitialState | null>(null);

  const initialProjectStateRefColChange = useRef<GridInitialState | null>(null);
  const initialLeadStateRefColChange = useRef<GridInitialState | null>(null);

  useEffect(() => {
    const getPresetListByUser = async () => {
      try {
        const presetList =
          currentTab === "project"
            ? await PresetsHttpService.getPresets()
            : await LeadsPresetsHttpService.getLeadsPresets();
        setPresetList(presetList);
      } catch (error: any) {
        const errorMessage = getErrorMessage(error);
        enqueueSnackbar(`Couldn't load presets: ${errorMessage}`, {
          variant: "error",
        });
      }
    };

    getPresetListByUser();
  }, [currentTab]);

  useEffect(() => {
    if (currentTab === "project" && !initialProjectStateRef.current) {
      initialProjectStateRef.current = apiRef.current.exportState();
    }
    if (currentTab === "lead" && !initialLeadStateRef.current) {
      initialLeadStateRef.current = apiRef.current.exportState();
    }
  }, [apiRef, currentTab]);

  const handleLoadPresetModalOpen = () => {
    setLoadPresetModalOpen(true);
  };

  const handleAddPresetModalOpen = () => {
    setAddPresetModalOpen(true);
  };

  const resetSelectedPreset = () => {
    setSelectedPreset(null);
  };

  const handleDelete = () => {
    if (currentTab === "project") {
      setAppliedProjectPreset(null);
      if (initialProjectStateRef.current) {
        apiRef.current.restoreState(initialProjectStateRef.current);
        syncState(initialProjectStateRef.current);
      }
    } else {
      setAppliedLeadPreset(null);
      if (initialLeadStateRef.current) {
        apiRef.current.restoreState(initialLeadStateRef.current);
        syncState(initialLeadStateRef.current);
      }
    }
    resetSelectedPreset();
  };

  const handleApplyPreset = (preset: Preset | null) => {
    setApplyingPreset(true);
    if (currentTab === "project") {
      setAppliedProjectPreset(preset);
    } else {
      setAppliedLeadPreset(preset);
    }
  };

  const handleEvent = useCallback(
    (event: "filter" | "column") => {
      if (!applyingPreset) {
        const currentStateRef =
          currentTab === "project"
            ? initialProjectStateRefColChange
            : initialLeadStateRefColChange;
        currentStateRef.current = apiRef.current.exportState();

        if (currentStateRef.current) {
          apiRef.current.restoreState(currentStateRef.current);
          syncState(currentStateRef.current);
        }

        if (
          event === "filter" &&
          !currentStateRef.current.filter?.filterModel?.items.at(-1)?.value
        )
          return;

        setAppliedProjectPreset(null);
        setAppliedLeadPreset(null);
      } else {
        setApplyingPreset(false);
      }
    },
    [applyingPreset, currentTab, apiRef, syncState]
  );

  const debouncedHandleEvent = useCallback(
    (event: "filter" | "column") => debounce(() => handleEvent(event), 300),
    [handleEvent]
  );

  useEffect(() => {
    const unsubscribeColumnVisibilityChange = apiRef.current.subscribeEvent(
      "columnVisibilityModelChange",
      debouncedHandleEvent("column")
    );

    const unsubscribeFilterModelChange = apiRef.current.subscribeEvent(
      "filterModelChange",
      debouncedHandleEvent("filter")
    );

    return () => {
      unsubscribeColumnVisibilityChange();
      unsubscribeFilterModelChange();
    };
  }, [apiRef, currentTab, debouncedHandleEvent]);

  useEffect(() => {
    apiRef.current.hidePreferences();
  }, [currentTab]);

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        gap={2}
        data-testid="dataGrid-tool-bar"
      >
        <Button
          startIcon={<ViewColumnOutlined />}
          onClick={() =>
            apiRef.current?.showPreferences(GridPreferencePanelsValue.columns)
          }
        >
          Columns
        </Button>

        <Button
          onClick={() => apiRef.current?.showFilterPanel("name")}
          startIcon={<FilterListOutlined />}
        >
          Filters
        </Button>

        <Tooltip title="Add Preset">
          <IconButton
            size="medium"
            color="secondary"
            onClick={handleAddPresetModalOpen}
            aria-label="Add Preset"
          >
            <LibraryAddOutlinedIcon />
          </IconButton>
        </Tooltip>

        <Tooltip title="Load Preset">
          <IconButton
            size="medium"
            color="secondary"
            onClick={handleLoadPresetModalOpen}
            aria-label="Load Preset"
          >
            <CollectionsBookmarkOutlinedIcon />
          </IconButton>
        </Tooltip>

        {(currentTab === "project" && appliedProjectPreset) ||
        (currentTab === "lead" && appliedLeadPreset) ? (
          <Stack direction="row" spacing={1}>
            <Chip
              label={
                currentTab === "project"
                  ? appliedProjectPreset?.name
                  : appliedLeadPreset?.name
              }
              variant="outlined"
              sx={{
                backgroundColor: theme.palette.background.paper,
                color: theme.palette.text.primary,
                borderColor: "transparent",
                fontSize: theme.typography.fontSize,
                height: "32px",
              }}
              onDelete={handleDelete}
              data-testid="preset-chip"
            />
          </Stack>
        ) : null}

        <ToolbarExportButton apiRef={apiRef} />
      </Box>
      {loadPresetModalOpen && (
        <LoadPresetModal
          modalOpen={loadPresetModalOpen}
          setModalOpen={setLoadPresetModalOpen}
          presetList={presetList}
          setPresetList={setPresetList}
          setAppliedPreset={handleApplyPreset}
          griStateToLoad={syncState}
          currentTab={currentTab}
          resetSelectedPreset={resetSelectedPreset}
        />
      )}

      {addPresetModalOpen && (
        <AddPresetModal
          modalOpen={addPresetModalOpen}
          setModalOpen={setAddPresetModalOpen}
          presetList={presetList}
          setPresetList={setPresetList}
          setAppliedPreset={handleApplyPreset}
          apiRef={apiRef}
          griStateToSave={syncState}
          currentTab={currentTab}
        />
      )}
    </>
  );
}
