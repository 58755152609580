import { Box, Stack, Tab } from "@mui/material";
import { Fragment, ReactElement } from "react";
import theme from "../../../theme";
import ContentWrapper from "../../ProjectDetails/ContentWrapper";
import PageHeaderSkeleton from "../../UI/PageHeaderSkeleton";
import { TabPanel } from "../../UI/TabPanel";
import { StyledAppBar, StyledTabsPanel } from "./VentureClientDetailsPage";
import VentureClientOverviewSkeleton from "./VentureClientOverview/VentureClientOverviewSkeleton";
import VentureClientUnitOverviewSkeleton from "./VentureClientUnitOverview/VentureClientUnitOverviewSkeleton";

export default function VentureClientDetailsPageSkeleton(): ReactElement {
  return (
    <Fragment>
      <PageHeaderSkeleton />
      <ContentWrapper zIndex={3} sx={{ paddingTop: theme.spacing(5) }}>
        <Box
          display="grid"
          gridTemplateColumns={`minmax(0, 312px) minmax(0,100%)`}
          gap={3}
        >
          <Stack gap={5}>
            <VentureClientOverviewSkeleton />
            <VentureClientUnitOverviewSkeleton />
          </Stack>
          <Stack>
            <StyledAppBar position="static">
              <StyledTabsPanel value={false}>
                <Tab label="Organizational Units" disabled />
                <Tab label="Contacts" disabled />
              </StyledTabsPanel>
            </StyledAppBar>
            <TabPanel value={0} index={0}>
              {/* Business Units Skeleton */}
            </TabPanel>
          </Stack>
        </Box>
      </ContentWrapper>
    </Fragment>
  );
}
