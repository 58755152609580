import {
  Dispatch,
  ReactElement,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import SignIn from "./SignIn";
import ResetPasswordToken from "./ResetPasswordToken";
import ResetPassword from "./ResetPassword";
import ActiveAccount from "./ActiveAccount";
import { Box, styled } from "@mui/material";
import useClient from "../../Hooks/useClient";
import DeloitteSignIn from "./DeloitteSignIn";

const AuthContainer = styled(Box, {
  shouldForwardProp: (prop: string) => !prop.startsWith("$"),
})(({ $backgroundUrl }: { $backgroundUrl?: string }) => ({
  display: "flex",
  width: "100%",
  position: "fixed",
  justifyContent: "center",
  height: "100%",
  backgroundImage: `url(${$backgroundUrl})`,
  backgroundSize: "cover",
  backgroundPosition: "center",
  borderImage: "linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)) fill 1",
}));

interface Props {
  setSignedIn: Dispatch<SetStateAction<boolean>>;
}

export default function Authentication(props: Props): ReactElement {
  const { background, isExternalUrl } = useClient();
  const [formType, setFormType] = useState<string>("signIn");
  const [user, setUser] = useState();

  useEffect(() => {
    document.title = "Venture Client Platform";
  }, []);

  return (
    <>
      {isExternalUrl ? (
        <AuthContainer $backgroundUrl={background}>
          {formType === "resetPasswordToken" ? (
            <ResetPasswordToken setFormType={setFormType} />
          ) : formType === "resetPassword" ? (
            <ResetPassword setFormType={setFormType} />
          ) : formType === "activateAccount" ? (
            <ActiveAccount
              setFormType={setFormType}
              user={user}
              setSignedIn={props.setSignedIn}
            />
          ) : (
            <SignIn
              setSignedIn={props.setSignedIn}
              setFormType={setFormType}
              setUser={setUser}
            />
          )}
        </AuthContainer>
      ) : (
        <DeloitteSignIn />
      )}
    </>
  );
}
