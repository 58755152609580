import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
  ToggleButton,
  ToggleButtonGroup,
  styled,
} from "@mui/material";
import { ReactElement, useMemo } from "react";
import theme from "../../../../theme";
import { QualityCheck } from "../../../../Types/QualityCheck";
import QualityVerificationProgress from "./QualityVerificationProgress";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";

const CustomToggleButton = styled(ToggleButton)({
  width: "20px",
  height: "20px",
  borderRadius: "2px",
  padding: "3px",
  border: "none",
});

interface QualityVerificationEditModalProps {
  open: boolean;
  handleCancel: () => void;
  qualityChecks: QualityCheck[];
  qualityCheckOptions: QualityCheck[];
  updateQualityChecks: React.Dispatch<React.SetStateAction<QualityCheck[]>>;
  handleSave: () => void;
}

export default function QualityVerificationEditModal({
  open,
  handleCancel,
  qualityChecks,
  updateQualityChecks,
  qualityCheckOptions,
  handleSave,
}: QualityVerificationEditModalProps): ReactElement {
  const handleToggleChange = (
    option: QualityCheck,
    newValue: boolean | null
  ) => {
    updateQualityChecks((prev) => {
      const index = prev.findIndex(
        (qualityCheck) => qualityCheck.id === option.id
      );
      if (index === -1 && newValue !== null) {
        return [...prev, { ...option, status: newValue }];
      } else {
        const updated = [...prev];
        if (newValue === null) {
          updated.splice(index, 1);
        } else {
          updated[index].status = newValue;
        }
        return updated;
      }
    });
  };

  const { passedChecks, failedChecks } = useMemo(() => {
    const passedChecks = qualityChecks.filter(
      (qc) => qc.status === true
    ).length;
    const failedChecks = qualityChecks.filter(
      (qc) => qc.status === false
    ).length;
    return { passedChecks, failedChecks };
  }, [qualityChecks]);

  return (
    <Dialog
      fullWidth
      maxWidth="xs"
      open={open}
      PaperProps={{
        sx: {
          gap: theme.spacing(4),
        },
      }}
      data-testid="quality-verification-edit-modal"
    >
      <DialogTitle>Venture Client Readiness</DialogTitle>
      <DialogContent>
        <Stack gap={2}>
          <Typography color="text.mediumEmphasis">
            Evaluate this startup&apos;s suitability for Venture Client
            projects. Please select all criteria that apply:
          </Typography>

          <Stack gap={1}>
            <Box display="flex" justifyContent="flex-end" gap={1}>
              <Typography
                variant="body2"
                color={theme.palette.text.mediumEmphasis}
              >
                Yes
              </Typography>
              <Typography
                variant="body2"
                color={theme.palette.text.mediumEmphasis}
              >
                No
              </Typography>
            </Box>

            <Stack gap={2}>
              {qualityCheckOptions.map((qualityCheckOption) => {
                const index = qualityChecks.findIndex(
                  (qualityCheck) => qualityCheck.id === qualityCheckOption.id
                );
                const qualityCheckStatus =
                  index === -1 ? null : qualityChecks[index].status;

                return (
                  <Box
                    key={qualityCheckOption.id}
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    gap={1}
                  >
                    <Typography>{qualityCheckOption.description}</Typography>
                    <ToggleButtonGroup
                      value={qualityCheckStatus}
                      exclusive
                      onChange={(_, newValue) =>
                        handleToggleChange(qualityCheckOption, newValue)
                      }
                      aria-label="quality check toggle"
                      size="small"
                      color="secondary"
                      sx={{
                        padding: "2px",
                        backgroundColor: theme.palette.surface.secondary.light,
                      }}
                    >
                      <CustomToggleButton value={true} aria-label="check">
                        <CheckIcon sx={{ width: "16px", height: "16px" }} />
                      </CustomToggleButton>
                      <CustomToggleButton value={false} aria-label="cross">
                        <ClearIcon sx={{ width: "16px", height: "16px" }} />
                      </CustomToggleButton>
                    </ToggleButtonGroup>
                  </Box>
                );
              })}
            </Stack>
          </Stack>
          <QualityVerificationProgress
            progress={passedChecks}
            target={qualityCheckOptions.length}
            failed={failedChecks}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={handleCancel}>
          Cancel
        </Button>
        <Button variant="contained" onClick={handleSave}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}
