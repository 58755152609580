import { forwardRef } from "react";
import NumberFormat from "react-number-format";

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  readOnly: boolean;
  name: string;
  decimalScale: number;
}

// eslint-disable-next-line
export const NumberFormatCustom = forwardRef<NumberFormat<any>, CustomProps>(
  function NumberFormatCustom(props, ref) {
    const { onChange, decimalScale = 2, ...other } = props;

    return (
      <NumberFormat
        {...other}
        getInputRef={ref}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        prefix="€ "
        isNumericString
        decimalScale={decimalScale}
        readOnly={props.readOnly}
      />
    );
  }
);

// eslint-disable-next-line
export const NumberFormatGeneral = forwardRef<NumberFormat<any>, CustomProps>(
  function NumberFormatGeneral(props, ref) {
    const { onChange, decimalScale = 2, ...other } = props;

    return (
      <NumberFormat
        {...other}
        getInputRef={ref}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        isNumericString
        decimalScale={decimalScale}
        readOnly={props.readOnly}
      />
    );
  }
);
