import axios from "axios";
import { FunnelStage, ProjectStatusChangeReason } from "../../Types/Project";
import { LeadProjectStatusChangeReason } from "../../Types/LeadProjectHistory";

export class StatusChangeReasonsHttpService {
  public static async getReasons(
    status: "on hold" | "archived",
    stage: FunnelStage
  ): Promise<ProjectStatusChangeReason[]> {
    return (
      await axios.get<ProjectStatusChangeReason[]>(
        `/api/status-change-reasons`,
        {
          params: {
            status,
            stage,
          },
        }
      )
    ).data;
  }
}

export class LeadStatusChangeReasonsHttpService {
  public static async getLeadStatusChangeReasons(
    status: "archived"
  ): Promise<LeadProjectStatusChangeReason[]> {
    return (
      await axios.get<LeadProjectStatusChangeReason[]>(
        `/api/status-change-reasons`,
        {
          params: {
            status,
            stage: "lead",
          },
        }
      )
    ).data;
  }
}
