import axios from "axios";
import { ProductDemo } from "../../Types/ProductDemo.d";

export class ProductDemoHttpService {
  public static async createProductDemo(
    demo: Omit<ProductDemo, "id">
  ): Promise<void> {
    await axios.post<void>(`/api/product-demos`, demo);
  }

  public static async deleteProductDemo(id: number): Promise<void> {
    await axios.delete<void>(`/api/product-demos/${id}`);
  }

  public static async updateProductDemo(demo: ProductDemo): Promise<void> {
    await axios.put<void>(`/api/product-demos/${demo.id}`, demo);
  }
}
