import {
  Typography,
  Box,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  styled,
  Stack,
} from "@mui/material";
import { ReactElement, useContext, useState } from "react";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import SolutionClusterOverviewModal from "./SolutionCluster/SolutionClusterOverviewModal/SolutionClusterOverviewModal";
import RecommendedStartupsOverviewModal from "./RecommendedStartupsOverviewModal/RecommendedStartupsOverviewModal";
import EditKeyFindingsModal from "./EditKeyFindingsModal/EditKeyFindingsModal";
import { Project } from "../../../../../../Types/Project";
import theme from "../../../../../../theme";
import {
  compareOpportunitiesByRelevance,
  findLogo,
} from "../../../../../../utils";
import { GlobalProjectEditContext } from "../../../../../../Context/ProjectDetailsContext";
import parse from "html-react-parser";
import { Opportunity } from "../../../../../../Types/Opportunity";
import NumberCard from "../../../../../UI/NumberCard";
import { RocketLaunchOutlined } from "@mui/icons-material";
import useRoles from "../../../../../../Hooks/useRoles";

const OverviewContent = styled(Box)(() => ({
  display: "flex",
  padding: "4px",
  overflowX: "scroll",
  gap: theme.spacing(2),
}));

const OverviewCard = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: theme.spacing(1),
  flex: 1,
  borderRadius: theme.shape.radius.minimal,
  boxShadow: theme.boxShadows[0],
  padding: theme.spacing(2, 2, 2, 3),
  minWidth: "230px",
  cursor: "pointer",
}));

const Title = styled(Typography)(({ theme }) => ({
  ...theme.typography.h6,
  fontSize: "11px",
  overflow: "hidden",
  textOverflow: "ellipsis",
  display: "-webkit-box",
  WebkitLineClamp: 1,
  WebkitBoxOrient: "vertical",
}));

const StyledList = styled(List)({
  paddingTop: theme.spacing(1),
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(1),
});

const StyledListItemText = styled(ListItemText)(() => ({
  overflow: "hidden",
  textOverflow: "ellipsis",
  display: "-webkit-box",
  WebkitLineClamp: 1,
  WebkitBoxOrient: "vertical",
}));

interface Props {
  project: Project;
  showFullList: boolean;
  handleSave: () => void;
}

const OverviewSection = (props: Props): ReactElement => {
  const { canEdit } = useRoles(props.project);
  const { activeStep } = useContext(GlobalProjectEditContext);

  const [openSolutionClusterModal, setOpenSolutionClusterModal] =
    useState(false);
  const [openRecommendedStartupsModal, setOpenRecommendedStartupsModal] =
    useState(false);
  const [openRecommendedForDemoModal, setOpenRecommendedForDemoModal] =
    useState(false);
  const [openEditKeyFindings, setOpenEditKeyFindings] = useState(false);
  const isAssessStageActive = activeStep === 1;

  const keyFindings = isAssessStageActive
    ? "keyFindings"
    : "curatedKeyFindings";

  const opportunities = isAssessStageActive
    ? props.project.opportunities.filter((opp) => opp.isQualified === true)
    : props.project.opportunities;

  const cumulatedFunding = opportunities
    .reduce(
      (totalFunding, currentOpportunity) =>
        totalFunding + +currentOpportunity.startup.totalFunding,
      0.0
    )
    .toFixed(2);

  const allARatedOpportunities = opportunities
    .filter((opp) => opp.rating === "A")
    .sort(compareOpportunitiesByRelevance);

  const allRecommendedForDemoOpportunities = opportunities.filter(
    (opp) => opp.recommendedForDemo
  );

  return (
    <>
      <OverviewContent>
        <Stack gap={3}>
          <Box
            display="flex"
            height="100%"
            width={props.showFullList ? "280px" : "200px"}
          >
            <NumberCard
              label="Total Startups"
              value={opportunities.length.toString()}
              hideUnit
              hideCurrency
              data-testid="total-startups-card"
              emptyMessage={
                opportunities.length === 0 ? "No startups added" : undefined
              }
            />
          </Box>
          <Box display="flex" height="100%">
            <NumberCard
              label="Cumulated Funding (in USD)"
              value={cumulatedFunding}
              currency="$"
              data-testid="total-funding-card"
              emptyMessage={
                opportunities.length === 0 ? "No startups added" : undefined
              }
            />
          </Box>
        </Stack>
        <OverviewCard
          onClick={() => setOpenSolutionClusterModal(true)}
          data-testid="solution-clusters-card"
        >
          <Stack gap={2} width="100%">
            <Box display="flex" gap={1} alignItems="center">
              <Title>Solution Clusters</Title>
            </Box>

            {props.project.solutionClusters.length ? (
              <StyledList disablePadding>
                <Stack gap={1}>
                  {props.project.solutionClusters
                    .slice(0, 4)
                    .map((cluster, index) => {
                      const lastItem = index === 3;
                      return (
                        <ListItem
                          key={cluster.id}
                          disablePadding
                          divider={!lastItem}
                          sx={{
                            pb: "10px",
                            borderBottomColor: theme.palette.borderOutline,
                          }}
                        >
                          <StyledListItemText
                            primary={cluster.name}
                            primaryTypographyProps={{
                              variant: "body2",
                            }}
                          />
                        </ListItem>
                      );
                    })}
                </Stack>
              </StyledList>
            ) : (
              <EmptyPlaceHolder text="No solution clusters assigned" />
            )}
          </Stack>
          <Stack justifyContent="space-between">
            <InfoOutlinedIcon htmlColor={theme.palette.icon.action.main} />
            {props.project.solutionClusters.length > 4 && (
              <MoreItemsIndicator
                indicator={props.project.solutionClusters.length}
              />
            )}
          </Stack>
        </OverviewCard>
        {isAssessStageActive ? (
          <RecommendedSection
            opportunities={allRecommendedForDemoOpportunities}
            onCardClick={() => setOpenRecommendedForDemoModal(true)}
            title="Recommended for Demo"
            cardTestId="recommended-startups-card"
          />
        ) : (
          <RecommendedSection
            opportunities={allARatedOpportunities}
            onCardClick={() => setOpenRecommendedStartupsModal(true)}
            title="Recommended Startups"
            cardTestId="recommended-startups-card"
          />
        )}
        <OverviewCard
          onClick={() => setOpenEditKeyFindings(true)}
          sx={{ outlineColor: theme.palette.grey[300] }}
          data-testid="key-findings-card"
        >
          <Stack gap={2} width="100%">
            <Title>Key Findings</Title>
            {props.project[keyFindings] ? (
              <Box display="flex" flexDirection="column">
                <Box
                  overflow="hidden"
                  sx={{
                    display: "-webkit-box",
                    WebkitBoxOrient: "vertical",
                    WebkitLineClamp: 7,
                    ...theme.typography.body1,
                  }}
                >
                  {parse(props.project[keyFindings])}
                </Box>
              </Box>
            ) : (
              <EmptyPlaceHolder text="Key findings to be determined" />
            )}
          </Stack>
          <InfoOutlinedIcon htmlColor={theme.palette.icon.action.main} />
        </OverviewCard>
      </OverviewContent>
      {openSolutionClusterModal && (
        <SolutionClusterOverviewModal
          modalOpen={openSolutionClusterModal}
          setModalOpen={() => setOpenSolutionClusterModal(false)}
          solutionClusters={props.project.solutionClusters}
          opportunities={opportunities}
          handleSave={props.handleSave}
          canEdit={canEdit}
        />
      )}
      {openRecommendedStartupsModal && (
        <RecommendedStartupsOverviewModal
          modalOpen={openRecommendedStartupsModal}
          setModalOpen={() => setOpenRecommendedStartupsModal(false)}
          opportunities={allARatedOpportunities}
          recommendationType="rating"
        />
      )}
      {openRecommendedForDemoModal && (
        <RecommendedStartupsOverviewModal
          modalOpen={openRecommendedForDemoModal}
          setModalOpen={() => setOpenRecommendedForDemoModal(false)}
          opportunities={allRecommendedForDemoOpportunities}
          recommendationType="demo"
        />
      )}
      {openEditKeyFindings && (
        <EditKeyFindingsModal
          modalOpen={openEditKeyFindings}
          setModalOpen={() => setOpenEditKeyFindings(false)}
          keyFindings={props.project[keyFindings]}
          handleSave={props.handleSave}
          canEdit={canEdit}
        />
      )}
    </>
  );
};

export default OverviewSection;

export const EmptyPlaceHolder = (props: { text: string }): ReactElement => {
  return (
    <Typography
      variant="caption"
      component="span"
      sx={{
        color: theme.palette.grey[500],
        fontStyle: "italic",
      }}
    >
      {props.text}
    </Typography>
  );
};

export const MoreItemsIndicator = (props: {
  indicator: number | string;
}): ReactElement => {
  const label =
    typeof props.indicator === "number"
      ? `+${props.indicator - 4}`
      : props.indicator;
  return (
    <Typography
      data-testid="more-items-indicator"
      display="flex"
      alignItems="center"
      justifyContent="center"
      variant="overline"
      padding="4px 5px 5px"
      bgcolor={theme.palette.background.default}
      borderRadius={theme.shape.radius.full}
      color={theme.palette.text.primary}
      width="24px"
      height="24px"
      alignSelf="flex-end"
    >
      {label}
    </Typography>
  );
};

interface RecommendedSectionProps {
  opportunities: Opportunity[];
  onCardClick: () => void;
  title: string;
  cardTestId: string;
}

export const RecommendedSection = (
  props: RecommendedSectionProps
): ReactElement => {
  return (
    <OverviewCard onClick={props.onCardClick} data-testid={props.cardTestId}>
      <Stack gap={2} width="100%">
        <Box display="flex" alignItems="center">
          <Title> {props.title}</Title>
        </Box>
        {props.opportunities.length ? (
          <StyledList disablePadding>
            <Stack gap={1}>
              {props.opportunities.slice(0, 4).map((opportunity, index) => {
                const lastItem = index === 3;
                const logo = findLogo(opportunity.startup.files);
                return (
                  <ListItem
                    key={opportunity.id}
                    disablePadding
                    divider={!lastItem}
                    sx={{
                      pb: theme.spacing(1),
                      gap: 5,
                    }}
                    data-testid={`recommended-startup-${opportunity.id}`}
                  >
                    <ListItemIcon sx={{ justifyContent: "center" }}>
                      {logo ? (
                        <img
                          src={logo}
                          alt="startup logo"
                          width="70px"
                          height="20px"
                          style={{
                            objectFit: "contain",
                          }}
                        />
                      ) : (
                        <Box
                          width="70px"
                          height="20px"
                          display="flex"
                          justifyContent="center"
                        >
                          <RocketLaunchOutlined color="disabled" />
                        </Box>
                      )}
                    </ListItemIcon>
                    <StyledListItemText
                      primary={opportunity.startup.name}
                      primaryTypographyProps={{
                        variant: "caption",
                      }}
                    />
                  </ListItem>
                );
              })}
            </Stack>
          </StyledList>
        ) : (
          <EmptyPlaceHolder text="No recommended startups selected" />
        )}
      </Stack>
      <Stack justifyContent="space-between">
        <InfoOutlinedIcon htmlColor={theme.palette.icon.action.main} />
        {props.opportunities.length > 4 && (
          <MoreItemsIndicator indicator={props.opportunities.length} />
        )}
      </Stack>
    </OverviewCard>
  );
};
