import axios from "axios";
import { ThirdPartyCategoryName } from "../../Types/ThirdPartyCategoryName";

export class ThirdPartyCategoryNamesHttpService {
  public static async getThirdPartyCategoryNames(
    ventureClientId: number,
    categoryType?: string
  ): Promise<ThirdPartyCategoryName[]> {
    const response = await axios.get<ThirdPartyCategoryName[]>(
      `/api/third-party-category-names`,
      {
        params: { ventureClientId, categoryType },
      }
    );
    return response.data;
  }

  public static async createThirdPartyCategoryName(
    categoryName: Omit<ThirdPartyCategoryName, "id">
  ): Promise<ThirdPartyCategoryName> {
    const response = await axios.post<ThirdPartyCategoryName>(
      "/api/third-party-category-names",
      categoryName
    );
    return response.data;
  }
}
