import { Paper, Stack, Box, Typography, Skeleton } from "@mui/material";
import { ReactElement } from "react";
import { LeadProjectLeaderSummarySkeleton } from "./LeadProjectLeaderSummary/LeadProjectLeaderSummarySkeleton";

export default function LeadProjectLeadersSkeleton(): ReactElement {
  return (
    <Paper component={Stack} p={3} gap={3}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="h6">Project Leaders</Typography>
        <Skeleton width={128} height={36} />
      </Box>
      <Stack gap={2}>
        <LeadProjectLeaderSummarySkeleton />
        <LeadProjectLeaderSummarySkeleton />
        <LeadProjectLeaderSummarySkeleton />
        <LeadProjectLeaderSummarySkeleton />
      </Stack>
    </Paper>
  );
}
