import { ProductDemo } from "../../Types/ProductDemo";

export default class ProductDemoMapper {
  static map(ProductDemo: ProductDemo): ProductDemo {
    return {
      ...ProductDemo,
      date: ProductDemo.date
        ? new Date(ProductDemo.date).toLocaleDateString("fr-ca")
        : null,
    };
  }
}
