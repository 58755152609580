import { Stack, Box } from "@mui/material";
import { ReactElement } from "react";
import theme from "../../theme";
import ContentWrapper from "../ProjectDetails/ContentWrapper";
import LeadProjectBackgroundInformationSkeleton from "./LeadProjectBackgroundInformation/LeadProjectBackgroundInformationSkeleton";
import LeadProjectFileSectionSkeleton from "./LeadProjectFiles/LeadProjectFileSection/LeadProjectFileSectionSkeleton";
import LeadProjectHeaderSkeleton from "./LeadProjectHeader/LeadProjectHeaderSkeleton";
import LeadProjectLeadersSkeleton from "./LeadProjectLeaders/LeadProjectLeadersSkeleton";
import LeadProjectOpportunitiesSkeleton from "./LeadProjectOpportunities/LeadProjectOpportunitiesSkeleton";
import LeadProjectSourceSkeleton from "./LeadProjectSource/LeadProjectSourceSkeleton";
import LeadProjectUpdatesSkeleton from "./LeadProjectStatus/LeadProjectStatusSkeleton";
import UseCasesListSkeleton from "./ProjectLeadUseCases/UseCasesList/UseCasesListSkeleton";
import ProjectsLaunchedSkeleton from "./ProjectsLaunched/ProjectsLaunchedSkeleton";

export default function LeadProjectPageSkeleton(): ReactElement {
  return (
    <Stack data-testid="lead-project-page-skeleton">
      <LeadProjectHeaderSkeleton />
      <ContentWrapper sx={{ paddingTop: theme.spacing(5) }}>
        <Box
          display="grid"
          gridTemplateColumns={`minmax(0, 312px) minmax(0,100%)`}
          gap={3}
        >
          <Stack gap={3}>
            <LeadProjectUpdatesSkeleton />
            <ProjectsLaunchedSkeleton />
            <LeadProjectSourceSkeleton />
            <LeadProjectFileSectionSkeleton />
          </Stack>
          <Stack gap={3}>
            <LeadProjectBackgroundInformationSkeleton />
            <UseCasesListSkeleton />
            <LeadProjectLeadersSkeleton />
            <LeadProjectOpportunitiesSkeleton />
          </Stack>
        </Box>
      </ContentWrapper>
    </Stack>
  );
}
