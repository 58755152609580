import { ReactElement } from "react";
import theme from "../../theme";
import { Box, Container, Stack, Skeleton } from "@mui/material";

function PageHeaderSkeleton(): ReactElement {
  return (
    <Box bgcolor={theme.palette.surface.cool} pt={15.5} pb={12.5}>
      <Container>
        <Stack gap={1}>
          <Skeleton height={32} width={152} />
          <Skeleton height={12} width={400} />
        </Stack>
      </Container>
    </Box>
  );
}

export default PageHeaderSkeleton;
