import { ContentCopyOutlined, OpenInNew } from "@mui/icons-material";
import {
  Box,
  IconButton,
  Link,
  Paper,
  Stack,
  styled,
  Typography,
} from "@mui/material";
import { ReactElement, useEffect, useState } from "react";
import { File } from "../../../../Types/File";
import { VentureClient } from "../../../../Types/VentureClient";
import { normalizeUrl } from "../../../../utils";
import ContactName from "../../../Contacts/ContactCard/ContactName/ContactName";
import LogoPlaceholder from "../../../ProjectDetails/SharedComponents/LogoPlaceholder";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import theme from "../../../../theme";
import Geocode from "react-geocode";
import { Marker, StaticGoogleMap } from "react-static-google-map";
import EditVentureClientModal from "./EditVentureClientModal";
import { enqueueSnackbar } from "notistack";
import useRoles from "../../../../Hooks/useRoles";

export const Website = styled(Typography)({
  overflow: "hidden",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
});

export const Logo = styled("img")({
  backgroundColor: "background.paper",
  maxHeight: 40,
  minWidth: 0,
  objectFit: "contain",
});

export const WebsiteLink = styled(Link)({
  display: "flex",
  alignItems: "center",
  gap: theme.spacing(0.5),
  minWidth: 0,
});
interface VentureClientOverviewProps {
  ventureClient: VentureClient;
  companyLogo?: File;
  handleSave(): void;
}

function VentureClientOverview({
  ventureClient,
  companyLogo,
  handleSave,
}: VentureClientOverviewProps): ReactElement {
  const { canManage } = useRoles(undefined, undefined, ventureClient);

  const [editModalOpen, setEditModalOpen] = useState(false);
  const [geoCode, setGeoCode] = useState<string>();

  const {
    name,
    industry,
    city,
    country,
    state,
    street,
    website,
    mainContact,
    postalCode,
  } = ventureClient;

  const hasAddress = !!(street || city || state || postalCode || country);

  useEffect(() => {
    if (!ventureClient) return;
    const { street, city, state, postalCode, country } = ventureClient;
    const startupAddress = `${street}, ${city}, ${state} ${postalCode}, ${country}`;

    if (process.env.REACT_APP_GOOGLE_API_KEY) {
      Geocode.setApiKey(process.env.REACT_APP_GOOGLE_API_KEY);
      Geocode.setLanguage("en");
      Geocode.setRegion("de");
      Geocode.fromAddress(startupAddress).then(
        (response) => {
          const { lat, lng } = response.results[0].geometry.location;
          const geoCodeString = lat.toString() + " " + lng.toString();
          setGeoCode(geoCodeString);
        },
        () => {
          setGeoCode("");
        }
      );
    }
  }, [ventureClient]);

  const copyAddressToClipboard = (e: React.MouseEvent<HTMLElement>) => {
    const addressInfo = [
      ventureClient.street,
      ventureClient.city,
      ventureClient.state,
      ventureClient.country,
      ventureClient.postalCode,
    ];

    let address = "";
    addressInfo.forEach((info) => {
      if (info) {
        address = `${address && `${address}, `}${info}`;
      }
    });

    e.stopPropagation();
    navigator.clipboard.writeText(address);
    enqueueSnackbar("Address successfully copied to clipboard", {
      variant: "success",
    });
  };

  return (
    <>
      <Paper
        component={Stack}
        p={3}
        gap={3}
        data-testid="venture-client-overview"
      >
        <Stack gap={2} width="100%">
          <Box display="flex" justifyContent="space-between" gap={3}>
            {companyLogo ? (
              <Logo src={companyLogo.url} />
            ) : (
              <Box width={64}>
                <LogoPlaceholder type="client" />
              </Box>
            )}

            {canManage && (
              <IconButton
                onClick={() => {
                  setEditModalOpen(true);
                }}
                data-testid="edit-venture-client-button"
                sx={{
                  color: theme.palette.icon.action.main,
                }}
              >
                <EditOutlinedIcon fontSize="small" />
              </IconButton>
            )}
          </Box>
          <Stack gap={0.5}>
            <Typography variant="h6">{name}</Typography>
            <Typography variant="body2">{industry}</Typography>
          </Stack>
        </Stack>
        <ContactName
          name={mainContact?.name}
          title={mainContact?.title || "Main Contact"}
          orientation="horizontal"
        />
        <Box display="flex" gap={2}>
          <Typography variant="subtitle2">Website</Typography>
          {website ? (
            <WebsiteLink
              href={normalizeUrl(website)}
              target="_blank"
              rel="noopener noreferrer"
            >
              <OpenInNew sx={{ fontSize: 16 }} />
              <Website>{normalizeUrl(website, true)}</Website>
            </WebsiteLink>
          ) : (
            <Typography>--</Typography>
          )}
        </Box>
        {hasAddress && (
          <Box display="flex" justifyContent="space-between">
            <Stack color="text.highEmphasis">
              <Typography>{street}</Typography>
              <Typography>
                {city}
                {state ? ", " : ""}
                {state}
              </Typography>
              <Typography>
                {postalCode ? " " : ""}
                {postalCode}
              </Typography>
              <Typography color={theme.palette.brand.accessibility}>
                {country}
              </Typography>
            </Stack>
            <IconButton
              sx={{
                alignSelf: "flex-start",
                color: theme.palette.icon.action.main,
              }}
              onClick={copyAddressToClipboard}
            >
              <ContentCopyOutlined fontSize="small" />
            </IconButton>
          </Box>
        )}
        {geoCode && (
          <Box
            sx={{
              borderRadius: "4px",
              overflow: "hidden",
            }}
          >
            <StaticGoogleMap
              size="334x120"
              zoom="2"
              apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
            >
              <Marker location={geoCode} color="red" label="P" />
            </StaticGoogleMap>
          </Box>
        )}
      </Paper>
      {editModalOpen && (
        <EditVentureClientModal
          open={editModalOpen}
          onClose={() => {
            setEditModalOpen(false);
          }}
          onSave={handleSave}
          ventureClient={ventureClient}
        />
      )}
    </>
  );
}

export default VentureClientOverview;
