import { Box, Button, Grid, Paper, Stack, Typography } from "@mui/material";
import { ReactElement, useEffect, useState } from "react";
import { BusinessUnitHttpService } from "../../../../Http/BusinessUnit/BusinessUnit.http.service";
import { BusinessUnit } from "../../../../Types/BusinessUnit";
import ManageBusinessUnitModal from "./ManageBusinessUnitModal";
import ContactName from "../../../Contacts/ContactCard/ContactName/ContactName";
import EditDeleteMenu from "../../../UI/EditDeleteMenu";
import DeleteOrRemoveModal from "../../../UI/Modals/DeleteOrRemoveModal/DeleteOrRemoveModal";
import ProjectsCounter from "../../../UI/ProjectsCounter";
import theme from "../../../../theme";
import { getErrorMessage } from "../../../../utils";
import { enqueueSnackbar } from "notistack";
import { VentureClient } from "../../../../Types/VentureClient";
import useRoles from "../../../../Hooks/useRoles";

interface BusinessUnitsListProps {
  ventureClient: VentureClient;
}

export default function BusinessUnitsList(
  props: BusinessUnitsListProps
): ReactElement {
  const { canManage } = useRoles(undefined, undefined, props.ventureClient);
  const [businessUnits, setBusinessUnits] = useState<BusinessUnit[]>([]);
  const [selectedBusinessUnit, setSelectedBusinessUnit] =
    useState<BusinessUnit>();
  const [refresh, setRefresh] = useState<boolean>(false);
  const [manageBusinessUnitModalOpen, setManageBusinessUnitModalOpen] =
    useState(false);
  const [deleteBusinessUnitModalOpen, setDeleteBusinessUnitModalOpen] =
    useState(false);

  useEffect(() => {
    BusinessUnitHttpService.getBusinessUnits({
      ventureClientId: props.ventureClient.id,
    })
      .then((data) => {
        setBusinessUnits(data.sort((a, b) => a.name.localeCompare(b.name)));
      })
      .catch(() => {
        enqueueSnackbar(
          "Something went wrong with fetching organizational units",
          {
            variant: "error",
          }
        );
      });
  }, [refresh]);

  const handleRefresh = () => {
    setRefresh(!refresh);
  };

  const handleDelete = () => {
    if (selectedBusinessUnit) {
      BusinessUnitHttpService.deleteBusinessUnit(selectedBusinessUnit.id)
        .then(() => {
          handleModalClose();
          handleRefresh();
          enqueueSnackbar("Organizational Unit deleted successfully", {
            variant: "success",
          });
        })
        .catch((error) => {
          const errorMessage = getErrorMessage(error);
          enqueueSnackbar(errorMessage, {
            variant: "error",
          });
        });
    }
  };

  const handleModalClose = () => {
    setSelectedBusinessUnit(undefined);
    setDeleteBusinessUnitModalOpen(false);
    setManageBusinessUnitModalOpen(false);
  };

  const handleCreateBusinessUnitModalOpen = () => {
    setSelectedBusinessUnit(undefined);
    setManageBusinessUnitModalOpen(true);
  };

  const handleEditBusinessUnitModalOpen = (businessUnit: BusinessUnit) => {
    setSelectedBusinessUnit(businessUnit);
    setManageBusinessUnitModalOpen(true);
  };

  const handleDeleteBusinessUnitModalOpen = (businessUnit: BusinessUnit) => {
    setSelectedBusinessUnit(businessUnit);
    setDeleteBusinessUnitModalOpen(true);
  };

  return (
    <>
      <Stack gap={2}>
        {canManage && (
          <Button
            variant="contained"
            onClick={handleCreateBusinessUnitModalOpen}
            sx={{ alignSelf: "flex-end" }}
          >
            Add Organizational Unit
          </Button>
        )}
        <Grid container spacing={2}>
          {businessUnits.map((unit) => (
            <Grid item key={unit.id} xs={4}>
              <Paper component={Stack} gap={3} p={3} height="100%">
                <Box display="flex" justifyContent="space-between">
                  <Stack gap={0.5}>
                    <Typography variant="h6">{unit.name}</Typography>
                    <Typography
                      variant="caption"
                      color="text.mediumEmphasis"
                      minHeight={theme.typography.caption.lineHeight}
                    >
                      {unit.shortDescription}
                    </Typography>
                  </Stack>
                  {canManage && (
                    <EditDeleteMenu
                      size="small"
                      entity="organizational-unit"
                      setEditModalOpen={() =>
                        handleEditBusinessUnitModalOpen(unit)
                      }
                      setDeleteModalOpen={() =>
                        handleDeleteBusinessUnitModalOpen(unit)
                      }
                    />
                  )}
                </Box>
                <Stack gap={3} mt="auto">
                  <ContactName
                    orientation="horizontal"
                    name={unit.mainContact?.name}
                    title={unit.mainContact?.title || "Program Manager"}
                  />
                  <ProjectsCounter
                    value={unit.projects.length}
                    color="secondary"
                  />
                </Stack>
              </Paper>
            </Grid>
          ))}
        </Grid>
      </Stack>
      {deleteBusinessUnitModalOpen && selectedBusinessUnit ? (
        <DeleteOrRemoveModal
          id={selectedBusinessUnit?.id}
          entity="organizational unit"
          actionType="delete"
          modalOpen={deleteBusinessUnitModalOpen}
          setModalOpen={setDeleteBusinessUnitModalOpen}
          handleDelete={handleDelete}
        />
      ) : (
        <></>
      )}
      {manageBusinessUnitModalOpen ? (
        <ManageBusinessUnitModal
          businessUnit={selectedBusinessUnit}
          ventureClientId={props.ventureClient.id}
          handleModalClose={handleModalClose}
          modalOpen={manageBusinessUnitModalOpen}
          handleSave={handleRefresh}
        />
      ) : (
        <></>
      )}
    </>
  );
}
