import {
  Box,
  CircularProgress,
  Typography,
  styled,
  InputLabel,
  Stack,
} from "@mui/material";
import { ReactElement, useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import UploadOutlinedIcon from "@mui/icons-material/UploadOutlined";
import theme from "../../theme";
import FileDownloadDoneOutlinedIcon from "@mui/icons-material/FileDownloadDoneOutlined";
import { useSnackbar } from "notistack";

const LogoUploadRoot = styled(Box, {
  shouldForwardProp: (prop: string) => !prop.startsWith("$"),
})(({ $isDragActive }: { $isDragActive: boolean }) => ({
  position: "relative",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  gap: theme.spacing(0.5),
  borderRadius: theme.spacing(0.5),
  width: 183,
  border: `1px solid ${theme.palette.borderOutline.main}`,
  flexShrink: "0",
  cursor: "pointer",
  transition: `all .1s ${theme.transitions.easing.easeInOut}`,
  ...($isDragActive && { outlineWidth: "5px" }),
  ":hover": {
    outlineColor: "black",
  },
}));

const StyledInputLabel = styled(InputLabel)(() => ({
  position: "absolute",
  top: -8,
  left: 8,
  paddingInline: 5,
  backgroundColor: "white",
}));

interface FileDropZoneProps {
  addLogo: (file: File) => Promise<void>;
}

const LogoUploadDropbox = (props: FileDropZoneProps): ReactElement => {
  const [isUploading, setIsUploading] = useState(false);
  const [isUploaded, setIsUploaded] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const onDrop = useCallback(async (acceptedFiles: File[]) => {
    const file = acceptedFiles.pop();
    if (file?.type === "image/png") {
      setIsUploading(true);
      await props.addLogo(file);
      setIsUploading(false);
      setIsUploaded(true);
    } else {
      return enqueueSnackbar("Only .png is accepted", {
        variant: "error",
      });
    }
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept:
      "application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation, image/png, image/jpeg, image/webp, text/plain, image/svg+xml, video/mp4, video/quicktime, audio/mpeg, audio/wav",
  });

  return (
    <LogoUploadRoot
      $isDragActive={isDragActive}
      id="upload-logo-dropzone"
      {...getRootProps()}
    >
      <StyledInputLabel htmlFor="dropzone-input" shrink>
        Upload Logo
      </StyledInputLabel>
      <input id="dropzone-input" {...getInputProps()} accept="image/png" />
      {isUploaded && (
        <FileDownloadDoneOutlinedIcon
          sx={{ fontSize: "4rem" }}
          color="primary"
        />
      )}

      {isUploading && <CircularProgress color="primary" />}

      {!isUploaded && !isUploading && (
        <Stack alignItems="center" gap={1}>
          <UploadOutlinedIcon sx={{ color: theme.palette.text.action.main }} />
          <Stack color="text.disabled" textAlign="center">
            <Typography variant="caption">Min Width: 150px</Typography>
            <Typography variant="caption">Min Height: 150px</Typography>
          </Stack>
        </Stack>
      )}
    </LogoUploadRoot>
  );
};

export default LogoUploadDropbox;
