import { Box, BoxProps, Container, styled } from "@mui/material";
import { ReactElement } from "react";
import theme from "../../theme";

const ContentWrapperBox = styled(Box)(() => ({
  padding: theme.spacing(13, 0, 3),
  backgroundColor: theme.palette.background.default,
}));

const ContentWrapper = ({ children, ...props }: BoxProps): ReactElement => {
  return (
    <ContentWrapperBox {...props}>
      <Container>{children}</Container>
    </ContentWrapperBox>
  );
};

export default ContentWrapper;
