import { ReactElement, useEffect, useState } from "react";
import AppBar from "@mui/material/AppBar";
import { styled } from "@mui/system";
import Header from "../UI/Header";
import theme from "../../theme";

const StyledAppBar = styled(AppBar)<{ opacity: number }>(({ opacity }) => ({
  backgroundColor: `rgba(0, 0, 0, ${opacity})`,
  backdropFilter: "blur(12px)",
  paddingLeft: theme.spacing(9),
}));

export default function ApplicationBar(): ReactElement {
  const [opacity, setOpacity] = useState(0.9);

  useEffect(() => {
    const checkHeaderPresence = () => {
      const pageHeader = document.getElementById("page-header");
      setOpacity(pageHeader ? 0.5 : 1);
    };

    checkHeaderPresence();

    const observer = new MutationObserver(checkHeaderPresence);
    observer.observe(document.body, { childList: true, subtree: true });

    return () => observer.disconnect();
  }, []);

  return (
    <StyledAppBar opacity={opacity}>
      <Header />
    </StyledAppBar>
  );
}
