import {
  Dialog,
  DialogTitle,
  Box,
  Typography,
  TextField,
  styled,
  Checkbox,
  FormControlLabel,
  FormGroup,
  DialogActions,
  Button,
  Stack,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useState, useContext, useReducer, Reducer, ReactElement } from "react";
import { useSnackbar } from "notistack";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import { GlobalProjectEditContext } from "../../../../Context/ProjectDetailsContext";
import { UserContext } from "../../../../Context/UserContext";
import ObjectiveHttpService from "../../../../Http/Objective/Objective.Http.service";
import { Objective } from "../../../../Types/Objective";
import theme from "../../../../theme";
import { getErrorMessage } from "../../../../utils";
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import ScrollableDialogContent from "../../../UI/Modals/ScrollableDialogContent";

const InfoSection = styled(Box)(() => ({
  paddingInline: theme.spacing(2),
  borderRadius: theme.shape.radius.minimal,
  backgroundColor: theme.palette.surface.secondary.main,
}));

const StyledFormGroup = styled(FormGroup)(() => ({
  gap: theme.spacing(1),
  "& .MuiButtonBase-root.MuiCheckbox-root": {
    backgroundColor: "transparent",
    padding: 0,
    marginRight: theme.spacing(2),
  },
  "& .MuiFormControlLabel-root": {
    marginLeft: 0,
  },
}));

interface Props {
  projectId: number;
  setModalOpen: (state: boolean) => void;
  modalOpen: boolean;
  objective?: Objective;
  updateObjective?: (objective: Objective) => void;
  createObjective: (newObjective: Objective) => void;
}

const ManageObjectiveModal = (props: Props): ReactElement => {
  const { activeStep } = useContext(GlobalProjectEditContext);
  const user = useContext(UserContext);
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [newObjective, setNewObjective] = useReducer<
    Reducer<Objective, Partial<Objective>>
  >(
    (state, newState) => ({ ...state, ...newState }),
    props.objective || ({ projectId: props.projectId } as Objective)
  );
  const isBuyStageActive = activeStep === 2;
  const isCreateMode = !props.objective;

  const createObjective = async () => {
    setIsLoading(true);
    await ObjectiveHttpService.createObjective(newObjective)
      .then((objective: Objective) => {
        props.createObjective(objective);
      })
      .catch((error) => {
        const errorMessage = getErrorMessage(error);
        return enqueueSnackbar(`Could not create Objective: ${errorMessage}`, {
          variant: "error",
        });
      })
      .finally(() => {
        setIsLoading(false);
        props.setModalOpen(false);
      });
  };

  const editObjective = async () => {
    setIsLoading(true);
    await ObjectiveHttpService.updateObjective(newObjective)
      .then(() => {
        props.updateObjective?.(newObjective);
      })
      .catch((error) => {
        const errorMessage = getErrorMessage(error);
        return enqueueSnackbar(`Could not update Objective: ${errorMessage}`, {
          variant: "error",
        });
      })
      .finally(() => {
        setIsLoading(false);
        props.setModalOpen(false);
      });
  };

  return (
    <>
      <Dialog
        open={props.modalOpen}
        fullWidth
        data-testid="manage-objective-modal"
        PaperProps={{
          sx: {
            gap: theme.spacing(4),
          },
        }}
      >
        <DialogTitle data-testid="manage-objective-header">
          {isCreateMode ? "Add Objective" : "Edit Objective"}
        </DialogTitle>
        <ScrollableDialogContent>
          {isCreateMode && (
            <Accordion
              sx={{
                backgroundColor: theme.palette.surface.secondary.main,
                "&.MuiAccordion-root": {
                  mb: theme.spacing(4),
                },
              }}
              defaultExpanded
            >
              <AccordionSummary expandIcon={<KeyboardArrowDown />}>
                <Box display="flex" alignItems="center" gap={1}>
                  <InfoOutlinedIcon sx={{ fontSize: "1rem" }} />
                  <Typography>Hello {user?.name}</Typography>
                </Box>
              </AccordionSummary>
              <AccordionDetails sx={{ p: 0 }}>
                <InfoSection data-testid="info-section">
                  <Typography>
                    You are about to create a new objective for your project
                    <br />
                    <br />
                    Project <b>objectives</b> should clearly describe the pilot
                    project&apos;s goals and define which aspects of the
                    solution are to be evaluated.
                    <br />
                    <br />
                    Here are a few tips to make things easier:
                  </Typography>
                  <ul
                    style={{
                      marginTop: 0,
                      paddingInlineStart: theme.spacing(2),
                      ...theme.typography.body1,
                    }}
                  >
                    <li>
                      Clearly articulate what the involved parties are looking
                      to achieve or evaluate in this pilot project
                    </li>
                    <li>
                      Ensure that your objective can be measured or quantified
                      in some way.
                    </li>
                    <li>
                      Set relevant objectives that are meaningful to the project
                      and avoid ambiguous, vague or contradictory objectives.
                    </li>
                  </ul>
                </InfoSection>
              </AccordionDetails>
            </Accordion>
          )}

          <Box pt={isBuyStageActive ? 1 : 0}>
            {isBuyStageActive ? (
              <TextField
                label="Objective"
                multiline
                value={newObjective.name}
                variant="outlined"
                onChange={(event) =>
                  setNewObjective({ name: event.target.value })
                }
                fullWidth
                placeholder="e.g. Demonstrate the capacity of the startup solution to substantially reduce energy consumption within the plant"
                helperText={
                  isCreateMode &&
                  "Enter a clear and concise description for the project objective."
                }
                inputProps={{ "data-testid": "objective-name-input" }}
              />
            ) : (
              <Stack gap={4}>
                <Typography variant="body1">{newObjective.name}</Typography>
                <StyledFormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        sx={{ backgroundColor: "transparent" }}
                        name="Objective Achieved"
                        disableRipple
                        checked={newObjective.fulfilled === true}
                        checkedIcon={
                          <CheckCircleIcon
                            sx={{
                              color: "brand.accessibility",
                            }}
                          />
                        }
                        icon={
                          <CheckCircleIcon sx={{ color: "icon.disabled" }} />
                        }
                        onChange={() => {
                          setNewObjective({ fulfilled: true });
                        }}
                      />
                    }
                    label={
                      <Typography variant="body2">
                        Objective Achieved
                      </Typography>
                    }
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="Objective Not Achieved"
                        disableRipple
                        checked={newObjective.fulfilled === false}
                        checkedIcon={
                          <CancelIcon
                            sx={{
                              color: "icon.error",
                            }}
                          />
                        }
                        icon={<CancelIcon sx={{ color: "icon.disabled" }} />}
                        onChange={() => {
                          setNewObjective({ fulfilled: false });
                        }}
                      />
                    }
                    label={
                      <Typography variant="body2">
                        Objective Not Achieved
                      </Typography>
                    }
                  />
                </StyledFormGroup>
              </Stack>
            )}
          </Box>
        </ScrollableDialogContent>
        <DialogActions>
          <Box display="flex" gap={1}>
            <Button
              onClick={() => props.setModalOpen(false)}
              disabled={isLoading}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={isCreateMode ? createObjective : editObjective}
              disabled={isLoading}
            >
              Save
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ManageObjectiveModal;
