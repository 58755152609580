import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  MenuItem,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/styles";
import { ReactElement, useReducer, Reducer } from "react";
import {
  CreateStartupContactDTO,
  StartupContactDTO,
} from "../../../Types/StartupContact";
import StartupContactHttpService from "../../../Http/StartupContact/StartupContact.http.service";
import { Gender } from "../../../Types/Common";

interface CreateContactModalProps {
  setModalOpen: (state: boolean) => void;
  modalOpen: boolean;
  getNewContact?: (contact: StartupContactDTO) => void;
  startupId: number;
}

const genders = ["male", "female", "non-binary"];

export default function CreateStartupContactGlobalModal(
  props: CreateContactModalProps
): ReactElement {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const [newContact, setNewContact] = useReducer<
    Reducer<CreateStartupContactDTO, Partial<CreateStartupContactDTO>>
  >(
    (state, newState) => ({ ...state, ...newState }),
    {} as CreateStartupContactDTO
  );

  const handleCreateContact = async () => {
    if (newContact.name != null) {
      const contactCreated = await StartupContactHttpService.createContact({
        ...newContact,
        startupId: props.startupId,
      });
      if (props.getNewContact && contactCreated) {
        props.getNewContact(contactCreated);
      }
      props.setModalOpen(false);
    }
  };

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      fullScreen={fullScreen}
      open={props.modalOpen}
      data-testid="startup-contact-create-view-global"
    >
      <DialogTitle>Create Startup Contact</DialogTitle>
      <DialogContent>
        <Box
          noValidate
          autoComplete="off"
          component="form"
          display="flex"
          flexDirection="column"
          gap={1}
          padding={1}
        >
          <Typography variant="h6">Personal Information</Typography>
          <Box display="flex" flexDirection="row" gap={3}>
            <TextField
              label="Name"
              id="contact-name"
              variant="outlined"
              required
              fullWidth
              value={newContact.name || ""}
              onChange={(event) => setNewContact({ name: event.target.value })}
            />
            <TextField
              label="Gender"
              id="contact-gender"
              variant="outlined"
              fullWidth
              select
              value={newContact.gender || ""}
              onChange={(event) =>
                setNewContact({ gender: event.target.value as Gender })
              }
            >
              {genders.map((gender) => (
                <MenuItem key={gender} value={gender}>
                  {gender}
                </MenuItem>
              ))}
            </TextField>
          </Box>
          <Typography variant="h6" mt={4}>
            Company
          </Typography>
          <Box display="flex" flexDirection="column" gap={3}>
            <TextField
              label="Title"
              id="contact-title"
              variant="outlined"
              required
              fullWidth
              value={newContact.title || ""}
              onChange={(event) => setNewContact({ title: event.target.value })}
            />
            <FormGroup
              sx={{
                height: "100%",
                justifyContent: "center",
                minWidth: "35ch",
              }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    data-cy="founder"
                    id="isFounder"
                    checked={newContact.founder ? true : false}
                    onChange={(e) =>
                      setNewContact({ founder: e.target.checked })
                    }
                  />
                }
                label="(Co-)Founder"
              />
            </FormGroup>
          </Box>
          <Typography variant="h6" mt={4}>
            Contact
          </Typography>
          <Box display="flex" flexDirection="row" gap={3}>
            <TextField
              label="Phone Number"
              id="contact-phone"
              variant="outlined"
              fullWidth
              value={newContact.phone || ""}
              onChange={(event) => setNewContact({ phone: event.target.value })}
            />
            <TextField
              label="Email"
              id="contact-email"
              variant="outlined"
              required
              fullWidth
              value={newContact.email || ""}
              onChange={(event) => setNewContact({ email: event.target.value })}
            />
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          autoFocus
          onClick={() => props.setModalOpen(false)}
          color="primary"
          id="close-startup-create-contact-view"
        >
          Cancel
        </Button>
        <Button
          onClick={handleCreateContact}
          variant="contained"
          id="save-startup-create-contact-view"
        >
          Create Contact
        </Button>
      </DialogActions>
    </Dialog>
  );
}
