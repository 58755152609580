import { Box, Button, Typography, styled } from "@mui/material";
import ModeStandbyOutlinedIcon from "@mui/icons-material/ModeStandbyOutlined";
import { ReactElement, useContext, useState } from "react";
import ManageObjectiveModal from "./ManageObjectiveModal";
import ManageKpiModal from "./Kpi/ManageKpiModal";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import { GlobalProjectEditContext } from "../../../../Context/ProjectDetailsContext";
import ObjectiveHttpService from "../../../../Http/Objective/Objective.Http.service";
import { Kpi } from "../../../../Types/Kpi";
import { Objective } from "../../../../Types/Objective";
import theme from "../../../../theme";
import KpiCard from "./Kpi/KpiCard";
import EditDeleteMenu from "../../../UI/EditDeleteMenu";
import DeleteOrRemoveModal from "../../../UI/Modals/DeleteOrRemoveModal/DeleteOrRemoveModal";
import AddIcon from "@mui/icons-material/Add";
import { Project } from "../../../../Types/Project";
import useRoles from "../../../../Hooks/useRoles";

interface Props {
  objective: Objective;
  updateObjective: (objective: Objective) => void;
  deleteObjective: (id: number) => void;
  createObjective: (newObjective: Objective) => void;
  updateKpi: (objectiveId: number, updatedKpi: Kpi) => void;
  createKpi: (newKpi: Kpi) => void;
  deleteKpi: (id: number) => void;
  project: Project;
}

const Header = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: theme.spacing(1),
}));

const ObjectiveContent = styled(Box)(({ theme }) => ({
  whiteSpace: "pre-line",
  paddingInline: theme.spacing(4, 5),
  display: "flex",
  flexDirection: "column",
}));

const ObjectiveAchievementResult = styled(Box, {
  shouldForwardProp: (prop: string) => !prop.startsWith("$"),
})(({ $fulfilled }: { $fulfilled: boolean | null }) => ({
  display: "flex",
  alignItems: "center",
  gap: theme.spacing(1),
  marginTop: theme.spacing(2),
  "& > p, & > svg": {
    color: $fulfilled ? theme.palette.success.main : theme.palette.error.main,
  },
  "& > svg": {
    fontSize: "1rem",
  },
}));

const MultiKpiWrapper = styled(Box)(({ theme }) => ({
  display: "grid",
  gap: theme.spacing(3),
  marginTop: theme.spacing(3),
  gridTemplateColumns: "repeat(3, 1fr)",
  gridAutoFlow: "row",
  gridAutoRows: "auto",
}));

const ObjectiveCard = ({ objective, ...props }: Props): ReactElement => {
  const { canEdit } = useRoles(props.project);
  const { globalEditMode, activeStep } = useContext(GlobalProjectEditContext);
  const [editObjectiveModalOpen, setEditObjectiveModalOpen] = useState(false);
  const [createKpiModalOpen, setCreateKpiModalOpen] = useState(false);
  const [deleteObjectiveModalOpen, setDeleteObjectiveModalOpen] =
    useState(false);

  const isBuyStageActive = activeStep === 2;
  const isPilotStageActive = activeStep === 3;

  const hasObjectiveAchievementResult =
    isPilotStageActive && objective.fulfilled !== null;

  // Update objective's initial fulfilled status
  const refreshObjective = async (
    kpiId: number,
    isKpiFulfilled: boolean | null
  ) => {
    if (objective.fulfilled !== null) return;

    const allKpisFulfilled = objective.kpis.every(
      (kpi) => (kpi.id === kpiId ? isKpiFulfilled : kpi.fulfilled) === true
    );

    if (allKpisFulfilled) {
      await ObjectiveHttpService.updateObjective({
        id: objective.id,
        fulfilled: true,
      } as Objective);
    }
  };

  return (
    <>
      <Box data-testid="objective-card">
        <Box sx={{ flexWrap: "nowrap", display: "flex", gap: 5 }}>
          <Box sx={{ flex: 1 }}>
            <Header data-testid="objective-header">
              <ModeStandbyOutlinedIcon fontSize="small" />
              <Typography variant="subtitle2">Objective</Typography>
              {canEdit && (
                <Box ml="auto">
                  {isBuyStageActive ? (
                    <EditDeleteMenu
                      entity="objective"
                      setEditModalOpen={setEditObjectiveModalOpen}
                      setDeleteModalOpen={setDeleteObjectiveModalOpen}
                    />
                  ) : (
                    <Button
                      data-testid="edit-buy-insights"
                      variant="contained"
                      onClick={() => setEditObjectiveModalOpen(true)}
                      disabled={globalEditMode}
                    >
                      Edit
                    </Button>
                  )}
                </Box>
              )}
            </Header>
            <ObjectiveContent data-testid="objective-content">
              <Typography variant="body1">{objective.name}</Typography>
              {hasObjectiveAchievementResult && (
                <ObjectiveAchievementResult
                  $fulfilled={objective.fulfilled}
                  data-testid="objective-achievement-result"
                >
                  {objective.fulfilled ? <CheckCircleIcon /> : <CancelIcon />}
                  <Typography variant="body2">
                    {objective.fulfilled ? "Achieved" : "Not Achieved"}
                  </Typography>
                </ObjectiveAchievementResult>
              )}
            </ObjectiveContent>
          </Box>
        </Box>
        <MultiKpiWrapper>
          {objective.kpis.map((kpi: Kpi) => (
            <KpiCard
              key={kpi.id}
              kpi={kpi}
              refreshObjective={refreshObjective}
              updateKpi={props.updateKpi}
              createKpi={props.createKpi}
              deleteKpi={props.deleteKpi}
              canEdit={canEdit}
            />
          ))}
          {isBuyStageActive && canEdit && (
            <Button
              startIcon={<AddIcon fontSize="small" />}
              sx={{ alignSelf: "center", justifySelf: "start" }}
              onClick={() => setCreateKpiModalOpen(true)}
            >
              Add KPI
            </Button>
          )}
        </MultiKpiWrapper>
      </Box>
      {editObjectiveModalOpen && (
        <ManageObjectiveModal
          projectId={objective.projectId}
          modalOpen={editObjectiveModalOpen}
          setModalOpen={setEditObjectiveModalOpen}
          objective={objective}
          updateObjective={props.updateObjective}
          createObjective={props.createObjective}
        />
      )}
      {createKpiModalOpen && (
        <ManageKpiModal
          objectiveId={objective.id}
          modalOpen={createKpiModalOpen}
          setModalOpen={setCreateKpiModalOpen}
          updateKpi={props.updateKpi}
          createKpi={props.createKpi}
        />
      )}
      {deleteObjectiveModalOpen && (
        <DeleteOrRemoveModal
          id={objective.id}
          entity="objective"
          modalOpen={deleteObjectiveModalOpen}
          setModalOpen={setDeleteObjectiveModalOpen}
          handleDelete={props.deleteObjective}
          actionType="delete"
        />
      )}
    </>
  );
};

export default ObjectiveCard;
