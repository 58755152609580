import { createContext } from "react";

export type OpportunitiesLoadingContextType = {
  opportunitiesLoading: boolean;
  setOpportunitiesLoading: React.Dispatch<React.SetStateAction<boolean>>;
};

export const OpportunitiesLoadingContext =
  createContext<OpportunitiesLoadingContextType>({
    opportunitiesLoading: true,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    setOpportunitiesLoading: () => {},
  });
