import { ReactElement } from "react";
import {
  CalendarPickerView,
  DatePicker,
  DatePickerProps,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "dayjs/locale/de";
import CustomTextField from "./CustomTextField";
import { formatDate } from "../../../utils";
import { TextFieldProps } from "@mui/material";

interface CustomTextFieldProps
  extends Omit<DatePickerProps<Date, Date>, "renderInput"> {
  id: string;
  label?: string;
  toolTipText?: string | null;
  editMode: boolean;
  error?: boolean;
  errorMessage?: string;
  value: Date | null;
  required?: boolean;
  fullWidth?: boolean;
  onChange: (
    value: Date | null,
    keyboardInputValue?: string | undefined
  ) => void;
  className?: string;
  views?: CalendarPickerView[];
  disableFuture?: boolean;
  renderInputProps?: TextFieldProps & { "data-testid"?: string };
}

const CustomDatePicker = ({
  id,
  views,
  inputFormat,
  label,
  value,
  onChange,
  disableFuture,
  fullWidth,
  required,
  editMode,
  toolTipText,
  renderInputProps,
  ...props
}: CustomTextFieldProps): ReactElement => {
  const isError = editMode && required && !value;

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en">
      <DatePicker
        {...props}
        views={views ? views : ["year", "day"]}
        label={label}
        value={value}
        onChange={onChange}
        disableFuture={disableFuture}
        inputFormat={inputFormat ?? "DD.MM.YYYY"}
        renderInput={(params) => (
          <CustomTextField
            {...params}
            {...renderInputProps}
            id={id}
            error={isError}
            fullWidth={fullWidth}
            required={required}
            displayValue={value ? formatDate(value) : null}
            editMode={editMode}
            toolTipText={toolTipText}
            data-testid={id}
          />
        )}
      />
    </LocalizationProvider>
  );
};

export default CustomDatePicker;
