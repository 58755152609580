import { Box, Stack, Typography } from "@mui/material";
import { Dispatch, ReactElement } from "react";
import {
  ADDITIONAL_RESOURCES_TOOLTIP,
  FIRST_OFFER_TOOLTIP,
} from "../../../../Constants/TooltipText";
import { Project } from "../../../../Types/Project";
import NumberCard from "../../../UI/NumberCard";
import CustomTextField from "../../../UI/InputFields/CustomTextField";

interface Props {
  project: Project;
  editMode: boolean;
  onChange: Dispatch<Partial<Project>>;
}

export default function PilotResourcesSection(props: Props): ReactElement {
  const { editMode } = props;

  return (
    <Stack gap={1}>
      <Typography variant="subtitle2">Pilot Resources</Typography>
      <Box display="grid" gridTemplateColumns="repeat(4, 1fr)" gap={3}>
        <>
          {editMode ? (
            <CustomTextField
              id="costEstimation"
              type="currency"
              label="Cost Estimation"
              editMode={true}
              value={props.project.costEstimation}
              onChange={(e) =>
                props.onChange({ costEstimation: e.target.value })
              }
            />
          ) : (
            <NumberCard
              label="Cost Estimation"
              value={props.project.costEstimation}
              hideUnit
            />
          )}

          {editMode ? (
            <CustomTextField
              id="firstOffer"
              type="currency"
              label="First Startup Offer"
              toolTipText={FIRST_OFFER_TOOLTIP}
              editMode={true}
              value={props.project.firstOffer}
              onChange={(e) => props.onChange({ firstOffer: e.target.value })}
              required
            />
          ) : (
            <NumberCard
              label="First Startup Offer"
              value={props.project.firstOffer}
              hideUnit
            />
          )}

          {editMode ? (
            <CustomTextField
              id="purchaseOrderAmount"
              type="currency"
              label="Purchase Order Amount"
              editMode={true}
              toolTipText={FIRST_OFFER_TOOLTIP}
              value={props.project.purchaseOrderAmount}
              onChange={(e) =>
                props.onChange({ purchaseOrderAmount: e.target.value })
              }
              required
            />
          ) : (
            <NumberCard
              label="Purchase Order Amount"
              value={props.project.purchaseOrderAmount}
              hideUnit
            />
          )}

          <CustomTextField
            id="additionalResources"
            data-testid="additional-resources"
            label="Additional Resources"
            toolTipText={ADDITIONAL_RESOURCES_TOOLTIP}
            value={props.project.additionalResources || ""}
            onChange={(e) =>
              props.onChange({ additionalResources: e.target.value })
            }
            editMode={editMode}
            maxCharacter={255}
            helperText={`${props.project.additionalResources?.length || 0}/255`}
          />
        </>
      </Box>
    </Stack>
  );
}
