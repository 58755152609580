import axios from "axios";

export class AiHttpService {
  public static async autoGenerateText(
    text: string,
    field: string
  ): Promise<string> {
    const response = await axios.post<string>(`/api/ai/${field}`, {
      text,
    });
    return response.data;
  }

  public static async generateAssessmentSummary(
    projectId: number
  ): Promise<string> {
    const response = await axios.post<string>(`/api/ai/assessment-summary`, {
      projectId,
    });
    return response.data;
  }
}
