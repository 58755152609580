import { ProjectHistory, ProjectHistoryDTO } from "../../Types/ProjectHistory";

export default class ProjectHistoryMapper {
  public static map(histories: ProjectHistoryDTO[]): ProjectHistory[] {
    return histories
      .filter(
        (history) =>
          !(history.newValue === "discover" && history.oldValue === null)
      )
      .map((history) => ({
        ...history,
        dateTriggered: new Date(history.dateTriggered),
      }))
      .sort((a, b) => b.dateTriggered.getTime() - a.dateTriggered.getTime());
  }
}
