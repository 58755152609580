import { ReactElement, ReactNode } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { Box, InputLabel, Stack, styled, Typography } from "@mui/material";
import parse from "html-react-parser";
import CustomToolTip from "../../CustomToolTip";
import { removeFormatting } from "../../../../utils";
import FormHelperText from "@mui/material/FormHelperText";
import "./RichTextEditor.css";
import theme from "../../../../theme";

interface Props {
  children?: ReactNode;
  onChange?: (value: string) => void;
  editMode: boolean;
  fieldValue: string;
  labelText: string;
  fieldId: string;
  required?: boolean;
  toolTipText?: string;
  maxCharacter?: number;
  isToolbarDisabled?: boolean;
  isListsDisabled?: boolean;
  demoAgendaToolBarBg?: boolean;
}

const CKEditorWrapper = styled(Box, {
  shouldForwardProp: (prop: string) => !prop.startsWith("$"),
})(
  ({
    $isToolbarDisabled,
    $toolTipText,
    $demoAgendaToolBarBg,
    $error,
  }: {
    $isToolbarDisabled?: boolean;
    $toolTipText?: string;
    $demoAgendaToolBarBg?: boolean;
    $error?: boolean;
  }) => ({
    position: "relative",
    border: "1px solid",
    borderRadius: theme.shape.borderRadius,
    borderColor: "rgba(0, 0, 0, 0.23)",
    height: "100%",

    "&:hover": {
      borderColor: "rgba(0, 0, 0, 1)",
    },
    "& .ck.ck-editor": {
      cursor: "text",
      height: "100%",
      marginTop: "4px",
    },

    // content
    "& .ck.ck-editor__main": {
      transform: `translateY(${$isToolbarDisabled ? "0" : "-.6rem"})`,
      paddingBlock: $isToolbarDisabled ? "16.5px" : "0",
      marginTop: $isToolbarDisabled ? "-1px" : "-5px",
      ...theme.typography.body1,
      height: "100%",
      paddingBottom: "1rem",

      "& p": {
        margin: 0,
      },
    },

    "& .ck.ck-editor__editable_inline": {
      paddingInline: "14px",
      height: "100%",
    },

    "& .ck.ck-editor__main>.ck-editor__editable": {
      background: "transparent",
    },

    "& .ck.ck-editor__top": {
      transform: "translateY(-18px)",
      marginInline: "auto .8rem",
      width: "max-content",
    },

    // toolbar (label and buttons)
    "& .ck.ck-editor__top .ck-sticky-panel .ck-toolbar": {
      border: "none",
      backgroundColor: "transparent",
    },

    // buttons container
    "& .ck.ck-editor__top .ck-sticky-panel .ck-toolbar .ck-toolbar__items": {
      flexGrow: "initial",
      backgroundColor: $demoAgendaToolBarBg
        ? theme.palette.surface.secondary.light
        : "#fff",
      outline: $demoAgendaToolBarBg ? "none" : "5px solid #fff",
    },

    "& .MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-animated.MuiInputLabel-shrink.MuiFormLabel-colorPrimary":
      {
        backgroundColor: $demoAgendaToolBarBg
          ? theme.palette.surface.secondary.light
          : "#fff",
      },

    // each button
    "& .ck.ck-editor__top .ck-sticky-panel .ck-toolbar .ck-toolbar__items .ck-button":
      {
        marginBlock: "0",
      },

    // tooltip for button
    "& .ck.ck-editor__top .ck-sticky-panel .ck-toolbar .ck-toolbar__items .ck-button .ck-tooltip":
      {
        display: "none",
      },

    // when button is clicked
    "& .ck.ck-editor__top .ck-sticky-panel .ck-toolbar .ck-toolbar__items .ck-button .ck-on":
      {
        backgroundColor: "#000",
      },

    // the element who creates border for content section
    "& .ck.ck-editor>.ck.ck-editor__main>.ck-content.ck-editor__editable.ck-rounded-corners":
      {
        border: "none",
        boxShadow: "none",
        height: "100%",
      },

    // label of the field when its in edit mode
    "& > label": {
      position: "absolute",
      color: theme.palette.text.secondary,
      top: $toolTipText ? "-14px" : "-9px",
      left: "8px",
      backgroundColor: "#fff",
      paddingInline: "8px",
    },

    // character count display
    "& .MuiFormHelperText-root": {
      position: "absolute",
      right: "14px",
      bottom: 0,
    },

    "& .auto-generate-text-button": {
      position: "absolute",
      right: $isToolbarDisabled ? "1rem" : "7.5rem",
      top: "-.9rem",
    },

    "& a": {
      color: theme.palette.text.action.main,
    },

    ...($error && {
      borderColor: theme.palette.surface.alert.main,
      "& .MuiInputLabel-root": {
        color: theme.palette.text.alert,
      },
    }),
  })
);

function RichTextEditor(props: Props): ReactElement {
  const error = props.editMode && props.required && !props.fieldValue;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleChange = (__: any, editor: ClassicEditor) => {
    props.onChange && props.onChange(editor.getData());
  };

  const characterCount = props.fieldValue
    ? removeFormatting(props.fieldValue).length
    : 0;

  const toolbar = props.isToolbarDisabled
    ? []
    : [
        "bold",
        "italic",
        ...(props.isListsDisabled ? [] : ["bulletedList", "numberedList"]),
        "link",
      ];
  const removePlugins = props.isToolbarDisabled
    ? ["Bold", "Italic", "List"]
    : props.isListsDisabled
    ? ["bulletedList", "numberedList", "List"]
    : [];

  return (
    <>
      {props.editMode ? (
        <CKEditorWrapper
          $isToolbarDisabled={props.isToolbarDisabled}
          $toolTipText={props.toolTipText}
          $demoAgendaToolBarBg={props.demoAgendaToolBarBg}
          $error={error}
        >
          <InputLabel
            shrink
            required={!!props.required}
            htmlFor={props.fieldId}
          >
            {props.labelText}
            {props.toolTipText && (
              <CustomToolTip
                id={`${props.fieldId}Tooltip`}
                key={`${props.fieldId}Tooltip`}
                toolTipText={props.toolTipText}
              />
            )}
          </InputLabel>
          <CKEditor
            editor={ClassicEditor}
            data={props.fieldValue || ""}
            onChange={handleChange}
            config={{
              toolbar: toolbar,
              removePlugins: removePlugins,
              link: { addTargetToExternalLinks: true },
            }}
          />
          {props.maxCharacter && (
            <FormHelperText
              error={characterCount > props.maxCharacter}
              data-testid="character-count"
            >
              {characterCount}/{props.maxCharacter}
            </FormHelperText>
          )}

          {props.children}
        </CKEditorWrapper>
      ) : (
        <Stack gap={0.5}>
          <InputLabel htmlFor={props.fieldId}>
            <Typography variant="caption">{props.labelText}</Typography>
          </InputLabel>
          <Typography
            id={props.fieldId}
            data-testid={props.fieldId}
            sx={{ "& > p": { margin: 0 } }}
          >
            {props.fieldValue ? parse(props.fieldValue) : "--"}
          </Typography>
        </Stack>
      )}
    </>
  );
}

export default RichTextEditor;
