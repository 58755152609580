import { Box, Container } from "@mui/material";
import ContentWrapper from "./ContentWrapper";
import { ReactElement } from "react";
import theme from "../../theme";
import ProjectDetailsHeaderSkeleton from "./SharedComponents/ProjectDetailsHeader/ProjectDetailsHeaderSkeleton";
import StageStepperSkeleton from "./SharedComponents/FunnelStageComponents/StageStepper/StageStepperSkeleton";
import ProjectDetailsNavigationSkeleton from "./SharedComponents/ProjectDetailsNavigation/ProjectDetailsNavigationSkeleton";
import ProjectOverviewSkeleton from "./SharedComponents/ProjectOverview/ProjectOverviewSkeleton";
import DiscoverStageSkeleton from "./FunnelStages/Discover/DiscoverStage/DiscoverStageSkeleton";

export default function ProjectDetailsPageSkeleton(): ReactElement {
  return (
    <Box
      maxWidth={`calc(100vw - ${theme.spacing(9)})`}
      data-testid="project-details-page-skeleton"
    >
      <ProjectDetailsHeaderSkeleton />
      <Box bgcolor={theme.palette.background.default} pt={5}>
        <Container>
          <StageStepperSkeleton />
        </Container>
      </Box>
      <Box bgcolor={theme.palette.background.default}>
        <Container>
          <ProjectDetailsNavigationSkeleton />
        </Container>
      </Box>
      <ContentWrapper display="grid" sx={{ pt: 5 }}>
        <Box
          display="grid"
          gridTemplateColumns="minmax(0, 1fr) minmax(0, 3fr)"
          gap={3}
        >
          <ProjectOverviewSkeleton />
          <DiscoverStageSkeleton />
        </Box>
      </ContentWrapper>
    </Box>
  );
}
