import { User } from "./Types/User";
import { Opportunity, StartupOpportunity } from "./Types/Opportunity";
import { stageScores } from "./Constants/FunnelStages";
import {
  FunnelStage,
  ProjectStartupOpportunityAssociation,
} from "./Types/Project";
import { File } from "./Types/File";
import { AxiosError } from "axios";

import * as helper from "./utils";
import { LeadOpportunity, LeadProject } from "./Types/LeadProject";
import { ImpactValue } from "./Types/ImpactValue";
import { clientSpecifications } from "./Constants/ClientSpecifications";
import theme from "./theme";
import { ClientContactDTO } from "./Types/ClientContact";
import { Meeting } from "./Types/Meeting";
import { toPng, toSvg } from "html-to-image";

const MS_IN_A_DAY = 1000 * 60 * 60 * 24;

export function capitalizeFirstLetter(word: string): string {
  if (word) {
    return word.charAt(0).toUpperCase() + word.slice(1);
  } else {
    return "";
  }
}

export const removeFormatting = (text: string): string => {
  if (text) {
    return text.replace(/(<\/?[^>]+(>|$)|&nbsp;?)/g, "");
  } else {
    return "";
  }
};

export const isCharLimitExceeded = (
  field: string,
  charLimit: number | undefined
): boolean => {
  const plainText = removeFormatting(field);
  if (charLimit && plainText.length > charLimit) {
    return true;
  } else {
    return false;
  }
};

export const compareOpportunitiesByRelevance = (
  opp1: Opportunity,
  opp2: Opportunity
): number => {
  if (opp1.rating !== opp2.rating) {
    if (opp1.rating === null) return 1;
    if (opp2.rating === null) return -1;
    return opp1.rating < opp2.rating ? -1 : 1;
  }

  const compareByRequirementFulfilment =
    compareOpportunitiesByRequirementFulfilment(opp1, opp2);
  if (compareByRequirementFulfilment !== 0) {
    return compareByRequirementFulfilment;
  }

  if (opp1.startup.eloRating.value !== opp2.startup.eloRating.value) {
    return opp1.startup.eloRating.value > opp2.startup.eloRating.value ? -1 : 1;
  }

  if (opp1.startup.totalFunding !== opp2.startup.totalFunding) {
    return +opp1.startup.totalFunding >= +opp2.startup.totalFunding ? -1 : 1;
  }

  return 0;
};

export const compareOpportunitiesByRequirementFulfilment = (
  opp1: Opportunity,
  opp2: Opportunity
): number => {
  const statuses = [
    "Fulfilled",
    "Partially Fulfilled",
    "Undisclosed",
    "Not Fulfilled",
  ];

  for (const status of statuses) {
    const opp1StatusCount = opp1.requirementStartupFits.filter(
      (requirement) => requirement.status === status
    ).length;
    const opp2StatusCount = opp2.requirementStartupFits.filter(
      (requirement) => requirement.status === status
    ).length;

    if (opp1StatusCount !== opp2StatusCount) {
      return opp1StatusCount > opp2StatusCount ? -1 : 1;
    }
  }
  return 0;
};

export const vclmsProjectsMapper = (ventureClient: string): string => {
  switch (ventureClient) {
    case "Airbus":
      return "https://airbus-vclms.27pilots.com";
    case "Holcim":
      return "https://holcim-vclms.27pilots.com";
    case "Siemens Energy":
      return "https://siemensenergy-vclms.27pilots.com";
    case "Cariad":
      return "https://cariad-vclms.27pilots.com";
    case "Otto":
      return "https://otto-vclms.27pilots.com";
    case "BMW":
      return "https://bmwstartupgarage-vclms.27pilots.com";
    case "OBI":
      return "https://obi-vclms.27pilots.com";
    case "Bosch":
      return "https://openbosch-vclms.27pilots.com";
    case "Siemens Mobility":
      return "https://stationx-vclms.27pilots.com";
    case "Continental":
      return "https://copace-vclms.27pilots.com";
    case "Vodafone":
      return "https://vodafone-vclms.27pilots.com";
    case "Bosch Corporate Research":
      return "https://obr-vclms.27pilots.com";
    case "Knauf":
      return "https://knauf-vclms.27pilots.com";
    case "Siemens Digital Industries":
      return "https://siemens-di-vclms.27pilots.com";
    case "Forvia":
      return "https://forvia-vclms.27pilots.com";
    case "MTU":
      return "https://mtu-vclms.27pilots.com";
    case "KSB":
      return "https://ksb-vclms.27pilots.com";
    default:
      return "https://vclms.27pilots.com";
  }
};

export const thousandSeparator = (
  number: number | string,
  maximumFractionDigits?: number
): string => {
  if (typeof number !== "number") number = +number;
  return new Intl.NumberFormat("en-US", {
    ...(maximumFractionDigits && {
      maximumFractionDigits: maximumFractionDigits,
    }),
  }).format(number);
};

export const formatToBillion = (value: number | string): string => {
  const isValueNumeric = !isNaN(+value) || typeof value === "number";

  if (isValueNumeric) {
    const numericValue = +value;
    return (numericValue / 1000).toLocaleString("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  } else {
    return value.toString();
  }
};

export const calculateMedian = (values: number[]): number => {
  const sortedList = values.sort((a, b) => a - b);
  const median =
    sortedList.length % 2
      ? sortedList[(sortedList.length - 1) / 2]
      : Math.round(
          (sortedList[sortedList.length / 2] +
            sortedList[sortedList.length / 2 - 1]) /
            2
        );

  return median;
};

export const isBillion = (
  value: number | string | null | undefined
): boolean => {
  if (!value) return false;
  const numericValue = typeof value === "string" ? parseFloat(value) : value;
  return numericValue >= 1000;
};

export const sortProjectByDateDescending = (
  project1: ProjectStartupOpportunityAssociation | LeadProject,
  project2: ProjectStartupOpportunityAssociation | LeadProject
): number => {
  const project1Date =
    project1.lastModifiedDate?.getTime() || project1.dateCreated.getTime();
  const project2Date =
    project2.lastModifiedDate?.getTime() || project2.dateCreated.getTime();

  if (project1Date === project2Date) {
    return 0;
  }
  return project1Date > project2Date ? -1 : 1;
};

export const getStartupStatus = (
  opportunity: StartupOpportunity | LeadOpportunity
): string => {
  if (checkIfLeadOpportunity(opportunity)) return "Evaluation";

  const projectStageScore = stageScores[opportunity.project.funnelStage];
  const isBeyondDiscover = projectStageScore > stageScores["discover"];
  const isBeyondAssess = projectStageScore > stageScores["assess"];
  const isAdopt = projectStageScore === stageScores["adopt"];

  if (
    (isBeyondDiscover && !opportunity.isQualified) ||
    (isBeyondAssess && !opportunity.isSelectedForPilot)
  ) {
    return "Ruled Out";
  } else if (isBeyondAssess && opportunity.isSelectedForPilot) {
    return isAdopt ? "Adopted" : "Selected";
  } else return "Evaluation";
};

export const checkIfLeadOpportunity = (
  opportunity: StartupOpportunity | LeadOpportunity
): opportunity is LeadOpportunity => !("project" in opportunity);

export const checkIfLeadProject = (
  project: LeadProject | ProjectStartupOpportunityAssociation
): project is LeadProject => !("funnelStage" in project);

export const sortOpportunitiesByRelevance = (
  opportunity1: StartupOpportunity | LeadOpportunity,
  opportunity2: StartupOpportunity | LeadOpportunity
): number => {
  const sortingScore1 = calculateSortingScore(opportunity1);
  const sortingScore2 = calculateSortingScore(opportunity2);
  const project1 = checkIfLeadOpportunity(opportunity1)
    ? opportunity1.leadProject
    : opportunity1.project;
  const project2 = checkIfLeadOpportunity(opportunity2)
    ? opportunity2.leadProject
    : opportunity2.project;

  if (sortingScore1 < sortingScore2) return -1;
  else if (sortingScore1 > sortingScore2) return 1;
  else {
    if (sortingScore1 === 4 || sortingScore1 === 5) {
      const projectStatus1 = project1.status;
      const projectStatus2 = project2.status;

      if (projectStatus1 === "active" && projectStatus2 !== "active") return -1;
      if (projectStatus1 !== "active" && projectStatus2 === "active") return 1;
      if (projectStatus1 === "on hold" && projectStatus2 !== "on hold")
        return -1;
      if (projectStatus1 !== "on hold" && projectStatus2 === "on hold")
        return 1;
    }

    if (sortingScore1 < 6)
      return helper.sortProjectByDateDescending(project1, project2);
  }

  return 0;
};

export const calculateSortingScore = (
  opportunity: StartupOpportunity | LeadOpportunity
): number => {
  if (checkIfLeadOpportunity(opportunity)) return 5;

  const funnelStage = opportunity.project.funnelStage;
  const projectStatus = opportunity.project.status;
  const startupStatus = getStartupStatus(opportunity);

  if (startupStatus === "Adopted") return 1;

  if (["active", "on hold", "adopted"].includes(projectStatus)) {
    switch (startupStatus) {
      case "Selected":
        return 2;
      case "Evaluation":
        return 3;
      case "Ruled Out":
        return 4;
      default:
        break;
    }
  } else if (projectStatus === "archived") {
    if (stageScores[funnelStage] < stageScores["adopt"]) return 6;
    if (startupStatus === "Ruled Out") return 4;
  }
  return 7;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const areObjectsShallowEqual = <T extends Record<string, any>>(
  object1: T,
  object2: T
): boolean => {
  const keys1 = Object.keys(object1);
  const keys2 = Object.keys(object2);
  let areObjectsEqual = true;
  for (const key of keys1) {
    if (typeof object1[key] === "object") continue;
    if (object1[key] !== object2[key]) {
      areObjectsEqual = false;
    }
  }
  for (const key of keys2) {
    if (typeof object2[key] === "object") continue;
    if (object2[key] !== object1[key]) {
      areObjectsEqual = false;
    }
  }
  return areObjectsEqual;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const areObjectsDeepEqual = <T extends Record<string, any>>(
  object1: T,
  object2: T,
  ignoreKeys: string[] = []
): boolean => {
  const keys1 = Object.keys(object1);
  const keys2 = Object.keys(object2);
  if (keys1.length !== keys2.length) return false;

  for (const key of keys1) {
    if (ignoreKeys.includes(key)) continue;
    if (typeof object1[key] === "object") {
      if (!areObjectsDeepEqual(object1[key], object2[key], ignoreKeys)) {
        return false;
      }
    } else if (object1[key] !== object2[key]) {
      return false;
    }
  }
  return true;
};

export const findLogo = (
  files?: File[],
  type: "Logo" | "companyLogo" = "Logo"
): string => {
  const file = files?.find((file: File) => file.type === type);
  return file ? file.url : "";
};

export function getErrorMessage(error: AxiosError): string {
  if (error.response) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const data = error.response.data as any;
    // The request was made and the server responded with a status code that falls out of the range of 2xx
    return data.message || error.response.statusText;
  } else if (error.request) {
    // The request was made but no response was received
    return "No response was received from the server.";
  } else {
    // Something happened in setting up the request that triggered an Error
    return error.message;
  }
}

export function normalizeUrl(
  url: string | null,
  readMode?: boolean,
  includeWWW = true
): string {
  if (!url) {
    return "";
  }

  const hasHttp = url.startsWith("http://") || url.startsWith("https://");
  const http = hasHttp ? url.split("//")[0] + "//" : "http://";

  const strippedUrl = hasHttp ? url.split("//")[1] : url;
  const normalizedUrl = strippedUrl.startsWith("www.")
    ? strippedUrl.split("www.")[1]
    : strippedUrl;

  if (!readMode) {
    const subdomain = includeWWW ? "www." : "";
    return http + subdomain + normalizedUrl;
  }

  return normalizedUrl.endsWith("/")
    ? normalizedUrl.slice(0, -1)
    : normalizedUrl;
}

export const isProjectBmwCheck = (ventureClientId: number): boolean => {
  return ventureClientId === 7;
};

export const delay = (ms: number): Promise<void> =>
  new Promise((resolve) => setTimeout(resolve, ms));

export const formatFunding = (funding: number | string | null): string => {
  if (!funding) return "";

  const fundingNumber =
    typeof funding === "string" ? parseFloat(funding) : funding;
  if (fundingNumber >= 1000) {
    return `${(fundingNumber / 1000).toLocaleString("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })} B`;
  }
  return `${fundingNumber.toLocaleString("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })} M`;
};

export const isBmwUserCheck = (user: User | undefined): boolean | undefined => {
  return (
    user?.roleName === "Admin" ||
    user?.rights?.some(
      (right) =>
        (right.scopeType === "Venture Client" && right.scopeId === 7) ||
        right.ventureClientId === 7
    )
  );
};

export const formatDate = (date: Date): string => {
  if (!date) return "";

  const options = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  } as const;

  return date.toLocaleDateString("de-DE", options);
};

export const nameToInitials = (name?: string): string => {
  const trimmedName = name?.trim();

  if (!trimmedName) return "--";

  const splittedName = trimmedName.split(" ");
  const firstName = splittedName[0];

  if (splittedName.length > 1) {
    const lastName = splittedName[splittedName.length - 1];
    return firstName[0].toLocaleUpperCase() + lastName[0].toLocaleUpperCase();
  } else {
    return firstName[0].toLocaleUpperCase();
  }
};

function hexToRgb(hex: string) {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : {
        r: 0,
        g: 0,
        b: 0,
      };
}

function rgbToHex(r: number, g: number, b: number) {
  return "#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
}

// returns an array of colors between startColor and endColor according to steps
export function getRamp(
  startColor: string,
  endColor: string,
  steps = 4
): string[] {
  const ramp: string[] = [];

  ramp.push(startColor);

  const startColorRgb = hexToRgb(startColor);
  const endColorRgb = hexToRgb(endColor);

  const rInc = Math.round((endColorRgb.r - startColorRgb.r) / (steps + 1));
  const gInc = Math.round((endColorRgb.g - startColorRgb.g) / (steps + 1));
  const bInc = Math.round((endColorRgb.b - startColorRgb.b) / (steps + 1));

  for (let i = 0; i < steps; i++) {
    startColorRgb.r += rInc;
    startColorRgb.g += gInc;
    startColorRgb.b += bInc;

    ramp.push(rgbToHex(startColorRgb.r, startColorRgb.g, startColorRgb.b));
  }
  ramp.push(endColor);

  return ramp;
}

export function getImpactValueByFunnelStage(
  funnelStage: FunnelStage,
  impactValues: ImpactValue[]
): ImpactValue | undefined {
  let stageToMatch = "discover";

  switch (funnelStage) {
    case "discover":
    case "assess":
      stageToMatch = "discover";
      break;
    case "buy":
      stageToMatch = "buy";
      break;
    case "pilot":
    case "adopt":
      stageToMatch = "pilot";
      break;
  }

  return impactValues.find((impactValue) => impactValue.stage === stageToMatch);
}

export function extractClientPrefixFromUrl(url: string): string {
  let clientPrefix = url.split(".")[0].split("-vclms")[0];

  if (
    (!clientPrefix || !clientSpecifications[clientPrefix]) &&
    clientPrefix !== "localhost" &&
    clientPrefix !== "vclos-dev"
  )
    clientPrefix = "27pilots";
  return clientPrefix;
}

export function lightenHexColor(hex: string, amount: number): string {
  if (hex[0] === "#") {
    hex = hex.slice(1);
  }

  const num = parseInt(hex, 16);
  let r = (num >> 16) + amount;
  let g = ((num >> 8) & 0x00ff) + amount;
  let b = (num & 0x0000ff) + amount;

  r = Math.min(255, Math.max(0, r));
  g = Math.min(255, Math.max(0, g));
  b = Math.min(255, Math.max(0, b));

  return `#${((r << 16) | (g << 8) | b).toString(16).padStart(6, "0")}`;
}

export function getStatusColor(status: string): string {
  if (
    status === "active" ||
    status === "adopted" ||
    status === "project launched"
  ) {
    return theme.palette.success.main;
  } else if (status === "on hold") {
    return theme.palette.text.mediumEmphasis;
  } else if (status === "archived") {
    return theme.palette.error.main;
  } else {
    return theme.palette.text.primaryInvert.main;
  }
}

export function dateToYYMMDD(date: Date): string {
  return date.toISOString().replaceAll("-", "").split("T")[0].slice(2);
}

export function trimStageName(stage: string): string {
  switch (stage) {
    case "No Institutional Investment":
      return "No Inst. Investment";
    case "Seed - Accelerator (no equity)":
    case "Seed - Accelerator (equity)":
      return "Seed - Accelerator";
    default:
      return stage;
  }
}

export function getClientSSO():
  | {
      customProvider: string;
      redirectSignIn: string;
      redirectSignOut: string;
    }
  | undefined {
  const clientPrefix = window.location.hostname.split(".")[0];
  const allowedSSOClients = [
    "holcim",
    "vclos-dev",
    "vclos",
    "localhost",
    "equinor",
    "zurich-insurance",
  ];

  if (!allowedSSOClients.includes(clientPrefix)) return;
  switch (clientPrefix) {
    case "zurich-insurance":
      return {
        customProvider: "Zurich",
        redirectSignIn: "https://zurich-insurance.27pilots.com/",
        redirectSignOut: "https://zurich-insurance.27pilots.com/",
      };
    case "holcim":
      return {
        customProvider: "Holcim",
        redirectSignIn: "https://holcim.27pilots.com/",
        redirectSignOut: "https://holcim.27pilots.com/",
      };
    case "equinor":
      return {
        customProvider: "Equinor",
        redirectSignIn: "https://equinor.27pilots.com/",
        redirectSignOut: "https://equinor.27pilots.com/",
      };
    case "vclos-dev":
      return {
        customProvider: "Deloitte",
        redirectSignIn: "https://vclos-dev.27pilots.com/",
        redirectSignOut: "https://vclos-dev.27pilots.com/",
      };
    case "vclos":
      return {
        customProvider: "Deloitte",
        redirectSignIn: "https://vclos.27pilots.com/",
        redirectSignOut: "https://vclos.27pilots.com/",
      };
    case "localhost":
      return {
        customProvider: "Deloitte",
        redirectSignIn: "http://localhost:3000/",
        redirectSignOut: "http://localhost:3000/",
      };
  }
}

export function getDateMonthsAfter(date: Date, numberOfMonths: number): Date {
  const newDate = new Date(date);
  const month = newDate.getMonth();
  newDate.setMonth(newDate.getMonth() + numberOfMonths);
  // if new month isn't 9 months after (e.g. 30 may -> 2 feb instead of 28 feb), set to last day
  const diff = (newDate.getMonth() + 12 - month) % 12;
  if (diff > numberOfMonths) newDate.setDate(0);

  return newDate;
}

export function formatStatusDate(
  date: Date | string | null | undefined,
  status: "on hold" | "archived"
): string {
  if (!date) return "";

  return ` ${status === "on hold" ? "until" : "since"} ${formatDate(
    new Date(date)
  )}`;
}

export function getNameWithDepartmentCode(person?: ClientContactDTO): string {
  if (!person?.name) {
    return "";
  }
  const departmentCode = person.departmentCode
    ? ` (${person.departmentCode})`
    : "";
  return `${person.name}${departmentCode}`;
}

export function roundNumberToMagnitude(number: number): string {
  let magnitude = 1;
  let roundedNumber = number;

  if (number < 1000) magnitude = 1;
  else if (number < 1000000) {
    magnitude = 1000;
  } else if (number < 1000000000) {
    magnitude = 1000000;
  } else {
    magnitude = 1000000000;
  }

  roundedNumber = Math.round((number / magnitude) * 100) / 100;

  return roundedNumber.toLocaleString("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
}

export function numberMagnitude(number: number): string {
  if (number < 1000 || isNaN(number)) return "";
  else if (number < 1000000) return "K";
  else if (number < 1000000000) return "M";
  else return "B";
}

export function compareMeetings(a: Meeting, b: Meeting): number {
  if (a.type === "Kick-off" || b.type === "Conclusion") return -1;
  if (b.type === "Kick-off" || a.type === "Conclusion") return 1;

  if (a.dateStart === b.dateStart) return a.id - b.id;
  if (a.dateStart === null) return 1;
  if (b.dateStart === null) return -1;

  return +new Date(a.dateStart) - +new Date(b.dateStart);
}

export function daysBetweenDates(startDate: Date, endDate: Date): number {
  return Math.ceil((endDate.getTime() - startDate.getTime()) / MS_IN_A_DAY);
}
export const downloadElementAsImage = async (
  element: HTMLDivElement,
  format: "svg" | "png",
  filename = "capture",
  elementsToHide: string[] = []
): Promise<void> => {
  try {
    const filter = (node: HTMLElement) => {
      return !elementsToHide.some((classname) =>
        node.classList?.contains(classname)
      );
    };

    const dataURL = await (async () => {
      const options = { cacheBust: true, filter };

      if (format === "svg") {
        return await toSvg(element, options);
      } else if (format === "png") {
        return await toPng(element, {
          ...options,
          fetchRequestInit: {
            method: "GET",
            cache: "no-cache",
            mode: "cors",
          },
        });
      }

      throw "Unsupported format";
    })();

    // Create a temporary download link
    const link = document.createElement("a");
    link.href = dataURL;
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    return;
  } catch (error) {
    console.error(error);
    throw "Failed to capture element as image: " + error;
  }
};

export function getMeetingTimes(meetings: Meeting[]): {
  kickOff: number | null;
  conclusion: number | null;
  today: number | null;
} {
  const today = new Date(Date.now());
  today.setHours(0, 0, 0, 0);
  const kickOffMeeting = meetings?.find(
    (meeting) => meeting.type === "Kick-off" && !meeting.isInitial
  );
  const conclusionMeeting = meetings?.find(
    (meeting) => meeting.type === "Conclusion" && !meeting.isInitial
  );

  const kickOffMeetingDate = new Date(kickOffMeeting?.dateStart || "");
  kickOffMeetingDate.setHours(0, 0, 0, 0);
  const conclusionMeetingDate = new Date(conclusionMeeting?.dateStart || "");
  conclusionMeetingDate.setHours(0, 0, 0, 0);

  const kickOffTime = kickOffMeetingDate.getTime();
  const conclusionTime = conclusionMeetingDate.getTime();
  const todayTime = today.getTime();

  return {
    kickOff: !isNaN(kickOffTime) ? kickOffTime : null,
    conclusion: !isNaN(conclusionTime) ? conclusionTime : null,
    today: !isNaN(todayTime) ? todayTime : null,
  };
}

export const getFundingDisplayValue = (
  startup: {
    totalFunding: string | number | null;
    lastFunding: string | number | null;
    fundingIsUndisclosed: boolean;
    currentInvestmentStage: string;
  },
  inputName: "totalFunding" | "lastFunding",
  editMode?: boolean,
  editedValue?: string | null
): string => {
  const isTotalFunding = inputName === "totalFunding";
  const value = isTotalFunding ? startup.totalFunding : startup.lastFunding;

  if (
    startup.currentInvestmentStage === "No Institutional Investment" &&
    isTotalFunding
  ) {
    return "0.00";
  }

  if (editMode) {
    return editedValue || "";
  }

  if (startup.fundingIsUndisclosed) {
    return "Funding Undisclosed";
  }

  if (value !== null) {
    return formatFunding(value);
  }

  if (startup.currentInvestmentStage === "No Institutional Investment") {
    return formatFunding(isTotalFunding ? "0.00" : 0);
  }

  return "No Funding Information";
};
