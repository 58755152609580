import { ReactElement } from "react";
import { Box } from "@mui/material";
import { formatDate } from "../../../../utils";
import { InfoItem } from "./ProjectMainInformation";
import { LeadProject } from "../../../../Types/LeadProject";

interface LeadProjectMainInformationProps {
  leadProject: LeadProject;
}

export default function LeadProjectMainInformation(
  props: LeadProjectMainInformationProps
): ReactElement {
  return (
    <Box display="flex" gap={4}>
      <InfoItem label="Company" value={props.leadProject.ventureClient.name} />

      <InfoItem
        label="Created"
        value={formatDate(props.leadProject.dateCreated)}
      />
      <InfoItem
        label="Program Manager"
        value={props.leadProject?.programManager?.name || "--"}
      />
    </Box>
  );
}
