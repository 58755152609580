import { RefObject, useEffect, useRef, useState } from "react";

function useElementHeight(
  dependencies: any[] = []
): [number, RefObject<HTMLElement>] {
  const ref = useRef<HTMLElement | null>(null);
  const [height, setHeight] = useState(0);

  useEffect(() => {
    const updateHeight = () => {
      requestAnimationFrame(() => {
        if (ref.current) {
          setHeight(ref.current.getBoundingClientRect().height);
        }
      });
    };

    updateHeight();
    if (ref.current) {
      const resizeObserver = new ResizeObserver(() => {
        updateHeight();
      });

      resizeObserver.observe(ref.current);

      return () => resizeObserver.disconnect();
    }
  }, [ref.current, ...dependencies]);

  return [height, ref];
}

export default useElementHeight;
