import {
  Dialog,
  DialogTitle,
  Button,
  IconButton,
  Stack,
  Chip,
  Box,
  DialogActions,
} from "@mui/material";
import { Opportunity } from "../../../../../../../../Types/Opportunity";
import { SolutionCluster } from "../../../../../../../../Types/SolutionCluster";
import theme from "../../../../../../../../theme";
import { ReactElement, useState } from "react";
import ManageSolutionClusterModal from "../ManageSolutionClusterModal";

import ClusterCard from "./ClusterCard";
import { Close } from "@mui/icons-material";
import ScrollableDialogContent from "../../../../../../../UI/Modals/ScrollableDialogContent";

interface SolutionClusterOverviewModalProps {
  modalOpen: boolean;
  setModalOpen: (state: boolean) => void;
  solutionClusters: SolutionCluster[];
  opportunities: Opportunity[];
  handleSave: () => void;
  canEdit: boolean;
}

const SolutionClusterOverviewModal = (
  props: SolutionClusterOverviewModalProps
): ReactElement => {
  const [createClusterModalOpen, setCreateClusterModalOpen] = useState(false);

  return (
    <>
      <Dialog
        fullWidth
        open={props.modalOpen}
        onClose={() => props.setModalOpen(false)}
        PaperProps={{
          sx: {
            gap: theme.spacing(4),
          },
        }}
        data-testid="solution-clusters-overview-modal"
      >
        <DialogTitle>
          <Box display="flex" gap={2} alignItems="center">
            Solution Clusters
            <Chip
              variant="counter"
              color="info"
              label={props.solutionClusters.length}
            />
          </Box>
          <IconButton
            onClick={() => props.setModalOpen(false)}
            sx={{ mr: theme.spacing(-1) }}
          >
            <Close htmlColor={theme.palette.icon.primary} />
          </IconButton>
        </DialogTitle>
        <ScrollableDialogContent>
          <Stack gap={7}>
            {props.solutionClusters.map((solutionCluster) => {
              const relatedOpportunities = props.opportunities.filter(
                (opportunity) =>
                  opportunity.solutionClusterId === solutionCluster.id
              );

              const numberOfOpportunities = relatedOpportunities.length;

              const totalFunding = relatedOpportunities
                .reduce((acc, opp) => {
                  return (
                    acc +
                    (opp.startup.totalFunding
                      ? parseFloat(opp.startup.totalFunding)
                      : 0)
                  );
                }, 0)
                .toFixed(2);

              return (
                <ClusterCard
                  key={solutionCluster.id}
                  solutionCluster={solutionCluster}
                  numberOfOpportunities={numberOfOpportunities}
                  totalFunding={totalFunding}
                  handleSave={props.handleSave}
                  canEdit={props.canEdit}
                />
              );
            })}
          </Stack>
        </ScrollableDialogContent>
        {props.canEdit && (
          <DialogActions>
            <Button
              variant="contained"
              onClick={() => setCreateClusterModalOpen(true)}
              sx={{ alignSelf: "flex-end" }}
            >
              Add Cluster
            </Button>
          </DialogActions>
        )}
      </Dialog>
      {createClusterModalOpen && (
        <ManageSolutionClusterModal
          modalOpen={createClusterModalOpen}
          setModalOpen={setCreateClusterModalOpen}
          handleSave={props.handleSave}
        />
      )}
    </>
  );
};

export default SolutionClusterOverviewModal;
