import { Box, Container, Typography, styled } from "@mui/material";
import { useRef, ReactElement } from "react";
import useClient from "../../Hooks/useClient";
import theme from "../../theme";

const isDevelopment = process.env.NODE_ENV === "development";
const baseS3Url = isDevelopment
  ? "https://vclos-frontend.s3.eu-central-1.amazonaws.com"
  : "https://vclos-frontend-prod.s3.eu-central-1.amazonaws.com";

interface PageHeaderProps {
  title: string;
  subtitle: string;
  headerTitleRef?: React.RefObject<HTMLElement>;
  actions?: React.ReactNode;
  dynamicBgImage?: string;
}

const StyledHeader = styled(Box, {
  shouldForwardProp: (prop: string) => !prop.startsWith("$"),
})<{ $backgroundUrl?: string }>(({ $backgroundUrl }) => ({
  padding: theme.spacing(3, 0, 0),
  background: `linear-gradient(to right, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0.5)), 
      url(${$backgroundUrl}) no-repeat top center fixed`,
  backgroundSize: "100% auto",
  position: "sticky",
  top: theme.spacing(-6),
  zIndex: 4,
  justifyContent: "space-between",
  display: "flex",
}));

const StyledHeaderSub = styled(StyledHeader)<{ $top: string }>(({ $top }) => ({
  padding: theme.spacing(1, 0, 3),
  marginBottom: theme.spacing(-1),
  zIndex: 2,
  top: $top,
}));

const HeaderTextContainer = styled(Box)({
  position: "relative",
  display: "flex",
  justifyContent: "space-between",
  width: "100%",
  paddingTop: theme.spacing(13),
});

const SubtitleContainer = styled(HeaderTextContainer)({
  paddingTop: 0,
  paddingBottom: theme.spacing(6),
});

function PageHeader(props: PageHeaderProps): ReactElement {
  const listHeaderRef = useRef<HTMLDivElement>(null);
  const { background: clientBackground, hero } = useClient();

  const backgroundUrl =
    props.dynamicBgImage ??
    hero ??
    clientBackground ??
    baseS3Url + "/hero_banner.png";

  const topValue =
    listHeaderRef.current?.getBoundingClientRect().height !== undefined
      ? `calc(${
          listHeaderRef.current.getBoundingClientRect().height
        }px - ${theme.spacing(16)})`
      : "0px";

  return (
    <>
      <StyledHeader
        id="page-header"
        ref={listHeaderRef}
        $backgroundUrl={backgroundUrl}
      >
        <Container>
          <HeaderTextContainer>
            <Box data-testid="list-header-text" maxWidth={800}>
              <Typography
                variant="h3"
                color="text.primaryInvert.main"
                ref={props.headerTitleRef}
              >
                {props.title}
              </Typography>
            </Box>
            {props.actions && (
              <Box data-testid="header-actions">{props.actions}</Box>
            )}
          </HeaderTextContainer>
        </Container>
      </StyledHeader>

      <StyledHeaderSub $backgroundUrl={backgroundUrl} $top={topValue}>
        <Container>
          <SubtitleContainer data-testid="list-subtitle-text">
            <Typography
              color={theme.palette.text.primaryInvert.main}
              maxWidth={600}
            >
              {props.subtitle}
            </Typography>
          </SubtitleContainer>
        </Container>
      </StyledHeaderSub>
    </>
  );
}

export default PageHeader;
