import { Box } from "@mui/material";
import { ReactElement } from "react";
import { Project } from "../../../../Types/Project";
import ObjectivesSection from "../../SharedComponents/ObjectiveSection/ObjectivesSection";
import ProjectTimeline from "./ProjectTimeline/ProjectTimeline";
import StrategicBenefitsSection from "../../SharedComponents/StrategicBenefitsSection/StrategicBenefitsSection";
import BuyInsights from "./BuyInsights";

interface BuyStageProps {
  projectData: Project;
  handleSaveNoScroll: () => void;
  moveProject: () => void;
  handleChangeTab: (index: number, keepFullWidth?: boolean) => void;
  activeTabs: number[];
  handleRefs: (el: HTMLDivElement | null, index: number) => void;
  fullWidthSectionOpen: boolean;
  setFullWidthSectionOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function BuyStage(props: BuyStageProps): ReactElement {
  return (
    <Box display="flex" flexDirection="column" gap={3} width="100%">
      {props.projectData && (
        <>
          <div ref={(element) => props.handleRefs(element, 0)}>
            <BuyInsights
              project={props.projectData}
              expanded={props.activeTabs.includes(0)}
              onChange={() => props.handleChangeTab(0)}
              handleSave={props.handleSaveNoScroll}
            />
          </div>
          <div ref={(element) => props.handleRefs(element, 1)}>
            <ObjectivesSection
              project={props.projectData}
              handleSave={props.handleSaveNoScroll}
              expanded={props.activeTabs.includes(1)}
              onChange={() => props.handleChangeTab(1)}
            />
          </div>
          <div ref={(element) => props.handleRefs(element, 2)}>
            <ProjectTimeline
              project={{
                ...props.projectData,
                meetings: props.projectData.meetings.filter(
                  (meeting) => meeting.isInitial === true
                ),
                phases: props.projectData.phases.filter(
                  (phase) => phase.isInitial === true
                ),
              }}
              handleSave={props.handleSaveNoScroll}
              expanded={props.activeTabs.includes(2)}
              onChange={() => props.handleChangeTab(2)}
            />
          </div>
          <div ref={(element) => props.handleRefs(element, 3)}>
            <StrategicBenefitsSection
              project={props.projectData}
              expanded={props.activeTabs.includes(3)}
              onChange={() => props.handleChangeTab(3)}
              handleSave={props.handleSaveNoScroll}
            />
          </div>
        </>
      )}
    </Box>
  );
}
