import { LeadOpportunity } from "../../Types/LeadProject";
import { StartupOpportunity } from "../../Types/Opportunity";
import { Startup, StartupDTO } from "../../Types/Startup";
import MapFile from "../File/File.mapper";

export default class StartupMapper {
  public static map(startup: StartupDTO): Startup {
    return {
      ...startup,
      files: startup.files ? MapFile.map(startup.files) : [],
      dateFounded: startup.dateFounded ? new Date(startup.dateFounded) : null,
      lastModifiedDate: startup.lastModifiedDate
        ? new Date(startup.lastModifiedDate)
        : null,
      dateCreated: new Date(startup.dateCreated),
      lastFundingDate: startup.lastFundingDate
        ? new Date(startup.lastFundingDate)
        : null,
      lastPitchbookSync: startup.lastPitchbookSync
        ? new Date(startup.lastPitchbookSync)
        : null,
      ...(startup.opportunities?.length && {
        opportunities: startup.opportunities.map(
          (startupOpportunity: StartupOpportunity) =>
            StartupOpportunityMapper.map(startupOpportunity)
        ),
      }),
      ...(startup.leadOpportunities?.length && {
        leadOpportunities: startup.leadOpportunities.map(
          (leadOpportunity: LeadOpportunity) =>
            LeadOpportunityMapper.map(leadOpportunity)
        ),
      }),
      ...(startup.qualityChecks?.length && {
        qualityChecks: startup.qualityChecks?.map((qualityCheck) => ({
          ...qualityCheck,
          status:
            qualityCheck.startupQualityChecks?.status || qualityCheck.status,
        })),
      }),
      lastQualityCheckDate: startup.lastQualityCheckDate
        ? new Date(startup.lastQualityCheckDate)
        : null,
    };
  }
}

export class StartupOpportunityMapper {
  static map(opportunity: StartupOpportunity): StartupOpportunity {
    return {
      ...opportunity,
      ...(opportunity.project && {
        project: {
          ...opportunity.project,
          lastModifiedDate: opportunity.project.lastModifiedDate
            ? new Date(opportunity.project.lastModifiedDate)
            : null,
          dateCreated: new Date(opportunity.project.dateCreated),
        },
      }),
    };
  }
}

export class LeadOpportunityMapper {
  static map(opportunity: LeadOpportunity): LeadOpportunity {
    return {
      ...opportunity,
      ...(opportunity.leadProject && {
        leadProject: {
          ...opportunity.leadProject,
          lastModifiedDate: opportunity.leadProject.lastModifiedDate
            ? new Date(opportunity.leadProject.lastModifiedDate)
            : null,
          dateCreated: new Date(opportunity.leadProject.dateCreated),
        },
      }),
    };
  }
}
