import {
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  Box,
  styled,
  Stack,
  Typography,
  Grid,
} from "@mui/material";
import { ReactElement, useContext } from "react";
import theme from "../../../../theme";
import ScrollableDialogContent from "../../../UI/Modals/ScrollableDialogContent";
import { Startup } from "../../../../Types/Startup";
import { NewReleasesOutlined } from "@mui/icons-material";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import LogoPlaceholder from "../LogoPlaceholder";
import { findLogo } from "../../../../utils";
import { GlobalLoaderContext } from "../../../../Context/LoaderContext";

const QualityCheckCard = styled(Box)(() => ({
  backgroundColor: theme.palette.warning.light,
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(1),
  borderRadius: theme.shape.radius.minimal,
  gap: theme.spacing(1),
}));

interface Props {
  setModalOpen: (state: boolean) => void;
  handleAddOpportunity: () => void;
  modalOpen: boolean;
  startup: Startup;
}

function NotVentureReadyConfirmationModal({
  setModalOpen,
  handleAddOpportunity,
  modalOpen,
  startup,
}: Props): ReactElement {
  const { globalLoader } = useContext(GlobalLoaderContext);
  return (
    <Dialog
      open={modalOpen}
      maxWidth="xs"
      fullWidth
      data-testid="non-venture-client-ready-confirmation"
      sx={{
        "& .MuiDialog-paper": {
          gap: theme.spacing(4),
        },
      }}
    >
      <DialogTitle>Not Venture Client-Ready</DialogTitle>
      <ScrollableDialogContent>
        <Stack gap={4}>
          <Stack gap={3}>
            <Box gap={2} display="flex" alignItems="center">
              <LogoPlaceholder logoUrl={findLogo(startup.files)} />
              <Typography variant="subtitle2" color="text.mediumEmphasis">
                {startup.name}
              </Typography>
            </Box>
            <Box display="flex" gap={0.5}>
              <NewReleasesOutlined
                color="warning"
                fontSize="small"
                sx={{ width: 16 }}
              />
              <Typography>
                {
                  "The startup you're trying to add does not meet the following required criteria for Venture Client Readiness:"
                }
              </Typography>
            </Box>
          </Stack>
          <Grid container columnSpacing={1} rowSpacing={2}>
            {startup.qualityChecks
              .filter((qualityCheck) => !qualityCheck.status)
              .sort((a, b) => a.sortOrder - b.sortOrder)
              .map((qualityCheck) => (
                <Grid item xs={6} key={qualityCheck.id}>
                  <QualityCheckCard data-testid="failed-quality-check-card">
                    <CancelOutlinedIcon color="warning" fontSize="small" />
                    <Typography variant="body2">{qualityCheck.name}</Typography>
                  </QualityCheckCard>
                </Grid>
              ))}
          </Grid>
          <Stack gap={2.5}>
            <Typography>
              Adding this startup could introduce potential risks or challenges
              to your Venture Client project.
            </Typography>
            <Typography>Do you want to add this startup anyway?</Typography>
          </Stack>
        </Stack>
      </ScrollableDialogContent>
      <DialogActions>
        <Button onClick={() => setModalOpen(false)} color="primary">
          Cancel
        </Button>
        <Button
          onClick={handleAddOpportunity}
          variant="contained"
          disabled={globalLoader}
        >
          Add Anyway
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default NotVentureReadyConfirmationModal;
