import { Box, styled, Typography } from "@mui/material";
import { ReactElement } from "react";
import theme from "../../theme";
import { Flag } from "@mui/icons-material";

export const FlagBannerContainer = styled(Box)(() => ({
  backgroundColor: theme.palette.surface.error.light,
  width: "100%",
  position: "absolute",
  top: 0,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: theme.spacing(1),
  padding: theme.spacing(1, 3),
}));

export default function FlagBanner(): ReactElement {
  return (
    <FlagBannerContainer>
      <Flag color="error" />
      <Typography variant="subtitle2">This project is off track!</Typography>
    </FlagBannerContainer>
  );
}
