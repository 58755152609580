import React, { ReactElement, useEffect } from "react";
import {
  Dialog,
  styled,
  Box,
  IconButton,
  Typography,
  Divider,
  Stack,
  Link,
  Button,
  alpha,
} from "@mui/material";
import LaunchIcon from "@mui/icons-material/Launch";
import CloseIcon from "@mui/icons-material/Close";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import theme from "../../../../theme";
import { Opportunity } from "../../../../Types/Opportunity";
import { RocketLaunchOutlined } from "@mui/icons-material";
import QuickoverViewImgPlaceHolder from "../../../../assets/quickOvervieModal-placeholder.png";
import QualityVerificationIcon from "../../../Startup/DetailsPage/QualityVerification/QualityVerificationIcon";
import {
  getDateMonthsAfter,
  getFundingDisplayValue,
  normalizeUrl,
} from "../../../../utils";
import NumberCard from "../../NumberCard";
import CustomExpendableText from "../../CustomExpendableText";
import HighlightStartup from "../../../ProjectDetails/SharedComponents/SolutionFitMatrixComponents/SolutionFitMatrixTable/HighlightStartup/HighlightStartup";
import { FunnelStage } from "../../../../Types/Project";
import { funnelStages } from "../../../../Constants/FunnelStages";
const NAV_BUTTON_OFFSET = "560px";

const StyledHeaderContainer = styled(Box, {
  shouldForwardProp: (prop: string) => !prop.startsWith("$"),
})<{
  $isSelected: boolean;
}>(({ theme, $isSelected }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: theme.spacing(2, 3),
  color: $isSelected
    ? theme.palette.text.primaryInvert.main
    : theme.palette.text.primary,
  backgroundColor: $isSelected ? theme.palette.surface.action.main : "inherit",
}));

const StickyFooter = styled(Box)(({ theme }) => ({
  position: "sticky",
  bottom: 0,
  zIndex: 10,
  backgroundColor: theme.palette.surface.primary.main,
  padding: theme.spacing(2, 3),
  display: "flex",
  justifyContent: "flex-end",
}));

const StyledImageContainer = styled(Box)(() => ({
  width: "320px",
  overflow: "hidden",
}));

const StyledImage = styled("img")({
  width: "100%",
  objectFit: "contain",
  borderRadius: theme.shape.radius.minimal,
});

const StyledHorizontalContainer = styled(Box)({
  display: "flex",
  width: "100%",
});

const Logo = styled(Box)<{ src: string; alt: string }>(() => ({
  display: "flex",
  overflow: "hidden",
  maxWidth: "%100",
  maxHeight: theme.spacing(5),
  objectFit: "contain",
}));

const NoLogoPlaceholder = styled(Box)(() => ({
  display: "flex",
  height: theme.spacing(8),
  width: theme.spacing(8),
  justifyContent: "center",
  alignItems: "center",
}));

const NavigationButton = styled(IconButton)<{ direction: "left" | "right" }>(
  ({ theme, direction }) => ({
    position: "fixed",
    top: "50%",
    transform: "translateY(-50%)",
    [direction]: `calc(50% - ${NAV_BUTTON_OFFSET})`,
    backgroundColor: theme.palette.surface.action.secondary,
    padding: theme.spacing(2),
  })
);
const PaginationBadge = styled(Box)(({ theme }) => ({
  position: "fixed",
  bottom: "2%",
  left: "50%",
  height: "28px",
  transform: "translateX(-50%)",
  zIndex: 1000,
  backgroundColor: alpha(theme.palette.surface.primary?.invert, 0.32),
  borderRadius: theme.spacing(10),
  padding: theme.spacing(1, 2),
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));

interface StartupQuickOverviewModal {
  handleModalClose: React.Dispatch<React.SetStateAction<boolean>>;
  opportunity: Opportunity;
  opportunityLogo: string | undefined;
  strategicImage: string | undefined;
  handleOpportunityStatusChange: (opportunity: Opportunity) => void;
  isSelectionDisabled: boolean;
  opportunityList: Opportunity[];
  handleNavigateOpportunity: (direction: "next" | "prev") => void;
  viewedFunnelStage: FunnelStage;
  showSelection: boolean;
}
const StartupQuickOverviewModal = ({
  handleModalClose,
  opportunity,
  opportunityLogo,
  strategicImage,
  handleOpportunityStatusChange,
  isSelectionDisabled,
  opportunityList,
  handleNavigateOpportunity,
  viewedFunnelStage,
  showSelection,
}: StartupQuickOverviewModal): ReactElement => {
  const { investors, referenceClients } = opportunity.startup;

  useEffect(() => {
    console.log("showSelection ", showSelection);
  }, [showSelection]);
  useEffect(() => {
    console.log(
      "funnelStages.indexOf(projectFunnelStage) ",
      funnelStages.indexOf(viewedFunnelStage)
    );
  }, [funnelStages.indexOf(viewedFunnelStage)]);

  const handlePrev = () => {
    handleNavigateOpportunity("prev");
  };

  const handleNext = () => {
    handleNavigateOpportunity("next");
  };

  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      if (event.key === "ArrowRight") {
        handleNext();
      } else if (event.key === "ArrowLeft") {
        handlePrev();
      }
    };

    window.addEventListener("keydown", handleKeyPress);

    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, [handleNext, handlePrev]);

  const currentIndex =
    opportunityList.findIndex((op) => op.id === opportunity.id) + 1;
  const totalOpportunitiesCount = opportunityList.length;

  const hasInvestors = investors.length > 0;
  const hasClients = referenceClients.length > 0;
  const hasInvestorOrClient = hasInvestors || hasClients;

  const isQualityChecked = opportunity.startup?.isQualityChecked;
  const expiryDate = opportunity.startup?.lastQualityCheckDate
    ? getDateMonthsAfter(opportunity.startup?.lastQualityCheckDate, 12)
    : new Date();
  const hasExpired =
    (opportunity.startup?.lastQualityCheckDate &&
      isQualityChecked &&
      expiryDate < new Date()) ??
    false;

  const fundingValue = getFundingDisplayValue(
    opportunity.startup,
    "totalFunding"
  );
  const lastFundingValue = getFundingDisplayValue(
    opportunity.startup,
    "lastFunding"
  );

  const isDiscover = viewedFunnelStage === "discover";
  const isAssess = viewedFunnelStage === "assess";

  const isSelected = isDiscover
    ? opportunity.isQualified
    : showSelection && opportunity.isSelectedForPilot;

  return (
    <>
      <Dialog
        open
        data-testid="startup-quick-overview-modal"
        fullWidth
        maxWidth="md"
        onClose={() => handleModalClose(false)}
        sx={{
          "& .MuiDialog-paper": {
            padding: 0,
            maxHeight: "calc(100% - 128px);",
          },
        }}
      >
        <Box>
          <NavigationButton
            data-testid="navigate-prev-btn"
            direction="left"
            onClick={handlePrev}
          >
            <ArrowBackIosNewIcon
              sx={{ color: theme.palette.icon.action.main }}
            />
          </NavigationButton>
          <NavigationButton
            data-testid="navigate-next-btn"
            direction="right"
            onClick={handleNext}
          >
            <ArrowForwardIosIcon
              sx={{ color: theme.palette.icon.action.main }}
            />
          </NavigationButton>
          <Stack>
            <Box
              sx={{
                position: "sticky",
                top: 0,
                zIndex: 10,
                backgroundColor: theme.palette.surface.primary.main,
              }}
            >
              <StyledHeaderContainer $isSelected={isSelected}>
                <Box display="flex" alignItems="center">
                  {isDiscover && (
                    <HighlightStartup
                      color={
                        opportunity.isQualified
                          ? theme.palette.surface.primary.main
                          : theme.palette.surface.primary.invert
                      }
                      opportunity={opportunity}
                      handleOpportunityStatusChange={() =>
                        handleOpportunityStatusChange(opportunity)
                      }
                      isSelectionDisabled={isSelectionDisabled}
                      checked={opportunity.isQualified}
                    />
                  )}
                  {showSelection && isAssess && (
                    <HighlightStartup
                      color={
                        opportunity.isSelectedForPilot
                          ? theme.palette.surface.primary.main
                          : theme.palette.surface.primary.invert
                      }
                      opportunity={opportunity}
                      handleOpportunityStatusChange={() =>
                        handleOpportunityStatusChange(opportunity)
                      }
                      isSelectionDisabled={isSelectionDisabled}
                      checked={opportunity.isSelectedForPilot}
                    />
                  )}
                  <Typography
                    variant="subtitle1"
                    color={showSelection ? "inherit" : "black"}
                  >
                    {opportunity.startup.name}
                  </Typography>
                </Box>
                <IconButton
                  data-testid="close-modal-btn"
                  onClick={() => handleModalClose(false)}
                >
                  <CloseIcon
                    sx={{
                      color: isSelected
                        ? theme.palette.text.primaryInvert.main
                        : theme.palette.text.primary,
                    }}
                  />
                </IconButton>
              </StyledHeaderContainer>

              <Divider sx={{ position: "sticky", top: "48px", zIndex: 10 }} />
            </Box>

            <Stack gap={3} marginTop={3} padding={theme.spacing(0, 3)}>
              <StyledHorizontalContainer>
                <Stack
                  direction="row"
                  gap={4}
                  justifyContent="space-between"
                  alignItems="flex-start"
                  width="100%"
                >
                  <Stack gap={2} flex={1}>
                    <Box display="flex" alignItems="center" gap={1}>
                      {opportunityLogo ? (
                        <Logo
                          component="img"
                          src={opportunityLogo}
                          alt="Startup Logo"
                          bgcolor="background.paper"
                        />
                      ) : (
                        <NoLogoPlaceholder>
                          <RocketLaunchOutlined
                            sx={{
                              fontSize: "48px",
                            }}
                            color="disabled"
                          />
                        </NoLogoPlaceholder>
                      )}
                    </Box>
                    <Box display="flex" gap={1} alignItems="center">
                      <Typography
                        variant="h3"
                        color={isAssess ? "black" : "inherit"}
                      >
                        {opportunity.startup.name}
                      </Typography>
                      <QualityVerificationIcon
                        lastQualityCheckBy={
                          opportunity.startup.lastQualityCheckBy
                        }
                        lastQualityCheckDate={
                          opportunity.startup.lastQualityCheckDate
                        }
                        isQualityChecked={isQualityChecked}
                        failedQualityChecks={opportunity.startup.qualityChecks?.filter(
                          (qc) => qc.status === false
                        )}
                        hasExpired={hasExpired}
                      />
                    </Box>
                    {opportunity.startup.shortDescription && (
                      <Typography variant="subtitle2">
                        {opportunity.startup.shortDescription}
                      </Typography>
                    )}

                    {opportunity.startup.description && (
                      <Typography>{opportunity.startup.description}</Typography>
                    )}

                    <Box display="flex" justifyContent="space-between">
                      <Box
                        display="flex"
                        gap={1}
                        color={theme.palette.text.mediumEmphasis}
                      >
                        <Typography>
                          {opportunity.startup.dateFounded?.getFullYear() ||
                            "-"}
                        </Typography>
                        <Box display="flex" gap={1}>
                          <Typography variant="subtitle2">
                            {opportunity.startup.billingCity &&
                              opportunity.startup.billingCity}
                          </Typography>
                          <Typography variant="subtitle2">
                            {opportunity.startup.billingCountry &&
                              opportunity.startup.billingCountry}
                          </Typography>
                        </Box>
                      </Box>

                      {opportunity.startup.employees !== "Undisclosed" && (
                        <Stack direction="row" gap={1}>
                          <Typography>
                            {opportunity.startup.employees}
                          </Typography>
                          <Typography
                            variant="subtitle2"
                            color={theme.palette.text.mediumEmphasis}
                          >
                            Employees
                          </Typography>
                        </Stack>
                      )}
                      {opportunity.startup.website && (
                        <Link
                          onClick={(event) => event.stopPropagation()}
                          target="_blank"
                          href={normalizeUrl(opportunity.startup.website)}
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: theme.spacing(1),
                          }}
                        >
                          <LaunchIcon
                            htmlColor={theme.palette.icon.action.main}
                            sx={{ fontSize: "20px" }}
                          />
                          <Typography>
                            {normalizeUrl(
                              opportunity.startup.website,
                              true
                            ).replace(/^www\./, "")}
                          </Typography>
                        </Link>
                      )}
                    </Box>
                  </Stack>

                  <StyledImageContainer>
                    <StyledImage
                      src={strategicImage ?? QuickoverViewImgPlaceHolder}
                      alt="Startup Image"
                    />
                  </StyledImageContainer>
                </Stack>
              </StyledHorizontalContainer>

              <StyledHorizontalContainer gap={theme.spacing(2)}>
                <NumberCard
                  label="Total Funding"
                  currency="$"
                  value={fundingValue}
                  cardSize="big"
                  fullWidth
                />
                <NumberCard
                  label="Funding Stage"
                  value={opportunity.startup.currentInvestmentStage}
                  cardSize="big"
                  hideCurrency
                  fullWidth
                />
                <NumberCard
                  label="Last Funding"
                  currency="$"
                  disabled={!!opportunity.startup.fundingIsUndisclosed}
                  value={lastFundingValue}
                  hideCurrency={
                    !opportunity.startup.lastFunding ||
                    opportunity.startup.fundingIsUndisclosed
                  }
                  cardSize="big"
                  fullWidth
                />
              </StyledHorizontalContainer>
              {hasInvestorOrClient && (
                <StyledHorizontalContainer flexDirection="column" gap={3}>
                  {hasInvestors && (
                    <Stack gap={1}>
                      <Typography variant="subtitle1">Investors</Typography>
                      <CustomExpendableText
                        maxLines={1}
                        text={
                          <Box>
                            {investors.map((investor) => (
                              <Typography
                                component="span"
                                key={investor.id}
                                mr={2}
                                variant="subtitle2"
                              >
                                {investor.name}
                              </Typography>
                            ))}
                          </Box>
                        }
                      />
                    </Stack>
                  )}
                  {hasClients && (
                    <Stack gap={1}>
                      <Typography variant="subtitle1">
                        Reference Clients
                      </Typography>
                      <CustomExpendableText
                        maxLines={1}
                        text={
                          <Box>
                            {referenceClients.map((client) => (
                              <Typography
                                component="span"
                                key={client.id}
                                mr={2}
                                variant="subtitle2"
                              >
                                {client.name}
                              </Typography>
                            ))}
                          </Box>
                        }
                      />
                    </Stack>
                  )}
                </StyledHorizontalContainer>
              )}
            </Stack>
          </Stack>
          <StickyFooter>
            <Button
              data-testid="view-details-btn"
              variant="text"
              sx={{
                backgroundColor: theme.palette.surface.action.secondary,
                width: "128px",
              }}
              target="_blank"
              href={`/startups/${opportunity.startup.id}`}
            >
              View Details
            </Button>
          </StickyFooter>
        </Box>

        <PaginationBadge>
          <Typography
            component="span"
            variant="subtitle2"
            color={theme.palette.text.primaryInvert.main}
            mr={0.5}
          >
            {currentIndex}
          </Typography>
          <Typography
            component="span"
            color={theme.palette.text.primaryInvert.mediumEmphasis}
          >
            / {totalOpportunitiesCount}
          </Typography>
        </PaginationBadge>
      </Dialog>
    </>
  );
};

export default StartupQuickOverviewModal;
