import styled from "@emotion/styled";
import { TooltipProps, Tooltip, tooltipClasses } from "@mui/material";
import { ReactElement } from "react";
import GrainIcon from "@mui/icons-material/Grain";
import UndoIcon from "@mui/icons-material/Undo";
import theme from "../../theme";

const AutoGenerateToolTip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} placement="top" classes={{ popper: className }}></Tooltip>
))({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "white",
    color: "black",
    translate: "0 10px",
    padding: theme.spacing(1),
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.3)",
    textAlign: "center",
  },
});

const AutoGenerateButton = styled("button")<{ $isGenerating: boolean }>(
  ({ $isGenerating }) => ({
    backgroundColor: "white",
    width: "30px",
    height: "30px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "2px",
    border: 0,
    outline: 0,
    padding: 0,
    "&:hover": {
      backgroundColor: "#e6e6e6",
      cursor: "pointer",
    },
    pointerEvents: $isGenerating ? "none" : "initial",
  })
);

interface AutoGenerateTextButtonProps {
  isGenerating: boolean;
  isGenerated: boolean;
  handleRevertModal: (open: boolean) => void;
  generateText: () => void;
  toolTipText: string | JSX.Element;
}

const AutoGenerateTextButton = (
  props: AutoGenerateTextButtonProps
): ReactElement => {
  const {
    isGenerating = false,
    isGenerated = false,
    handleRevertModal,
    generateText,
  } = props;

  return (
    <>
      <AutoGenerateToolTip
        title={
          props.isGenerated ? (
            <span>Revert to original input</span>
          ) : (
            props.toolTipText
          )
        }
      >
        <AutoGenerateButton
          data-testid={
            isGenerated ? "revert-button" : "auto-generate-text-button"
          }
          className="auto-generate-text-button"
          onClick={(e) => {
            e.preventDefault();
            props.isGenerated ? handleRevertModal(true) : generateText();
          }}
          $isGenerating={isGenerating}
        >
          {isGenerated ? <UndoIcon /> : <GrainIcon />}
        </AutoGenerateButton>
      </AutoGenerateToolTip>
    </>
  );
};

export default AutoGenerateTextButton;
