import {
  ReactElement,
  useState,
  useEffect,
  useRef,
  useMemo,
  useContext,
} from "react";
import {
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
  styled,
  Typography,
  IconButton,
} from "@mui/material";
import {
  compareOpportunitiesByRelevance,
  delay,
  findLogo,
} from "../../../../../utils";
import { Requirement } from "../../../../../Types/Requirement";
import { FunnelStage, Project } from "../../../../../Types/Project";
import { SolutionCluster } from "../../../../../Types/SolutionCluster";
import {
  CreateOpportunityDTO,
  Opportunity,
  OpportunityRating as OpportunityRatingType,
  RejectionReason,
} from "../../../../../Types/Opportunity";
import OpportunityHttpService from "../../../../../Http/Opportunity/Opportunity.Http.service";
import { useSnackbar } from "notistack";
import RequirementStartupFitHttpService from "../../../../../Http/RequirementStartupFit/RequirementStartupFit.http.service";
import { ProjectHttpService } from "../../../../../Http/Project/Project.http.service";
import { RequirementStartupFit } from "../../../../../Types/RequirementStartupFit";
import SolutionFitMatrixOpportunity from "./SolutionFitMatrixOpportunity/SolutionFitMatrixOpportunity";
import { AddStartup } from "./AddStartup/AddStartup";
import AddIcon from "@mui/icons-material/Add";
import { Flipper } from "react-flip-toolkit";
import theme from "../../../../../theme";
import usePitchbookSync from "../../../../../Hooks/usePitchbookSync";
import SolutionFitMatrixVerticalHeader from "./SolutionFitMatrixVerticalHeader/SolutionFitMatrixVerticalHeader";
import { OpportunitiesLoadingContext } from "../../../../../Context/OpportunitiesLoaderContext";
import SkeletonWrapper from "../../../../UI/SkeletonWrapper";
import useRoles from "../../../../../Hooks/useRoles";

export const StyledTableContainer = styled(TableContainer)(() => ({
  boxShadow: "none",
  overflowX: "unset",
  width: "auto",
}));

export const StyledTable = styled(Table)(() => ({
  border: "none",
  "& .highlighted-selection td:first-of-type": {
    backgroundColor: theme.palette.surface.action.main,
  },
  "& .highlighted-selection.selected-for-pilot": {
    borderTop: "none",
    "& td:first-of-type": {
      borderRight: "none",
    },
  },

  "& td.startup-name, & td:first-of-type": {
    backgroundColor: theme.palette.surface.tertiary.dark,
    color: theme.palette.common.white,
    display: "flex",
    alignItems: "center",
    position: "sticky",
    padding: `${theme.spacing(2)} ${theme.spacing(1.75)}`,
    fontSize: theme.typography.htmlFontSize,
    fontWeight: "500",
    "& a": {
      color: theme.palette.common.white,
    },
  },
  "& tr.empty-row": {
    width: "100%",
    "& td": {
      width: "100%",
    },
    "& .text-container": {
      position: "relative",
      margin: "auto",
      top: "40%",
      maxWidth: "260px",
      textAlign: "center",
      border: "none",
    },
  },
  "& tr.add-startup-button": {
    backgroundColor: theme.palette.surface.action.secondary,
    borderRadius: `0 ${theme.shape.radius.minimal} ${theme.shape.radius.minimal} 0`,
    boxShadow: theme.boxShadows[1],
    width: theme.spacing(3),
    padding: theme.spacing(1),
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "sticky",
    right: "-1px",
    cursor: "pointer",
    td: {
      backgroundColor: "transparent",
      padding: 0,
      borderRight: "none",
    },
  },
  "& tr.opportunity-column:last-of-type td:first-of-type": {
    borderRadius: `0 ${theme.shape.radius.minimal} 0 0`,
  },
  "& tr.requirements:hover ": {
    position: "relative",
  },
  "& tr.requirements:hover:not(.opened-comments):last-of-type::after": {
    borderRadius: `0 ${theme.shape.radius.minimal} 0 0`,
  },
  "& tr.opportunity-column.requirements:nth-last-of-type(2)::after": {
    content: "''",
    borderRadius: `0 ${theme.shape.radius.minimal} 0 0`,
  },
  "& tr.requirements:hover + .requirement-comment-indicator": {
    zIndex: "2",
    display: "block",
    "& td": { borderRight: "none" },
    "& svg": {
      display: "block",
    },
  },
  "& .opportunity-column:hover + .requirement-comment": {
    display: "inline",
  },
  "& tr:first-of-type.head": {
    width: "330px",
    backgroundColor: theme.palette.common.white,
    "& td": {
      width: "330px",
    },
  },
  "& tr": {
    width: "288px",
  },
  "& tr.comment-column": {
    width: "460px",
    "& .no-requirement-cell": {
      background: theme.palette.surface.secondary.light,
    },
  },
  "& tr.add-startup-column": {
    width: "370px",
  },
  "& tr.highlighted-selection+ tr.comment-column": {
    "& td:first-of-type": {
      backgroundColor: theme.palette.surface.action.main,
    },
  },
  "tr.highlighted-selection.selected-for-pilot + tr.comment-column": {
    height: "fit-content",
  },

  "& tr.requirement-comment-indicator": {
    display: "none",
    width: 0,
    "& td": {
      padding: 0,
      height: "100%",
    },
  },

  "& td.selection-reason-divider": {
    height: theme.spacing(3),
    padding: 0,
  },
  "& td.selection-reason-comment": {
    background: theme.palette.surface.secondary.light,
  },
  "& td.assign-cluster,  .rating-cell": {
    padding: 0,
  },
  "& td.selection-rationale-Title": {
    padding: `0 ${theme.spacing(2)}`,
  },
  "& td.no-requirement-cell": {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
    gap: theme.spacing(2),
    padding: `0 ${theme.spacing(2)}`,
    background: "none",
    color: "black",
    height: theme.spacing(11),
  },
  "& td.requirement-cell": {
    height: theme.spacing(11),
    padding: `0 ${theme.spacing(2)}`,
    alignItems: "center",
    "& svg": {
      height: theme.spacing(2.5),
      width: theme.spacing(2.5),
    },
    "& svg.semi-filled-icon": {
      height: 0,
      width: 0,
    },
  },

  "& td": {
    borderColor: theme.palette.borderOutline.soft,
    borderRight: `solid 1px ${theme.palette.borderOutline.soft}`,
    height: theme.spacing(7),
    alignItems: "center",
    display: "grid",
  },
  "& td.startup-description-short": {
    height: theme.spacing(21),
    alignItems: "start",
  },
  "& td.startup-description-long": {
    height: theme.spacing(28),
    alignItems: "start",
  },
  "& .opened-comments td:not(.startup-name)": {
    backgroundColor: theme.palette.surface.secondary.light,
  },
  "& .opened-comments td.startup-name": {
    borderWidth: "1px 0px 1px 1px",
  },
  "& .startup-link-logo": {
    display: "flex",
    alignItems: "center",
    height: theme.spacing(8),
    "& img, .MuiSvgIcon-root": {
      width: "auto",
      maxWidth: theme.spacing(10),
      maxHeight: theme.spacing(5),
    },
  },
}));

export const StyledFlipper = styled(Flipper)(() => ({
  display: "flex",
}));

const createMockOpportunity = (id: number): Opportunity => ({
  id,
  projectId: -1768,
  startupId: -24700 + id,
  isQualified: false,
  isSelectedForPilot: false,
  insights: "",
  rating: "A",
  solutionClusterId: null,
  assessmentDecision: "",
  inAssessmentConclusion: undefined,
  recommendedForDemo: false,
  rejectionDescription: "",
  startup: {
    id: 24700 + id,
    name: "Loading...",
    shortDescription: "",
    description: "",
    currentInvestmentStage: "",
    fundingIsUndisclosed: false,
    totalFunding: "",
    lastFunding: 0,
    dateFounded: null,
    employees: "",
    website: "",
    billingCountry: "",
    billingCity: "",
    lastQualityCheckDate: null,
    isQualityChecked: null,
    investors: [],
    referenceClients: [],
    qualityChecks: [],
    lastQualityCheckBy: null,
    eloRating: {
      value: 0,
      startupId: 0,
      lastUpdated: new Date(),
    },
    files: [],
    mainContact: null,
    additionalContacts: null,
    opportunities: [],
    leadOpportunities: [],
  },
  requirementStartupFits: [],
  rejectionReasons: [],
  files: [],
  productDemos: [],
});

const mockOpportunities = Array.from({ length: 5 }, (_, i) =>
  createMockOpportunity(i)
);

interface SolutionFitMatrixTableProps {
  project: Project;
  opportunities: Opportunity[];
  requirements: Requirement[];
  clusters: SolutionCluster[];
  detailsView: boolean;
  handleSave: () => void;
  isAddingStartup: boolean;
  setIsAddingStartup: (state: boolean) => void;
  projectFunnelStage: FunnelStage;
  currentProjectStage: FunnelStage;
  ventureClientId: number;
  children?: ReactElement;
}

export default function SolutionFitMatrixTable(
  props: SolutionFitMatrixTableProps
): ReactElement {
  const { canEdit } = useRoles(props.project);
  const { enqueueSnackbar } = useSnackbar();
  const { opportunitiesLoading } = useContext(OpportunitiesLoadingContext);

  const [opportunities, setOpportunities] = useState<Opportunity[]>([]);
  const [expandedCommentsOpportunity, setExpandedCommentsOpportunity] =
    useState<number | null>(null);
  const [editingOpportunity, setEditingOpportunity] = useState<string | null>(
    null
  );
  const ref = useRef<null | HTMLTableSectionElement>(null);

  const { syncStartup } = usePitchbookSync();

  const isAssessStage = props.projectFunnelStage === "assess";

  const refreshHighlightOpportunities = (): number[] => {
    const highlight = props.opportunities.filter(
      (opportunity) =>
        (opportunity.isQualified && props.projectFunnelStage === "discover") ||
        (opportunity.isSelectedForPilot && isAssessStage)
    );
    return highlight.map((opportunity) => opportunity.id);
  };

  const [highlightedOpportunities, setHighlightedOpportunities] = useState<
    number[]
  >(refreshHighlightOpportunities());

  const updateOpportunities = async (
    updatedOpportunities: Opportunity[],
    selectedOpportunityIds: number[]
  ) => {
    try {
      await Promise.all(
        selectedOpportunityIds.map(async (opportunityId) => {
          const updatedOpportunity = updatedOpportunities.find(
            (opp) => opp.id === opportunityId
          );
          if (updatedOpportunity) {
            await OpportunityHttpService.updateOpportunity(
              updatedOpportunity,
              opportunityId
            );
          }
        })
      );
      setOpportunities(updatedOpportunities);
      await delay(500);
      props.handleSave();
    } catch (error) {
      enqueueSnackbar("Something went wrong while updating opportunity", {
        variant: "error",
      });
    }
  };

  const handleCreateOpportunity = async (
    createdOpportunity: CreateOpportunityDTO
  ) => {
    try {
      delete createdOpportunity.id;
      setOpportunities([...opportunities, createdOpportunity as Opportunity]);
      await OpportunityHttpService.createOpportunity(createdOpportunity);
      await syncStartup(createdOpportunity.startupId);
      props.handleSave();
    } catch (error) {
      enqueueSnackbar("Something went wrong while creating opportunity", {
        variant: "error",
      });
    }
  };

  const handleRequirementChange = async (
    selectedOpportunity: Opportunity,
    updatedRequirementId: number,
    updatedStatus: string
  ) => {
    const reqFitToBeUpdated = selectedOpportunity.requirementStartupFits.find(
      (requirement) => requirement.requirementId === updatedRequirementId
    ) as RequirementStartupFit;

    const updatedOpportunities = opportunities.map((opportunity) =>
      opportunity.id === selectedOpportunity.id
        ? {
            ...opportunity,
            requirementStartupFits:
              selectedOpportunity.requirementStartupFits.map((fit) =>
                fit.id === updatedRequirementId
                  ? { ...fit, status: updatedStatus }
                  : fit
              ),
          }
        : opportunity
    );

    setOpportunities(updatedOpportunities);

    try {
      await RequirementStartupFitHttpService.updateById({
        id: reqFitToBeUpdated.id,
        status: updatedStatus,
      });
      await ProjectHttpService.updateProject({
        id: props.project.id,
      } as Project);
      await delay(500);
      props.handleSave();
    } catch (error) {
      enqueueSnackbar(
        "Something went wrong while updating requirement startup fits",
        {
          variant: "error",
        }
      );
    }
  };

  const handleRequirementCommentChange = async (
    selectedOpportunity: Opportunity,
    updatedRequirementId: number,
    updatedComment: string
  ) => {
    const reqFitToBeUpdated = selectedOpportunity.requirementStartupFits.find(
      (requirement) => requirement.requirementId === updatedRequirementId
    ) as RequirementStartupFit;

    const updatedOpportunities = opportunities.map((opportunity) =>
      opportunity.id === selectedOpportunity.id
        ? {
            ...opportunity,
            requirementStartupFits:
              selectedOpportunity.requirementStartupFits.map((fit) =>
                fit.id === updatedRequirementId
                  ? { ...fit, fit: updatedComment }
                  : fit
              ),
          }
        : opportunity
    );

    setOpportunities(updatedOpportunities);

    try {
      await RequirementStartupFitHttpService.updateById({
        id: reqFitToBeUpdated.id,
        fit: updatedComment,
      });
      await ProjectHttpService.updateProject({
        id: props.project.id,
      } as Project);
      await delay(500);
      props.handleSave();
    } catch (error) {
      enqueueSnackbar(
        "Something went wrong while updating requirement startup fits",
        {
          variant: "error",
        }
      );
    }
  };

  const handleRatingChange = async (
    selectedOpportunity: Opportunity,
    selectedRating: OpportunityRatingType
  ) => {
    const updatedOpportunities = opportunities.map((opportunity) =>
      opportunity.id === selectedOpportunity.id
        ? { ...opportunity, rating: selectedRating }
        : opportunity
    );
    updateOpportunities(updatedOpportunities, [selectedOpportunity.id]);
  };

  const handleChangeCluster = async (
    selectedOpportunity: Opportunity,
    selectedClusterId: number
  ) => {
    const updatedOpportunities = opportunities.map((opportunity) =>
      opportunity.id === selectedOpportunity.id
        ? { ...opportunity, solutionClusterId: selectedClusterId }
        : opportunity
    );
    updateOpportunities(updatedOpportunities, [selectedOpportunity.id]);
  };

  const handleRejectionReasonChange = async (
    selectedOpportunity: Opportunity,
    updatedRejectionReasons: RejectionReason[],
    updatedDescription: string
  ) => {
    const updatedOpportunities = opportunities.map((opportunity) =>
      opportunity.id === selectedOpportunity.id
        ? {
            ...opportunity,
            rejectionReasons: updatedRejectionReasons,
            rejectionDescription: updatedDescription,
          }
        : opportunity
    );
    updateOpportunities(updatedOpportunities, [selectedOpportunity.id]);
  };

  const handleChangeRecommended = async (
    selectedOpportunity: Opportunity,
    recommended: boolean
  ) => {
    try {
      await OpportunityHttpService.updateOpportunity(
        { ...selectedOpportunity, recommendedForDemo: recommended },
        selectedOpportunity.id
      );
      props.handleSave();
    } catch (error) {
      enqueueSnackbar("Something went wrong while updating opportunity", {
        variant: "error",
      });
    }
  };

  const handleInsightsUpdate = async (
    selectedOpportunity: Opportunity,
    updatedInsights: string
  ) => {
    const updatedOpportunities = opportunities.map((opportunity) =>
      opportunity.id === selectedOpportunity.id
        ? { ...opportunity, insights: updatedInsights }
        : opportunity
    );
    updateOpportunities(updatedOpportunities, [selectedOpportunity.id]);
  };

  const handleOpportunityStatusChange = async (
    selectedOpportunity: Opportunity
  ) => {
    const opportunitiesToUpdate: number[] = [];
    const statusField =
      props.projectFunnelStage === "discover"
        ? "isQualified"
        : "isSelectedForPilot";

    let unselectedOpportunityId: number | undefined = undefined;

    if (isAssessStage) {
      unselectedOpportunityId = opportunities.find(
        (opportunity) => opportunity.isSelectedForPilot
      )?.id;
    }

    const updatedOpportunities = opportunities.map((opportunity) =>
      opportunity.id === selectedOpportunity.id
        ? { ...opportunity, [statusField]: !opportunity[statusField] }
        : opportunity.id === unselectedOpportunityId
        ? {
            ...opportunity,
            isSelectedForPilot: false,
          }
        : opportunity
    );

    opportunitiesToUpdate.push(selectedOpportunity.id);

    if (unselectedOpportunityId) {
      opportunitiesToUpdate.push(unselectedOpportunityId);
    }
    updateOpportunities(updatedOpportunities, opportunitiesToUpdate);
  };

  const toggleComment = (opportunityId: number) => {
    setExpandedCommentsOpportunity(
      expandedCommentsOpportunity === opportunityId ? null : opportunityId
    );
  };
  const scrollToAddStartup = () => {
    if (ref.current) {
      const lastChildElement = ref.current?.lastElementChild;
      lastChildElement?.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
      });
    }
  };

  useEffect(() => {
    if (props.isAddingStartup) {
      scrollToAddStartup();
    }
  }, [props.isAddingStartup]);

  useEffect(() => {
    setHighlightedOpportunities(refreshHighlightOpportunities());
    setOpportunities(props.opportunities.sort(compareOpportunitiesByRelevance));
  }, [props.opportunities]);

  // Calculate a unique flipKey based on opportunity IDs to trigger animations when opportunities are reordered.
  const flipKey = useMemo(() => {
    return opportunities.reduce((acc, opportunity) => {
      return acc + opportunity.id;
    }, "");
  }, [opportunities]);

  const isSelectionReasonVisible: boolean =
    props.opportunities.find((opportunity) => opportunity.isQualified) !==
      undefined && props.projectFunnelStage == "discover";

  const topContendersIds = (() => {
    const sortedOpportunities = [...opportunities].sort(
      (a, b) => +b.startup.eloRating.value - +a.startup.eloRating.value
    );

    let topContenders = [];

    if (sortedOpportunities.length >= 12) {
      topContenders = sortedOpportunities.slice(0, 3);
    } else if (sortedOpportunities.length >= 6) {
      topContenders = sortedOpportunities.slice(0, 2);
    } else {
      topContenders = sortedOpportunities.slice(0, 1);
    }

    return topContenders.map((opportunity) => opportunity.id);
  })();

  const displayedOpportunities = opportunitiesLoading
    ? mockOpportunities
    : opportunities;

  return (
    <StyledFlipper className="flipper" flipKey={flipKey}>
      <SolutionFitMatrixVerticalHeader
        project={props.project}
        requirements={props.requirements}
        detailsView={props.detailsView}
        isAssessStage={isAssessStage}
        isSelectionReasonVisible={isSelectionReasonVisible}
        handleSave={props.handleSave}
      />
      <StyledTableContainer>
        <StyledTable>
          <TableBody ref={ref} sx={{ display: "flex" }}>
            {displayedOpportunities.length
              ? displayedOpportunities.map((opportunity) => {
                  return (
                    <SolutionFitMatrixOpportunity
                      key={`opportunity-column-${opportunity.id}`}
                      ventureClientId={props.ventureClientId}
                      opportunity={opportunity}
                      opportunityLogo={
                        opportunity.startup.files
                          ? findLogo(opportunity.startup.files)
                          : null
                      }
                      isSelectionReasonVisible={isSelectionReasonVisible}
                      isTopContender={topContendersIds.includes(opportunity.id)}
                      projectFunnelStage={props.projectFunnelStage}
                      currentProjectStage={props.currentProjectStage}
                      opportunityList={props.opportunities}
                      detailsView={props.detailsView}
                      clusters={props.clusters}
                      requirements={props.requirements}
                      expandedCommentsOpportunity={expandedCommentsOpportunity}
                      highlightedOpportunities={highlightedOpportunities}
                      toggleComment={() => {
                        toggleComment(opportunity.id);
                      }}
                      isAddingStartup={props.isAddingStartup}
                      editingOpportunity={editingOpportunity}
                      setEditingOpportunity={(id) => setEditingOpportunity(id)}
                      handleRequirementCommentChange={(
                        selectedOpportunity: Opportunity,
                        updatedRequirementId: number,
                        updatedComment: string
                      ) =>
                        handleRequirementCommentChange(
                          selectedOpportunity,
                          updatedRequirementId,
                          updatedComment
                        )
                      }
                      handleRejectionReasonChange={(
                        updatedRejectionReasons: RejectionReason[],
                        updatedDescription: string
                      ) =>
                        handleRejectionReasonChange(
                          opportunity,
                          updatedRejectionReasons,
                          updatedDescription
                        )
                      }
                      handleSave={props.handleSave}
                      handleRatingChange={(option: OpportunityRatingType) =>
                        handleRatingChange(opportunity, option)
                      }
                      handleOpportunityStatusChange={
                        handleOpportunityStatusChange
                      }
                      handleInsightsUpdate={(updatedInsights: string) =>
                        handleInsightsUpdate(opportunity, updatedInsights)
                      }
                      handleChangeCluster={(cluster: number) =>
                        handleChangeCluster(opportunity, cluster)
                      }
                      handleChangeRecommended={(recommended: boolean) =>
                        handleChangeRecommended(opportunity, recommended)
                      }
                      handleRequirementChange={(
                        updatedRequirementId: number,
                        updatedStatus: string
                      ) =>
                        handleRequirementChange(
                          opportunity,
                          updatedRequirementId,
                          updatedStatus
                        )
                      }
                      project={props.project}
                    />
                  );
                })
              : !props.isAddingStartup && (
                  <TableRow key="empty-row" className="empty-row">
                    <TableCell></TableCell>
                    <TableCell className="text-container">
                      <SkeletonWrapper
                        width={0}
                        isLoading={opportunitiesLoading}
                      >
                        <Typography variant="body1">
                          There are no startups yet.
                          {canEdit ? (
                            <>
                              <br /> Go ahead and add the first one.
                            </>
                          ) : null}
                        </Typography>
                      </SkeletonWrapper>
                      {!opportunitiesLoading && canEdit ? (
                        <>
                          <IconButton
                            color="secondary"
                            onClick={() => props.setIsAddingStartup(true)}
                            sx={{ width: "fit-content", margin: "auto" }}
                          >
                            <AddIcon>+</AddIcon>
                          </IconButton>
                        </>
                      ) : null}
                    </TableCell>
                  </TableRow>
                )}
            {(props.isAddingStartup || opportunities.length) && canEdit ? (
              <AddStartup
                isAddingStartup={props.isAddingStartup}
                setIsAddingStartup={props.setIsAddingStartup}
                assignedStartupsIds={props.opportunities?.flatMap(
                  (opportunity) => opportunity.startupId
                )}
                createOpportunity={handleCreateOpportunity}
                projectId={props.project.id}
                projectFunnelStage={props.projectFunnelStage}
              />
            ) : null}
          </TableBody>
        </StyledTable>
      </StyledTableContainer>
      {props.children}
    </StyledFlipper>
  );
}
