import axios from "axios";
import { StartupPb } from "../../Types/Startup";

export class PitchbookHttpService {
  public static async getStartupsFromPitchbook(
    name: string
  ): Promise<StartupPb[]> {
    const response = await axios.get<StartupPb[]>(`/api/pitchbook`, {
      params: { name },
    });

    return response.data;
  }

  public static async getSingleStartupFromPitchbook(
    id: string
  ): Promise<StartupPb> {
    const response = await axios.get<StartupPb>(`/api/pitchbook/${id}/basic`);
    return response.data;
  }
}
