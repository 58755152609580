import { Box, DialogContent, DialogContentProps, styled } from "@mui/material";
import { ReactElement, useEffect, useRef, useState } from "react";
import theme from "../../../theme";
import scrollAnimation from "./../LottieAnimations/scrollAnimation.json";
import Lottie from "react-lottie";

const TopGradient = styled(Box)({
  position: "sticky",
  width: "calc(100% + 64px)",
  transform: "translateX(-32px)",
  transition: "opacity 0.2s ease",
  pointerEvents: "none",
  zIndex: 1,
  top: 0,
  height: 24,
  marginTop: -24,
  background:
    "linear-gradient(180deg, rgba(0, 0, 0, 0.12) -76.91%, rgba(0, 0, 0, 0.00) 100%)",
});

const BottomGradient = styled(TopGradient)({
  top: undefined,
  height: 48,
  marginTop: -48,
  bottom: 0,
  background:
    "linear-gradient(0deg, #FFF 6.64%, rgba(255, 255, 255, 0.00) 93.34%)",
  "& path": {
    stroke: theme.palette.icon.disabled,
  },
});

const ScrollableDialogContent = (props: DialogContentProps): ReactElement => {
  const dialogRef = useRef<HTMLDivElement>(null);
  const [topVisible, setTopVisible] = useState(
    dialogRef.current && dialogRef.current?.scrollTop > 0
  );
  const [bottomVisible, setBottomVisible] = useState(
    dialogRef.current &&
      dialogRef.current.scrollHeight > dialogRef.current.offsetHeight
  );

  useEffect(() => {
    const handleScroll = () => {
      if (!dialogRef.current) return;

      const scrollableHeight =
        dialogRef.current.scrollHeight - dialogRef.current.offsetHeight;

      if (dialogRef.current?.scrollTop > 0) {
        setTopVisible(true);
      } else if (dialogRef.current?.scrollTop === 0) setTopVisible(false);

      if (
        dialogRef.current?.scrollTop > 0 &&
        dialogRef.current.scrollTop < scrollableHeight - 1
      ) {
        setBottomVisible(true);
      } else if (dialogRef.current?.scrollTop >= scrollableHeight - 1) {
        setBottomVisible(false);
      }
    };

    dialogRef.current?.addEventListener("scroll", handleScroll);

    return () => {
      dialogRef.current?.removeEventListener("scroll", handleScroll);
    };
  }, [dialogRef.current]);

  return (
    <>
      <DialogContent
        {...props}
        ref={dialogRef}
        sx={{
          overflow: "scroll",
          overflowX: "hidden",
          ...props.sx,
        }}
      >
        <TopGradient sx={{ opacity: topVisible ? 1 : 0 }} />
        {props.children}
        <BottomGradient
          sx={{
            opacity: bottomVisible ? 1 : 0,
          }}
        >
          <Lottie
            options={{
              loop: true,
              autoplay: true,
              animationData: scrollAnimation,
            }}
            height={24}
            width={24}
            style={{ position: "sticky", left: "50%", top: "100%" }}
          />
        </BottomGradient>
      </DialogContent>
    </>
  );
};

export default ScrollableDialogContent;
