import { ReactElement, useContext, useEffect, useState } from "react";
import {
  Typography,
  Box,
  Stack,
  styled,
  Link,
  CircularProgress,
} from "@mui/material";
import { Startup } from "../../../../Types/Startup";
import Button from "@mui/material/Button";
import theme from "../../../../theme";
import { RocketLaunchOutlined } from "@mui/icons-material";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { GlobalStartupEditContext } from "../../../../Context/StartupDetailsContext";
import {
  dateToYYMMDD,
  formatDate,
  formatFunding,
  getErrorMessage,
  normalizeUrl,
} from "../../../../utils";
import EditStartupOverviewModal from "./EditStartupOverviewModal";
import Geocode from "react-geocode";
import { StaticGoogleMap, Marker } from "react-static-google-map";
import WrongLocationOutlinedIcon from "@mui/icons-material/WrongLocationOutlined";
import { enqueueSnackbar } from "notistack";
import { StartupHttpService } from "../../../../Http/Startup/Startup.http.service";
import { useParams } from "react-router-dom";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import useRoles from "../../../../Hooks/useRoles";

interface Props {
  startup: Startup;
  handleSave: (withScroll?: boolean) => void;
  setAddStartupToProjectModalOpen: (value: boolean) => void;
}

const Container = styled(Box)({
  display: "grid",
  gridTemplateColumns: "0.5fr 1fr",
  gap: theme.spacing(1),
});

const Logo = styled(Box)<{ src: string; alt: string }>(() => ({
  display: "flex",
  overflow: "hidden",
  maxWidth: "%100",
  maxHeight: theme.spacing(5),
  objectFit: "contain",
}));

const NoLogoPlaceholder = styled(Box)(() => ({
  display: "flex",
  height: theme.spacing(16),
  width: theme.spacing(16),
  justifyContent: "center",
  alignItems: "center",
}));

const displayValue = (
  value: string | number | undefined | null,
  valueType: string
) => {
  const isDefined = !(value === undefined || value === "" || value === null);
  const isLink = isDefined && valueType === "website";

  return (
    <Typography
      component={isLink ? Link : Typography}
      color={
        isDefined
          ? isLink
            ? theme.palette.text.action.main
            : theme.palette.brand.accessibility
          : theme.palette.text.disabled
      }
      sx={{ wordBreak: "break-word" }}
      data-testid={valueType}
      href={isLink ? normalizeUrl(value as string) : undefined}
      target={isLink ? "_blank" : undefined}
      rel={isLink ? "noopener noreferrer" : undefined}
    >
      {value ? (isLink ? normalizeUrl(value as string, true) : value) : "--"}
    </Typography>
  );
};

export default function StartupOverview(props: Props): ReactElement {
  const [editMode, setEditMode] = useState<boolean>(false);
  const [geoCode, setGeoCode] = useState<string>();
  const [isDownloading, setIsDownloading] = useState<boolean>(false);
  const { id } = useParams<{ id: string }>();
  const { isExternalUser, isViewer } = useRoles();
  const logo = props.startup.files.find((file) => file.type === "Logo");
  const imgAltTag = logo?.name.substring(0, logo.name.lastIndexOf("."));
  const { globalEditMode, setGlobalEditMode } = useContext(
    GlobalStartupEditContext
  );

  const lastModifiedDate = props.startup.lastModifiedDate
    ? formatDate(props.startup.lastModifiedDate)
    : "";

  const lastModifiedBy = props.startup.lastModifiedBy?.name;

  const isAddressEmpty =
    props.startup.billingCity && props.startup.billingCountry;

  const toggleEditMode = () => {
    setGlobalEditMode(!globalEditMode);
    setEditMode(!editMode);
  };

  const download = async () => {
    setIsDownloading(true);
    const downloadDate = dateToYYMMDD(new Date());
    const fileName = downloadDate + "_" + props.startup.name;
    await StartupHttpService.getStartupOnePager(
      id,
      fileName,
      isExternalUser ? ".pdf" : ".pptx"
    )
      .catch((error) => {
        enqueueSnackbar("Could not download: " + getErrorMessage(error), {
          variant: "error",
        });
        setIsDownloading(false);
      })
      .finally(() => {
        setIsDownloading(false);
      });
  };

  const getAddressString = (startup: Startup) => {
    const {
      billingStreet,
      billingCity,
      billingState,
      billingPostalCode,
      billingCountry,
    } = startup;
    return `${billingStreet}, ${billingCity}, ${billingState} ${billingPostalCode}, ${billingCountry}`;
  };

  const yearFounded = props.startup.dateFounded
    ? props.startup.dateFounded?.getFullYear()
    : "Undisclosed";

  useEffect(() => {
    const startupAddress = getAddressString(props.startup);

    if (process.env.REACT_APP_GOOGLE_API_KEY) {
      Geocode.setApiKey(process.env.REACT_APP_GOOGLE_API_KEY);
      Geocode.setLanguage("en");
      Geocode.setRegion("de");
      Geocode.fromAddress(startupAddress).then(
        (response) => {
          const { lat, lng } = response.results[0].geometry.location;
          const geoCodeString = lat.toString() + " " + lng.toString();
          setGeoCode(geoCodeString);
        },
        () => {
          // handle error here
          setGeoCode("");
        }
      );
    }
  }, [props.startup]);

  const getTotalFunding = (() => {
    if (
      !props.startup.totalFunding &&
      props.startup.currentInvestmentStage === "No Institutional Investment"
    )
      return "0.00";
    return props.startup.totalFunding;
  })();

  return (
    <Stack gap={3}>
      <Stack gap={2} width="100%">
        <Box display="flex" justifyContent="space-between">
          {logo ? (
            <Logo
              component="img"
              id="startup-logo"
              src={logo.url}
              alt={imgAltTag ? imgAltTag : "27Pilots Logo"}
              bgcolor="background.paper"
            />
          ) : (
            <NoLogoPlaceholder id="placeholder-logo">
              <RocketLaunchOutlined
                sx={{
                  fontSize: theme.spacing(6),
                }}
                color="disabled"
              />
            </NoLogoPlaceholder>
          )}
          {!isExternalUser && (
            <Button
              onClick={toggleEditMode}
              disabled={globalEditMode}
              id="edit-overview-button"
            >
              <EditOutlinedIcon
                sx={{
                  fontSize: "20px",
                  color: globalEditMode
                    ? theme.palette.text.disabled
                    : theme.palette.text.primary,
                }}
              />
            </Button>
          )}
        </Box>
        <Box display="flex" gap={0.5}>
          <Typography variant="h6" data-testid="name">
            {props.startup.name}
          </Typography>
          {props.startup.legalEntity !== "Other" && (
            <Typography variant="h6" data-testid="legal-entity">
              {props.startup.legalEntity}
            </Typography>
          )}
        </Box>
      </Stack>
      <Stack gap={0.25} justifyContent="center" width="100%">
        <Typography variant="subtitle1" data-testid="short-description">
          {props.startup.shortDescription}
        </Typography>
        <Typography variant="body2" data-testid="description">
          {props.startup.description}
        </Typography>
      </Stack>
      <Stack gap={0.5}>
        <Container>
          <Typography variant="subtitle2">Founded</Typography>
          {displayValue(yearFounded, "date-founded")}
        </Container>
        <Container>
          <Typography variant="subtitle2">Employees</Typography>
          {displayValue(props.startup.employees, "employees")}
        </Container>

        <Container>
          <Typography variant="subtitle2">Funding</Typography>
          <Box display="flex" gap={1}>
            {displayValue(formatFunding(getTotalFunding), "total-funding")}
            {displayValue(
              props.startup.currentInvestmentStage,
              "current-invesment-stage"
            )}
          </Box>
        </Container>
        <Container>
          <Typography variant="subtitle2">Website</Typography>
          {displayValue(props.startup?.website, "website")}
        </Container>
      </Stack>
      {isAddressEmpty ? (
        <Stack gap={2}>
          <Box
            sx={{
              flexDirection: "column",
              display: "flex",
              gap: theme.spacing(0.5),
            }}
          >
            <Box display="flex" flexDirection="column">
              <Typography variant="caption" data-testid="billingStreet">
                {props.startup.billingStreet}
              </Typography>
              <Typography variant="caption">
                {props.startup.billingCity}
                {props.startup.billingState ? ", " : ""}
                {props.startup.billingState}
              </Typography>
              <Typography variant="caption" data-testid="billingPostalCode">
                {props.startup.billingPostalCode ? " " : ""}
                {props.startup.billingPostalCode}
              </Typography>
              <Typography
                variant="caption"
                color={theme.palette.brand.main}
                data-testid="billingCountry"
              >
                {props.startup.billingCountry}
              </Typography>
            </Box>
          </Box>
          <Box>
            {geoCode && (
              <Box
                sx={{
                  borderRadius: "4px",
                  overflow: "hidden",
                }}
                data-testid="startup-map"
              >
                <StaticGoogleMap
                  size="334x120"
                  zoom="2"
                  apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
                >
                  <Marker location={geoCode} color="red" label="P" />
                </StaticGoogleMap>
              </Box>
            )}
          </Box>
        </Stack>
      ) : (
        <Box
          sx={{
            flexDirection: "column",
            display: "flex",
            gap: theme.spacing(3),
          }}
        >
          <Typography variant="body1" color={theme.palette.text.mediumEmphasis}>
            No startup address added yet.
          </Typography>
          <Box
            sx={{
              display: "grid",
              height: theme.spacing(17.5),
              backgroundColor: theme.palette.surface.secondary.main,
              padding: theme.spacing(4),
              borderRadius: theme.shape.radius.cardSmall,
              placeItems: "center",
            }}
            data-testid="placeholder-map"
          >
            <WrongLocationOutlinedIcon sx={{ fontSize: theme.spacing(3) }} />
          </Box>
        </Box>
      )}
      <Stack gap={1}>
        {!isViewer && (
          <Button
            onClick={() => props.setAddStartupToProjectModalOpen(true)}
            variant="contained"
            data-testid="add-startup-to-project-button"
          >
            + Add
          </Button>
        )}
        {isDownloading ? (
          <Button variant="contained" disabled={isDownloading}>
            <CircularProgress size="1rem" id="circular-progress-icon" />
          </Button>
        ) : (
          <Button
            onClick={download}
            sx={{
              borderRadius: theme.shape.radius.cardSmall,
              p: theme.spacing(1),
            }}
            variant="contained"
            color="secondary"
            data-testid="download-startup-onepager"
          >
            <DownloadOutlinedIcon fontSize="small" />
            Download Onepager
          </Button>
        )}
      </Stack>
      <Stack direction="row" justifyContent="space-between">
        <Stack direction="row" gap={0.5}>
          <Typography variant="overline" color="text.disabled">
            Last Edited
          </Typography>
          <Typography
            variant="overline"
            color="text.mediumEmphasis"
            data-testid="last-updated-date"
          >
            {lastModifiedDate}
          </Typography>
        </Stack>
        {!isExternalUser && (
          <Stack direction="row" gap={0.5}>
            <Typography variant="overline" color="text.disabled">
              By
            </Typography>
            <Typography
              variant="overline"
              color="text.mediumEmphasis"
              data-testid="last-updated-user"
            >
              {lastModifiedBy}
            </Typography>
          </Stack>
        )}
      </Stack>
      {editMode && (
        <EditStartupOverviewModal
          open={editMode}
          handleClose={toggleEditMode}
          startup={props.startup}
          handleSave={props.handleSave}
        />
      )}
    </Stack>
  );
}
