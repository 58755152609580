import axios from "axios";
import { User } from "../../Types/User";

export default class UserHttpService {
  public static async getUsersByName(name: string): Promise<User[]> {
    return (
      await axios.get<User[]>(`/api/users`, {
        params: {
          name,
        },
      })
    ).data;
  }

  public static async getUser(id: number): Promise<User> {
    return (await axios.get<User>(`/api/users/${id}`)).data;
  }

  public static async getUsers(isStartupSourcing?: boolean): Promise<User[]> {
    return (
      await axios.get<User[]>(`/api/users`, {
        params: {
          ...(isStartupSourcing && { isStartupSourcing }),
        },
      })
    ).data;
  }
}
