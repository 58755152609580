import { FormHelperText } from "@mui/material";
import { ReactElement } from "react";
import theme from "../../theme";

export default function PasswordRequirements(): ReactElement {
  return (
    <FormHelperText>
      <ul
        style={{
          margin: 0,
          color: theme.palette.text.primary,
          paddingInlineStart: theme.spacing(2),
          ...theme.typography.body2,
        }}
      >
        <li>At least 12 Characters</li>
        <li>Contains Number</li>
        <li>Contains Uppercase Letter</li>
        <li>Contains Lowercase Letter</li>
      </ul>
    </FormHelperText>
  );
}
