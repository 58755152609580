import {
  Box,
  Button,
  IconButton,
  Stack,
  Typography,
  styled,
} from "@mui/material";
import { ReactElement, useContext, useEffect, useState } from "react";
import { StartupHttpService } from "../../../Http/Startup/Startup.http.service";
import { getErrorMessage } from "../../../utils";
import CustomTextField from "../../UI/InputFields/CustomTextField";
import { useSnackbar } from "notistack";
import { ModeEditOutlineOutlined } from "@mui/icons-material";
import theme from "../../../theme";
import { GlobalStartupEditContext } from "../../../Context/StartupDetailsContext";

const StyledCustomTextField = styled(CustomTextField)(() => ({
  minWidth: "auto",
}));

const StyledIconButton = styled(IconButton)(() => ({
  padding: 0,
  height: theme.spacing(3),
  width: theme.spacing(3),
  color: "inherit",
}));

interface Props {
  startupId: string;
}

const BmwSalesforce = ({ startupId }: Props): ReactElement => {
  const { enqueueSnackbar } = useSnackbar();
  const { globalEditMode, setGlobalEditMode } = useContext(
    GlobalStartupEditContext
  );
  const [editMode, setEditMode] = useState(false);
  const [originalComment, setOriginalComment] = useState("");
  const [startupInfo, setStartupInfo] = useState({
    statusComment: "",
    status: "",
    stage: "",
  });

  useEffect(() => {
    const getSalesforceStatusComment = async () => {
      await StartupHttpService.getSalesforceStartupInfo(startupId)
        .then((info) => {
          setStartupInfo(info);
          setOriginalComment(info.statusComment);
        })
        .catch((error) => {
          const errorMessage = getErrorMessage(error);
          return enqueueSnackbar(
            `Could not receive the salesforce status comment: ${errorMessage}`,
            {
              variant: "error",
            }
          );
        });
    };
    getSalesforceStatusComment();
  }, []);

  const saveSalesforceStatusComment = async () => {
    await StartupHttpService.updateSalesforceStatusComment(
      startupId,
      startupInfo.statusComment
    )
      .then(() => {
        setEditMode(false);
        setGlobalEditMode(false);
        setOriginalComment(startupInfo.statusComment);
      })
      .catch((error) => {
        const errorMessage = getErrorMessage(error);
        return enqueueSnackbar(
          `Could not update the salesforce status comment: ${errorMessage}`,
          {
            variant: "error",
          }
        );
      });
  };

  const handleCancel = () => {
    setStartupInfo({ ...startupInfo, statusComment: originalComment });
    setEditMode(false);
    setGlobalEditMode(false);
  };

  const StartupInformation = (props: {
    title: string;
    value: string;
  }): ReactElement => (
    <Box display="flex" gap={2}>
      <Typography variant="subtitle2" width={theme.spacing(10)}>
        {props.title}
      </Typography>
      <Typography>{props.value}</Typography>
    </Box>
  );

  return (
    <Stack gap={3}>
      <Box
        display="flex"
        justifyContent="space-between"
        color={theme.palette.text.primary}
      >
        <Typography variant="h6">
          BMW
          <br />
          Salesforce Status Comment
        </Typography>
        {!editMode && (
          <StyledIconButton
            onClick={() => {
              setEditMode(true);
              setGlobalEditMode(true);
            }}
            data-testid="edit-salesforce-status-comment-button"
            disabled={globalEditMode}
          >
            <ModeEditOutlineOutlined fontSize="small" />
          </StyledIconButton>
        )}
      </Box>
      <Stack gap={0.5}>
        <StartupInformation title="Status" value={startupInfo.status} />
        <StartupInformation title="Stage" value={startupInfo.stage} />
      </Stack>
      {(editMode || startupInfo.statusComment) && (
        <StyledCustomTextField
          fullWidth
          onChange={(e) =>
            setStartupInfo({ ...startupInfo, statusComment: e.target.value })
          }
          value={startupInfo.statusComment}
          editMode={editMode}
          multiline
          id="salesforce-status-comment"
          data-testid="salesforce-status-comment"
        />
      )}
      {editMode && (
        <Box display="flex" justifyContent="flex-end" gap={2}>
          <Button
            onClick={handleCancel}
            data-testid="cancel-salesforce-status-comment"
          >
            Cancel
          </Button>
          <Button
            onClick={saveSalesforceStatusComment}
            variant="contained"
            color="primary"
            data-testid="save-salesforce-status-comment"
          >
            Save
          </Button>
        </Box>
      )}
    </Stack>
  );
};

export default BmwSalesforce;
