import {
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  Stack,
  Typography,
} from "@mui/material";
import {
  ReactElement,
  Fragment,
  useState,
  useEffect,
  useReducer,
  Reducer,
} from "react";
import { LeadProject } from "../../../Types/LeadProject";
import theme from "../../../theme";
import CustomTextField from "../../UI/InputFields/CustomTextField";
import { VentureClient } from "../../../Types/VentureClient";
import { VentureClientHttpService } from "../../../Http/VentureClient/VentureClient.http.service";
import { SelectInput } from "../../UI/InputFields/SelectInput";
import UserSelectInput from "../../UI/InputFields/UserSelect";
import { useSnackbar } from "notistack";
import { LeadProjectsHttpService } from "../../../Http/LeadProjects/LeadProjects.http.service";
import useRoles from "../../../Hooks/useRoles";
import ScrollableDialogContent from "../../UI/Modals/ScrollableDialogContent";
import ClientContactSelect from "../../UI/InputFields/ClientContactSelect";

interface editLeadProjectRequiredFields {
  Name?: string;
  "Short Description"?: string;
}

interface LeadProjectSummaryEditModalProps {
  handleModalClose: () => void;
  modalOpen: boolean;
  leadProject: LeadProject;
  onRefresh: () => void;
}

function LeadProjectSummaryEditModal(
  props: LeadProjectSummaryEditModalProps
): ReactElement {
  const { enqueueSnackbar } = useSnackbar();
  const { isExternalUser } = useRoles();

  const [newLeadProject, setNewLeadProject] = useReducer<
    Reducer<LeadProject, Partial<LeadProject>>
  >((state, newState) => ({ ...state, ...newState }), props.leadProject);
  const [ventureClientSelectOptions, setVentureClientSelectOptions] = useState<
    VentureClient[]
  >([]);

  useEffect(() => {
    if (!isExternalUser)
      VentureClientHttpService.getVentureClients().then((ventureClients) => {
        setVentureClientSelectOptions(ventureClients);
      });
  }, [isExternalUser]);

  const handleSelectVentureClient = async (ventureClientId: number) => {
    setNewLeadProject({ ventureClientId });
  };

  const showError = (value: string) => {
    enqueueSnackbar(`Please Enter ${value}`, {
      variant: "error",
    });
  };

  const editProjectLeadRequiredFields: editLeadProjectRequiredFields = {
    Name: newLeadProject.name,
    "Short Description": newLeadProject.shortDescription,
  };

  const checkRequiredFields = () => {
    const emptyRequiredFieldsArray: string[] = [];
    for (const [key, value] of Object.entries(editProjectLeadRequiredFields)) {
      if (("" + value).trim().length === 0 || !value) {
        emptyRequiredFieldsArray.push(key);
      }
    }
    return emptyRequiredFieldsArray;
  };

  const saveProject = async () => {
    const emptyRequiredFields = checkRequiredFields();
    if (emptyRequiredFields.length > 0) {
      return showError(emptyRequiredFields[0]);
    }
    await LeadProjectsHttpService.updateLeadProject(newLeadProject);
    props.handleModalClose();
    props.onRefresh();
  };

  return (
    <Fragment>
      <Dialog
        fullWidth
        open={props.modalOpen}
        data-testid="edit-lead-project-modal"
        PaperProps={{
          sx: {
            gap: theme.spacing(4),
          },
        }}
      >
        <DialogTitle>Edit Project Lead</DialogTitle>
        <ScrollableDialogContent>
          <form noValidate autoComplete="off">
            <Stack gap={4}>
              <Stack gap={3}>
                <Typography variant="subtitle1">Basic Information</Typography>
                <CustomTextField
                  id="name"
                  label="Name"
                  editMode={true}
                  value={newLeadProject.name || ""}
                  onChange={(e) => {
                    setNewLeadProject({
                      name: e.target.value,
                    });
                  }}
                  fullWidth
                  required
                />
                <CustomTextField
                  id="shortDescription"
                  label="Short Description"
                  editMode={true}
                  value={newLeadProject.shortDescription || ""}
                  onChange={(e) => {
                    setNewLeadProject({
                      shortDescription: e.target.value,
                    });
                  }}
                  fullWidth
                  required
                  multiline
                  minRows={3}
                />
                {!isExternalUser && (
                  <SelectInput
                    id="venture-client"
                    label="Company"
                    selectValues={ventureClientSelectOptions}
                    value={newLeadProject.ventureClientId || ""}
                    onChange={(event) =>
                      handleSelectVentureClient(parseInt(event.target.value))
                    }
                    editMode
                    required
                    ignoreValidation
                    fullWidth
                  />
                )}
              </Stack>

              <Stack gap={3}>
                <Typography variant="subtitle1">
                  Project Administrators
                </Typography>
                <UserSelectInput
                  onChange={(user) => {
                    setNewLeadProject({
                      projectOwnerId: user?.id,
                    });
                  }}
                  defaultUserId={props.leadProject.projectOwnerId}
                  label="Lead Owner"
                />
                <ClientContactSelect
                  editMode
                  labelText="Program Manager"
                  ventureClientId={props.leadProject.ventureClientId}
                  onChange={setNewLeadProject}
                  contactData={props.leadProject.programManager}
                  fieldId="programManagerId"
                  field="programManager"
                />
              </Stack>
            </Stack>
          </form>
        </ScrollableDialogContent>
        <DialogActions>
          <Button onClick={props.handleModalClose}>Cancel</Button>
          <Button onClick={saveProject} variant="contained">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}
export default LeadProjectSummaryEditModal;
