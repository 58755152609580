import { Skeleton, Box } from "@mui/material";
import { ReactElement } from "react";
import { UseCaseTableRow, UseCaseTableCell } from "./UseCaseItem";

export function UseCaseItemSkeleton(): ReactElement {
  return (
    <UseCaseTableRow>
      <UseCaseTableCell width="40%">
        <Skeleton width={200} height={20} />
      </UseCaseTableCell>
      <UseCaseTableCell width="60%">
        <Skeleton width="100%" height={16} />
      </UseCaseTableCell>
      <UseCaseTableCell width="auto">
        <Box display="flex" gap={1}>
          <Skeleton width={24} height={24} />
          <Skeleton width={24} height={24} />
        </Box>
      </UseCaseTableCell>
    </UseCaseTableRow>
  );
}
