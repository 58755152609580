import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, {
  ReactElement,
  useState,
  useEffect,
  useReducer,
  Reducer,
} from "react";
import ClientContactHttpService from "../../../Http/ClientContact/ClientContact.http.service";
import {
  ClientContactDTO,
  EditClientContactDTO,
} from "../../../Types/ClientContact";
import { SelectInput } from "../../UI/InputFields/SelectInput";
import { Gender, SelectOption } from "../../../Types/Common";
import { BusinessUnitHttpService } from "../../../Http/BusinessUnit/BusinessUnit.http.service";
import { useSnackbar } from "notistack";
import CloseIcon from "@mui/icons-material/Close";
import { getErrorMessage } from "../../../utils";

interface ManageClientContactModalProps {
  setModalOpen: (state: boolean) => void;
  modalOpen: boolean;
  handleSave: () => void;
  contact?: ClientContactDTO;
  ventureClientId: number;
}

const genders = ["male", "female", "non-binary"];

export default function ManageClientContactModal(
  props: ManageClientContactModalProps
): ReactElement {
  const { enqueueSnackbar } = useSnackbar();
  const [contact, setContact] = useReducer<
    Reducer<EditClientContactDTO, Partial<EditClientContactDTO>>
  >((state, newState) => ({ ...state, ...newState }), {
    ...props.contact,
    ventureClientId: props.ventureClientId,
  } as EditClientContactDTO);
  const [businessUnitSelectOptions, setBusinessUnitSelectOptions] = useState<
    SelectOption[]
  >([]);

  const isCreateMode = !props.contact;

  useEffect(() => {
    BusinessUnitHttpService.getBusinessUnits({
      ventureClientId: props.ventureClientId,
    })
      .then((data) => {
        setBusinessUnitSelectOptions(data);
      })
      .catch((error) => {
        const errorMessage = getErrorMessage(error);
        enqueueSnackbar(
          `Something went wrong with fetching organizational units: ${errorMessage}`,
          {
            variant: "error",
          }
        );
      });
  }, []);

  const showError = (value: string) => {
    enqueueSnackbar(`Please enter ${value}`, {
      variant: "error",
    });
  };

  const handleSaveButton = async () => {
    if (isCreateMode) {
      const requiredFields: {
        key: keyof EditClientContactDTO;
        label: string;
      }[] = [
        { key: "name", label: "Name" },
        { key: "title", label: "Title" },
        { key: "businessUnitId", label: "Organizational Unit" },
        { key: "email", label: "Email" },
      ];

      for (const { key, label } of requiredFields) {
        if (!contact[key] || `${contact[key]}`.trim() === "") {
          showError(label);
          return;
        }
      }

      try {
        await ClientContactHttpService.createContact(contact);
        props.handleSave();
        props.setModalOpen(false);
      } catch (error) {
        enqueueSnackbar("Failed to create contact. Please try again.", {
          variant: "error",
        });
      }
    } else {
      try {
        await ClientContactHttpService.updateContact(contact);
        props.handleSave();
        props.setModalOpen(false);
      } catch (error) {
        enqueueSnackbar("Failed to update contact. Please try again.", {
          variant: "error",
        });
      }
    }
    props.handleSave();
    props.setModalOpen(false);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = e.target;

    setContact({ [id]: value || null });
  };

  const handleGenderChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setContact({ gender: e.target.value as Gender });
  };

  const handleSelectBusinessUnit = (id: string) => {
    const buId = parseInt(id);
    if (buId) {
      setContact({
        businessUnitId: buId,
      });
      return;
    }
  };

  return (
    <Dialog
      fullWidth
      open={props.modalOpen}
      id="manage-client-contact-modal"
      data-testid="manage-client-contact-modal"
    >
      <DialogTitle>
        {isCreateMode ? "Add " : "Edit "}Contact
        <IconButton
          sx={{ color: "icon.primary" }}
          onClick={() => props.setModalOpen(false)}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Stack gap={4}>
          <Stack gap={3}>
            <Typography variant="subtitle1">Personal Information</Typography>
            <Box display="flex" gap={2}>
              <TextField
                label="Name"
                id="name"
                variant="outlined"
                required
                fullWidth
                value={contact.name || ""}
                onChange={handleInputChange}
              />
              <TextField
                label="Gender"
                id="gender"
                variant="outlined"
                fullWidth
                select
                value={contact.gender || ""}
                onChange={handleGenderChange}
                sx={{ maxWidth: 130 }}
              >
                {genders.map((gender) => (
                  <MenuItem key={gender} value={gender}>
                    {gender}
                  </MenuItem>
                ))}
              </TextField>
            </Box>
          </Stack>
          <Stack gap={2}>
            <Typography variant="subtitle1">Company Information</Typography>
            <Box display="flex" gap={2}>
              <TextField
                label="Title"
                id="title"
                variant="outlined"
                fullWidth
                required
                value={contact.title || ""}
                onChange={handleInputChange}
              />
              <TextField
                label="Department Code"
                id="departmentCode"
                variant="outlined"
                fullWidth
                value={contact.departmentCode || ""}
                onChange={handleInputChange}
              />
            </Box>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <SelectInput
                  label="Organizational Unit"
                  selectValues={businessUnitSelectOptions}
                  value={
                    businessUnitSelectOptions.length
                      ? contact.businessUnitId
                      : ""
                  }
                  onChange={(e) => handleSelectBusinessUnit(e.target.value)}
                  editMode={true}
                  required
                  fullWidth
                  ignoreValidation
                />
              </Grid>
            </Grid>
          </Stack>
          <Stack gap={2}>
            <Typography variant="subtitle1">Contact</Typography>
            <Box display="flex" gap={2}>
              <TextField
                label="Email"
                id="email"
                variant="outlined"
                fullWidth
                required
                value={contact.email || ""}
                onChange={handleInputChange}
              />
              <TextField
                label="Phone Number"
                id="phone"
                variant="outlined"
                fullWidth
                value={contact.phone || ""}
                onChange={handleInputChange}
              />
            </Box>
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => props.setModalOpen(false)}
          id="close-manage-client-contact-modal"
        >
          Cancel
        </Button>
        <Button
          disabled={!contact.name || !contact.businessUnitId}
          onClick={handleSaveButton}
          variant="contained"
          id="save-manage-client-contact-modal"
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}
