import { Box } from "@mui/material";
import { ReactElement } from "react";
import { Project } from "../../../../Types/Project";
import AdoptionInsights from "./AdoptionInsights";

interface AdoptStageProps {
  projectData: Project;
  handleSaveNoScroll: () => void;
  handleChangeTab: (index: number, keepFullWidth?: boolean) => void;
  activeTabs: number[];
  handleRefs: (el: HTMLDivElement | null, index: number) => void;
  fullWidthSectionOpen: boolean;
  setFullWidthSectionOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function AdoptStage(props: AdoptStageProps): ReactElement {
  return (
    <Box display="flex" flexDirection="column" gap={3} width="100%">
      {props.projectData && (
        <div ref={(element) => props.handleRefs(element, 0)}>
          <AdoptionInsights
            project={props.projectData}
            expanded={props.activeTabs.includes(0)}
            onChange={() => props.handleChangeTab(0)}
            handleSave={props.handleSaveNoScroll}
          />
        </div>
      )}
    </Box>
  );
}
