import * as React from "react";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { FileItemMenuOption } from "./FileItem";
import { Typography } from "@mui/material";

interface FileItemMenuButtonProps {
  options: FileItemMenuOption[];
}

export default function FileItemMenuButton(
  props: FileItemMenuButtonProps
): React.ReactElement {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton onClick={handleClick} data-testid="file-menu-button">
        <MoreVertIcon sx={{ fontSize: "16px" }} />
      </IconButton>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        {props.options
          .filter((option) => option.visible)
          .map((option, index) => (
            <MenuItem
              key={index}
              onClick={() => {
                option.action();
                handleClose();
              }}
              disabled={option.disabled}
            >
              <Typography
                variant={option.isHighlighted ? "subtitle2" : "body1"}
                color={option.isHighlighted ? "text.action.main" : "inherit"}
              >
                {option.label}
              </Typography>
            </MenuItem>
          ))}
      </Menu>
    </>
  );
}
