import axios from "axios";
import {
  CreateVentureClientDTO,
  VentureClient,
  VentureClientDTO,
} from "../../Types/VentureClient";
import VentureClientMapper from "./VentureClient.mapper";

export class VentureClientHttpService {
  public static async getVentureClients(): Promise<VentureClient[]> {
    const response = await axios.get<VentureClientDTO[]>(
      `/api/venture-clients`
    );
    return response.data.map((ventureClient) =>
      VentureClientMapper.map(ventureClient)
    );
  }

  public static async getVentureClientById(id: number): Promise<VentureClient> {
    const response = await axios.get<VentureClientDTO>(
      `/api/venture-clients/${id}`
    );
    return VentureClientMapper.map(response.data);
  }

  public static async createVentureClient(
    newVentureClient: CreateVentureClientDTO
  ): Promise<void> {
    await axios.post<void>(`/api/venture-clients`, newVentureClient);
  }

  public static async updateVentureClient(
    ventureClient: VentureClient
  ): Promise<void> {
    await axios.put<void>(
      `/api/venture-clients/${ventureClient.id}`,
      ventureClient
    );
  }
}
