import axios from "axios";
import { ProjectFileRules, ProjectFieldRules } from "../../Types/ProjectRules";

export class ProjectRulesHttpService {
  public static async getProjectFieldRules(): Promise<ProjectFieldRules> {
    const response = await axios.get<ProjectFieldRules>(
      `/api/project-rules/fields`
    );
    return response.data;
  }

  public static async getProjectFileRules(): Promise<ProjectFileRules[]> {
    const response = await axios.get<ProjectFileRules[]>(
      `/api/project-rules/files`
    );
    return response.data;
  }
}
