import axios from "axios";
import Company from "../../Types/Company";

export class CompaniesHttpService {
  public static async getCompaniesByName(name: string): Promise<Company[]> {
    const response = await axios.get<Company[]>(`/api/companies`, {
      params: { name },
    });
    return response.data;
  }

  public static async createCompany(
    client: Omit<Company, "id">
  ): Promise<Company> {
    const response = await axios.post<Company>(`/api/companies`, client);
    return response.data;
  }
}
