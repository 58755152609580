import React, { Fragment, ReactElement, useEffect, useState } from "react";
import { User } from "../../../Types/User";
import { Autocomplete, CircularProgress, TextField } from "@mui/material";
import UserHttpService from "../../../Http/User/User.Http.service";
import { useSnackbar } from "notistack";

interface Props {
  onChange: (args: User | undefined) => void;
  defaultUserId: number | null;
  label: string;
  sourcing?: boolean;
}

export default function UserSelectInput(props: Props): ReactElement {
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [users, setUsers] = useState<User[]>([]);
  const [selectedUser, setSelectedUser] = useState<User | null>();

  useEffect(() => {
    const getInputValues = async () => {
      setIsLoading(true);
      try {
        await UserHttpService.getUsers(props.sourcing).then((users) => {
          setUsers(users);
        });
      } catch (error) {
        enqueueSnackbar("Could not get users", {
          variant: "error",
        });
      } finally {
        setIsLoading(false);
      }
    };
    getInputValues();
  }, []);

  useEffect(() => {
    const getSelectedUser = async () => {
      if (props.defaultUserId && props.defaultUserId !== -1) {
        getUser();
      } else if (!props.defaultUserId) {
        setSelectedUser(null);
      }
    };
    getSelectedUser();
  }, [props.defaultUserId]);

  const getUser = () => {
    UserHttpService.getUser(props.defaultUserId || -1).then((user) => {
      setUsers((prevState) =>
        prevState.find((p) => p.id === user.id)
          ? [...prevState]
          : [...prevState, user]
      );
      setSelectedUser(user);
    });
  };

  return (
    <Autocomplete
      sx={{ width: "100%" }}
      isOptionEqualToValue={(option: User, value: User) =>
        option.id === value.id
      }
      onChange={(_, selectedUser) => {
        setSelectedUser(selectedUser as User);
        props.onChange(selectedUser as User);
      }}
      getOptionLabel={(option) => option.name}
      options={users}
      noOptionsText="No user found"
      loading={isLoading}
      value={selectedUser || null}
      renderOption={(props, option) => {
        return (
          <li {...props} key={option.id}>
            {option.name}
          </li>
        );
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          fullWidth
          label={props.label}
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <Fragment>
                {isLoading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </Fragment>
            ),
          }}
        />
      )}
    />
  );
}
