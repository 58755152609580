import { Box, Stack, Typography, styled } from "@mui/material";
import { SolutionCluster } from "../../../../../../../../Types/SolutionCluster";
import { ReactElement, useState } from "react";
import ManageSolutionClusterModal from "../ManageSolutionClusterModal";
import EditDeleteMenu from "../../../../../../../UI/EditDeleteMenu";
import NumberCard from "../../../../../../../UI/NumberCard";
import DeleteOrRemoveModal from "../../../../../../../UI/Modals/DeleteOrRemoveModal/DeleteOrRemoveModal";
import { SolutionClusterHttpService } from "../../../../../../../../Http/SolutionCluster/SolutionCluster.http.service";
import { useSnackbar } from "notistack";
import { getErrorMessage } from "../../../../../../../../utils";

const ClusterWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(2),
}));

const Header = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
}));

interface Props {
  solutionCluster: SolutionCluster;
  numberOfOpportunities: number;
  totalFunding: string;
  handleSave: () => void;
  canEdit: boolean;
}

const ClusterCard = (props: Props): ReactElement => {
  const { enqueueSnackbar } = useSnackbar();
  const [editClusterModalOpen, setEditClusterModalOpen] = useState(false);
  const [deleteClusterModalOpen, setDeleteClusterModalOpen] = useState(false);

  const handleDeleteCluster = (id: number) => {
    try {
      SolutionClusterHttpService.deleteSolutionCluster(id);
      props.handleSave();
      // eslint-disable-next-line
    } catch (error: any) {
      const message = getErrorMessage(error);
      enqueueSnackbar(message, {
        variant: "error",
      });
    }
  };

  return (
    <>
      <ClusterWrapper
        key={props.solutionCluster.id}
        data-testid={`solution-cluster-${props.solutionCluster.id}`}
      >
        <Stack gap={0.5}>
          <Header>
            <Typography variant="subtitle1">
              {props.solutionCluster.name}
            </Typography>
            {props.canEdit && (
              <EditDeleteMenu
                size="small"
                entity="cluster"
                setEditModalOpen={setEditClusterModalOpen}
                setDeleteModalOpen={setDeleteClusterModalOpen}
                sx={{ fontSize: "1rem" }}
              />
            )}
          </Header>
          {props.solutionCluster.description && (
            <Typography variant="body1">
              {props.solutionCluster.description}
            </Typography>
          )}
        </Stack>
        <Box display="flex" gap={2} maxWidth="528px">
          <NumberCard
            label="Total Startups"
            value={props.numberOfOpportunities}
            hideCurrency
            hideUnit
          />
          <NumberCard
            label="Total Funding (in USD)"
            value={props.totalFunding}
            currency="$"
          />
        </Box>
      </ClusterWrapper>

      {editClusterModalOpen && (
        <ManageSolutionClusterModal
          modalOpen={editClusterModalOpen}
          setModalOpen={setEditClusterModalOpen}
          solutionCluster={props.solutionCluster}
          handleSave={props.handleSave}
          numberOfOpportunities={props.numberOfOpportunities}
          totalFunding={props.totalFunding}
        />
      )}
      {deleteClusterModalOpen && (
        <DeleteOrRemoveModal
          id={props.solutionCluster.id}
          modalOpen={deleteClusterModalOpen}
          setModalOpen={setDeleteClusterModalOpen}
          entity="Solution Cluster"
          handleDelete={handleDeleteCluster}
          actionType="delete"
        />
      )}
    </>
  );
};

export default ClusterCard;
