import {
  DataGridPremiumProps,
  GridColDef,
  GridInitialState,
} from "@mui/x-data-grid-premium";
import { ReactElement, useEffect } from "react";
import { Opportunity } from "../../Types/Opportunity";
import useRoles from "../../Hooks/useRoles";
import {
  formatDate,
  getImpactValueByFunnelStage,
  getNameWithDepartmentCode,
} from "../../utils";
import { FocusArea } from "../../Types/VentureClient";
import { FunnelStage, ProjectHistoryChange } from "../../Types/Project";
import { Meeting } from "../../Types/Meeting";
import CustomDataGrid from "./CustomDataGrid";
import { GridApiPremium } from "@mui/x-data-grid-premium/models/gridApiPremium";
import { funnelStages } from "../../Constants/FunnelStages";
import { ProjectHistory } from "../../Types/ProjectHistory";

interface ProjectsDataGrid extends Omit<DataGridPremiumProps, "columns"> {
  apiRef: React.MutableRefObject<GridApiPremium>;
  gridState: GridInitialState | null;
}

type GridColumn = GridColDef & {
  hidden?: boolean;
};

const getFunnelStageIndex = (stage: FunnelStage): number => {
  const index = funnelStages.indexOf(stage);
  return index === -1 ? funnelStages.length : index;
};

export default function ProjectsDataGrid(
  props: ProjectsDataGrid
): ReactElement {
  const { apiRef, paginationModel } = props;
  const { isExternalUser } = useRoles();

  useEffect(() => {
    if (props.gridState) {
      apiRef.current.restoreState(props.gridState);
    }
  }, [apiRef, props.gridState]);

  const baseColumns: GridColumn[] = [
    {
      flex: 0.5,
      field: "id",
      type: "number",
      headerName: "Project ID",
      valueFormatter: (value) => value,
    },
    {
      flex: 1,
      field: "name",
      headerName: "Project Name",
      editable: false,
    },
    {
      flex: 1,
      field: "shortDescription",
      headerName: "Short Description",
    },
    {
      flex: 0.75,
      field: "ventureClientName",
      headerName: "Company",
      hidden: isExternalUser,
      valueGetter: (_value, row) => {
        return row.businessUnit?.ventureClient?.name || "";
      },
    },
    {
      flex: 0.5,
      field: "businessUnit",
      headerName: "Organizational Unit",
      valueGetter: (_value, row) => {
        return row.businessUnit?.name || "";
      },
    },
    {
      flex: 1,
      field: "focusAreas",
      headerName: "Focus Areas",
      valueGetter: (_value, row) => {
        return (
          row.focusAreas
            ?.map((focusArea: FocusArea) => focusArea.name)
            .join(", ") || ""
        );
      },
    },
    {
      flex: 0.5,
      field: "funnelStage",
      headerName: "Stage",
      sortComparator: (v1, v2) =>
        getFunnelStageIndex(v1) - getFunnelStageIndex(v2),
    },
    {
      flex: 0.5,
      field: "status",
      headerName: "Status",
    },
    {
      flex: 1,
      field: "archivedReason",
      headerName: "Archived Reason",
      valueGetter: (_value, row) => {
        if (row.status === "archived" && row.projectHistoryStatusChange) {
          const statusChange = row.projectHistoryStatusChange.find(
            (change: ProjectHistory) =>
              change.projectStatusChangeReason?.type === "archived"
          );

          return statusChange
            ? statusChange.projectStatusChangeReason?.description
            : "";
        }
        return "";
      },
    },
    {
      flex: 1,
      field: "onHoldReason",
      headerName: "On Hold Reason",
      valueGetter: (_value, row) => {
        if (row.status === "on hold" && row.projectHistoryStatusChange) {
          const statusChange = row.projectHistoryStatusChange.find(
            (change: ProjectHistory) =>
              change.projectStatusChangeReason?.type === "on hold"
          );
          return statusChange
            ? statusChange.projectStatusChangeReason?.description
            : "";
        }
        return "";
      },
    },
    {
      flex: 1,
      field: "statusComment",
      headerName: "Latest Status Update Comment",
    },
    {
      flex: 1,
      field: "projectOwnerName",
      headerName: "Project Owner",
      valueGetter: (_value, row) => {
        return row.projectOwner?.name || "";
      },
    },
    {
      flex: 1,
      field: "projectLeader",
      headerName: "Project Leader",
      valueGetter: (_value, row) =>
        getNameWithDepartmentCode(row.projectLeader),
    },
    {
      flex: 1,
      field: "programManager",
      headerName: "Program Manager",
      valueGetter: (_value, row) =>
        getNameWithDepartmentCode(row.programManager),
    },
    {
      flex: 1,
      field: "adoptionOwner",
      headerName: "Adoption Owner",
      valueGetter: (_value, row) =>
        getNameWithDepartmentCode(row.adoptionOwner),
    },
    {
      flex: 1,
      field: "projectSponsor",
      headerName: "Project Sponsor",
      valueGetter: (_value, row) =>
        getNameWithDepartmentCode(row.projectSponsor),
    },
    {
      flex: 1,
      field: "otherStakeholders",
      headerName: "Other Stakeholders",
      valueGetter: (_value, row) => {
        if (!row.otherStakeholders) {
          return "";
        }
        return `${row.otherStakeholders
          .map(getNameWithDepartmentCode)
          .join(", ")}`;
      },
    },
    {
      flex: 0.5,
      field: "discoveredStartups",
      type: "number",
      headerName: "Discovered Startups",
      valueGetter: (_value, row) => {
        if (!row?.opportunities) return 0;
        return row.opportunities.length;
      },
    },
    {
      flex: 1,
      field: "assessedStartups",
      headerName: "Assessed Startups",
      type: "number",
      valueGetter: (_value, row) => {
        if (!row?.opportunities) return 0;
        return row.opportunities.filter(
          (opportunity: Opportunity) => opportunity?.isQualified === true
        ).length;
      },
    },
    {
      flex: 1,
      field: "selectedStartup",
      headerName: "Selected Startup",
      valueGetter: (_value, row) => {
        if (!row?.opportunities) return 0;
        return row.opportunities.find(
          (opportunity: Opportunity) => opportunity.isSelectedForPilot === true
        )?.startup.name;
      },
    },
    {
      flex: 1,
      field: "selectedStartupCountry",
      headerName: "Selected Startup Country",
      valueGetter: (_value, row) => {
        const selectedOpportunity: Opportunity = row.opportunities?.find(
          (opp: Opportunity) => opp.isSelectedForPilot
        );

        return selectedOpportunity
          ? `${selectedOpportunity.startup.billingCountry}`
          : "";
      },
    },
    {
      flex: 1,
      field: "impactType",
      headerName: "Impact Type",
    },
    {
      flex: 0.5,
      field: "impactValue",
      headerName: "Impact Value",
      type: "number",
      valueGetter: (_value, row) => {
        const impactValue = getImpactValueByFunnelStage(
          row.funnelStage,
          row.impactValues
        );
        return impactValue?.value;
      },
    },
    {
      flex: 1,
      field: "strategicBenefits",
      headerName: "Intangible Benefits",
    },
    {
      flex: 1,
      field: "originType",
      headerName: "Origin Type",
      valueGetter: (_value, row) => row.leadProject?.originType,
    },
    {
      flex: 1,
      field: "originContact",
      headerName: "Origin Contact",
      valueGetter: (_value, row) => row.leadProject?.originContact?.name,
    },
    {
      flex: 1,
      field: "originStartup",
      headerName: "Origin Startup",
      valueGetter: (_value, row) => row.leadProject?.originStartup?.name,
    },
    {
      flex: 1,
      field: "originThirdPartyCategory",
      headerName: "Origin Third Party Category",
      valueGetter: (_value, row) =>
        row.leadProject?.originThirdPartyCategoryName?.categoryType,
    },
    {
      flex: 1,
      field: "originThirdPartyName",
      headerName: "Origin Third Party Name",
      valueGetter: (_value, row) =>
        row.leadProject?.originThirdPartyCategoryName?.value,
    },
    {
      flex: 1,
      field: "initiativeType",
      headerName: "Initiative Type",
      valueGetter: (_value, row) =>
        row.leadProject?.initiativeName?.initiativeType,
    },
    {
      flex: 1,
      field: "initiativeName",
      headerName: "Initiative Name",
      valueGetter: (_value, row) => row.leadProject?.initiativeName?.value,
    },
    {
      flex: 0.5,
      field: "dateCreated",
      headerName: "Creation Date",
      type: "date",
      availableAggregationFunctions: ["min", "max"],
      valueGetter: (value) => new Date(value),
      valueFormatter: (value) => formatDate(value),
    },
    {
      flex: 1,
      field: "lastStageChange",
      type: "date",
      availableAggregationFunctions: ["min", "max"],
      headerName: "Last Stage Change",
      valueGetter: (_value, row) =>
        row.projectHistoryStageChange?.[0]?.dateTriggered
          ? new Date(row.projectHistoryStageChange[0].dateTriggered)
          : null,
      valueFormatter: (value) => formatDate(value),
    },
    {
      flex: 1,
      field: "onHoldDate",
      headerName: "Last Put On Hold",
      type: "date",
      availableAggregationFunctions: ["min", "max"],
      valueGetter: (_value, row) => {
        const onHoldDate = row.projectHistoryStatusChange?.find(
          (change: ProjectHistoryChange) => change.newValue === "on hold"
        )?.dateTriggered;
        return onHoldDate ? new Date(onHoldDate) : null;
      },
      valueFormatter: (value) => formatDate(value),
    },
    {
      flex: 1,
      field: "archivedDate",
      headerName: "Last Put on Archived",
      type: "date",
      availableAggregationFunctions: ["min", "max"],
      valueGetter: (_value, row) => {
        const archivedDate = row.projectHistoryStatusChange?.find(
          (change: ProjectHistoryChange) => change.newValue === "archived"
        )?.dateTriggered;
        return archivedDate ? new Date(archivedDate) : null;
      },
      valueFormatter: (value) => formatDate(value),
    },
    {
      flex: 1,
      field: "discoverDate",
      headerName: "First Moved to Discover",
      type: "date",
      availableAggregationFunctions: ["min", "max"],
      valueGetter: (_value, row) => {
        const discoverDate = row.projectHistoryStageChange?.findLast(
          (change: ProjectHistoryChange) => change.newValue === "discover"
        )?.dateTriggered;
        return discoverDate ? new Date(discoverDate) : null;
      },
      valueFormatter: (value) => formatDate(value),
    },
    {
      flex: 1,
      field: "assessDate",
      headerName: "First Moved to Assess",
      type: "date",
      availableAggregationFunctions: ["min", "max"],
      valueGetter: (_value, row) => {
        const assessDate = row.projectHistoryStageChange?.findLast(
          (change: ProjectHistoryChange) => change.newValue === "assess"
        )?.dateTriggered;
        return assessDate ? new Date(assessDate) : null;
      },
      valueFormatter: (value) => formatDate(value),
    },
    {
      flex: 1,
      field: "buyDate",
      headerName: "First Moved to Buy",
      type: "date",
      availableAggregationFunctions: ["min", "max"],
      valueGetter: (_value, row) => {
        const buyDate = row.projectHistoryStageChange?.findLast(
          (change: ProjectHistoryChange) => change.newValue === "buy"
        )?.dateTriggered;
        return buyDate ? new Date(buyDate) : null;
      },
      valueFormatter: (value) => formatDate(value),
    },
    {
      flex: 1,
      field: "pilotDate",
      headerName: "First Moved to Pilot",
      type: "date",
      availableAggregationFunctions: ["min", "max"],
      valueGetter: (_value, row) => {
        const pilotDate = row.projectHistoryStageChange?.findLast(
          (change: ProjectHistoryChange) => change.newValue === "pilot"
        )?.dateTriggered;
        return pilotDate ? new Date(pilotDate) : null;
      },
      valueFormatter: (value) => formatDate(value),
    },
    {
      flex: 1,
      field: "adoptDate",
      headerName: "First Moved to Adopt",
      type: "date",
      availableAggregationFunctions: ["min", "max"],
      valueGetter: (_value, row) => {
        const adoptDate = row.projectHistoryStageChange?.findLast(
          (change: ProjectHistoryChange) => change.newValue === "adopt"
        )?.dateTriggered;
        return adoptDate ? new Date(adoptDate) : null;
      },
      valueFormatter: (value) => formatDate(value),
    },
    {
      flex: 1,
      field: "pilotKickoffDate",
      headerName: "Pilot Kickoff Date",
      type: "date",
      availableAggregationFunctions: ["min", "max"],
      valueGetter: (_value, row) => {
        const kickoffMeeting = row.meetings?.find(
          (meeting: Meeting) => meeting.type === "Kick-off"
        );
        return kickoffMeeting?.dateStart
          ? new Date(kickoffMeeting.dateStart)
          : null;
      },
      valueFormatter: (value) => formatDate(value),
    },
    {
      flex: 1,
      field: "pilotConclusionDate",
      headerName: "Pilot Conclusion Date",
      type: "date",
      availableAggregationFunctions: ["min", "max"],
      valueGetter: (_value, row) => {
        const conclusionMeeting = row.meetings?.find(
          (meeting: Meeting) => meeting.type === "Conclusion"
        );
        return conclusionMeeting?.dateStart
          ? new Date(conclusionMeeting.dateStart)
          : null;
      },
      valueFormatter: (value) => formatDate(value),
    },
    {
      flex: 1,
      field: "pilotOutcome",
      headerName: "Pilot Outcome",
    },
  ];

  const columns = baseColumns.filter((column) => !column.hidden);

  return (
    <CustomDataGrid
      {...props}
      apiRef={apiRef}
      paginationModel={paginationModel}
      initialState={
        props.gridState || {
          columns: {
            columnVisibilityModel: {
              id: false,
              businessUnit: false,
              focusAreas: false,
              otherStakeholders: false,
              strategicBenefits: false,
              selectedStartupCountry: false,
              discoverDate: false,
              assessDate: false,
              statusComment: false,
              buyDate: false,
              pilotDate: false,
              adoptDate: false,
              onHoldDate: false,
              archivedDate: false,
              pilotKickoffDate: false,
              pilotConclusionDate: false,
              selectedStartup: false,
              adoptionOwner: false,
              impactType: false,
              impactValue: false,
              lastStageChange: false,
              projectSponsor: false,
              programManager: false,
              dateCreated: false,
              pilotOutcome: false,
              shortDescription: false,
              assessedStartups: false,
              archivedReason: false,
              onHoldReason: false,
              originType: false,
              originContact: false,
              originStartup: false,
              originThirdPartyCategory: false,
              originThirdPartyName: false,
              initiativeName: false,
              initiativeType: false,
            },
          },
        }
      }
      columns={columns}
      noRowsText="No Project Found"
    />
  );
}
