import { Skeleton, SkeletonProps } from "@mui/material";
import { ReactElement } from "react";

interface SkeletonWrapperProps extends SkeletonProps {
  isLoading: boolean;
}

export default function SkeletonWrapper(
  props: SkeletonWrapperProps
): ReactElement {
  const { isLoading, ...defaultProps } = props;
  return (
    <>
      {isLoading ? (
        <Skeleton {...defaultProps}>{props.children}</Skeleton>
      ) : (
        props.children
      )}
    </>
  );
}
