import {
  InputLabel,
  Stack,
  TextField,
  TextFieldProps,
  Typography,
} from "@mui/material";
import { Fragment, ReactElement, ReactNode } from "react";
import { NumberFormatCustom } from "./NumberFormat";
import CustomToolTip from "../CustomToolTip";

type CustomTextFieldProps = TextFieldProps & {
  toolTipText?: string | null;
  editMode: boolean;
  maxCharacter?: number;
  children?: ReactNode;
  displayValue?: unknown;
  "data-testid"?: string;
};

const CustomTextField = (props: CustomTextFieldProps): ReactElement => {
  const {
    editMode,
    toolTipText = null,
    maxCharacter,
    displayValue,
    ...defaultProps
  } = props;

  const isError = editMode && defaultProps.required && !defaultProps.value;

  return (
    <Fragment>
      {props.editMode ? (
        <TextField
          {...defaultProps}
          label={
            !!!toolTipText ? (
              defaultProps.label
            ) : (
              <>
                {defaultProps.label}
                <CustomToolTip
                  id={`${defaultProps.id}Tooltip`}
                  key={`${defaultProps.id}Tooltip`}
                  toolTipText={toolTipText}
                  warning={isError}
                />
              </>
            )
          }
          InputLabelProps={{
            shrink: true,
            sx: {
              marginTop: !!toolTipText ? "-7px" : "0px",
              paddingRight: "8px",
            },
          }}
          InputProps={{
            ...defaultProps.InputProps,
            ...(defaultProps.type === "currency" && {
              name: defaultProps.id,
              // eslint-disable-next-line
              inputComponent: NumberFormatCustom as any,
            }),
          }}
          inputProps={{
            ...defaultProps.inputProps,
            ...(maxCharacter && {
              maxLength: maxCharacter,
            }),
            "data-testid": `input-${props["data-testid"]}`,
          }}
          FormHelperTextProps={{
            sx: {
              position: "absolute",
              bottom: 14,
              right: 0,
              mt: 0,
            },
          }}
          helperText={
            maxCharacter
              ? (defaultProps.value && typeof defaultProps.value === "string"
                  ? defaultProps.value.length
                  : 0) +
                "/" +
                maxCharacter
              : defaultProps.helperText
          }
          error={isError}
          sx={{
            ...defaultProps.sx,
            ...(maxCharacter && {
              "& .MuiInputBase-root": {
                paddingBottom: "46px",
              },
            }),
          }}
        />
      ) : (
        <Stack gap={0.5} whiteSpace="pre-wrap">
          {!defaultProps.hiddenLabel && (
            <InputLabel>
              <Typography variant="caption">{defaultProps.label}</Typography>
            </InputLabel>
          )}
          <Typography id={defaultProps.id} data-testid={defaultProps.id}>
            {String(displayValue || defaultProps.value || "--")}
          </Typography>
        </Stack>
      )}
    </Fragment>
  );
};

export default CustomTextField;
