import {
  Logout,
  RocketLaunchOutlined,
  AssessmentOutlined,
  CorporateFare,
} from "@mui/icons-material";
import ListIcon from "@mui/icons-material/List";
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import { ReactElement } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { Tooltip, TooltipProps, styled, tooltipClasses } from "@mui/material";
import theme from "../../theme";
import useRoles from "../../Hooks/useRoles";

interface NavOption {
  title: string;
  to: string;
  iconName: string;
  hidden?: boolean;
}

const iconComponents: { [name: string]: ReactElement } = {
  AssessmentOutlined: (
    <AssessmentOutlined sx={{ transform: "rotate(180deg)" }} />
  ),
  ListIcon: <ListIcon />,
  RocketLaunchOutlined: <RocketLaunchOutlined />,
  CategorySearch: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M2 20.5V12.5H10V20.5H2ZM4 18.5H8V14.5H4V18.5ZM5.5 10L11 1L16.5 10H5.5ZM9.05 8H12.95L11 4.85L9.05 8ZM21.575 22.95L18.925 20.3C18.575 20.5333 18.1958 20.7083 17.7875 20.825C17.3792 20.9417 16.95 21 16.5 21C15.25 21 14.1875 20.5625 13.3125 19.6875C12.4375 18.8125 12 17.75 12 16.5C12 15.25 12.4375 14.1875 13.3125 13.3125C14.1875 12.4375 15.25 12 16.5 12C17.75 12 18.8125 12.4375 19.6875 13.3125C20.5625 14.1875 21 15.25 21 16.5C21 16.9333 20.9417 17.3542 20.825 17.7625C20.7083 18.1708 20.5417 18.55 20.325 18.9L22.975 21.55L21.575 22.95ZM16.5 19C17.2 19 17.7917 18.7583 18.275 18.275C18.7583 17.7917 19 17.2 19 16.5C19 15.8 18.7583 15.2083 18.275 14.725C17.7917 14.2417 17.2 14 16.5 14C15.8 14 15.2083 14.2417 14.725 14.725C14.2417 15.2083 14 15.8 14 16.5C14 17.2 14.2417 17.7917 14.725 18.275C15.2083 18.7583 15.8 19 16.5 19Z"
        fill="#E8EAED"
      />
    </svg>
  ),
  CorporateFare: <CorporateFare />,

  PersonOutlineIcon: <PersonOutlineIcon />,
  QueryStatsIcon: <QueryStatsIcon />,
};

const StyledDrawer = styled(Drawer)(() => ({
  width: theme.spacing(9),
  zIndex: 1000,
  "& .MuiPaper-root": {
    backgroundColor: theme.palette.surface.primary.invert,
    border: "none",
    width: theme.spacing(9),
  },
}));

const MainNav = styled(List)(() => ({
  height: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  gap: theme.spacing(7.25),
}));

const LogoutItem = styled(ListItem)(() => ({
  cursor: "pointer",
  position: "absolute",
  bottom: theme.spacing(3),
  left: theme.spacing(3),
  width: "fit-content",
  padding: 0,
}));

const StyledListItemIcon = styled(ListItemIcon)(() => ({
  minWidth: 0,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: theme.spacing(0.5),
  color: theme.palette.icon.primaryInvert.main,
  padding: 0,
}));

const ActiveIcon = styled("div")(() => ({
  width: "6px",
  height: "6px",
  borderRadius: theme.shape.radius.full,
  backgroundColor: theme.palette.icon.brand.main,
}));

const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.surface.action.main,
    boxShadow: theme.boxShadows[12],
    ...theme.typography.caption,
  },
}));

interface SideNavProps {
  handleSignOut: () => void;
}

export default function SideNav(props: SideNavProps): ReactElement {
  const location = useLocation();
  const { isExternalUser, ventureClientId } = useRoles();

  const navigationOptions: NavOption[] = [
    {
      title: "Kanban",
      to: "/projects",
      iconName: "AssessmentOutlined",
    },
    {
      title: "Data & Reports",
      to: "/data",
      iconName: "QueryStatsIcon",
    },
    {
      title: "Startups",
      to: "/startups",
      iconName: "RocketLaunchOutlined",
    },
    {
      title: "Problem Discovery",
      to: "/problem-discovery",
      iconName: "CategorySearch",
      hidden: isExternalUser,
    },
    {
      title: "Companies",
      to: "/companies",
      iconName: "CorporateFare",
      hidden: isExternalUser,
    },
    {
      title: "Company",
      to: `/companies/${ventureClientId}`,
      iconName: "CorporateFare",
      hidden: !isExternalUser,
    },
  ];

  const pathMapper = (pathname: string) => {
    if (pathname.startsWith("/lead-project")) {
      return "/projects";
    }
    return pathname;
  };

  const isActive = (pathname: string, option: NavOption): boolean => {
    const mappedPath = pathMapper(pathname);
    return option.to === "/"
      ? mappedPath === "/"
      : mappedPath.startsWith(option.to);
  };

  return (
    <StyledDrawer variant="permanent">
      <MainNav>
        {navigationOptions
          .filter((option) => !option.hidden)
          .map((option) => {
            return (
              <StyledTooltip
                key={option.title}
                title={option.title}
                placement="right"
              >
                <NavLink
                  to={option.to}
                  exact
                  data-testid={"navlink-" + option.to.slice(1)}
                >
                  <StyledListItemIcon sx={{ height: "34px" }}>
                    {iconComponents[option.iconName]}
                    {isActive(location.pathname, option) && <ActiveIcon />}
                  </StyledListItemIcon>
                </NavLink>
              </StyledTooltip>
            );
          })}
        <StyledTooltip title="Logout" placement="right-start">
          <LogoutItem onClick={props.handleSignOut}>
            <StyledListItemIcon>
              <Logout />
            </StyledListItemIcon>
          </LogoutItem>
        </StyledTooltip>
      </MainNav>
    </StyledDrawer>
  );
}
