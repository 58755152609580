import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { ReactElement, useState } from "react";
import { FocusAreasHttpService } from "../../../../Http/FocusArea/FocusAreas.http.service";
import { InvestorsHttpService } from "../../../../Http/Investors/Investors.http.service";
import { UseCasesHttpService } from "../../../../Http/UseCases/UseCases.http.service";
import theme from "../../../../theme";
import { UnitHttpService } from "../../../../Http/Unit/Unit.http.service";
import { CompaniesHttpService } from "../../../../Http/Companies/Companies.http.service";

interface CreateEntityByNameProps {
  name: string;
  label: string;
  modalOpen: boolean;
  setModalOpen: (state: boolean) => void;
  handleAdd: (createdValue: CreatedValue) => void;
  maxCharacter?: number;
  ventureClientId?: number;
  initialValue?: string;
}

type ValueToBeCreated = Omit<CreatedValue, "id">;

type CreatedValue = {
  id: number;
  name: string;
  ventureClientId?: number;
};

async function create(
  label: string,
  newValue: ValueToBeCreated,
  ventureClientId?: number
) {
  switch (label) {
    case "Focus Area":
      return FocusAreasHttpService.createFocusArea({
        ...newValue,
        ventureClientId,
      });
    case "Use Case":
      return UseCasesHttpService.createUseCase(newValue);
    case "Investor":
      return InvestorsHttpService.createInvestor(newValue);
    case "Reference Client":
    case "Parent Company":
      return CompaniesHttpService.createCompany(newValue);
    case "Unit":
      return UnitHttpService.createUnit(newValue);
    default:
      return {} as CreatedValue;
  }
}

const CreateEntityByName = (props: CreateEntityByNameProps): ReactElement => {
  const { enqueueSnackbar } = useSnackbar();
  const [newValue, setNewValue] = useState<ValueToBeCreated>({
    name: props.initialValue || "",
  });

  const handleCreate = async () => {
    if (!newValue.name)
      return enqueueSnackbar("No input provided", { variant: "error" });

    try {
      const createdValue: CreatedValue = await create(
        props.label,
        newValue,
        props.ventureClientId
      );

      // displaying the newly created value on the field once its created
      if (createdValue) {
        props.handleAdd(createdValue);
      }
      props.setModalOpen(false);
      // eslint-disable-next-line
    } catch (error: any) {
      enqueueSnackbar(`Something went wrong: ${error.response.data.message}`, {
        variant: "error",
      });
    }
  };

  return (
    <Dialog
      fullWidth
      open={props.modalOpen}
      data-testid={`${props.name}-create-modal`}
      PaperProps={{
        sx: {
          gap: theme.spacing(4),
        },
      }}
    >
      <DialogTitle>Create {props.label}</DialogTitle>
      <DialogContent>
        <TextField
          id={props.name}
          label={props.label}
          fullWidth
          value={newValue.name}
          onChange={(event) => {
            setNewValue({ name: event.target.value });
          }}
          inputProps={{
            ...(props.maxCharacter && {
              maxLength: props.maxCharacter,
            }),
          }}
          helperText={
            props.maxCharacter &&
            (newValue.name && typeof newValue.name === "string"
              ? newValue.name.length
              : 0) +
              "/" +
              props.maxCharacter
          }
        />
      </DialogContent>
      <DialogActions>
        <Button
          autoFocus
          onClick={() => props.setModalOpen(false)}
          color="primary"
          data-testid={`${props.name}-cancel-button`}
        >
          Cancel
        </Button>
        <Button
          onClick={() => handleCreate()}
          variant="contained"
          data-testid={`${props.name}-create-button`}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateEntityByName;
