import { Stack } from "@mui/material";
import { ReactElement } from "react";
import ProjectTeamLeadersSectionSkeleton from "./ProjectTeamLeadersSection/ProjectTeamLeadersSectionSkeleton";
import LeadSectionSkeleton from "./LeadSection/LeadSectionSkeleton";
import ProjectStatusSkeleton from "./ProjectStatus/ProjectStatusSkeleton";
import ProjectTasksSectionSkeleton from "./ProjectTasks/ProjectTasksSectionSkeleton";
import ProjectFilesSectionSkeleton from "./ProjectFilesSection/ProjectFilesSectionSkeleton";

export default function ProjectOverviewSkeleton(): ReactElement {
  return (
    <>
      <Stack gap={3}>
        <ProjectTeamLeadersSectionSkeleton />
        <LeadSectionSkeleton />
        <ProjectStatusSkeleton />
        <ProjectTasksSectionSkeleton />
        <ProjectFilesSectionSkeleton />
      </Stack>
    </>
  );
}
