import { ReactElement } from "react";
import { Button, CircularProgress } from "@mui/material";
import { AddLink, Link } from "@mui/icons-material";
import { StartupPb } from "../../../../../Types/Startup";

interface Props {
  startupPb?: StartupPb;
  isLoading: boolean;
  onClick: () => void;
}
export default function PitchBookAddLink(props: Props): ReactElement {
  if (props.isLoading && !props.startupPb) return <CircularProgress size={8} />;

  return (
    <Button
      data-testid="pb-add-link"
      startIcon={
        props.startupPb ? (
          <Link fontSize="small" sx={{ color: "text.action.main" }} />
        ) : (
          <AddLink fontSize="small" sx={{ color: "text.action.main" }} />
        )
      }
      onClick={props.onClick}
    >
      {props.startupPb?.name || "Add Link"}
    </Button>
  );
}
