import { ReactElement } from "react";
import { Box, Button, styled, Typography } from "@mui/material";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import theme from "../../theme";

const SessionWarningContainer = styled(Box)(() => ({
  zIndex: 999,
  width: "100%",
  padding: theme.spacing(1),
  marginLeft: theme.spacing(7),
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: theme.spacing(3),
  position: "fixed",
  bottom: theme.spacing(0),
  backgroundColor: theme.palette.surface.tertiary.light,
}));

interface SessionWarningProps {
  handleSignOut: () => void;
  remainingSessionTime: number;
}

const SessionWarning = (props: SessionWarningProps): ReactElement => {
  return (
    <SessionWarningContainer>
      <Box display="flex" alignItems="center" gap={1}>
        <ReportProblemIcon color="warning" />
        <Typography variant="subtitle2">
          Session Timeout in {props.remainingSessionTime} minutes
        </Typography>
      </Box>
      <Typography>
        Make sure to save your work before this session ends.
      </Typography>
      <Button variant="contained" onClick={props.handleSignOut}>
        Reauthenticate
      </Button>
    </SessionWarningContainer>
  );
};

export default SessionWarning;
