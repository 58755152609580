import { Paper, Stack, Box, Typography, Skeleton } from "@mui/material";
import { ReactElement } from "react";

export default function LeadProjectFileSectionSkeleton(): ReactElement {
  return (
    <Paper component={Stack} p={3} gap={2}>
      <Box justifyContent="space-between" flexDirection="row" display="flex">
        <Typography variant="h6">Files</Typography>
        <Box display="flex" gap={2}>
          <Skeleton width={20} height={20} />
          <Skeleton width={20} height={20} />
        </Box>
      </Box>
      <Skeleton width={264} height={56} />
      <Skeleton width={80} height={28} sx={{ mt: 2 }} />
      <Skeleton width={264} height={56} />
    </Paper>
  );
}
