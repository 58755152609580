import axios from "axios";
import { Preset, CreatePresetDto, EditPresetDto } from "../../Types/Preset";

export class LeadsPresetsHttpService {
  public static async getLeadsPresets(): Promise<Preset[]> {
    const response = await axios.get<Preset[]>("/api/leads-preset");
    return response.data;
  }

  public static async createLeadsPreset(
    preset: CreatePresetDto
  ): Promise<Preset> {
    const response = await axios.post<Preset>("/api/leads-preset", preset);
    return response.data;
  }

  public static async deleteLeadsPreset(id: number): Promise<void> {
    const response = await axios.delete(`/api/leads-preset/${id}`);
    return response.data;
  }

  public static async updateLeadsPreset(
    id: number,
    preset: EditPresetDto
  ): Promise<void> {
    await axios.put(`/api/leads-preset/${id}`, preset);
  }
}
