import axios from "axios";
import {
  ProjectTaskStatus,
  ProjectTasksCluster,
} from "../../Types/ProjectTask";
import { FunnelStage } from "../../Types/Project";
import ProjectTasksMapper from "./ProjectTasks.mapper";

export default class ProjectTasksHttpService {
  public static async getClusteredProjectTasks(
    projectId: number,
    stage: FunnelStage
  ): Promise<ProjectTasksCluster[]> {
    const response = await axios.get<ProjectTasksCluster[]>(
      `/api/projects/${projectId}/tasks?stage=${stage}`
    );
    return ProjectTasksMapper.sort(response.data);
  }

  public static async updateProjectTask(
    taskId: number,
    status: ProjectTaskStatus
  ): Promise<void> {
    await axios.put(`/api/project-tasks/${taskId}`, {
      status,
    });
  }
}
