import { otherFileTypes } from "../../Constants/Files";
import { File, FileIcons } from "../../Types/File";

export const getIconFromType = (fileType: string): FileIcons => {
  const articleType = [
    "Problem Deep Dive",
    "Startup Rejection",
    "Assessment Report",
    "Demo protocol",
    "SF Confirmation",
    "Internal Product Demo Briefing",
    "External Product Demo Briefing",
    "Follow-up Protocol",
    "Pilot Approval",
    "Pilot Fact Sheet",
    "Pilot Request",
    "Kickoff Protocol",
    "MS I Protocol",
    "MS II Protocol",
    "MS III Protocol",
    "Project Report",
    "Conclusion Protocol",
    "Project Success Report",
    "Pilot Synthesis",
    "Adoption Fact Sheet",
  ];
  const tableChartType = [
    "Curated List",
    "Business Case - Discover",
    "Business Case - Assess",
    "Business Case - Buy",
    "Business Case - Pilot",
    "Business Case - Adopt",
  ];

  const datasetType = [
    "Curated List Presentation",
    "Product Deck",
    "Qualified List Presentation",
    "Demo Deck",
    "MS I Deck",
    "MS II Deck",
    "MS III Deck",
    "Strategic Onepager",
    "Kickoff Deck",
    "Conclusion Deck",
    "Product Deck",
  ];
  const documentType = [
    "Partnership Confirmation",
    "NDA",
    "Request for Proposal",
    "Startup Offer",
    "Startup Contract",
  ];
  const imageType = ["Logo", "Strategic Image"];

  if (articleType.includes(fileType)) {
    return "Article";
  } else if (tableChartType.includes(fileType)) {
    return "TableChart";
  } else if (datasetType.includes(fileType)) {
    return "Dataset";
  } else if (documentType.includes(fileType)) {
    return "Document";
  } else if (imageType.includes(fileType)) {
    return "Image";
  } else {
    return "Other";
  }
};

export const sortDateAscending = (file1: File, file2: File): number => {
  const date1 = new Date(file1.dateCreated).getTime();
  const date2 = new Date(file2.dateCreated).getTime();
  return date1 - date2;
};

export const getFileName = (fileTypeNumber: string[], file: File): string => {
  if (!file.preview) {
    fileTypeNumber.push(file.type);
  }
  const typeNumber = fileTypeNumber.reduce(
    (currentElement, arrElement) =>
      arrElement == file.type ? currentElement + 1 : currentElement,
    0
  );

  if (!otherFileTypes.includes(file.type)) {
    return typeNumber < 2
      ? file.type
      : file.preview
      ? file.type
      : `${file.type} (${typeNumber})`;
  } else {
    return file.name;
  }
};

const getFormat = (key: string, url: string): string => {
  const getExtension = (str: string): string => {
    const lastDot = str.lastIndexOf(".");
    return lastDot !== -1 ? str.slice(lastDot + 1).toUpperCase() : "";
  };

  //use url to get type when key is not provided ex. startup files
  return key ? getExtension(key) : getExtension(url);
};

export default class MapFile {
  static map(files: File[]): File[] {
    const fileTypeNumber: string[] = [];

    return files
      ? files.sort(sortDateAscending).map((file: File) => {
          return {
            ...file,
            format: file.isLink ? "Link" : getFormat(file.key, file.url),
            fileIcon: file.isLink ? "Link" : getIconFromType(file.type),
            displayName: getFileName(fileTypeNumber, file),
          };
        })
      : [];
  }
}
