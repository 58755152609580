import { Box } from "@mui/material";
import { memo, useMemo, ReactElement } from "react";
import { Meeting } from "../../Types/Meeting";
import { FunnelStage, Project } from "../../Types/Project";
import { daysBetweenDates, getMeetingTimes } from "../../utils";
import { EmptyStageKanbanCard } from "./EmptyStageKanbanCard";
import ProjectKanbanCardUserBased from "./ProjectKanbanCard";

const getSortingScoreAndDays = (
  meetings: Meeting[],
  funnelStage: FunnelStage,
  funnelStageAge: number
): { score: number; days: number } => {
  const { kickOff, conclusion, today } = getMeetingTimes(meetings);

  if (!conclusion || !kickOff || !today || funnelStage !== "pilot")
    return { score: 6, days: funnelStageAge };

  if (today === conclusion) {
    return { score: 1, days: 0 };
  }

  if (today === kickOff) {
    return { score: 2, days: 0 };
  }

  if (today < kickOff) {
    const daysUntilKickOff = daysBetweenDates(
      new Date(today),
      new Date(kickOff)
    );
    return { score: 4, days: daysUntilKickOff };
  }

  if (today < conclusion) {
    const daysUntilConclusion = daysBetweenDates(
      new Date(today),
      new Date(conclusion)
    );
    return { score: 3, days: daysUntilConclusion };
  }

  if (today > conclusion) {
    const daysSinceConclusion = daysBetweenDates(
      new Date(conclusion),
      new Date(today)
    );
    return { score: 5, days: daysSinceConclusion };
  }

  return { score: 7, days: 0 };
};

function getProjectCardsByStage(
  projects: Project[],
  stage: string,
  refreshProjectData: () => void
): ReactElement[] {
  if (projects) {
    return projects
      ?.sort((a, b) => {
        const { score: scoreA, days: daysA } = getSortingScoreAndDays(
          a.meetings,
          a.funnelStage,
          a.funnelStageAge
        );
        const { score: scoreB, days: daysB } = getSortingScoreAndDays(
          b.meetings,
          b.funnelStage,
          b.funnelStageAge
        );

        if (scoreA === scoreB) return daysA - daysB;

        return scoreA - scoreB;
      })
      .map((project) => (
        <Box key={project.id} data-testid="projectCard" width="100%">
          <ProjectKanbanCardUserBased
            project={project}
            refreshProjectData={refreshProjectData}
          />
        </Box>
      ));
  }
  return [
    <Box key={`${stage}-empty`} data-testid="emptyProjectCard">
      <EmptyStageKanbanCard />
    </Box>,
  ];
}

const ProjectCardsByStage = ({
  projects,
  stage,
  refreshProjectData,
}: {
  projects: Project[];
  stage: string;
  refreshProjectData: () => void;
}) => {
  const projectCards = useMemo(
    () => getProjectCardsByStage(projects, stage, refreshProjectData),
    [projects, stage]
  );

  return <>{projectCards}</>;
};

const MemoizedProjectCardsByStage = memo(ProjectCardsByStage);

export default MemoizedProjectCardsByStage;
