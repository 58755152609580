import { InfoOutlined } from "@mui/icons-material";
import {
  Box,
  Stack,
  styled,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { ReactElement, useContext, useEffect, useRef, useState } from "react";
import WidgetWrapper from "./WidgetWrapper";
import {
  FunnelCustomLayerProps,
  FunnelDatum,
  ResponsiveFunnel,
} from "@nivo/funnel";
import theme from "../../../theme";
import {
  PartLabelProps,
  PartLabelsProps,
  PartProps,
  PartsProps,
} from "../../../Types/Dashboard";
import { animated, useSpring } from "@react-spring/web";
import { useAnimatedPath, useMotionConfig } from "@nivo/core";
import { ProjectCountByStage } from "../../../Types/Analytics";
import { CONVERSION_RATE_TOOLTIP } from "../../../Constants/TooltipText";
import { GlobalLoaderContext } from "../../../Context/LoaderContext";
import SkeletonWrapper from "../../UI/SkeletonWrapper";

const FunnelWrapper = styled(Box, {
  shouldForwardProp: (prop: string) => !prop.startsWith("$"),
})(({ $noValues }: { $noValues?: boolean }) => ({
  height: $noValues ? 90 : 120,
  [theme.breakpoints.down("lg")]: {
    height: $noValues ? 187 : 217,
  },
  [theme.breakpoints.down("md")]: {
    height: $noValues ? 131 : 161,
  },
  "& line:nth-of-type(4n)": {
    display: "none",
  },
}));

interface ConversionRateProps {
  projectCount: ProjectCountByStage;
  prePilotDeaths: number;
  pilotDeaths: number;
}

function ConversionRate({
  projectCount,
  pilotDeaths,
  prePilotDeaths,
}: ConversionRateProps): ReactElement {
  const { globalLoader } = useContext(GlobalLoaderContext);
  const totalProjectsCount = projectCount
    ? Object.values(projectCount).reduce((sum, funnelStage) => {
        return sum + funnelStage.total;
      }, 0)
    : 0;
  const pilotOrAdopt = projectCount.pilot.total + projectCount.adopt.total;
  const adopt = projectCount.adopt.total;

  const funnelData = [
    {
      id: "Started Projects",
      label: "Started Projects",
      value: totalProjectsCount,
    },
    {
      id: "Pilot Projects",
      label: "Pilot Projects",
      value: pilotOrAdopt,
    },
    {
      id: "Adoptions",
      label: "Adoptions",
      value: adopt,
    },
  ];

  const rateStartedToPilot = Math.round(
    (pilotOrAdopt / (pilotOrAdopt + prePilotDeaths)) * 100
  );
  const ratePilotToAdopt = Math.round((adopt / (adopt + pilotDeaths)) * 100);

  const noValues = funnelData.every((part) => part.value === 0);

  const PartsLayer = (
    props: React.PropsWithChildren<
      FunnelCustomLayerProps<{
        id: string;
        label: string;
        value: number;
      }>
    >
  ) => (
    <Parts
      {...props}
      disabled={noValues}
      ratePilotToAdopt={ratePilotToAdopt}
      rateStartedToPilot={rateStartedToPilot}
    />
  );

  return (
    <WidgetWrapper data-testid="conversion-rate" filename="Conversion Rate">
      <Box display="flex" gap={0.5} alignItems="center">
        <SkeletonWrapper isLoading={globalLoader}>
          <Typography variant="h6">Conversion Rate</Typography>
        </SkeletonWrapper>
        <SkeletonWrapper isLoading={globalLoader} variant="circular" width={21}>
          <Tooltip
            className="info-tooltip"
            title={CONVERSION_RATE_TOOLTIP}
            componentsProps={{
              tooltip: {
                sx: {
                  maxWidth: 422,
                },
              },
            }}
          >
            <InfoOutlined
              sx={{ color: "icon.mediumEmphasis", fontSize: "16px" }}
            />
          </Tooltip>
        </SkeletonWrapper>
      </Box>
      {globalLoader ? (
        <FunnelWrapper display="flex" flexDirection="column" gap={1}>
          <SkeletonWrapper
            isLoading={globalLoader}
            width="100%"
            height="calc(100% - 40px)"
            sx={{ marginY: "20px" }}
          ></SkeletonWrapper>
          <Box display="flex" justifyContent="space-evenly">
            <SkeletonWrapper isLoading={globalLoader}>
              <Typography variant="h6">33%</Typography>
            </SkeletonWrapper>
            <SkeletonWrapper isLoading={globalLoader}>
              <Typography variant="h6">33%</Typography>
            </SkeletonWrapper>
          </Box>
        </FunnelWrapper>
      ) : (
        <Stack gap={2}>
          <FunnelWrapper $noValues={noValues}>
            <ResponsiveFunnel
              data={funnelData}
              direction="horizontal"
              spacing={1}
              interpolation="smooth"
              motionConfig="wobbly"
              shapeBlending={0}
              borderWidth={8}
              isInteractive={false}
              margin={{ top: 8, right: 6, bottom: noValues ? 0 : 30, left: 6 }}
              colors={[
                theme.palette.chart.dark.primary,
                theme.palette.chart.dark.secondary,
                theme.palette.chart.medium.primary,
              ]}
              labelColor={theme.palette.text.primaryInvert.main}
              layers={
                noValues
                  ? [PartsLayer, PartLabels, "annotations"]
                  : ["separators", PartsLayer, PartLabels, "annotations"]
              }
            />
          </FunnelWrapper>
          {noValues && (
            <Typography variant="overline" color="text.mediumEmphasis">
              No data to display due to insufficient data in the filtered
              projects. Adjust filters or review project data.
            </Typography>
          )}
        </Stack>
      )}
    </WidgetWrapper>
  );
}

const PartLabel = <D extends FunnelDatum>({
  part,
}: PartLabelProps<D>): ReactElement => {
  const { animate, config: motionConfig } = useMotionConfig();

  const animatedProps = useSpring({
    transform: `translate(${part.x}, ${part.y})`,
    color: "#fff",
    config: motionConfig,
    immediate: !animate,
  });

  const valueRef = useRef<SVGTextElement>(null);
  const labelRef = useRef<SVGTextElement>(null);
  const [positions, setPositions] = useState({ value: 0, label: 0 });

  const rightSideHeight = part.areaPoints[3].y1 - part.areaPoints[3].y0;
  const showOnTop = rightSideHeight < 13 || !part.data.value;
  const topOffset = part.data.value === 0 ? 16 : part.height / 2 + 16;

  useEffect(() => {
    if (valueRef.current && labelRef.current) {
      const valueBox = valueRef.current.getBBox();
      const labelBox = labelRef.current.getBBox();
      const totalWidth = valueBox.width + labelBox.width + 4;

      setPositions({
        value: -totalWidth / 2,
        label: -totalWidth / 2 + valueBox.width + 4,
      });
    }
  }, [part]);

  return (
    <animated.g transform={animatedProps.transform}>
      <animated.text
        ref={valueRef}
        style={{
          ...theme.typography.subtitle2,
          fill: showOnTop
            ? theme.palette.text.mediumEmphasis
            : theme.palette.text.primaryInvert.main,
        }}
        dominantBaseline="central"
        transform={`translate(${positions.value}, ${
          showOnTop ? `-${topOffset}` : "0"
        })`}
      >
        {part.data.value}
      </animated.text>
      <animated.text
        ref={labelRef}
        style={{
          ...theme.typography.body2,
          fill: showOnTop
            ? theme.palette.text.mediumEmphasis
            : theme.palette.text.primaryInvert.main,
        }}
        dominantBaseline="central"
        transform={`translate(${positions.label}, ${
          showOnTop ? `-${topOffset}` : "0"
        })`}
      >
        {part.data.label}
      </animated.text>
    </animated.g>
  );
};

const PartLabels = <D extends FunnelDatum>({ parts }: PartLabelsProps<D>) => (
  <>
    {parts.map((part) => {
      return <PartLabel key={part.data.id} part={part} />;
    })}
  </>
);

const Part = <D extends FunnelDatum>({
  part,
  areaGenerator,
  borderGenerator,
  disabled,
  rateStartedToPilot,
  ratePilotToAdopt,
}: PartProps<D> & {
  disabled: boolean;
  rateStartedToPilot: number;
  ratePilotToAdopt: number;
}) => {
  const { animate, config: motionConfig } = useMotionConfig();

  const isMediumScreenSize = useMediaQuery(theme.breakpoints.down("lg"));
  const isSmallScreenSize = useMediaQuery(theme.breakpoints.down("md"));

  const animatedAreaPath = useAnimatedPath(
    disabled
      ? `M${part.x0} ${part.y} L${part.x1} ${part.y}`
      : (areaGenerator(part.areaPoints) as string)
  );

  const animatedBorderPath = useAnimatedPath(
    disabled
      ? `M${part.x0} ${part.y} L${part.x1} ${part.y}`
      : (borderGenerator(part.areaPoints) as string)
  );
  const animatedProps = useSpring({
    areaColor: part.color,
    borderWidth: part.borderWidth,
    borderColor: part.borderColor,
    config: motionConfig,
    immediate: !animate,
  });

  let background;
  if (part.data.id === "Started Projects") {
    background = theme.palette.chart.dark.primary;
  }
  if (part.data.id === "Pilot Projects") {
    background = theme.palette.chart.dark.secondary;
  }
  if (part.data.id === "Adoptions") {
    background = theme.palette.chart.medium.primary;
  }

  return (
    <>
      {part.borderWidth > 0 && (
        <>
          {part.data.id === "Started Projects" && (
            <animated.g>
              <defs>
                <clipPath id="clip-right">
                  <rect
                    x={part.x0 - 4}
                    y={part.y0 - 6}
                    width={part.width + 4}
                    height={part.height + 12}
                  />
                </clipPath>
              </defs>
              <animated.path
                d={animatedAreaPath}
                strokeOpacity={0.2}
                stroke={animatedProps.borderColor}
                strokeWidth={animatedProps.borderWidth}
                strokeLinejoin="round"
                strokeLinecap="round"
                clipPath="url(#clip-right)"
              />
            </animated.g>
          )}
          {part.data.id === "Pilot Projects" && (
            <animated.g>
              <defs>
                <clipPath id="clip-middle">
                  <rect
                    x={part.x0}
                    y={part.y0 - 6}
                    width={part.width}
                    height={part.height + 12}
                  />
                </clipPath>
              </defs>
              <animated.path
                d={disabled ? animatedBorderPath : animatedAreaPath}
                stroke={animatedProps.borderColor}
                strokeWidth={animatedProps.borderWidth}
                strokeOpacity={0.2}
                fill={`${background}`}
                clipPath="url(#clip-middle)"
              />
            </animated.g>
          )}
          {part.data.id === "Adoptions" && (
            <animated.g>
              <defs>
                <clipPath id="clip-left">
                  <rect
                    x={part.x0}
                    y={part.y0 - 6}
                    width={part.width + 4}
                    height={part.height + 12}
                  />
                </clipPath>
              </defs>
              <animated.path
                d={animatedAreaPath}
                strokeOpacity={0.2}
                stroke={animatedProps.borderColor}
                strokeWidth={animatedProps.borderWidth}
                strokeLinejoin="round"
                strokeLinecap="round"
                clipPath="url(#clip-left)"
              />
            </animated.g>
          )}
        </>
      )}
      <animated.path
        d={animatedAreaPath}
        fill={`${background}`}
        fillOpacity={part.fillOpacity}
        onMouseEnter={part.onMouseEnter}
        onMouseLeave={part.onMouseLeave}
        onMouseMove={part.onMouseMove}
        onClick={part.onClick}
      />
      <animated.g
        transform={`translate(${part.width}, ${
          isSmallScreenSize ? 140 : isMediumScreenSize ? 200 : 100
        })`}
      >
        {part.data.id === "Pilot Projects" && (
          <animated.text
            textAnchor="middle"
            dominantBaseline="central"
            style={{
              ...theme.typography.h6,
              fill: `${background}`,
            }}
          >
            {isNaN(rateStartedToPilot) ? "--" : rateStartedToPilot}%
          </animated.text>
        )}
        {part.data.id === "Adoptions" && (
          <animated.text
            textAnchor="middle"
            dominantBaseline="central"
            style={{
              ...theme.typography.h6,
              fill: `${background}`,
            }}
            transform={`translate(${part.width}, 0)`}
          >
            {isNaN(ratePilotToAdopt) ? "--" : ratePilotToAdopt}%
          </animated.text>
        )}
      </animated.g>
    </>
  );
};

const Parts = <D extends FunnelDatum>({
  parts,
  areaGenerator,
  borderGenerator,
  disabled,
  rateStartedToPilot,
  ratePilotToAdopt,
}: PartsProps<D> & {
  disabled: boolean;
  rateStartedToPilot: number;
  ratePilotToAdopt: number;
}): ReactElement => {
  return (
    <>
      {parts.map((part) => {
        return (
          <>
            <Part<D>
              key={part.data.id}
              part={part}
              areaGenerator={areaGenerator}
              borderGenerator={borderGenerator}
              disabled={disabled}
              ratePilotToAdopt={ratePilotToAdopt}
              rateStartedToPilot={rateStartedToPilot}
            />
          </>
        );
      })}
    </>
  );
};

export default ConversionRate;
