import axios from "axios";
import {
  EditProjectHistoryDTO,
  ProjectHistory,
  ProjectHistoryDTO,
} from "../../Types/ProjectHistory";
import ProjectHistoryMapper from "./ProjectHistory.mapper";

export default class ProjectHistoryHttpService {
  public static async getProjectHistory(
    projectId: number
  ): Promise<ProjectHistory[]> {
    const response = await axios.get<ProjectHistoryDTO[]>(
      `/api/projects/${projectId}/history`
    );
    return ProjectHistoryMapper.map(response.data);
  }

  public static async createProjectHistory(
    history: Partial<ProjectHistory>
  ): Promise<void> {
    await axios.post<void>("/api/project-history", history);
  }

  public static async updateProjectHistory(
    history: EditProjectHistoryDTO
  ): Promise<void> {
    await axios.put<void>(`/api/project-history/${history.id}`, history);
  }

  public static async deleteProjectHistory(
    projectHistoryId: number
  ): Promise<void> {
    await axios.delete<void>(`/api/project-history/${projectHistoryId}`);
  }
}
