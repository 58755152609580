import {
  Autocomplete,
  Box,
  IconButton,
  Radio,
  Stack,
  styled,
  TextField,
  Typography,
} from "@mui/material";
import { ReactElement, useEffect, useState } from "react";
import theme from "../../../../theme";
import ClientContactSelect from "../../InputFields/ClientContactSelect";
import StartupSelect from "../../InputFields/StartupSelect";
import {
  CreateLeadProjectDTO,
  LeadProjectOriginType,
  ThirdPartyCategoryType,
} from "../../../../Types/LeadProject";
import { Startup } from "../../../../Types/Startup";
import { SelectInput } from "../../InputFields/SelectInput";
import { ThirPartyCategoryValues } from "../../../../Constants/ThirdPartyCategories";
import { Add } from "@mui/icons-material";
import CustomCreateEntityByName from "../CustomCreateEntityByName/CustomCreateEntityByName";
import useCategoryNames from "../../../../Hooks/useCategoryNames";

const StyledOriginTypeContainer = styled(Stack)({
  border: `1px solid ${theme.palette.borderOutline.soft}`,
  borderRadius: theme.shape.radius.minimal,
  padding: theme.spacing(2),
  gap: 24,
  transition: "box-shadow 0.3s ease-in-out",
});

interface CreateLeadOriginProps {
  leadProject: CreateLeadProjectDTO;
  setLeadProject: React.Dispatch<Partial<CreateLeadProjectDTO>>;
}

function CreateLeadOrigin(props: CreateLeadOriginProps): ReactElement {
  const { leadProject, setLeadProject } = props;
  const { categoryNames, createCategoryName } = useCategoryNames(
    leadProject.ventureClientId,
    leadProject.originThirdPartyCategory
  );

  const [currentOrigin, setCurrentOrigin] = useState<LeadProjectOriginType>(
    leadProject.originType || "Organizational Unit"
  );
  const [categoryInputValue, setCategoryInputValue] = useState("");
  const [createCategoryNameModalOpen, setCreateCategoryNameModalOpen] =
    useState(false);

  useEffect(() => {
    if (!leadProject.originType) {
      setLeadProject({ originType: "Organizational Unit" });
      setCurrentOrigin("Organizational Unit");
    }
  }, [leadProject]);

  const handleSelectStartup = (selectedStartup: Startup | null) => {
    setLeadProject({
      originStartup: selectedStartup || undefined,
      originStartupId: selectedStartup?.id,
    });
  };

  const handleCreateCategoryName = (name: string) => {
    createCategoryName(name, (newCategoryName) => {
      setLeadProject({ originThirdPartyCategoryNameId: newCategoryName.id });
      setCreateCategoryNameModalOpen(false);
    });
  };

  const handleSelectOrigin = (origin: LeadProjectOriginType) => {
    setCurrentOrigin(origin);
    setLeadProject({
      originType: origin,
      originContact: undefined,
      originContactId: null,
      originStartup: undefined,
      originStartupId: null,
      originThirdPartyCategory: undefined,
      originThirdPartyCategoryName: undefined,
      originThirdPartyCategoryNameId: null,
      initiativeNameId: null,
      initiativeName: null,
      initiativeType: null,
    });
  };

  const originControlProps = (origin: LeadProjectOriginType) => ({
    title: origin,
    selected: origin === currentOrigin,
    onSelect: () => handleSelectOrigin(origin),
  });

  return (
    <Stack gap={1}>
      <Typography variant="body2">
        Select the party who actively initiated this Lead.
      </Typography>
      <OriginType
        description="The organizational unit approached an organizational unit with a use case or startup."
        {...originControlProps("Organizational Unit")}
      >
        <ClientContactSelect
          editMode
          labelText="Contact"
          ventureClientId={leadProject.ventureClientId}
          onChange={setLeadProject}
          contactData={leadProject.originContact}
          fieldId="originContactId"
          field="originContact"
        />
      </OriginType>
      <OriginType
        description="The Venture Client Unit approached the organizational unit with a use case or startup."
        {...originControlProps("Venture Client Unit")}
      />
      <OriginType
        description="A startup reached out to the Venture Client Unit or organizational unit."
        {...originControlProps("Startup")}
      >
        <StartupSelect
          label="Startup"
          data-testid="add-startup-input"
          assignedStartupIds={undefined}
          handleSelectStartup={handleSelectStartup}
          selectedStartup={leadProject.originStartup}
        />
      </OriginType>
      <OriginType
        description="A third party (e.g. venture capital firm or service provider) reached out with a startup referral."
        {...originControlProps("Third Party")}
      >
        <Stack gap={3}>
          <SelectInput
            label="Category"
            data-testid="category-select-input"
            selectValues={ThirPartyCategoryValues}
            value={leadProject.originThirdPartyCategory}
            disableSorting
            onChange={(e) =>
              setLeadProject({
                originThirdPartyCategory: e.target
                  .value as ThirdPartyCategoryType,
                originThirdPartyCategoryNameId: undefined,
                originThirdPartyCategoryName: undefined,
              })
            }
            editMode
          />
          <Autocomplete
            id="category-name"
            forcePopupIcon={false}
            options={categoryNames}
            filterSelectedOptions
            filterOptions={(options) => options}
            getOptionLabel={(option) => option.value}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            onInputChange={(_, newInputValue) =>
              setCategoryInputValue(newInputValue)
            }
            onChange={(_, selectedCategoryName) => {
              setLeadProject({
                originThirdPartyCategoryNameId: selectedCategoryName?.id,
                originThirdPartyCategoryName: selectedCategoryName || undefined,
              });
            }}
            noOptionsText="No category name found"
            value={
              categoryNames.find(
                (name) => name.id === leadProject.originThirdPartyCategoryNameId
              ) || null
            }
            renderOption={(props, option) => (
              <li {...props} key={option.id}>
                {option.value}
              </li>
            )}
            disabled={!leadProject.originThirdPartyCategory}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Name"
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {params.InputProps.endAdornment}
                      <IconButton
                        data-testid="add-new-category-name-button"
                        onClick={() => setCreateCategoryNameModalOpen(true)}
                        disabled={!leadProject.originThirdPartyCategory}
                      >
                        <Add fontSize="small" />
                      </IconButton>
                    </>
                  ),
                }}
                inputProps={{
                  ...params.inputProps,
                  "data-testid": "category-name-input",
                }}
              />
            )}
          />
        </Stack>
      </OriginType>
      {createCategoryNameModalOpen && (
        <CustomCreateEntityByName
          name="categoryName"
          label="Category Name"
          maxCharacter={50}
          modalOpen={createCategoryNameModalOpen}
          setModalOpen={setCreateCategoryNameModalOpen}
          handleCreate={handleCreateCategoryName}
          initialValue={categoryInputValue}
        />
      )}
    </Stack>
  );
}

interface OriginTypeProps {
  children?: React.ReactNode;
  title: string;
  description: string;
  selected: boolean;
  onSelect: () => void;
}

function OriginType(props: OriginTypeProps): ReactElement {
  const { children, title, description, selected, onSelect } = props;

  return (
    <StyledOriginTypeContainer
      sx={{ boxShadow: selected ? theme.boxShadows[0] : undefined }}
    >
      <Box display="flex" gap={1}>
        <Box>
          <Radio
            onChange={onSelect}
            checked={selected}
            sx={{ padding: 0.25 }}
            data-testid={`origin-${title}`}
          />
        </Box>
        <Stack>
          <Typography variant="subtitle2">{title}</Typography>
          <Typography variant="body2" color="text.mediumEmphasis">
            {description}
          </Typography>
        </Stack>
      </Box>
      {children && selected && (
        <Box padding={theme.spacing(0, 2, 0, 4)}>{children}</Box>
      )}
    </StyledOriginTypeContainer>
  );
}

export default CreateLeadOrigin;
