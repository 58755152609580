import { Close } from "@mui/icons-material";
import { Box, Chip, Dialog, DialogTitle, IconButton } from "@mui/material";
import { ReactElement } from "react";
import theme from "../../../../theme";
import { Startup } from "../../../../Types/Startup";
import ScrollableDialogContent from "../../../UI/Modals/ScrollableDialogContent";
import StartupCard from "../../StartupCard";
import { CompetitorsInfoTooltip } from "./CompetitorsInfoTooltip";

interface CompetitorsModalProps {
  competitors: Startup[];
  modalOpen: boolean;
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onCompetitorAddedToProject: () => void;
  isListInaccurate: boolean;
}

export default function CompetitorsModal(
  props: CompetitorsModalProps
): ReactElement {
  return (
    <Dialog
      open={props.modalOpen}
      fullWidth
      onClose={() => {
        props.setModalOpen(false);
      }}
      maxWidth="md"
      data-testid="competitors-modal"
      PaperProps={{
        sx: {
          gap: theme.spacing(4),
        },
      }}
    >
      <DialogTitle sx={{ pb: 0 }}>
        Competitors
        <CompetitorsInfoTooltip isListInaccurate={props.isListInaccurate} />
        <Chip
          variant="counter"
          color="info"
          label={props.competitors.length}
          sx={{ mt: "1px", ml: theme.spacing(1) }}
        />
        <IconButton
          sx={{ ml: "auto", p: 0, color: "icon.primary" }}
          onClick={() => props.setModalOpen(false)}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <ScrollableDialogContent>
        <Box
          id="startup-list"
          display="grid"
          boxSizing="border-box"
          gap={3}
          //max size of column => 200px, no. column+1 => 7,
          gridTemplateColumns="repeat(auto-fill,minmax(max(190px,(100% - 1px)/7), 1fr))"
          py={1}
        >
          {props.competitors.map((competitor) => (
            <StartupCard
              startup={competitor}
              key={competitor.id}
              onStartupAddedToProject={props.onCompetitorAddedToProject}
              hasShadow
              showWebsite
            />
          ))}
        </Box>
      </ScrollableDialogContent>
    </Dialog>
  );
}
