import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
} from "@mui/material";
import { AxiosError } from "axios";
import { enqueueSnackbar } from "notistack";
import { ReactElement, Reducer, useReducer } from "react";
import ProjectHistoryHttpService from "../../../../../Http/ProjectHistory/ProjectHistory.http.service";
import { ProjectHistory } from "../../../../../Types/ProjectHistory";
import { getErrorMessage } from "../../../../../utils";

interface Props {
  modalOpen: boolean;
  setModalOpen: (state: boolean) => void;
  history?: ProjectHistory;
  projectId: number;
  handleSave: (newComment?: string) => void;
  openAssociatedModal: boolean;
  setProjectStatusOverviewModal: (state: boolean) => void;
  refreshHistory?: () => void;
}

const ManageStatusComment = (props: Props): ReactElement => {
  const [newHistoryItem, setNewHistoryItem] = useReducer<
    Reducer<ProjectHistory, Partial<ProjectHistory>>
  >(
    (state, newState) => ({ ...state, ...newState }),
    props.history ||
      ({ projectId: props.projectId, description: "" } as ProjectHistory)
  );

  const handleAddComment = async () => {
    if (newHistoryItem.description.trim().length === 0) {
      enqueueSnackbar(`Please enter a comment`, {
        variant: "error",
      });
      return;
    }

    await ProjectHistoryHttpService.createProjectHistory({
      description: newHistoryItem.description,
      projectId: props.projectId,
      type: "Project Status Comment",
    })
      .then(() => {
        props.handleSave();
        props.setModalOpen(false);
        if (props.openAssociatedModal) {
          props.setProjectStatusOverviewModal(true);
        }
      })
      .catch((error) => {
        const errorMessage = getErrorMessage(error as AxiosError);
        enqueueSnackbar(`Could not add the comment: ${errorMessage}`, {
          variant: "error",
        });
      });
  };

  const handleUpdateComment = async () => {
    if (newHistoryItem.description.trim().length === 0) {
      enqueueSnackbar(`Please enter a comment`, {
        variant: "error",
      });
      return;
    }

    if (!props.history) return;
    const updatedHistory = {
      id: props.history.id,
      description: newHistoryItem.description,
    };
    await ProjectHistoryHttpService.updateProjectHistory(updatedHistory)
      .then(() => {
        enqueueSnackbar("Status Comment successfully saved", {
          variant: "success",
        });
        if (props.refreshHistory) props.refreshHistory();
        props.handleSave();
        props.setModalOpen(false);
      })
      .catch((error) => {
        const errorMessage = getErrorMessage(error as AxiosError);
        enqueueSnackbar(`Could not update the comment: ${errorMessage}`, {
          variant: "error",
        });
      });
  };

  const handleCancel = () => {
    props.setModalOpen(false);
    if (props.openAssociatedModal) props.setProjectStatusOverviewModal(true);
  };

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={props.modalOpen}
      data-testid="status-comment-modal"
    >
      <Stack gap={4}>
        <DialogTitle>{newHistoryItem.id ? "Edit" : "Add"} Comment</DialogTitle>
        <DialogContent>
          <TextField
            data-testid="input-comment"
            label="Comment"
            value={newHistoryItem.description}
            onChange={(e) => setNewHistoryItem({ description: e.target.value })}
            multiline
            fullWidth
            minRows={3}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel}>Cancel</Button>
          <Button
            onClick={() => {
              props.history ? handleUpdateComment() : handleAddComment();
            }}
            variant="contained"
          >
            Save
          </Button>
        </DialogActions>
      </Stack>
    </Dialog>
  );
};

export default ManageStatusComment;
