import Box from "@mui/material/Box";
import { ReactElement, useCallback, useEffect, useRef, useState } from "react";
import { Prompt, useHistory, useParams } from "react-router-dom";
import { ProjectHttpService } from "../../Http/Project/Project.http.service";
import { FunnelStage, Project } from "../../Types/Project";
import AdoptStage from "./FunnelStages/Adopt/AdoptStage";
import AssessStage from "./FunnelStages/Assess/AssessStage";
import BuyStage from "./FunnelStages/Buy/BuyStage";
import DiscoverStage from "./FunnelStages/Discover/DiscoverStage/DiscoverStage";
import PilotStage from "./FunnelStages/Pilot/PilotStage";
import StageValidator, {
  getSelectedStartupName,
} from "../../StageValidation/StageValidator";
import { useSnackbar } from "notistack";
import StageConfirmationModal from "./SharedComponents/FunnelStageComponents/StageConfirmationModal";
import StageStepper from "./SharedComponents/FunnelStageComponents/StageStepper/StageStepper";
import StageValidationModal from "./SharedComponents/FunnelStageComponents/StageValidationModal";
import { GlobalProjectEditContext } from "../../Context/ProjectDetailsContext";
import { funnelStages } from "../../Constants/FunnelStages";
import UnsavedChangesModal from "./UnsavedChangesModal";
import { Location } from "history";
import ProjectOverview from "./SharedComponents/ProjectOverview/ProjectOverview";
import theme from "../../theme";
import ProjectDetailsNavigation from "./SharedComponents/ProjectDetailsNavigation/ProjectDetailsNavigation";
import ProjectDetailsHeader from "./SharedComponents/ProjectDetailsHeader/ProjectDetailsHeader";
import ContentWrapper from "./ContentWrapper";
import { Container } from "@mui/material";
import Banner from "../UI/Banner";
import useElementHeight from "../../Hooks/useElementHeight";
import ProjectDetailsPageSkeleton from "./ProjectDetailsPageSkeleton";
import { OFFTRACK_FLAG_BANNER_TOOLTIP } from "../../Constants/TooltipText";
import { Opportunity } from "../../Types/Opportunity";
import { getErrorMessage } from "../../utils";
import { OpportunitiesLoadingContext } from "../../Context/OpportunitiesLoaderContext";

export default function ProjectDetailsPage(): ReactElement {
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const [refresh, setRefresh] = useState(false);
  const [projectData, setProjectData] = useState<Project>();
  const [opportunityData, setOpportunityData] = useState<Opportunity[]>([]);
  const [opportunitiesLoading, setOpportunitiesLoading] = useState(true);
  const [activeStep, setActiveStep] = useState<number | null>(null);
  const [selectedStep, setSelectedStep] = useState<number | null>(null);
  const [activeTabs, setActiveTabs] = useState<number[]>([0]);
  const [fullWidthSectionOpen, setFullWidthSectionOpen] = useState(false);
  const [validationDialogOpen, setValidationDialogOpen] =
    useState<boolean>(false);
  const [proceedToBuyDialogOpen, setProceedToBuyDialogOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [globalEditMode, setGlobalEditMode] = useState(false);
  const [unsavedModalOpen, setUnsavedModalOpen] = useState(false);
  const [navigationCallback, setNavigationCallback] = useState<
    (() => void) | null
  >();
  const [nextLocation, setNextLocation] = useState<Location | null>(null);
  const [shouldSaveProject, setShouldSaveProject] = useState(false);
  const [activeTab, setActiveTab] = useState<number | null>(0);

  const sectionRefArray = useRef<HTMLDivElement[]>([]);

  const [archiveBannerHeight, archiveBannerRef] = useElementHeight();
  const [projectNameHeight, projectNameRef] = useElementHeight();
  const [bottomHeaderHeight, bottomHeaderRef] = useElementHeight();
  const [stageStepperHeight, stageStepperRef] = useElementHeight();

  const handleRefs = useCallback(
    (element: HTMLDivElement | null, index: number) => {
      if (!element || sectionRefArray.current.includes(element)) return;
      sectionRefArray.current.splice(index, 0, element);
    },
    []
  );

  const topHeaderPadding = parseInt(theme.spacing(10.5));
  const headerPadding = parseInt(theme.spacing(3));

  const [headerMaxHeight, setHeaderMaxHeight] = useState(
    topHeaderPadding + headerPadding + projectNameHeight
  );

  useEffect(() => {
    setHeaderMaxHeight(topHeaderPadding + headerPadding + projectNameHeight);
  }, [projectNameHeight]);

  useEffect(() => {
    const fetchData = async () => {
      ProjectHttpService.getProjectById(id)
        .then((fetchedProjectData) => {
          setProjectData(fetchedProjectData);
          let stepToDisplay = funnelStages.indexOf(
            fetchedProjectData.funnelStage
          );
          if (selectedStep !== null) stepToDisplay = selectedStep;
          if (fetchedProjectData.funnelStage === "discover") {
            setActiveStep(0);
          } else {
            setActiveStep(stepToDisplay);
          }
        })
        .catch(() => {
          history.push("/projects");
        });

      ProjectHttpService.getProjectOpportunities(id)
        .then((fetchedOpportunities) => {
          setOpportunityData(fetchedOpportunities);
          setOpportunitiesLoading(false);
        })
        .catch((error) => {
          const errorMessage = getErrorMessage(error);
          return enqueueSnackbar(`Could not get startups: ${errorMessage}`, {
            variant: "error",
          });
        });
    };

    fetchData();
  }, [refresh]);

  useEffect(() => {
    setSelectedStep(activeStep);
  }, [activeStep]);

  useEffect(() => {
    if (shouldSaveProject) {
      if (navigationCallback) {
        navigationCallback();
        setNavigationCallback(null);
        setNextLocation(null);
        setShouldSaveProject(false);
        setGlobalEditMode(false);
      } else if (nextLocation) {
        history.push(nextLocation.pathname);
      }
    }
  }, [shouldSaveProject]);

  useEffect(() => {
    if (projectData?.name) {
      document.title = `${projectData?.name} - Project`;
    } else {
      document.title = `Project`;
    }
  }, [projectData?.name]);

  const handleBlockedNavigation = (callback?: () => void): boolean => {
    if (!globalEditMode || shouldSaveProject) {
      if (!callback) return true;
      else callback();
    } else if (!shouldSaveProject) {
      setUnsavedModalOpen(true);
      if (callback) setNavigationCallback(() => () => callback());
      return false;
    }

    setGlobalEditMode(false);
    return false;
  };

  const handleSave = (withScroll = true) => {
    setRefresh((prevRefresh) => !prevRefresh);
    if (withScroll) {
      window.scrollTo({ behavior: "smooth", top: 0 });
    }
  };

  const handleSaveNoScroll = () => {
    setRefresh((prevRefresh) => !prevRefresh);
  };

  const getCurrentFunnelStageIndex = () => {
    if (projectData) {
      return funnelStages.indexOf(projectData.funnelStage);
    }
    return 0;
  };

  const getNextFunnelStage = (): FunnelStage => {
    return funnelStages[getCurrentFunnelStageIndex() + 1];
  };

  const confirmMoveToNextStage = async () => {
    setSelectedStep(null);
    await setFunnelStage(getNextFunnelStage());
    setValidationDialogOpen(false);
  };

  const overrideValidationRules = async () => {
    if (projectData?.funnelStage === "assess") {
      const selectedStartupName = getSelectedStartupName(projectData);
      if (!selectedStartupName) {
        return enqueueSnackbar("Please select the startup for Pilot", {
          variant: "error",
        });
      } else {
        setProceedToBuyDialogOpen(true);
        setValidationDialogOpen(false);
        return;
      }
    }
    await confirmMoveToNextStage();
  };

  // Check stage validation before moving to a new stage
  // Get confirmation before moving project to Buy stage
  const moveProjectToTheNewStage = async () => {
    if (projectData) {
      if (StageValidator.getInvalidProperties(projectData).length > 0) {
        setValidationDialogOpen(true);
      } else if (
        getNextFunnelStage().toLocaleLowerCase() === "buy" &&
        !proceedToBuyDialogOpen
      ) {
        setProceedToBuyDialogOpen(true);
      } else {
        setSelectedStep(null);
        await setFunnelStage(getNextFunnelStage());
      }
    }
  };

  const setFunnelStage = async (funnelStage: FunnelStage) => {
    if (projectData) {
      await ProjectHttpService.updateProject({
        ...projectData,
        funnelStage: funnelStage,
      });
      handleSave();
    }
  };

  const handleClickTab = (index: number, fullWidth?: boolean) => {
    const isTabAlreadyActive = activeTabs.includes(index);
    if (!isTabAlreadyActive) {
      const openTabs = [...activeTabs, index];
      setActiveTabs(openTabs);
    }
    setActiveTab(index);
    isTabAlreadyActive
      ? handleScrollToSection(index)
      : setTimeout(() => handleScrollToSection(index), 200);

    setFullWidthSectionOpen(isTabAlreadyActive ? !!fullWidth : false);
  };

  const handleClickSection = (index: number, controlFullWidth?: boolean) => {
    const openTabs = activeTabs.includes(index)
      ? activeTabs.filter((value) => value !== index)
      : [...activeTabs, index];
    setActiveTabs(() => {
      if (controlFullWidth && !fullWidthSectionOpen) return [index];
      return openTabs;
    });
    setActiveTab(openTabs.includes(index) ? index : null);

    controlFullWidth
      ? setFullWidthSectionOpen(!fullWidthSectionOpen)
      : setFullWidthSectionOpen(false);
  };

  const handleChangeStep = (step: number) => {
    handleBlockedNavigation(() => {
      if (sectionRefArray.current) sectionRefArray.current = [];
      setActiveStep(step);
      setActiveTabs([0]);
      setActiveTab(0);
      setFullWidthSectionOpen(false);
    });
  };

  const handleScrollToSection = (index: number | null) => {
    if (index === null) return;

    const gapHeight = parseInt(theme.spacing(3));
    const topHeaderStickyDistance = parseInt(theme.spacing(3));

    const topScroll =
      sectionRefArray.current[index].getBoundingClientRect().top -
      sectionRefArray.current[0].getBoundingClientRect().top +
      topHeaderStickyDistance +
      bottomHeaderHeight +
      stageStepperHeight +
      gapHeight;

    window.scrollTo({
      top: topScroll,
      behavior: "smooth",
    });
  };

  return (
    <>
      {!projectData ? (
        <ProjectDetailsPageSkeleton />
      ) : (
        <Box
          maxWidth={`calc(100vw - ${theme.spacing(9)})`}
          data-testid="project-details-page"
        >
          <GlobalProjectEditContext.Provider
            value={{
              globalEditMode,
              setGlobalEditMode,
              activeStep,
              shouldSaveProject,
              setShouldSaveProject,
            }}
          >
            <UnsavedChangesModal
              modalOpen={unsavedModalOpen}
              setModalOpen={setUnsavedModalOpen}
            />
            <Prompt
              when={true}
              message={(location) => {
                setNextLocation(location);
                return handleBlockedNavigation();
              }}
            />
            {projectData && (
              <ProjectDetailsHeader
                getNextFunnelStage={getNextFunnelStage}
                moveProjectToTheNewStage={moveProjectToTheNewStage}
                projectData={projectData}
                setFunnelStage={setFunnelStage}
                projectNameRef={projectNameRef}
                bottomHeaderRef={bottomHeaderRef}
                handleSave={handleSave}
              />
            )}
            {projectData?.offTrack && (
              <Banner
                ref={archiveBannerRef}
                type="flag"
                top={headerMaxHeight + "px"}
                mainText="This project is off track!"
                tooltipText={OFFTRACK_FLAG_BANNER_TOOLTIP}
              />
            )}
            {projectData?.status == "archived" && (
              <Banner
                ref={archiveBannerRef}
                type="warning"
                top={headerMaxHeight + "px"}
                mainText="This project has been archived!"
                subText="Be sure to set it to active if you want to move it to other stages."
              />
            )}
            {projectData && activeStep !== null && (
              <Box
                position="sticky"
                zIndex={2}
                top={headerMaxHeight + "px"}
                bgcolor={theme.palette.background.default}
                pt={5}
                ref={stageStepperRef}
              >
                <Container>
                  <StageStepper
                    activeStepIndex={activeStep}
                    handleChangeStep={handleChangeStep}
                    funnelStages={funnelStages}
                    currentStageIndex={getCurrentFunnelStageIndex()}
                    projectData={projectData}
                  />
                </Container>
              </Box>
            )}
            {projectData && activeStep !== null && (
              <Box
                position="sticky"
                zIndex={3}
                top={headerMaxHeight + archiveBannerHeight + "px"}
                bgcolor={theme.palette.background.default}
              >
                <Container>
                  <ProjectDetailsNavigation
                    handleChangeTab={handleClickTab}
                    headerHeight={headerMaxHeight}
                    archiveBannerHeight={archiveBannerHeight}
                    activeTab={activeTab}
                  />
                </Container>
              </Box>
            )}

            <ContentWrapper position="relative" zIndex={2} sx={{ pt: 5 }}>
              <OpportunitiesLoadingContext.Provider
                value={{
                  opportunitiesLoading,
                  setOpportunitiesLoading,
                }}
              >
                {projectData && (
                  <Box
                    display="grid"
                    gridTemplateColumns={`minmax(0, ${
                      fullWidthSectionOpen ? "0fr" : "1fr"
                    }) minmax(0, ${fullWidthSectionOpen ? "1fr" : "3fr"})`}
                    gap={fullWidthSectionOpen ? 0 : theme.spacing(3)}
                    sx={{ transition: "all 0.5s" }}
                    position="relative"
                  >
                    {activeStep !== null && (
                      <Box
                        sx={{
                          transition: "max-width 1s, opacity 0.5s",
                          opacity: fullWidthSectionOpen ? 0 : 1,
                        }}
                        overflow="hidden"
                      >
                        <ProjectOverview
                          project={projectData}
                          funnelStage={funnelStages[activeStep]}
                          handleSave={() => handleSave(false)}
                          moveProjectToTheNewStage={moveProjectToTheNewStage}
                        />
                      </Box>
                    )}
                    {activeStep === -1 && projectData && (
                      <DiscoverStage
                        opportunities={opportunityData}
                        projectData={projectData}
                        handleSave={handleSave}
                        handleSaveNoScroll={handleSaveNoScroll}
                        moveProject={moveProjectToTheNewStage}
                        handleChangeTab={handleClickSection}
                        activeTabs={activeTabs}
                        handleRefs={handleRefs}
                        setFullWidthSectionOpen={setFullWidthSectionOpen}
                        fullWidthSectionOpen={fullWidthSectionOpen}
                      />
                    )}
                    {activeStep === 0 && projectData && (
                      <DiscoverStage
                        projectData={projectData}
                        opportunities={opportunityData}
                        handleSave={handleSave}
                        handleSaveNoScroll={handleSaveNoScroll}
                        moveProject={moveProjectToTheNewStage}
                        handleChangeTab={handleClickSection}
                        activeTabs={activeTabs}
                        handleRefs={handleRefs}
                        setFullWidthSectionOpen={setFullWidthSectionOpen}
                        fullWidthSectionOpen={fullWidthSectionOpen}
                      />
                    )}
                    {activeStep === 1 && projectData && (
                      <AssessStage
                        opportunities={opportunityData}
                        projectData={projectData}
                        handleSave={handleSave}
                        handleSaveNoScroll={handleSaveNoScroll}
                        moveProject={moveProjectToTheNewStage}
                        handleChangeTab={handleClickSection}
                        handleBlockedNavigation={handleBlockedNavigation}
                        activeTabs={activeTabs}
                        handleRefs={handleRefs}
                        setFullWidthSectionOpen={setFullWidthSectionOpen}
                        fullWidthSectionOpen={fullWidthSectionOpen}
                      />
                    )}
                    {activeStep === 2 && projectData && (
                      <BuyStage
                        projectData={projectData}
                        handleSaveNoScroll={handleSaveNoScroll}
                        moveProject={moveProjectToTheNewStage}
                        handleChangeTab={handleClickSection}
                        activeTabs={activeTabs}
                        handleRefs={handleRefs}
                        setFullWidthSectionOpen={setFullWidthSectionOpen}
                        fullWidthSectionOpen={fullWidthSectionOpen}
                      />
                    )}
                    {activeStep === 3 && projectData && (
                      <PilotStage
                        projectData={projectData}
                        handleSaveNoScroll={handleSaveNoScroll}
                        moveProject={moveProjectToTheNewStage}
                        handleChangeTab={handleClickSection}
                        activeTabs={activeTabs}
                        handleRefs={handleRefs}
                        setFullWidthSectionOpen={setFullWidthSectionOpen}
                        fullWidthSectionOpen={fullWidthSectionOpen}
                      />
                    )}
                    {activeStep === 4 && projectData && (
                      <AdoptStage
                        projectData={projectData}
                        handleSaveNoScroll={handleSaveNoScroll}
                        handleChangeTab={handleClickSection}
                        activeTabs={activeTabs}
                        handleRefs={handleRefs}
                        setFullWidthSectionOpen={setFullWidthSectionOpen}
                        fullWidthSectionOpen={fullWidthSectionOpen}
                      />
                    )}
                  </Box>
                )}
              </OpportunitiesLoadingContext.Provider>
            </ContentWrapper>
          </GlobalProjectEditContext.Provider>
          {projectData && (
            <StageValidationModal
              projectData={projectData}
              setValidationDialogOpen={setValidationDialogOpen}
              modalOpen={validationDialogOpen}
              overrideValidationRules={overrideValidationRules}
            />
          )}
          {projectData && (
            <StageConfirmationModal
              setModalOpen={setProceedToBuyDialogOpen}
              modalOpen={proceedToBuyDialogOpen}
              moveProject={confirmMoveToNextStage}
              projectData={projectData}
            />
          )}
        </Box>
      )}
    </>
  );
}
