import { Box, Tab, Tabs, styled } from "@mui/material";
import { ReactElement, useContext, useEffect, useState } from "react";
import { funnelStages } from "../../../../Constants/FunnelStages";
import { FunnelStage } from "../../../../Types/Project";
import theme from "../../../../theme";
import { GlobalProjectEditContext } from "../../../../Context/ProjectDetailsContext";
import useElementHeight from "../../../../Hooks/useElementHeight";

const stageSections: { [stage in FunnelStage]: string[] } = {
  discover: [
    "Problem Insights",
    "Solution Requirements",
    "Stakeholders",
    "Strategic Benefits",
    "Startup Supply",
    "Curated List",
  ],
  assess: ["Qualified List", "Demos", "Assessment Conclusion"],
  buy: [
    "Buy Insights",
    "Objectives & KPIs",
    "Project Timeline",
    "Strategic Benefits",
  ],
  pilot: [
    "Pilot Insights",
    "Objectives & KPIs",
    "Project Timeline",
    "Strategic Benefits",
    "Pilot Conclusion",
  ],
  adopt: ["Adoption Insights"],
};

const StyledBox = styled(Box)({
  position: "relative",
  transition: "none",
  width: "100%",
  display: "flex",
  justifyContent: "center",
  backgroundColor: theme.palette.surface.primary.main,
  "&.stickied": {
    width: `calc(100vw - ${theme.spacing(9)})`,
    marginLeft: `calc((100vw - 100% - ${theme.spacing(9)})/-2)`,
    boxShadow: theme.boxShadows[3],
  },
});

const StyledTabs = styled(Tabs)({
  "& .MuiTabs-flexContainer": {
    borderBottom: "none",
  },
  "& .MuiTab-root": {
    padding: theme.spacing(2, 3),
    ...theme.typography.subtitle2,
    "&.Mui-selected": {
      color: theme.palette.text.action.main,
    },
  },
  "& .MuiTabs-indicator": {
    backgroundColor: theme.palette.surface.action.main,
  },
});

interface ProjectDetailsNavigationProps {
  handleChangeTab: (index: number, fullWidth?: boolean) => void;
  headerHeight: number;
  archiveBannerHeight: number;
  activeTab: number | null;
}

const ProjectDetailsNavigation = ({
  handleChangeTab,
  headerHeight,
  archiveBannerHeight,
  activeTab,
}: ProjectDetailsNavigationProps): ReactElement => {
  const { activeStep } = useContext(GlobalProjectEditContext);
  const sections =
    (activeStep !== null && stageSections[funnelStages[activeStep]]) || [];
  const [isStickied, setIsStickied] = useState(false);

  const [navigationHeight, navigationRef] = useElementHeight();

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([e]) => {
        if (e.intersectionRatio < 1) {
          setIsStickied(
            e.boundingClientRect.bottom <=
              headerHeight + navigationHeight + archiveBannerHeight + 1
          );
        }
      },
      {
        root: null,
        threshold: 0,
        rootMargin: `-${
          headerHeight + navigationHeight + archiveBannerHeight + 1
        }px 0px 0px 0px`,
      }
    );

    navigationRef.current && observer.observe(navigationRef.current);

    return () => {
      navigationRef.current && observer.unobserve(navigationRef.current);
    };
  }, [navigationRef, navigationHeight, headerHeight, archiveBannerHeight]);

  return (
    <>
      {sections && (
        <StyledBox
          id="project-details-navigation"
          className={isStickied ? "stickied" : ""}
          ref={navigationRef}
        >
          <StyledTabs value={activeTab}>
            {sections.map((tab, index) => {
              const isCuratedorQualified =
                tab === "Curated List" || tab === "Qualified List";
              return (
                <Tab
                  label={tab}
                  key={index}
                  onClick={() => {
                    handleChangeTab(index, isCuratedorQualified);
                  }}
                />
              );
            })}
          </StyledTabs>
        </StyledBox>
      )}
    </>
  );
};

export default ProjectDetailsNavigation;
