import { ReactElement } from "react";
import { CalculationType } from "../../../../Types/ImpactValue";
import { Stack } from "@mui/material";
import Calculation from "./Calculation";
import { calculate, hasMonetaryValue } from "./helper/helper";

interface Props {
  calculations: CalculationType[];
  handleImpactvalue: () => void;
  expandedCalculationId: number | null;
  setExpandedCalculationId: (id: number | null) => void;
  canEdit: boolean;
}

const CalculationContainer = (props: Props): ReactElement => {
  const { calculations, handleImpactvalue, canEdit } = props;

  return (
    <Stack>
      {calculations.map((calculation, index) => {
        const variables = calculation.variables;
        const result = hasMonetaryValue(variables) ? calculate(variables) : 0;
        return (
          <Calculation
            key={calculation.id}
            calculation={calculation}
            index={index}
            variables={variables}
            result={result}
            handleImpactValue={handleImpactvalue}
            expanded={props.expandedCalculationId === calculation.id}
            handleToggleExpand={() => {
              props.setExpandedCalculationId(calculation.id);
            }}
            data-testid={`calculation-${calculation.id}`}
            canEdit={canEdit}
          />
        );
      })}
    </Stack>
  );
};

export default CalculationContainer;
