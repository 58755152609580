import {
  Button,
  Dialog,
  Typography,
  Box,
  Chip,
  IconButton,
  Stack,
  StepConnector,
  stepConnectorClasses,
  styled,
  StepLabel,
  StepIconProps,
  DialogActions,
  DialogTitle,
} from "@mui/material";
import { ReactElement, useContext, useEffect, useState, useMemo } from "react";
import { Link } from "react-router-dom";
import { ProjectHttpService } from "../../../../Http/Project/Project.http.service";
import theme from "../../../../theme";
import { ProjectStartupOpportunityAssociation } from "../../../../Types/Project";
import { useSnackbar } from "notistack";
import { GlobalLoaderContext } from "../../../../Context/LoaderContext";
import {
  capitalizeFirstLetter,
  findLogo,
  formatDate,
  getImpactValueByFunnelStage,
  getStatusColor,
} from "../../../../utils";
import CloseIcon from "@mui/icons-material/Close";
import {
  ArrowDropDown,
  ArrowRight,
  Check,
  DesktopWindowsOutlined,
} from "@mui/icons-material";
import ClientContactSelect from "../../InputFields/ClientContactSelect";
import RocketLaunchOutlined from "@mui/icons-material/RocketLaunchOutlined";
import {
  hasAccessToVentureClient,
  UserContext,
} from "../../../../Context/UserContext";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import RatingButton from "../../../ProjectDetails/RatingButton";
import RatingContent from "../../../ProjectDetails/RatingContent";
import ProjectMainInformation from "./ProjectMainInformation";
import ScrollableDialogContent from "../ScrollableDialogContent";
import parse from "html-react-parser";
import TaskStepperSection from "./TaskStepperSection";
import CustomExpendableText from "../../CustomExpendableText";
import ObjectivesOverview from "./ObjectivesOverview";
import TimelineOverview from "./TimelineOverview";
import NumberCard from "../../NumberCard";
import FlagBanner from "../../FlagBanner";

export const ShortDescriptionText = styled(Typography)(() => ({
  overflow: "hidden",
  textOverflow: "ellipsis",
  display: "-webkit-box",
  WebkitLineClamp: 2,
  WebkitBoxOrient: "vertical",
}));

export const StatusCommentText = styled(Typography)(() => ({
  overflow: "hidden",
  textOverflow: "ellipsis",
  display: "-webkit-box",
  WebkitLineClamp: 5,
  WebkitBoxOrient: "vertical",
  "& a": {
    color: theme.palette.text.action.main,
    ...theme.typography.subtitle2,
  },
}));

export const ContactsWrapper = styled(Box)({
  display: "grid",
  gridTemplateColumns: "1fr 1fr",
  gap: theme.spacing(2),
  pointerEvents: "none",
  alignItems: "center",
});

export const ContactBox = styled(Box)({
  height: "100%",
  padding: theme.spacing(2),
  backgroundColor: theme.palette.surface.secondary.main,
});

export const StartupBox = styled(Box)({
  boxShadow: theme.boxShadows[0],
  display: "grid",
  gridTemplateColumns: "1fr 6fr auto",
  gap: theme.spacing(2),
  padding: theme.spacing(2),
  alignItems: "center",
  maxHeight: "107px",
});

export const ProjectCardConnector = styled(StepConnector)(() => ({
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: "transparent",
  },
}));

const ProjectCardStepIconRoot = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  "& .ProjectCardStepIcon-completedIcon": {
    color: "white",
    fontSize: 15,
    borderRadius: "50%",
    padding: "2px",
    backgroundColor: theme.palette.icon.primary,
  },
  "& .ProjectCardStepIcon-circle": {
    width: 15,
    height: 15,
    borderRadius: "50%",
    border: "1px solid currentColor",
  },
}));

export const ProjectCardStepLabel = styled(StepLabel)(({ theme }) => ({
  "& .MuiStepLabel-label": {
    fontSize: theme.typography.overline.fontSize,
    color: theme.palette.text.primary,
  },
  "& .MuiStepLabel-label.Mui-completed,": {
    color: theme.palette.text.primary,
  },
  "& .Mui-disabled": {
    color: theme.palette.text.disabled,
  },
}));

export function ProjectCardStepIcon(props: StepIconProps): ReactElement {
  const { completed, className } = props;
  return (
    <ProjectCardStepIconRoot className={className}>
      {completed ? (
        <Check className="ProjectCardStepIcon-completedIcon" />
      ) : (
        <div className="ProjectCardStepIcon-circle" />
      )}
    </ProjectCardStepIconRoot>
  );
}

const ArrowIconContainer = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  border: "1px solid",
  borderRadius: theme.shape.radius.full,
  height: 16,
  width: 16,
  color: theme.palette.icon.brand.main,
});

interface ProjectDetailsModalProps {
  modalOpen: boolean;
  setModalOpen: (state: boolean) => void;
  projectId: number;
  shouldOpenInNewTab?: boolean;
  hideStageSpecifics?: boolean;
}

export default function ProjectDetailsModal(
  props: ProjectDetailsModalProps
): ReactElement {
  const { enqueueSnackbar } = useSnackbar();
  const { setGlobalLoader } = useContext(GlobalLoaderContext);
  const user = useContext(UserContext);
  const [project, setProject] =
    useState<ProjectStartupOpportunityAssociation>();

  const sortedOpportunities = useMemo(() => {
    return (
      project?.opportunities.sort((a, b) => {
        if (a.isSelectedForPilot && !b.isSelectedForPilot) {
          return -1;
        }
        if (!a.isSelectedForPilot && b.isSelectedForPilot) {
          return 1;
        }

        // If both are selected or not selected for pilot, then consider ratings
        if (a.rating !== null && b.rating !== null) {
          if (a.rating === b.rating) {
            return (
              Number(b.startup.totalFunding) - Number(a.startup.totalFunding)
            );
          } else {
            return a.rating.localeCompare(b.rating);
          }
        } else if (a.rating === null && b.rating !== null) {
          return 1;
        } else if (a.rating !== null && b.rating === null) {
          return -1;
        } else {
          return (
            Number(b.startup.totalFunding) - Number(a.startup.totalFunding)
          );
        }
      }) || []
    );
  }, [project?.opportunities]);

  useEffect(() => {
    setGlobalLoader(true);
    ProjectHttpService.getProjectById(props.projectId, "slim")
      .then((project) => {
        setProject(project as ProjectStartupOpportunityAssociation);
      })
      .catch(() => {
        enqueueSnackbar("Something went wrong with opening the project modal", {
          variant: "error",
        });
      })
      .finally(() => {
        setGlobalLoader(false);
      });
  }, []);

  if (!project) {
    return <></>;
  }

  const statusCommentLastModifiedDate = formatDate(
    project.projectHistoryLatestChange[0]?.dateTriggered
  );
  const lastModifiedBy = project.projectHistoryLatestChange[0]?.user.name;
  const isArchived = project.status === "archived";
  const isOnHold = project.status === "on hold";
  const statusComment = project.projectHistoryLatestChange[0]?.description;
  const onHoldDeadline = project.onHoldDeadline
    ? formatDate(new Date(project.onHoldDeadline))
    : null;
  const statusReason =
    project.projectHistoryLatestChange[0]?.projectStatusChangeReason
      ?.description;
  const isPilotOrAdopt =
    (project.funnelStage === "adopt" || project.funnelStage === "pilot") &&
    !props.hideStageSpecifics;
  const isBuyStage = project.funnelStage === "buy" && !props.hideStageSpecifics;
  const isDiscover = project.funnelStage === "discover";
  const isAssess =
    project.funnelStage === "assess" ||
    (props.hideStageSpecifics && project.funnelStage !== "discover");
  const opportunitiesToDisplay =
    (() => {
      if (isPilotOrAdopt || isBuyStage)
        return sortedOpportunities[0] ? [sortedOpportunities[0]] : [];

      if (project.funnelStage !== "discover")
        return sortedOpportunities.filter(
          (opportunity) => opportunity.isQualified
        );

      return sortedOpportunities;
    })() || [];
  const assessedStartups = sortedOpportunities.filter(
    (opportunity) => opportunity.isQualified
  );
  const startupDemos = sortedOpportunities.filter(
    (opportunity) => opportunity.isSelectedForPilot
  );

  function convertToMillion(value: number | string | undefined) {
    return Number(value) / 1000000 || "--";
  }

  const totalImpactValue = convertToMillion(
    getImpactValueByFunnelStage(project.funnelStage, project.impactValues)
      ?.value
  );
  const purchaseOrderAmount = convertToMillion(project.purchaseOrderAmount);
  const firstOffer = convertToMillion(project.firstOffer);

  return (
    <Dialog
      fullWidth
      open={props.modalOpen}
      onClose={() => props.setModalOpen(false)}
      data-testid="related-project-details-modal"
      PaperProps={{
        sx: { gap: theme.spacing(4) },
      }}
    >
      {project.offTrack && <FlagBanner />}
      <Stack gap={1} px={4} pt={project.offTrack ? 6 : 1}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Chip
            label={capitalizeFirstLetter(project.status)}
            variant="outlined"
            sx={{
              maxWidth: "fit-content",
              borderColor: getStatusColor(project.status),
              color: getStatusColor(project.status),
              backgroundColor:
                project.status === "Adopted"
                  ? "surface.success"
                  : "transparent",
            }}
          />
          <IconButton
            sx={{ color: "icon.primary", p: 0 }}
            onClick={() => props.setModalOpen(false)}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </Box>
        <Stack gap={3}>
          <DialogTitle
            sx={{
              p: 0,
            }}
          >
            {project.name}
          </DialogTitle>
          <ProjectMainInformation project={project} />
          <CustomExpendableText text={project.shortDescription} maxLines={3} />
        </Stack>
      </Stack>
      <ScrollableDialogContent>
        <Stack gap={4} paddingBottom={0.5}>
          {statusComment && (
            <Stack bgcolor={theme.palette.surface.secondary.main} p={2} gap={2}>
              {(isArchived || isOnHold) && (
                <Stack>
                  <Typography variant="subtitle2">
                    Project is {project.status}{" "}
                    {onHoldDeadline ? `until ${onHoldDeadline}` : ""}
                  </Typography>

                  <Typography>
                    <b>Reason: </b>
                    {statusReason}
                  </Typography>
                </Stack>
              )}
              <StatusCommentText>{parse(statusComment)}</StatusCommentText>
              {statusCommentLastModifiedDate && lastModifiedBy && (
                <Stack>
                  <Typography>{lastModifiedBy}</Typography>
                  <Typography variant="body2" color="text.mediumEmphasis">
                    Updated on {statusCommentLastModifiedDate}
                  </Typography>
                </Stack>
              )}
            </Stack>
          )}
          <TaskStepperSection
            projectId={project.id}
            funnelStage={project.funnelStage}
          />
          <Stack gap={1}>
            <Typography variant="subtitle1">Project Stakeholders</Typography>
            <ContactsWrapper>
              <Box height="100%">
                <ClientContactSelect
                  editMode={false}
                  labelText="Project Leader"
                  fieldId="projectLeaderId"
                  field="projectLeader"
                  ventureClientId={project.businessUnit.ventureClient.id}
                  onChange={() => null}
                  contactData={project.projectLeader}
                />
              </Box>
              <Box height="100%">
                <ClientContactSelect
                  editMode={false}
                  labelText="Venture Client Program Manager"
                  fieldId="programManagerId"
                  field="programManager"
                  ventureClientId={project.businessUnit.ventureClient.id}
                  onChange={() => null}
                  contactData={project.programManager}
                />
              </Box>
            </ContactsWrapper>
          </Stack>
          <Stack gap={2} order={isBuyStage || isPilotOrAdopt ? undefined : 1}>
            <Box display="flex" gap={1} alignItems="center">
              <Typography variant="subtitle1">
                {isPilotOrAdopt || isBuyStage ? "Selected Startup" : "Startups"}
              </Typography>
              {(isDiscover || isAssess) && (
                <Chip
                  variant="counter"
                  color="info"
                  label={opportunitiesToDisplay.length}
                />
              )}
              {isDiscover && (
                <Box display="flex" alignItems="center" gap={1} ml="auto">
                  <Typography variant="caption" color="text.mediumEmphasis">
                    Startup Opportunity:
                  </Typography>
                  <Typography variant="subtitle2" color="text.highEmphasis">
                    {project.availabilityOfStartups || "--"}
                  </Typography>
                </Box>
              )}
            </Box>
            <Stack alignItems="center">
              {isBuyStage && (
                <>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    width="100%"
                    p={theme.spacing(1, 2)}
                    bgcolor={theme.palette.surface.secondary.light}
                  >
                    <Box display="flex" gap={1} alignItems="center">
                      <Typography
                        variant="subtitle1"
                        data-testid="total-startups"
                      >
                        {sortedOpportunities.length}
                      </Typography>
                      <Typography
                        variant="overline"
                        color="text.mediumEmphasis"
                      >
                        Total Curated Startups
                      </Typography>
                    </Box>
                    <ArrowIconContainer>
                      <ArrowRight
                        htmlColor={theme.palette.icon.brand.main}
                        sx={{ fontSize: 16 }}
                      />
                    </ArrowIconContainer>
                    <Box display="flex" gap={1} alignItems="center">
                      <Typography
                        variant="subtitle1"
                        data-testid="assessed-startups"
                      >
                        {assessedStartups.length}
                      </Typography>
                      <Typography
                        variant="overline"
                        color="text.mediumEmphasis"
                      >
                        Assessed Startups
                      </Typography>
                    </Box>
                    <ArrowIconContainer>
                      <ArrowRight
                        htmlColor={theme.palette.icon.brand.main}
                        sx={{ fontSize: 16 }}
                      />
                    </ArrowIconContainer>
                    <Box display="flex" gap={1} alignItems="center">
                      <Typography
                        variant="subtitle1"
                        data-testid="startup-demos"
                      >
                        {startupDemos.length}
                      </Typography>
                      <Typography
                        variant="overline"
                        color="text.mediumEmphasis"
                      >
                        Startup Demos
                      </Typography>
                    </Box>
                  </Box>
                  <ArrowDropDown htmlColor={theme.palette.icon.brand.main} />
                </>
              )}
              <Stack gap={2} px={0.5} width="100%">
                {opportunitiesToDisplay?.map((opportunity) => {
                  const logo = findLogo(opportunity.startup.files);
                  const startup = opportunity.startup;
                  const startupDateFounded = startup.dateFounded
                    ? formatDate(new Date(startup.dateFounded))
                    : null;
                  const foundedYear = startupDateFounded
                    ? new Date(startupDateFounded).getFullYear()
                    : undefined;
                  const isFundingUndisclosed =
                    opportunity.startup.fundingIsUndisclosed ||
                    !opportunity.startup.totalFunding;

                  return (
                    <Link
                      target="_blank"
                      key={opportunity.id}
                      to={"/startups/" + opportunity.startup.id}
                    >
                      <StartupBox data-testid={`opportunity-${opportunity.id}`}>
                        <Box
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          height="100%"
                          minHeight="100%"
                        >
                          {logo ? (
                            <img
                              src={logo}
                              alt="startup logo"
                              style={{
                                width: theme.spacing(12),
                                height: "100%",
                                objectFit: "contain",
                              }}
                            />
                          ) : (
                            <RocketLaunchOutlined color="disabled" />
                          )}
                        </Box>

                        <Stack gap={0.5}>
                          <Box display="flex" gap={1}>
                            <Typography variant="body2" color="text.disabled">
                              {startup.name}
                            </Typography>
                            <Typography
                              variant="body2"
                              color="text.brand.accessibility"
                            >
                              {foundedYear}
                            </Typography>
                          </Box>

                          <ShortDescriptionText variant="body2">
                            {startup.shortDescription}
                          </ShortDescriptionText>

                          <Box display="flex" alignItems="center" gap={2}>
                            <Box display="flex" alignItems="center" gap={0.5}>
                              <Typography
                                variant="overline"
                                color="text.mediumEmphasis"
                              >
                                Funding
                              </Typography>
                              <Typography
                                variant="body2"
                                color="text.brand.accessibility"
                              >
                                {isFundingUndisclosed
                                  ? "Undisclosed"
                                  : `$${startup.totalFunding}M`}
                              </Typography>
                            </Box>
                            <Box display="flex" alignItems="center" gap={0.5}>
                              <Typography
                                variant="overline"
                                color="text.mediumEmphasis"
                              >
                                Stage
                              </Typography>
                              <Typography
                                variant="body2"
                                color="text.brand.accessibility"
                              >
                                {startup.currentInvestmentStage || "N/A"}
                              </Typography>
                            </Box>
                          </Box>
                        </Stack>
                        <Box display="flex" gap={2} alignSelf="flex-start">
                          {isDiscover && (
                            <RatingButton>
                              <RatingContent
                                className={`${opportunity.rating}`}
                                rating={opportunity.rating}
                              >
                                {opportunity.rating}
                              </RatingContent>
                            </RatingButton>
                          )}
                          {isAssess && (
                            <Box display="flex" alignItems="center" gap={2}>
                              {opportunity.productDemos.length > 0 && (
                                <DesktopWindowsOutlined
                                  sx={{ fontSize: 16 }}
                                  data-testid="demo-icon"
                                />
                              )}
                              {opportunity.isSelectedForPilot && (
                                <CheckCircleIcon
                                  sx={{
                                    color: theme.palette.surface.success.main,
                                  }}
                                  data-testid="check-icon"
                                />
                              )}
                            </Box>
                          )}
                        </Box>
                      </StartupBox>
                    </Link>
                  );
                })}
              </Stack>
            </Stack>
          </Stack>
          {(isBuyStage || isDiscover || isAssess) && (
            <Stack gap={2} order={isBuyStage || isPilotOrAdopt ? 2 : undefined}>
              <Typography variant="subtitle1">Strategic Benefits</Typography>
              <Box display="flex" gap={1}>
                <NumberCard
                  label="Total Impact Value"
                  value={totalImpactValue}
                  numberSize="small"
                />
                <NumberCard
                  label="Impact Type"
                  value={project.impactType}
                  hideCurrency
                  valueColor="text.mediumEmphasis"
                  numberSize="small"
                />
              </Box>
            </Stack>
          )}
          {isBuyStage && (
            <Stack gap={2} order={2}>
              <Typography variant="subtitle1">Pilot Cost</Typography>
              <Box display="flex" gap={1}>
                <NumberCard
                  label="First Startup Offer"
                  value={firstOffer}
                  numberSize="small"
                />
                <NumberCard
                  label="Purchase Order Amount"
                  value={purchaseOrderAmount}
                  numberSize="small"
                />
              </Box>
            </Stack>
          )}
          {isPilotOrAdopt && (
            <>
              <ObjectivesOverview objectives={project.objectives} />
              <TimelineOverview
                meetings={project.meetings.filter(
                  (meeting) => !meeting.isInitial
                )}
              />
            </>
          )}
        </Stack>
      </ScrollableDialogContent>
      <DialogActions>
        {hasAccessToVentureClient(
          user,
          project.businessUnit.ventureClient.id
        ) && (
          <Button
            variant="contained"
            color="secondary"
            component="a"
            href={`/projects/${project.id}`}
            target={props.shouldOpenInNewTab ? "_blank" : undefined}
            data-testid="view-project-details"
          >
            View Details
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}
