import {
  LeadProjectHistory,
  LeadProjectHistoryDTO,
} from "../../Types/LeadProjectHistory";

export default class LeadProjectHistoryMapper {
  public static map(histories: LeadProjectHistoryDTO[]): LeadProjectHistory[] {
    return histories
      .map((history) => ({
        ...history,
        dateTriggered: new Date(history.dateTriggered),
      }))
      .sort((a, b) => b.dateTriggered.getTime() - a.dateTriggered.getTime());
  }
}
