import { Box, IconButton, TextField, styled } from "@mui/material";
import { ReactElement, useEffect, useRef, useState } from "react";
import { Draggable } from "react-beautiful-dnd";
import { Requirement } from "../../../../../Types/Requirement";
import { DeleteOutline, DragIndicator } from "@mui/icons-material";
import theme from "../../../../../theme";

interface Props {
  index: number;
  requirement: Requirement;
  onCreateRequirement: (
    requirement: Requirement,
    isEnterPressed: boolean
  ) => void;
  onRequirementChange: (requirement: Requirement) => void;
  loader?: boolean;
  deleteRequirement: (id: number) => void;
  onChangeEditRequirement: (id: number | null) => void;
  currentEditRequirement: number | null;
  canEdit: boolean;
}

const StyledBox = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  "& fieldset": { border: "none" },
  borderBottom: `1px solid ${theme.palette.borderOutline.soft}`,
  "&:last-of-type": {
    borderBottom: "none",
  },
}));

const StyledTextField = styled(TextField)(() => ({
  flexGrow: 1,
  border: "none",
  transition: "max-width 0.3s ease, padding 0.3s ease",
  textarea: {
    ":focus": {
      color: theme.palette.text.mediumEmphasis,
    },
  },
  "& .MuiInputBase-root": {
    padding: theme.spacing(2, 0),
    backgroundColor: "transparent",
  },
  "& .MuiInputBase-input.Mui-disabled": {
    textFillColor: theme.palette.text.primary,
  },
}));

const StyledIconButton = styled(IconButton)(({ disabled }) => ({
  transition: "all 0.3s ease-in-out",
  width: disabled ? 0 : theme.spacing(5),
  opacity: disabled ? 0 : theme.spacing(12.5),
  color: theme.palette.icon.mediumEmphasis,
}));

const SolutionRequirementItem = (props: Props): ReactElement => {
  const [currentDescription, setCurrentDescription] = useState(
    props.requirement.description
  );
  const [blur, setBlur] = useState(false);
  const [isEscapePressed, setIsEscapePressed] = useState(false);
  const [isEnterPressed, setIsEnterPressed] = useState(false);

  const buttonRef = useRef(null);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (
      currentDescription !== props.requirement.description &&
      props.requirement.id !== -1 &&
      !isEscapePressed
    ) {
      props.onRequirementChange({
        ...props.requirement,
        description: currentDescription,
      });
    }
    setIsEscapePressed(false);
  }, [props.currentEditRequirement]);

  const handleDescriptionBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    if (e.relatedTarget === buttonRef.current) return e.stopPropagation();
    if (props.requirement.id === -1 && currentDescription && !isEscapePressed) {
      props.onCreateRequirement(
        {
          ...props.requirement,
          description: currentDescription,
        },
        isEnterPressed
      );
    } else if (!currentDescription) {
      props.deleteRequirement(props.requirement.id);
    } else if (props.requirement.id !== -1 && isEscapePressed) {
      setCurrentDescription(props.requirement.description);
    }
    setIsEscapePressed(false);
    setIsEnterPressed(false);
  };

  const handleEnterKeyDown = () => {
    setIsEnterPressed(true);
    props.onChangeEditRequirement(null);
    setBlur(true);
  };

  const handleEscapeKeyDown = () => {
    setIsEscapePressed(true);
    setCurrentDescription(props.requirement.description);
    props.onChangeEditRequirement(null);
    setBlur(true);
  };

  useEffect(() => {
    if (blur) {
      inputRef.current?.blur();
      setBlur(false);
    }
  }, [blur]);

  const isEditing = props.requirement.id === props.currentEditRequirement;

  return (
    <Draggable
      key={props.requirement.id}
      draggableId={props.requirement.id?.toString()}
      index={props.index}
    >
      {(provided, snapshot) => (
        <StyledBox
          ref={provided.innerRef}
          {...provided.draggableProps}
          style={{
            ...provided.draggableProps.style,
            backgroundColor: theme.palette.surface.primary.main,
            ...(snapshot.isDragging && {
              border: `2px solid ${theme.palette.borderOutline.focus}`,
              borderRadius: theme.shape.radius.minimal,
              boxShadow: theme.boxShadows[2],
            }),
          }}
        >
          {props.canEdit && (
            <StyledIconButton
              className="drag-handle-button"
              disabled={!!props.currentEditRequirement}
              {...provided.dragHandleProps}
            >
              <DragIndicator
                fontSize="medium"
                sx={{
                  transition: "all 0.3s ease",
                  width: !!props.currentEditRequirement ? 0 : "initial",
                }}
              />
            </StyledIconButton>
          )}
          <StyledTextField
            maxRows={3}
            inputRef={inputRef}
            value={currentDescription}
            sx={{ input: { cursor: isEditing ? "text" : "pointer" } }}
            onChange={(e) => {
              setCurrentDescription(e.target.value);
            }}
            onKeyDown={(event) => {
              if (event.key === "Enter") {
                handleEnterKeyDown();
              }
              if (event.key === "Escape") {
                handleEscapeKeyDown();
              }
            }}
            onBlur={handleDescriptionBlur}
            onFocus={() => {
              props.onChangeEditRequirement(props.requirement.id);
            }}
            data-testid={"requirement-" + props.requirement.id}
            placeholder="Add a requirement..."
            id="requirementsInput"
            autoFocus={props.currentEditRequirement === -1}
            multiline
            disabled={!props.canEdit}
          />
          <Box
            className="delete-button"
            display="flex"
            alignItems="center"
            sx={{
              transition: "opacity 0.3s ease",
              opacity: isEditing ? 100 : 0,
              display: isEditing ? "flex" : "none",
              color: theme.palette.icon.mediumEmphasis,
            }}
          >
            <IconButton
              onClick={() => {
                props.deleteRequirement(props.requirement.id);
              }}
              data-testid={"delete-requirement-" + props.requirement.id}
              ref={buttonRef}
            >
              <DeleteOutline sx={{ color: "icon.mediumEmphasis" }} />
            </IconButton>
          </Box>
        </StyledBox>
      )}
    </Draggable>
  );
};

export default SolutionRequirementItem;
