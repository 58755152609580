import axios from "axios";
import Investor from "../../Types/Investor";

export class InvestorsHttpService {
  public static async getInvestorsByName(name: string): Promise<Investor[]> {
    const response = await axios.get<Investor[]>(`/api/investors`, {
      params: { name },
    });
    return response.data;
  }

  public static async createInvestor(
    investor: Omit<Investor, "id">
  ): Promise<Investor> {
    const response = await axios.post<Investor>(`/api/investors`, investor);
    return response.data;
  }
}
