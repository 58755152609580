import { Paper, Stack, Typography, Box, IconButton } from "@mui/material";
import { LeadProject } from "../../../Types/LeadProject";
import { ReactElement, useState } from "react";
import { EditOutlined } from "@mui/icons-material";
import EditLeadProjectSource from "./EditLeadProjectSource";
import useRoles from "../../../Hooks/useRoles";

interface Props {
  leadProject: LeadProject;
  handleSave: () => void;
}

export default function LeadProjectSource({
  leadProject,
  handleSave,
}: Props): ReactElement {
  const { canEdit } = useRoles(undefined, undefined, leadProject.ventureClient);
  const [editModalOpen, setEditModalOpen] = useState(false);

  const renderLeadSourceContent = () => {
    switch (leadProject.originType) {
      case "Organizational Unit":
        return (
          <LeadSourceAttribute
            title="Name"
            value={leadProject.originContact?.name}
          />
        );

      case "Startup":
        return (
          <LeadSourceAttribute
            title="Name"
            value={leadProject.originStartup?.name}
          />
        );

      case "Third Party":
        return (
          <>
            <LeadSourceAttribute
              title="Category"
              value={leadProject.originThirdPartyCategoryName?.categoryType}
            />
            <LeadSourceAttribute
              title="Name"
              value={leadProject.originThirdPartyCategoryName?.value}
            />
          </>
        );

      default:
        return null;
    }
  };

  return (
    <>
      <Paper component={Stack} p={3} gap={2} data-testid="lead-source-section">
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h6">Lead Source</Typography>
          {canEdit && (
            <IconButton
              data-testid="edit-lead-source-button"
              onClick={() => setEditModalOpen(true)}
            >
              <EditOutlined color="secondary" fontSize="small" />
            </IconButton>
          )}
        </Box>

        <Stack gap={3}>
          <Stack gap={1}>
            <Typography color="text.mediumEmphasis">Origin</Typography>
            <Stack gap={0.5}>
              <LeadSourceAttribute
                title="Initiator"
                value={leadProject.originType}
              />
              {renderLeadSourceContent()}
            </Stack>
          </Stack>
          {leadProject.originType !== "Third Party" && (
            <Stack gap={1}>
              <Typography color="text.mediumEmphasis">Initiative</Typography>
              <Stack gap={0.5}>
                <LeadSourceAttribute
                  title="Type"
                  value={leadProject.initiativeName?.initiativeType}
                />
                <LeadSourceAttribute
                  title="Name"
                  value={leadProject.initiativeName?.value}
                />
              </Stack>
            </Stack>
          )}
        </Stack>
      </Paper>
      {editModalOpen && (
        <EditLeadProjectSource
          refreshLead={handleSave}
          leadProject={leadProject}
          setModalOpen={setEditModalOpen}
          modalOpen={editModalOpen}
        />
      )}
    </>
  );
}

interface LeadSourceAttributeProps {
  title: string;
  value: string | undefined;
}

function LeadSourceAttribute({
  title,
  value,
}: LeadSourceAttributeProps): ReactElement {
  return (
    <Box gap={2} display="flex" width="100%">
      <Typography variant="subtitle2" width="80px">
        {title}
      </Typography>
      <Typography width="100%">{value || "--"}</Typography>
    </Box>
  );
}
