import { Box, Paper, Skeleton, Stack, Typography } from "@mui/material";
import { ReactElement } from "react";

export default function ProjectTasksSectionSkeleton(): ReactElement {
  return (
    <>
      <Paper component={Stack} p={3} gap={2}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography variant="h6">Tasks</Typography>
          <Skeleton height={20} width={36} />
        </Box>
        <Skeleton width="auto" height={8} />
        <Skeleton width="auto" height={140} />
      </Paper>
    </>
  );
}
