import { ReactElement } from "react";
import { FunnelStage } from "../../../../../Types/Project";
import { funnelStages } from "../../../../../Constants/FunnelStages";
import { Box, Skeleton, Stack, Tab, Tabs, Typography } from "@mui/material";
import { RadioButtonUnchecked } from "@mui/icons-material";

function CustomTabLabelSkeleton({
  stageName,
}: {
  stageName: string;
}): ReactElement {
  return (
    <Stack alignSelf="start">
      <Box display="flex" gap={1} alignItems="center">
        <RadioButtonUnchecked sx={{ fontSize: "18px" }} />
        <Typography variant="subtitle1" textTransform="capitalize">
          {stageName}
        </Typography>
      </Box>
      <Box pl={3}>
        <Skeleton width={56} height={12} />
      </Box>
    </Stack>
  );
}

export default function StageStepperSkeleton(): ReactElement {
  return (
    <>
      <Tabs
        value={null}
        variant="fullWidth"
        sx={{
          "& .MuiTabs-flexContainer": {
            borderBottom: "none",
          },
        }}
      >
        {funnelStages.map((funnelStage: FunnelStage) => {
          return (
            <Tab
              disabled={true}
              sx={{ paddingLeft: 0 }}
              key={funnelStage}
              label={<CustomTabLabelSkeleton stageName={funnelStage} />}
            />
          );
        })}
      </Tabs>
    </>
  );
}
