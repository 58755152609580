import { Box } from "@mui/material";
import { ReactElement } from "react";
import { Project } from "../../../../../Types/Project";
import SolutionRequirements from "../SolutionRequirements/SolutionRequirements";
import StrategicBenefitsSection from "../../../SharedComponents/StrategicBenefitsSection/StrategicBenefitsSection";
import StakeholdersSection from "../StakeholdersSection";
import CuratedList from "../CuratedList/CuratedList";
import StartupSupplySection from "../StartupSupplySection";
import ProblemInsightsSection from "../ProblemInsightsSection";
import { Opportunity } from "../../../../../Types/Opportunity";

interface DiscoverStageProps {
  projectData: Project;
  opportunities: Opportunity[];
  handleSave: (withScroll?: boolean) => void;
  handleSaveNoScroll: () => void;
  moveProject: () => void;
  handleChangeTab: (index: number, controlFullWidth?: boolean) => void;
  activeTabs: number[];
  handleRefs: (el: HTMLDivElement | null, index: number) => void;
  fullWidthSectionOpen: boolean;
  setFullWidthSectionOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function DiscoverStage(props: DiscoverStageProps): ReactElement {
  return (
    <Box display="flex" flexDirection="column" gap={3} width="100%">
      {props.projectData && (
        <>
          <div ref={(element) => props.handleRefs(element, 0)}>
            <ProblemInsightsSection
              project={props.projectData}
              expanded={props.activeTabs.includes(0)}
              onChange={() => props.handleChangeTab(0)}
              handleSave={props.handleSave}
            />
          </div>
          <div ref={(element) => props.handleRefs(element, 1)}>
            <SolutionRequirements
              project={props.projectData}
              handleSaveNoScroll={() => props.handleSave(false)}
              expanded={props.activeTabs.includes(1)}
              onChange={() => props.handleChangeTab(1)}
            />
          </div>
          <div ref={(element) => props.handleRefs(element, 2)}>
            <StakeholdersSection
              project={props.projectData}
              expanded={props.activeTabs.includes(2)}
              onChange={() => props.handleChangeTab(2)}
              handleSave={props.handleSave}
            />
          </div>
          <div ref={(element) => props.handleRefs(element, 3)}>
            <StrategicBenefitsSection
              project={props.projectData}
              expanded={props.activeTabs.includes(3)}
              onChange={() => props.handleChangeTab(3)}
              handleSave={props.handleSave}
            />
          </div>
          <div ref={(element) => props.handleRefs(element, 4)}>
            <StartupSupplySection
              project={props.projectData}
              expanded={props.activeTabs.includes(4)}
              onChange={() => props.handleChangeTab(4)}
              handleSave={props.handleSave}
            />
          </div>
          <div ref={(element) => props.handleRefs(element, 5)}>
            <CuratedList
              project={props.projectData}
              opportunities={props.opportunities}
              handleSave={props.handleSaveNoScroll}
              showFullList={props.fullWidthSectionOpen}
              onFullWidthButtonClick={() => props.handleChangeTab(5, true)}
              expanded={props.activeTabs.includes(5)}
              onChange={() => props.handleChangeTab(5)}
            />
          </div>
        </>
      )}
    </Box>
  );
}
