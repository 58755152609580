import { ReactElement } from "react";
import { StartupOpportunity } from "../../../../Types/Opportunity";
import { Box } from "@mui/material";
import theme from "../../../../theme";
import NumberCard from "../../../UI/NumberCard";
import { LeadOpportunity } from "../../../../Types/LeadProject";

interface ProjectStatsProps {
  opportunities: StartupOpportunity[];
  leadOpportunities: LeadOpportunity[];
}

export default function ProjectStats(props: ProjectStatsProps): ReactElement {
  const projectsInAdoption = props.opportunities.filter(
    (opp) => opp.project.funnelStage === "adopt" && opp.isSelectedForPilot
  ).length;

  return (
    <Box
      display="flex"
      gap={theme.spacing(2)}
      data-testid="relatedProjectsStatsSection"
    >
      <NumberCard
        label="Leads"
        value={props.leadOpportunities.length}
        hideUnit
        hideCurrency
        data-testid="stat-card-Leads"
      />
      <NumberCard
        label="Projects"
        value={props.opportunities.length}
        hideUnit
        hideCurrency
        data-testid="stat-card-Projects"
      />
      <NumberCard
        label="Adoptions"
        value={projectsInAdoption}
        hideUnit
        hideCurrency
        data-testid="stat-card-Adoptions"
      />
    </Box>
  );
}
