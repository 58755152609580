import axios from "axios";
import { InitiativeName } from "../../Types/InitiativeName";
import { InitiativeType } from "../../Types/LeadProject";

export class InitiativeNamesHttpService {
  public static async getInitiativeNames(
    ventureClientId: number,
    initiativeType: InitiativeType
  ): Promise<InitiativeName[]> {
    const response = await axios.get<InitiativeName[]>(
      `/api/initiative-names`,
      {
        params: { ventureClientId, initiativeType },
      }
    );
    return response.data;
  }

  public static async createInitiativeName(
    initiativeName: Omit<InitiativeName, "id">
  ): Promise<InitiativeName> {
    const response = await axios.post<InitiativeName>(
      "/api/initiative-names",
      initiativeName
    );
    return response.data;
  }
}
