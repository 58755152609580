import { Box, Stack, Typography } from "@mui/material";
import { Dispatch, ReactElement } from "react";
import { Project } from "../../../../Types/Project";
import ClientContactSelect from "../../../UI/InputFields/ClientContactSelect";
import StartupContactSelect from "../../../UI/InputFields/StartupContactSelect";

interface Props {
  project: Project;
  editMode: boolean;
  onChange: Dispatch<Partial<Project>>;
  startupId: number;
  ventureClientId: number;
}

export default function PilotTeamSection(props: Props): ReactElement {
  return (
    <Stack gap={1}>
      <Typography variant="subtitle2">Pilot Team</Typography>
      <Box display="grid" gridTemplateColumns="repeat(4, 1fr)" gap={3}>
        <ClientContactSelect
          editMode={props.editMode}
          labelText="Adoption Owner (Venture Client)"
          fieldId="adoptionOwnerId"
          field="adoptionOwner"
          ventureClientId={props.ventureClientId}
          onChange={props.onChange}
          contactData={props.project.adoptionOwner}
          required
        />
        <ClientContactSelect
          editMode={props.editMode}
          labelText="Purchasing Contact"
          fieldId="purchasingContactId"
          field="purchasingContact"
          ventureClientId={props.ventureClientId}
          onChange={props.onChange}
          contactData={props.project.purchasingContact}
        />
        <StartupContactSelect
          editMode={props.editMode}
          labelText="Project Lead (Startup)"
          startupId={props.startupId}
          onChange={props.onChange}
          contactData={props.project.projectLeadStartup}
          fieldId="projectLeadStartupId"
          field="projectLeadStartup"
          required
        />
      </Box>
    </Stack>
  );
}
