import {
  Box,
  IconButton,
  Link,
  styled,
  TableCell,
  Typography,
} from "@mui/material";
import {
  StyledTableRow,
  TruncatedTableCell,
} from "../../../../SharedComponents/SolutionFitMatrixComponents/SolutionFitMatrixTable/SolutionFitMatrixOpportunity/SolutionFitMatrixOpportunity";
import { Startup } from "../../../../../../Types/Startup";
import { ReactElement, useContext, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import { Link as RouterLink } from "react-router-dom";
import {
  findLogo,
  formatToBillion,
  getDateMonthsAfter,
  isBillion,
  normalizeUrl,
} from "../../../../../../utils";
import { RocketLaunchOutlined } from "@mui/icons-material";
import theme from "../../../../../../theme";
import LaunchIcon from "@mui/icons-material/Launch";
import QualityVerificationIcon from "../../../../../Startup/DetailsPage/QualityVerification/QualityVerificationIcon";
import LimitedNameList from "../../../../SharedComponents/SolutionFitMatrixComponents/SolutionFitMatrixTable/LimitedNameList/LimitedNameList";
import { GlobalLoaderContext } from "../../../../../../Context/LoaderContext";
import NotVentureReadyConfirmationModal from "../../../../SharedComponents/SolutionFitMatrixComponents/NotVentureReadyConfirmationModal";

const CustomTruncatedTableCell = styled(TruncatedTableCell)(({ theme }) => ({
  gap: theme.spacing(2),
  color: theme.palette.text.disabled,
  "& button": {
    display: "flex",
    marginLeft: 0,
    color: theme.palette.icon.action.main,
  },
  "& td": {
    color: "red",
  },
}));

interface SuggestedStartupsItemProps {
  startup: Startup;
  detailsView: boolean;
  createOpportunity: (startup: Startup) => void;
}

export default function SuggestedStartupsItem({
  startup,
  detailsView,
  createOpportunity,
}: SuggestedStartupsItemProps): ReactElement {
  const logo = findLogo(startup.files);
  const { globalLoader } = useContext(GlobalLoaderContext);
  const [ventureReadyModalOpen, setVentureReadyModalOpen] = useState(false);

  const isQualityChecked = startup.isQualityChecked;
  const expiryDate = startup.lastQualityCheckDate
    ? getDateMonthsAfter(startup.lastQualityCheckDate, 12)
    : new Date();
  const hasExpired =
    (startup.lastQualityCheckDate &&
      isQualityChecked &&
      expiryDate < new Date()) ??
    false;

  const handleCreateOpportunity = () => {
    if (startup.isQualityChecked === false) {
      setVentureReadyModalOpen(true);
    } else {
      createOpportunity(startup);
    }
  };

  const formatFunding = (
    totalFunding: number | null,
    isUndisclosed: boolean
  ) => {
    if (isUndisclosed) return "Funding is undisclosed";
    if (!totalFunding) return "-";

    const isBillionUnit = isBillion(totalFunding);
    const fundingUnit = isBillionUnit ? "B" : "M";
    const fundingValue = isBillionUnit
      ? formatToBillion(+totalFunding)
      : +totalFunding;

    return `$${fundingValue}${fundingUnit}`;
  };

  return (
    <>
      <StyledTableRow key={startup.id}>
        <CustomTruncatedTableCell
          className="startup-name"
          key={`startup-name-cell-${startup.id}`}
          data-testid="startup-name"
        >
          <IconButton
            color="secondary"
            data-testid={`add-suggested-startup-${startup.id}`}
            onClick={handleCreateOpportunity}
            disabled={globalLoader}
            size="small"
          >
            <AddIcon fontSize="small" />
          </IconButton>
          <RouterLink
            className="truncated-link"
            onClick={(event) => event.stopPropagation()}
            target="_blank"
            to={"/startups/" + startup.id}
          >
            <Typography variant="subtitle2">{startup.name}</Typography>
          </RouterLink>
        </CustomTruncatedTableCell>
        <TableCell
          className="startup-link-logo"
          data-testid="startup-link"
          sx={{
            justifyContent: "space-between",
          }}
        >
          <RouterLink
            onClick={(event) => event.stopPropagation()}
            target="_blank"
            to={"/startups/" + startup.id}
          >
            {logo ? (
              <img
                src={logo}
                alt="logo associated to the startup"
                style={{ opacity: "0.5" }}
              />
            ) : (
              <RocketLaunchOutlined color="disabled" />
            )}
          </RouterLink>
          <Box display="flex">
            {startup.website && (
              <Link
                onClick={(event) => event.stopPropagation()}
                target="_blank"
                href={normalizeUrl(startup.website)}
              >
                <LaunchIcon
                  data-testid="startup-link-icon"
                  htmlColor={theme.palette.icon.action.main}
                  sx={{ fontSize: "20px" }}
                />
              </Link>
            )}
          </Box>
        </TableCell>
        <TableCell></TableCell>
        <TableCell></TableCell>
        <TableCell
          sx={{
            justifyContent: "center",
          }}
        >
          <QualityVerificationIcon
            lastQualityCheckBy={startup.lastQualityCheckBy}
            lastQualityCheckDate={startup.lastQualityCheckDate}
            isQualityChecked={isQualityChecked}
            hasExpired={hasExpired}
          />
        </TableCell>
        {detailsView ? (
          <>
            <CustomTruncatedTableCell>
              {startup.dateFounded?.getFullYear() || "-"}
            </CustomTruncatedTableCell>
            <CustomTruncatedTableCell>
              {startup.billingCountry || "-"}
            </CustomTruncatedTableCell>
            <CustomTruncatedTableCell>
              {startup.employees || "-"}
            </CustomTruncatedTableCell>
            <CustomTruncatedTableCell>
              <LimitedNameList entityNames={startup.referenceClients} />
            </CustomTruncatedTableCell>
            <CustomTruncatedTableCell>
              {startup.currentInvestmentStage || "-"}
            </CustomTruncatedTableCell>
            <CustomTruncatedTableCell>
              {formatFunding(
                startup.totalFunding,
                startup.fundingIsUndisclosed
              )}
            </CustomTruncatedTableCell>
            <CustomTruncatedTableCell className="extra-high-cell highlighted-border">
              <LimitedNameList entityNames={startup.investors} />
            </CustomTruncatedTableCell>
          </>
        ) : (
          <>
            <TableCell className="requirement-cell"></TableCell>
          </>
        )}
      </StyledTableRow>
      {ventureReadyModalOpen && (
        <NotVentureReadyConfirmationModal
          setModalOpen={setVentureReadyModalOpen}
          modalOpen={ventureReadyModalOpen}
          handleAddOpportunity={() => createOpportunity(startup)}
          startup={startup}
        />
      )}
    </>
  );
}
