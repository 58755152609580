import { Box, Stack } from "@mui/material";
import { ReactElement, useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { LeadProject } from "../../Types/LeadProject";
import theme from "../../theme";
import { useSnackbar } from "notistack";
import { getErrorMessage } from "../../utils";
import ContentWrapper from "../ProjectDetails/ContentWrapper";
import LaunchProjectModal from "./LaunchProjectModal";
import { LeadProjectFileSection } from "./LeadProjectFiles/LeadProjectFileSection/LeadProjectFileSection";
import LeadProjectHeader from "./LeadProjectHeader/LeadProjectHeader";
import LeadProjectLeaders from "./LeadProjectLeaders/LeadProjectLeaders";
import LeadProjectOpportunities from "./LeadProjectOpportunities/LeadProjectOpportunities";
import UseCasesList from "./ProjectLeadUseCases/UseCasesList/UseCasesList";
import { LeadProjectsHttpService } from "../../Http/LeadProjects/LeadProjects.http.service";
import ProjectsLaunched from "./ProjectsLaunched/ProjectsLaunched";
import Banner from "../UI/Banner";
import LeadProjectUpdates from "./LeadProjectStatus/LeadProjectStatus";
import LeadProjectBackgroundInformation from "./LeadProjectBackgroundInformation/LeadProjectBackgroundInformation";
import useElementHeight from "../../Hooks/useElementHeight";
import LeadProjectSource from "./LeadProjectSource/LeadProjectSource";
import LeadProjectPageSkeleton from "./LeadProjectSkeleton";

export default function LeadProjectPage(): ReactElement {
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const [leadProject, setLeadProject] = useState<LeadProject>();
  const [modalOpen, setModalOpen] = useState(false);
  const topHeaderPadding = parseInt(theme.spacing(10.5));
  const headerPadding = parseInt(theme.spacing(3));

  const [projectNameHeight, projectNameRef] = useElementHeight([
    leadProject?.name,
  ]);
  const bottomHeaderRef = useRef<HTMLDivElement>();
  const [headerMaxHeight, setHeaderMaxHeight] = useState(
    topHeaderPadding + headerPadding + projectNameHeight
  );

  useEffect(() => {
    getLeadProject();
  }, []);

  useEffect(() => {
    if (leadProject?.name) {
      document.title = `${leadProject?.name} - Lead`;
    } else {
      document.title = `Lead`;
    }
  }, [leadProject?.name]);

  const getLeadProject = async () => {
    await LeadProjectsHttpService.getLeadProjectById(id)
      .then((data) => {
        setLeadProject(data);
      })
      .catch((error) => {
        const errorMessage = getErrorMessage(error);
        enqueueSnackbar(errorMessage, { variant: "error" });
        history.push(`/projects`);
      });
  };

  useEffect(() => {
    setHeaderMaxHeight(topHeaderPadding + headerPadding + projectNameHeight);
  }, [projectNameHeight]);

  return (
    <>
      {!leadProject ? (
        <LeadProjectPageSkeleton />
      ) : (
        <Box
          maxWidth={`calc(100vw - ${theme.spacing(9)})`}
          data-testid="lead-project-page"
        >
          {leadProject && (
            <LeadProjectHeader
              leadProject={leadProject}
              projectNameRef={projectNameRef}
              bottomHeaderRef={bottomHeaderRef}
              handleSave={getLeadProject}
              handleClickLaunchProject={() => setModalOpen(true)}
            />
          )}
          {leadProject?.status == "archived" && (
            <Banner
              type="warning"
              top={headerMaxHeight + "px"}
              mainText="This lead has been archived!"
              subText="Be sure to set it to active if you want to launch any projects."
            />
          )}
          {leadProject && (
            <ContentWrapper
              zIndex={3}
              position="relative"
              sx={{ paddingTop: theme.spacing(5) }}
            >
              <Box
                display="grid"
                gridTemplateColumns={`minmax(0, 312px) minmax(0,100%)`}
                gap={3}
              >
                <Stack flexShrink={0} overflow="hidden" gap={3}>
                  <LeadProjectUpdates
                    leadProject={leadProject}
                    handleSave={getLeadProject}
                  />
                  <ProjectsLaunched projects={leadProject.projects} />
                  <LeadProjectSource
                    leadProject={leadProject}
                    handleSave={getLeadProject}
                  />
                  <LeadProjectFileSection
                    handleSave={getLeadProject}
                    leadProject={leadProject}
                    opportunityFileTypes={[
                      "Strategic Onepager",
                      "Product Deck",
                      "Partnership Confirmation",
                      "SF Confirmation",
                      "Startup Rejection",
                    ]}
                    projectFileTypes={["Other"]}
                  />
                </Stack>
                <Stack gap={3}>
                  <LeadProjectBackgroundInformation
                    leadProject={leadProject}
                    onRefresh={getLeadProject}
                  />
                  <UseCasesList
                    leadProject={leadProject}
                    onRefresh={getLeadProject}
                  />
                  <LeadProjectLeaders
                    leadProject={leadProject}
                    onRefresh={getLeadProject}
                  />
                  <LeadProjectOpportunities
                    leadProject={leadProject}
                    onRefresh={getLeadProject}
                  />
                </Stack>
              </Box>
            </ContentWrapper>
          )}

          {modalOpen && leadProject && (
            <LaunchProjectModal
              leadProject={leadProject}
              modalOpen={modalOpen}
              handleModalClose={() => setModalOpen(false)}
            />
          )}
        </Box>
      )}
    </>
  );
}
