import { Accordion, Box, Skeleton, Stack } from "@mui/material";
import { ReactElement } from "react";
import ProjectDetailsAccordionSummary from "../../../SharedComponents/ProjectDetailsAccordionSummary";

export default function DiscoverStageSkeleton(): ReactElement {
  return (
    <>
      <Stack gap={3} width="100%">
        {[140, 200, 200, 140, 160].map((width, index) => (
          <Box key={index}>
            <Accordion disabled expanded={false}>
              <ProjectDetailsAccordionSummary>
                <Skeleton width={width} height={20} />
              </ProjectDetailsAccordionSummary>
            </Accordion>
          </Box>
        ))}
      </Stack>
    </>
  );
}
