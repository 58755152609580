import { ReactElement, useState } from "react";
import {
  styled,
  Typography,
  Box,
  tooltipClasses,
  Tooltip,
  Checkbox,
  TooltipProps,
  CircularProgress,
} from "@mui/material";
import RocketLaunchOutlinedIcon from "@mui/icons-material/RocketLaunchOutlined";
import { Opportunity } from "../../../../../../Types/Opportunity";
import { enqueueSnackbar } from "notistack";
import { delay } from "../../../../../../utils";

const ToolTipContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  padding: theme.spacing(0.5),
  columnGap: theme.spacing(1),
  color: theme.palette.common.black,
  alignItems: "center",
}));

const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "white",
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.3)",
  },
}));

interface HighlightStartupProps {
  opportunity: Opportunity;
  handleOpportunityStatusChange: () => void;
  isSelectionDisabled: boolean;
  checked: boolean;
  color?: string;
}

export default function HighlightStartup(
  props: HighlightStartupProps
): ReactElement {
  const [loading, setLoading] = useState(false);

  const handleCheckboxChange = async () => {
    setLoading(true);
    try {
      props.handleOpportunityStatusChange();
    } catch (error) {
      enqueueSnackbar(`Error updating opportunity status`, {
        variant: "error",
      });
    } finally {
      await delay(500);
      setLoading(false);
    }
  };

  return (
    <StyledTooltip
      onClick={(event) => event.stopPropagation()}
      title={
        <ToolTipContainer>
          <RocketLaunchOutlinedIcon />
          <Typography
            color="text.primary"
            variant="caption"
            data-testid="next-stage"
          >
            Select for next stage
          </Typography>
        </ToolTipContainer>
      }
    >
      <Box display="flex" alignItems="center">
        {loading && (
          <CircularProgress size={20} color="inherit" sx={{ mr: 2 }} />
        )}
        <Checkbox
          color="secondary"
          disabled={props.isSelectionDisabled}
          data-testid={`select-opportunity-checkbox-${props.opportunity.id}`}
          checked={props.checked}
          className={props.isSelectionDisabled ? "disabled" : ""}
          sx={{ display: loading ? "none" : "flex" }}
          onChange={handleCheckboxChange}
        />
      </Box>
    </StyledTooltip>
  );
}
