import { createContext } from "react";
import { User } from "../Types/User";

export const UserContext = createContext<User | undefined>(undefined);

export const hasAccessToVentureClient = (
  user: User | undefined,
  ventureClientId: number
): boolean => {
  if (user === undefined) {
    throw new Error("user is undefined");
  }
  return (
    user.roleName === "Admin" ||
    user.rights.some(
      (right) =>
        right.ventureClientId === ventureClientId ||
        (right.scopeType === "Venture Client" &&
          right.scopeId === ventureClientId)
    )
  );
};
