import {
  Accordion,
  AccordionDetails,
  AccordionProps,
  Button,
  CircularProgress,
  Typography,
} from "@mui/material";
import React, { ReactElement, useContext } from "react";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import SolutionRequirementItem from "./SolutionRequirementItem";
import useRequirements from "../../../../../Hooks/useRequirements";
import ProjectDetailsAccordionSummary from "../../../SharedComponents/ProjectDetailsAccordionSummary";
import { GlobalProjectEditContext } from "../../../../../Context/ProjectDetailsContext";
import useRoles from "../../../../../Hooks/useRoles";
import { Project } from "../../../../../Types/Project";

interface SolutionRequirementsProps extends Omit<AccordionProps, "children"> {
  project: Project;
  handleSaveNoScroll: () => void;
}

export default function SolutionRequirements(
  props: SolutionRequirementsProps
): ReactElement {
  const {
    requirements,
    currentEditRequirement,
    setCurrentEditRequirement,
    addRequirement,
    deleteRequirement,
    changeRequirement,
    saveRequirement,
    handleDragEnd,
    isLoading,
  } = useRequirements({
    initialRequirements: props.project.requirements,
    projectId: props.project.id,
    handleSaveNoScroll: props.handleSaveNoScroll,
  });

  const { globalEditMode } = useContext(GlobalProjectEditContext);
  const { canEdit } = useRoles(props.project);

  const handleNewRequirementClick = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    addRequirement();
  };

  return (
    <Accordion
      expanded={props.expanded}
      data-testid="solution-requirements-accordion"
      onChange={props.onChange}
    >
      <ProjectDetailsAccordionSummary
        actionButton={
          canEdit && props.expanded ? (
            <Button
              onClick={handleNewRequirementClick}
              disabled={!!currentEditRequirement || globalEditMode || isLoading}
              sx={{ transition: "all 0.3s ease" }}
              variant="contained"
              data-testid="new-requirement-button"
            >
              {isLoading ? (
                <CircularProgress color="inherit" size={16} />
              ) : (
                <>Add Requirement</>
              )}
            </Button>
          ) : null
        }
      >
        Solution Requirements
      </ProjectDetailsAccordionSummary>
      <AccordionDetails>
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId="requirementList">
            {(provided) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                onBlur={() => setCurrentEditRequirement(null)}
              >
                {requirements?.length ? (
                  requirements.map((requirement, index) => (
                    <SolutionRequirementItem
                      key={requirement.id}
                      index={index}
                      currentEditRequirement={currentEditRequirement}
                      onChangeEditRequirement={setCurrentEditRequirement}
                      deleteRequirement={deleteRequirement}
                      onRequirementChange={changeRequirement}
                      onCreateRequirement={saveRequirement}
                      requirement={requirement}
                      canEdit={canEdit}
                    />
                  ))
                ) : (
                  <Typography variant="body1">
                    There are no solution requirements yet. Go ahead and add the
                    first one.
                  </Typography>
                )}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </AccordionDetails>
    </Accordion>
  );
}
