import { HTMLAttributes } from "react";
import { styled } from "@mui/material/styles";

interface RatingContentProps extends HTMLAttributes<HTMLSpanElement> {
  rating: string | null;
}

const RatingContent = styled("span")<RatingContentProps>(
  ({ theme, rating }) => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: theme.shape.radius.full,
    width: theme.spacing(6),
    color:
      rating === "A"
        ? theme.palette.text.primaryInvert.main
        : theme.palette.text.mediumEmphasis,
    ...theme.typography.subtitle2,
  })
);

export default RatingContent;
