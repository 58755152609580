import { ReactElement, useContext } from "react";
import { Skeleton, TableCell } from "@mui/material";
import { Opportunity } from "../../../../../../Types/Opportunity";
import LimitedNameList from "../LimitedNameList/LimitedNameList";
import { formatToBillion, isBillion } from "../../../../../../utils";
import { OpportunitiesLoadingContext } from "../../../../../../Context/OpportunitiesLoaderContext";

export function DetailsViewRowLabels(): ReactElement {
  return (
    <>
      <TableCell>Founding Year</TableCell>
      <TableCell>Location</TableCell>
      <TableCell>Employees</TableCell>
      <TableCell>Reference Clients</TableCell>
      <TableCell>Funding Stage</TableCell>
      <TableCell>Funding Amount (in USD)</TableCell>
      <TableCell className="extra-high-cell highlighted-border">
        Investor Names
      </TableCell>
    </>
  );
}

const formatFunding = (totalFunding: string, isUndisclosed: boolean) => {
  if (isUndisclosed) return "Funding is undisclosed";
  if (!totalFunding) return "-";

  const isBillionUnit = isBillion(totalFunding);
  const fundingUnit = isBillionUnit ? "B" : "M";
  const fundingValue = isBillionUnit
    ? formatToBillion(+totalFunding)
    : +totalFunding;

  return `$${fundingValue}${fundingUnit}`;
};

interface DetailsViewStartupCellsProps {
  opportunity: Opportunity;
}

export function DetailsViewStartupCells({
  opportunity,
}: DetailsViewStartupCellsProps): ReactElement {
  const { startup } = opportunity;
  const { opportunitiesLoading } = useContext(OpportunitiesLoadingContext);

  return (
    <>
      <TableCell>
        {opportunitiesLoading ? (
          <Skeleton width="80px" />
        ) : (
          startup.dateFounded?.getFullYear() || "-"
        )}
      </TableCell>

      <TableCell>
        {opportunitiesLoading ? (
          <Skeleton width="100px" />
        ) : (
          startup.billingCountry || "-"
        )}
      </TableCell>

      <TableCell>
        {opportunitiesLoading ? (
          <Skeleton width="60px" />
        ) : (
          startup.employees || "-"
        )}
      </TableCell>

      <TableCell>
        {opportunitiesLoading ? (
          <Skeleton width="120px" />
        ) : (
          <LimitedNameList entityNames={startup.referenceClients} />
        )}
      </TableCell>

      <TableCell>
        {opportunitiesLoading ? (
          <Skeleton width="100px" />
        ) : (
          startup.currentInvestmentStage || "-"
        )}
      </TableCell>

      <TableCell>
        {opportunitiesLoading ? (
          <Skeleton width="90px" />
        ) : (
          formatFunding(startup.totalFunding, startup.fundingIsUndisclosed)
        )}
      </TableCell>

      <TableCell className="extra-high-cell highlighted-border">
        {opportunitiesLoading ? (
          <Skeleton width="150px" />
        ) : (
          <LimitedNameList entityNames={startup.investors} />
        )}
      </TableCell>
    </>
  );
}
