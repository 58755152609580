import axios from "axios";
import USP from "../../Types/StartupUsp";

export default class StartupUspHttpService {
  public static async updateAllForStartup(
    startupId: number,
    usps: USP[]
  ): Promise<void> {
    await axios.put<void>(`/api/startup-usps`, usps, {
      params: { startupId },
    });
  }
}
