import { Close } from "@mui/icons-material";
import {
  Box,
  Button,
  Checkbox,
  Drawer,
  FormControlLabel,
  IconButton,
  Stack,
  Switch,
  ToggleButton,
  ToggleButtonGroup,
  Toolbar,
  Typography,
} from "@mui/material";
import React, { ReactElement, useState, useEffect } from "react";
import {
  StartupFilterCriteria,
  VentureClientReadinessStatus,
} from "../../Types/Startup";
import {
  employeesOptions,
  investmentStagesOptions,
} from "../../Constants/Startup";
import theme from "../../theme";
import useRoles from "../../Hooks/useRoles";
import CheckboxListWithSearch from "../UI/CheckboxListWithSearch";
import { SelectOption } from "../../Types/Common";

interface FilterDrawerProps {
  open: boolean;
  onClose: () => void;
  setFilterDrawerOpen: (state: boolean) => void;
  handleFilters: (state: StartupFilterCriteria) => void;
  activeFilters: StartupFilterCriteria;
}

//add default value of filters
const defaultFilters: StartupFilterCriteria = {
  currentInvestmentStage: [],
  employees: [],
  isMissingValidation: false,
  receivedFunding: false,
  ventureClientReadiness: ["Ready", "Not Ready", "Not Evaluated"],
};

export function FilterDrawer(props: FilterDrawerProps): ReactElement {
  const { isExternalUser } = useRoles();
  const [filterOptions, setFilterOptions] = useState(props.activeFilters);

  useEffect(() => {
    setFilterOptions(props.activeFilters);
  }, [props.activeFilters]);

  const resetFilters = () => {
    setFilterOptions(defaultFilters);
  };

  const handleAcceptFilterChanges = () => {
    if (JSON.stringify(filterOptions) !== JSON.stringify(props.activeFilters)) {
      props.handleFilters(filterOptions);
    }
    props.setFilterDrawerOpen(false);
  };

  const handleFundingCheckbox = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFilterOptions((prevState) => ({
      ...prevState,
      receivedFunding: e.target.checked,
    }));
  };

  const handleToggleSwitchFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.target;
    setFilterOptions({ ...filterOptions, isMissingValidation: checked });
  };

  const handleFundingStageSelect = (stages: SelectOption[] | undefined) => {
    setFilterOptions({
      ...filterOptions,
      currentInvestmentStage: stages?.map((stage) => stage.id as string) ?? [],
    });
  };

  const handleVentureClientReadiness = (
    _event: React.MouseEvent<HTMLElement>,
    options: VentureClientReadinessStatus[]
  ) => {
    setFilterOptions({ ...filterOptions, ventureClientReadiness: options });
  };

  const handleEmployee = (employees?: SelectOption[]) => {
    const employeeFilters = employees?.map((employee) => employee.name);
    if (employees) {
      setFilterOptions({ ...filterOptions, employees: employeeFilters });
    }
  };

  const handleDrawerClose = () => {
    props.setFilterDrawerOpen(false);
    setFilterOptions(props.activeFilters);
  };

  return (
    <Drawer
      variant="temporary"
      anchor="right"
      open={props.open}
      onClose={handleDrawerClose}
    >
      <Toolbar sx={{ padding: 3 }}>
        <IconButton
          id="filter-drawer-close-button"
          onClick={handleDrawerClose}
          sx={{
            color: theme.palette.icon.primary,
            ml: "auto",
          }}
        >
          <Close />
        </IconButton>
      </Toolbar>
      <Stack paddingInline={3} width="480px" gap={4}>
        <Box display="flex" alignItems="center">
          <Typography variant="h3">Filters</Typography>
        </Box>
        <Stack gap={1}>
          <Typography variant="subtitle2">
            Venture Client Information
          </Typography>
          <Stack gap={0.5}>
            <Typography variant="body2" color="text.mediumEmphasis">
              Venture Client Readiness
            </Typography>
            <ToggleButtonGroup
              value={filterOptions.ventureClientReadiness}
              onChange={handleVentureClientReadiness}
              color="secondary"
            >
              <ToggleButton value="Ready">Ready</ToggleButton>
              <ToggleButton value="Not Ready">Not Ready</ToggleButton>
              <ToggleButton value="Not Evaluated">Not Evaluated</ToggleButton>
            </ToggleButtonGroup>
          </Stack>
        </Stack>
        <Stack gap={3}>
          <Stack>
            <Typography variant="subtitle2">Company Information</Typography>
            <FormControlLabel
              sx={{ marginBottom: 1 }}
              control={
                <Checkbox
                  id="receivedFunding"
                  checked={filterOptions.receivedFunding}
                  onChange={handleFundingCheckbox}
                />
              }
              label="Received Funding"
            />
            <CheckboxListWithSearch
              onChange={handleFundingStageSelect}
              label="Funding Stage"
              options={investmentStagesOptions.map((inv) => ({
                id: inv.id as number,
                name: inv.name as string,
              }))}
              selectedOptions={investmentStagesOptions
                .filter((option) =>
                  filterOptions.currentInvestmentStage?.includes(
                    String(option.id)
                  )
                )
                .map(({ id, name }) => ({ id, name }))}
            />
          </Stack>

          <CheckboxListWithSearch
            onChange={handleEmployee}
            label="Employees"
            options={employeesOptions.map((employee) => ({
              id: employee.id,
              name: employee.name,
            }))}
            selectedOptions={
              filterOptions.employees?.map((employee) => ({
                id: employee,
                name: employee,
              })) || []
            }
          />
        </Stack>
        <Stack>
          {!isExternalUser && (
            <FormControlLabel
              data-testid="incomplete-startup-profile-switch"
              control={
                <Switch
                  onChange={handleToggleSwitchFilter}
                  checked={filterOptions.isMissingValidation}
                />
              }
              label="Incomplete Startup Profiles"
            />
          )}
          <Box display="flex" mr={1} alignSelf="flex-end" height="fit-content">
            <Button
              onClick={() => resetFilters()}
              variant="text"
              id="reset-startup-filters"
              startIcon={<Close />}
            >
              Clear All
            </Button>
          </Box>
        </Stack>
      </Stack>
      <Box mt="auto" p={3}>
        <Button
          fullWidth
          variant="contained"
          onClick={handleAcceptFilterChanges}
          id="apply-startup-filters"
        >
          Apply
        </Button>
      </Box>
    </Drawer>
  );
}
