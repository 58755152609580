import { DeleteOutline } from "@mui/icons-material";
import { Box, styled } from "@mui/material";
import { ReactElement, useState } from "react";
import theme from "../../../theme";
import DeleteOrRemoveModal from "../../UI/Modals/DeleteOrRemoveModal/DeleteOrRemoveModal";

const DeleteContainer = styled(Box)(() => ({
  cursor: "pointer",
  color: theme.palette.icon.mediumEmphasis,
}));

const HoverableDeleteIcon = styled(DeleteOutline)(() => ({
  opacity: 0,
  transition: "opacity 0.2s",
}));

interface FadingDeleteButtonProps {
  id: number;
  onDelete: (id: number) => void;
}

export default function FadingDeleteButton(
  props: FadingDeleteButtonProps
): ReactElement {
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <>
      <DeleteContainer
        data-testid="delete-lead-project-opportunity"
        onClick={() => setModalOpen(true)}
      >
        <HoverableDeleteIcon
          color="inherit"
          className="deleteIcon"
          fontSize="medium"
        />
      </DeleteContainer>
      {modalOpen && (
        <DeleteOrRemoveModal
          id={props.id}
          entity="Startup"
          modalOpen={modalOpen}
          setModalOpen={setModalOpen}
          handleDelete={props.onDelete}
          actionType="remove"
        />
      )}
    </>
  );
}
