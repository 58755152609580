import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { Fragment, ReactElement, useState } from "react";
import theme from "../../../theme";
import StartupSelect from "../../UI/InputFields/StartupSelect";
import { Startup } from "../../../Types/Startup";
import { LeadProject } from "../../../Types/LeadProject";
import { useSnackbar } from "notistack";
import { LeadProjectsHttpService } from "../../../Http/LeadProjects/LeadProjects.http.service";
import usePitchbookSync from "../../../Hooks/usePitchbookSync";
interface AddLeadOpportunityModalProps {
  leadProject: LeadProject;
  handleModalClose: () => void;
  modalOpen: boolean;
  onRefresh: () => void;
}

export default function AddLeadProjectOpportunityModal(
  props: AddLeadOpportunityModalProps
): ReactElement {
  const [selectedStartup, setSelectedStartup] = useState<Startup | null>();
  const handleSelectStartup = (startup?: Startup | null) => {
    setSelectedStartup(startup);
  };
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { syncStartup } = usePitchbookSync();

  const handleaddLeadOpportunity = async () => {
    if (!selectedStartup) return;

    setIsLoading(true);

    await LeadProjectsHttpService.addLeadOpportunity({
      leadProjectId: props.leadProject.id,
      startupId: selectedStartup.id,
    })
      .then(async () => {
        await syncStartup(selectedStartup.id);
        setIsLoading(false);
        props.onRefresh();
        props.handleModalClose();
      })
      .catch((error) => {
        const errorMessage = error.response?.data.message;
        enqueueSnackbar(`Could not add startup: ${errorMessage}`, {
          variant: "error",
        });
      });
  };

  return (
    <Fragment>
      <Dialog
        fullWidth
        open={props.modalOpen}
        data-testid="add-lead-project-opportunity-modal"
        PaperProps={{
          sx: {
            gap: theme.spacing(4),
          },
        }}
      >
        <DialogTitle>Add Startup</DialogTitle>
        <DialogContent>
          <form noValidate autoComplete="off">
            <StartupSelect
              label="Startup"
              handleSelectStartup={handleSelectStartup}
              assignedStartupIds={props.leadProject.opportunities.map(
                (leadOpportunity) => leadOpportunity.startupId
              )}
              selectedStartup={selectedStartup}
            />
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.handleModalClose}>Cancel</Button>
          <Button
            onClick={handleaddLeadOpportunity}
            variant="contained"
            disabled={isLoading}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}
