import {
  Box,
  Button,
  MenuItem,
  Stack,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import { Fragment, ReactElement, useState } from "react";
import { FileHttpService } from "../../../../../Http/File/File.http.service";
import { Project } from "../../../../../Types/Project";
import { FileDropzone } from "./Dropzone";
import { DisabledFiles } from "../../../../../Types/File";
import theme from "../../../../../theme";
import { Opportunity } from "../../../../../Types/Opportunity";
import { useSnackbar } from "notistack";
import { isProjectBmwCheck } from "../../../../../utils";
import { InsertDriveFileOutlined, Link } from "@mui/icons-material";

interface FileUploadSectionProps {
  project: Project;
  projectFileTypes: string[];
  opportunityFileTypes: string[];
  disabledFileTypes?: DisabledFiles;
  handleSave: () => void;
  setLastUploadFile: (id: number) => void;
  stageSpecificOpportunities: Opportunity[];
  projectStage: string;
}

export function FileUploadSection(props: FileUploadSectionProps): ReactElement {
  const { enqueueSnackbar } = useSnackbar();
  const [fileType, setFileType] = useState<string>("");
  const [selectedOpportunityId, setSelectedOpportunityId] = useState(0);
  const [toggleValue, setToggleValue] = useState<"upload" | "link">("upload");
  const [fileLink, setFileLink] = useState<string>("");

  const mapFileType = (fileType: string): string => {
    if (fileType === "Other") {
      return props.projectStage;
    } else {
      return fileType;
    }
  };

  const isOpportunityFile = (): boolean => {
    return !!(
      props.opportunityFileTypes?.includes(fileType) ||
      props.projectStage === "buy" ||
      props.projectStage === "pilot" ||
      props.projectStage === "adopt"
    );
  };

  const showOpportunityDropdown = (): boolean => {
    return !!(
      props.opportunityFileTypes?.includes(fileType) &&
      props.projectStage !== "buy" &&
      props.projectStage !== "pilot" &&
      props.projectStage !== "adopt"
    );
  };

  const fileTypeIsDisabled = (typeCheck: string): boolean => {
    return props.disabledFileTypes &&
      Object.keys(props.disabledFileTypes).includes(typeCheck)
      ? !props.disabledFileTypes[typeCheck]?.includes(
          props.project.businessUnit.ventureClientId
        )
      : true;
  };

  const getLinkableId = (): number => {
    if (!isOpportunityFile()) {
      return props.project.id;
    }

    if (
      ["discover", "assess"].includes(props.projectStage) &&
      isOpportunityFile()
    ) {
      return selectedOpportunityId;
    }

    const selectedOpportunity = props.project.opportunities?.find(
      (opportunity) => opportunity.isSelectedForPilot === true
    );

    if (
      ["buy", "pilot", "adopt"].includes(props.projectStage) &&
      selectedOpportunity
    ) {
      return selectedOpportunity.id;
    }

    return props.project.id;
  };

  const uploadFile = async (file: File): Promise<void> => {
    try {
      await FileHttpService.upload(
        file,
        getLinkableId(),
        isOpportunityFile() ? "opportunity" : "project",
        mapFileType(fileType)
      ).catch(() => {
        enqueueSnackbar("File can not be uploaded", {
          variant: "error",
        });
      });

      if (isOpportunityFile()) {
        props.setLastUploadFile(selectedOpportunityId);
      }

      resetAddFile();
      props.handleSave();
    } catch (error) {
      enqueueSnackbar("File can not be uploaded", {
        variant: "error",
      });
    }
  };

  const createFileAsLink = async (): Promise<void> => {
    try {
      await FileHttpService.createFileAsLink(
        isOpportunityFile() ? "opportunity" : "project",
        getLinkableId(),
        mapFileType(fileType),
        fileLink
      );
      resetAddFile();
      props.handleSave();
    } catch (error) {
      enqueueSnackbar("File can not be uploaded", {
        variant: "error",
      });
    }
  };

  const resetAddFile = () => {
    setFileType("");
    setFileLink("");
    setSelectedOpportunityId(0);
    setToggleValue("upload");
  };

  const showToggleButtons = (): boolean => {
    if (!fileType) {
      return false;
    } else if (!showOpportunityDropdown()) {
      return true;
    }
    return !!(showOpportunityDropdown() && selectedOpportunityId);
  };

  const showDropZone = showToggleButtons() && toggleValue === "upload";
  const showAddLink = showToggleButtons() && toggleValue === "link";

  const handleToggleTypeChange = (
    _: React.MouseEvent<HTMLElement>,
    type: "upload" | "link"
  ) => {
    if (type) setToggleValue(type);
  };

  return (
    <Fragment>
      <Typography variant="subtitle1">Add File</Typography>
      <TextField
        label="File Type to Add"
        name="File Type to Add"
        id="fileType"
        variant="outlined"
        select
        fullWidth
        value={fileType}
        onChange={(e) => setFileType(e.target.value as string)}
      >
        {props.projectFileTypes
          .filter((projectFileType) => fileTypeIsDisabled(projectFileType))
          .map((option) => (
            <MenuItem value={option} key={option}>
              {option}
            </MenuItem>
          ))}
        {props.opportunityFileTypes
          .filter((opportunityFileType) =>
            fileTypeIsDisabled(opportunityFileType)
          )
          .map((option) => (
            <MenuItem value={option} key={option}>
              {option}
            </MenuItem>
          ))}
      </TextField>
      {showOpportunityDropdown() && (
        <TextField
          label="Select Startup"
          id="startupSelect"
          variant="outlined"
          select
          fullWidth
          value={selectedOpportunityId || ""}
          sx={{ marginTop: theme.spacing(2) }}
          onChange={(e) => setSelectedOpportunityId(parseInt(e.target.value))}
        >
          {props.stageSpecificOpportunities.map((opportunity) => (
            <MenuItem
              value={opportunity.id}
              key={opportunity.id}
              data-cy={
                "MenuItem-" + opportunity.startup.name.replaceAll(" ", "_")
              }
            >
              {opportunity.startup.name}
            </MenuItem>
          ))}
        </TextField>
      )}
      {showToggleButtons() && (
        <ToggleButtonGroup
          value={toggleValue}
          onChange={handleToggleTypeChange}
          color="secondary"
          exclusive
          sx={{ width: "100%" }}
        >
          <ToggleButton
            sx={{ gap: theme.spacing(0.5) }}
            fullWidth
            value="upload"
          >
            <InsertDriveFileOutlined sx={{ fontSize: "16px" }} /> Upload File
          </ToggleButton>
          <ToggleButton sx={{ gap: theme.spacing(0.5) }} fullWidth value="link">
            <Link sx={{ fontSize: "16px" }} /> Add Link
          </ToggleButton>
        </ToggleButtonGroup>
      )}
      {showDropZone && (
        <FileDropzone
          addFile={uploadFile}
          isBmwProject={isProjectBmwCheck(
            props.project.businessUnit.ventureClientId
          )}
        />
      )}
      {showAddLink && (
        <Stack gap={2}>
          <TextField
            label="Add Link"
            value={fileLink}
            inputProps={{ "data-testid": "add-link" }}
            onChange={(e) => setFileLink(e.target.value)}
          />
          <Box display="flex" gap={2}>
            <Button fullWidth onClick={resetAddFile}>
              Cancel
            </Button>
            <Button
              fullWidth
              onClick={createFileAsLink}
              variant="contained"
              disabled={!fileLink}
            >
              Save
            </Button>
          </Box>
        </Stack>
      )}
    </Fragment>
  );
}
