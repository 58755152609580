import { LeadProject } from "../../Types/LeadProject";

export const leadProject = {
  id: 1,
  name: "Sample Lead Project",
  shortDescription: "A sample Lead project",
  backgroundInformation: "",
  ventureClientId: 123,
  ventureClient: {
    id: 1,
    name: "Bosch",
    industry: "Automotive",
    mainContactId: 1,
    unitContactId: 1,
    dateCreated: new Date("2022-01-01T00:00:00.000Z"),
    focusAreas: [],
    unitName: "",
    files: [],
    businessUnits: [
      {
        id: 1,
        name: "Rohan",
        mainContactId: 1,
        shortDescription: "A sample business unit",
        dateCreated: new Date("2022-01-01T00:00:00.000Z"),
        ventureClientId: 5,
        projects: [],
      },
    ],
  },
  projectOwnerId: 5,
  projectOwner: {
    id: 1,
    name: "Michael Jordan",
    email: "",
    oauthSubject: "444-444",
    roleName: "Client User",
    rights: [],
  },
  dateCreated: new Date("2022-01-01T00:00:00.000Z"),
  lastModifiedDate: new Date("2022-02-02T00:00:00.000Z"),
  lastModifiedBy: {
    id: 6,
    name: "Mike Tyson",
    email: "",
    oauthSubject: "123-456",
    roleName: "Client User",
    rights: [],
  },
  opportunities: [],
  useCases: [],
  leaders: [],
  files: [],
  status: "active",
  statusComment: "This is a comment",
  sourceType: "Event",
  sourceId: 2,
  projects: [],
  leadProjectHistoryLatestChange: [],
  originType: "Venture Client Unit",
  initiativeName: {
    id: 2,
    value: "Test",
    ventureClientId: 21,
    initiativeType: "Company Strategy / Management Push",
  },
  initiativeNameId: 2,
  initiativeType: "Company Strategy / Management Push",
  bookmarked: false,
  leadProjectHistoryStatusChange: [],
  statusChangeReasonId: null,
} as LeadProject;
