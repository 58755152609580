import {
  Autocomplete,
  IconButton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { ReactElement, useEffect, useState } from "react";
import { SelectInput } from "../../InputFields/SelectInput";
import { Add } from "@mui/icons-material";
import {
  CreateLeadProjectDTO,
  InitiativeType,
} from "../../../../Types/LeadProject";
import { enqueueSnackbar } from "notistack";
import { getErrorMessage } from "../../../../utils";
import { InitiativeName } from "../../../../Types/InitiativeName";
import { InitiativeNamesHttpService } from "../../../../Http/Initiatives/InitiativeNames.http.service";
import { InitiativesByOrigin } from "../../../../Constants/LeadInitiatives";
import CustomCreateEntityByName from "../CustomCreateEntityByName/CustomCreateEntityByName";

interface OriginTypeProps {
  leadProject: CreateLeadProjectDTO;
  setLeadProject: React.Dispatch<Partial<CreateLeadProjectDTO>>;
}

function CreateLeadInitiative(props: OriginTypeProps): ReactElement {
  const { leadProject, setLeadProject } = props;

  const [initiativeNames, setInitiativeNames] = useState<InitiativeName[]>([]);
  const [initiativeInputValue, setInitiativeInputValue] = useState("");
  const [createInitiativeNamesModalOpen, setCreateInitiativeNamesModalOpen] =
    useState(false);

  useEffect(() => {
    const getInitiativeNamesByVentureClient = async () => {
      if (leadProject.ventureClientId && leadProject.initiativeType) {
        await InitiativeNamesHttpService.getInitiativeNames(
          leadProject.ventureClientId,
          leadProject.initiativeType
        )
          .then((initiativeNames) => setInitiativeNames(initiativeNames))
          .catch((error) => {
            const message = getErrorMessage(error);
            enqueueSnackbar(`Could not fetch initiative names: ${message}`, {
              variant: "error",
            });
          });
      }
    };

    getInitiativeNamesByVentureClient();
  }, [leadProject.initiativeType]);

  const createInitiativeName = async (initiativeName: string) => {
    if (
      !leadProject.ventureClientId ||
      !leadProject.initiativeType ||
      !leadProject.originType
    )
      return;

    if (!initiativeName) {
      enqueueSnackbar("Initiative Name cannot be empty", {
        variant: "error",
      });
      return;
    }

    await InitiativeNamesHttpService.createInitiativeName({
      value: initiativeName,
      ventureClientId: leadProject.ventureClientId,
      initiativeType: leadProject.initiativeType,
    })
      .then((newInitiativeName) => {
        setInitiativeNames([...initiativeNames, newInitiativeName]);
        setLeadProject({ initiativeNameId: newInitiativeName.id });
        setCreateInitiativeNamesModalOpen(false);
      })
      .catch((error) => {
        const message = getErrorMessage(error);
        enqueueSnackbar(`Could not create initiative name: ${message}`, {
          variant: "error",
        });
      });
  };

  const InitiativeValues = leadProject.originType
    ? InitiativesByOrigin[leadProject.originType].map((value) => ({
        id: value,
        name: value,
      }))
    : [];

  return (
    <Stack gap={2}>
      <Typography variant="body2">
        Select which initiative or activity prompted the initiation of this
        Lead.
      </Typography>
      <SelectInput
        id="initiative-type"
        label="Initiative Type"
        selectValues={InitiativeValues}
        value={leadProject.initiativeType}
        disableSorting
        onChange={(e) =>
          setLeadProject({
            initiativeType: e.target.value as InitiativeType,
            initiativeNameId: undefined,
          })
        }
        editMode
      />
      <Autocomplete
        id="initiative-name"
        forcePopupIcon={false}
        options={initiativeNames}
        filterSelectedOptions
        filterOptions={(options) => options}
        getOptionLabel={(option) => option.value}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        onInputChange={(_, newInputValue) =>
          setInitiativeInputValue(newInputValue)
        }
        onChange={(_, selectedInitiativeName) => {
          setLeadProject({
            initiativeNameId: selectedInitiativeName?.id,
            initiativeName: selectedInitiativeName || undefined,
          });
        }}
        noOptionsText="No initiative name found"
        value={
          initiativeNames.find(
            (name) => name.id === leadProject.initiativeNameId
          ) || null
        }
        renderOption={(props, option) => (
          <li {...props} key={option.id}>
            {option.value}
          </li>
        )}
        disabled={!leadProject.initiativeType}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Initiative Name"
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {params.InputProps.endAdornment}
                  <IconButton
                    data-testid="add-new-initiative-name-button"
                    onClick={() => setCreateInitiativeNamesModalOpen(true)}
                    disabled={!leadProject.initiativeType}
                  >
                    <Add fontSize="small" />
                  </IconButton>
                </>
              ),
            }}
            inputProps={{
              ...params.inputProps,
              "data-testid": "initiative-name-input",
            }}
          />
        )}
      />
      {createInitiativeNamesModalOpen && (
        <CustomCreateEntityByName
          name="initiativeName"
          label="Initiative Name"
          modalOpen={createInitiativeNamesModalOpen}
          setModalOpen={setCreateInitiativeNamesModalOpen}
          handleCreate={createInitiativeName}
          initialValue={initiativeInputValue}
        />
      )}
    </Stack>
  );
}

export default CreateLeadInitiative;
