import { Paper, Stack, Box, Typography, Skeleton } from "@mui/material";
import { ReactElement } from "react";

export default function ProjectsLaunchedSkeleton(): ReactElement {
  return (
    <Paper component={Stack} p={3} gap={2}>
      <Box display="flex" gap={1} alignItems="center" mb={3}>
        <Typography variant="h6">Projects Launched</Typography>
      </Box>
      <Skeleton width={264} height={36} />
    </Paper>
  );
}
