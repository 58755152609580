import {
  Checkbox,
  CheckboxProps,
  FormControlLabel,
  FormControlLabelProps,
  styled,
  Tooltip,
} from "@mui/material";
import { ChangeEvent, ReactElement } from "react";

const StyledFormControlLabel = styled(FormControlLabel)(() => ({
  margin: 0,
}));

interface LabeledCheckboxProps {
  handleChange: (event: ChangeEvent<HTMLInputElement>) => void;
  checked: boolean;
  label?: string;
  disableDeselection?: boolean;
  disabledTooltip?: string | JSX.Element;
  checkboxProps?: Partial<CheckboxProps>;
  formControlLabelProps?: Partial<FormControlLabelProps>;
  dataTestId?: string;
}

export default function LabeledCheckbox({
  handleChange,
  checked,
  label,
  disableDeselection,
  disabledTooltip,
  checkboxProps,
  formControlLabelProps,
  dataTestId,
}: LabeledCheckboxProps): ReactElement {
  const formattedName = label?.replace(" ", "-").toLocaleLowerCase();
  const disabled = disableDeselection && checked;

  return (
    <Tooltip title={disabled && disabledTooltip ? disabledTooltip : null}>
      <StyledFormControlLabel
        {...formControlLabelProps}
        disabled={disabled}
        slotProps={{
          typography: {
            noWrap: true,
          },
        }}
        onClick={(event) => event.stopPropagation()}
        control={
          <Checkbox
            className="icon-toggle-btn"
            name={label?.toLocaleLowerCase() || ""}
            data-testid={dataTestId || `filter-checkbox-${formattedName}`}
            disableRipple
            checked={!!checked}
            onChange={handleChange}
            edge="start"
            {...checkboxProps}
          />
        }
        label={label}
      />
    </Tooltip>
  );
}
