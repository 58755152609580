import { Box, Skeleton, styled } from "@mui/material";
import { ReactElement } from "react";
import theme from "../../../../theme";

const StyledBox = styled(Box)({
  gap: theme.spacing(6),
  padding: theme.spacing(2),
  position: "relative",
  transition: "none",
  width: "100%",
  display: "flex",
  justifyContent: "center",
  backgroundColor: theme.palette.surface.primary.main,
  "&.stickied": {
    width: `calc(100vw - ${theme.spacing(9)})`,
    marginLeft: `calc((100vw - 100% - ${theme.spacing(9)})/-2)`,
    boxShadow: theme.boxShadows[3],
  },
});

export default function ProjectDetailsNavigationSkeleton(): ReactElement {
  return (
    <>
      <StyledBox>
        <Skeleton height={12} width={120} />
        <Skeleton height={12} width={120} />
        <Skeleton height={12} width={120} />
        <Skeleton height={12} width={120} />
        <Skeleton height={12} width={120} />
      </StyledBox>
    </>
  );
}
