import { ReactElement, useEffect, useState } from "react";
import { LeadProject, LeadProjectUseCase } from "../../../../Types/LeadProject";
import {
  Badge,
  Box,
  Button,
  Paper,
  Stack,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { getErrorMessage } from "../../../../utils";
import { LeadProjectsHttpService } from "../../../../Http/LeadProjects/LeadProjects.http.service";
import UseCaseItem, {
  UseCaseTableCell,
  UseCaseTableRow,
} from "../UseCaseItem/UseCaseItem";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import theme from "../../../../theme";
import { AxiosError } from "axios";
import useRoles from "../../../../Hooks/useRoles";

interface UseCasesListProps {
  leadProject: LeadProject;
  onRefresh: () => void;
}

export default function UseCasesList(props: UseCasesListProps): ReactElement {
  const { canEdit } = useRoles(undefined, props.leadProject);
  const { enqueueSnackbar } = useSnackbar();
  const [useCases, setUseCases] = useState<LeadProjectUseCase[]>(
    props.leadProject.useCases
  );
  const [originalUseCase, setOriginalUseCase] =
    useState<LeadProjectUseCase | null>(null);
  const [editIndex, setEditIndex] = useState<number | null>(null);
  const disableEdit =
    !!useCases.find((useCase) => useCase.id === -1) || editIndex !== null;

  useEffect(() => {
    setUseCases(props.leadProject.useCases);
  }, [props.leadProject]);

  const handleCancel = () => {
    if (originalUseCase) {
      setUseCases(
        useCases.map((uc) =>
          uc.id === originalUseCase.id ? originalUseCase : uc
        )
      );
    } else {
      setUseCases(useCases.filter((uc) => uc.id !== -1));
    }
    setEditIndex(null);
    setOriginalUseCase(null);
  };

  const addUseCase = () => {
    setUseCases([
      ...useCases,
      {
        id: -1,
        name: "",
        leadProjectId: props.leadProject.id,
        description: "",
      },
    ]);
    setEditIndex(-1);
  };

  const deleteUseCase = async (id: number) => {
    const isNew = id === -1;
    if (isNew) {
      setUseCases([...useCases.filter((useCase) => useCase.id !== id)]);
    } else {
      try {
        await LeadProjectsHttpService.deleteUseCase(props.leadProject.id, id);
        props.onRefresh();
      } catch (error) {
        const errorMessage = getErrorMessage(error as AxiosError);
        enqueueSnackbar(`Could not delete the use case: ${errorMessage}`, {
          variant: "error",
        });
      }
    }
    setEditIndex(null);
  };

  const saveUseCase = async (newUseCase: LeadProjectUseCase) => {
    if (newUseCase?.name) {
      try {
        const useCase = await LeadProjectsHttpService.addUseCase({
          leadProjectId: props.leadProject.id,
          name: newUseCase.name,
          description: newUseCase.description,
        });
        setUseCases(
          useCases.map((_useCase) => {
            if (_useCase.id == -1) return useCase;
            return _useCase;
          })
        );
      } catch (error) {
        const errorMessage = getErrorMessage(error as AxiosError);
        enqueueSnackbar(errorMessage, { variant: "error" });
      }
    } else {
      setUseCases(useCases.filter((useCase) => useCase.id !== -1));
    }
    setEditIndex(null);
    props.onRefresh();
  };

  const updateUseCase = async (updatedUseCase: LeadProjectUseCase) => {
    const isNew = updatedUseCase.id === -1;
    if (!isNew) {
      try {
        await LeadProjectsHttpService.updateUseCase(updatedUseCase);
      } catch (error) {
        const errorMessage = getErrorMessage(error as AxiosError);
        enqueueSnackbar(errorMessage, { variant: "error" });
      }
    }
    const orderedUseCases = useCases.map((useCase) =>
      useCase.id !== updatedUseCase.id ? useCase : updatedUseCase
    );
    setUseCases(orderedUseCases);
    setEditIndex(null);
    setOriginalUseCase(null);
    props.onRefresh();
  };

  return (
    <Paper component={Stack} p={3} gap={3}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box display="flex">
          <Typography variant="h6">Use Cases</Typography>
          {useCases.length === 0 && (
            <Badge
              color="warning"
              variant="dot"
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              sx={{
                "& .MuiBadge-badge": {
                  right: theme.spacing(-1),
                  maxHeight: "6px",
                  minWidth: "6px",
                },
              }}
            />
          )}
        </Box>
        {canEdit && (
          <Button
            variant="contained"
            onClick={addUseCase}
            data-testid="add-use-case-button"
            disabled={disableEdit}
          >
            Add Use Case
          </Button>
        )}
      </Box>
      <TableContainer
        sx={{
          borderTopLeftRadius: theme.shape.radius.minimal,
          borderTopRightRadius: theme.shape.radius.minimal,
        }}
      >
        <Table
          aria-label="Use Cases"
          sx={{
            borderCollapse: "separate",
            borderSpacing: theme.spacing(0, 0.5),
          }}
        >
          <TableHead>
            <UseCaseTableRow>
              <UseCaseTableCell>Use Case</UseCaseTableCell>
              <UseCaseTableCell>Description</UseCaseTableCell>
              {canEdit && <UseCaseTableCell></UseCaseTableCell>}
            </UseCaseTableRow>
          </TableHead>
          <TableBody>
            {useCases.length > 0 ? (
              <>
                {useCases.map((useCase, index) => (
                  <UseCaseItem
                    key={index}
                    editMode={useCase.id === editIndex}
                    index={index}
                    useCase={useCase}
                    setEditMode={(index) => {
                      setEditIndex(index);
                      setOriginalUseCase({ ...useCase });
                    }}
                    saveUseCase={saveUseCase}
                    updateUseCase={updateUseCase}
                    deleteUseCase={deleteUseCase}
                    disableEdit={disableEdit && useCase.id !== editIndex}
                    handleCancel={handleCancel}
                    leadProject={props.leadProject}
                  />
                ))}
              </>
            ) : (
              <UseCaseTableRow>
                <UseCaseTableCell>
                  <Box display="flex" alignItems="center">
                    <ErrorOutlineIcon color="warning" />
                    <Typography ml={1} color="text.highEmphasis">
                      Please add Use Case
                    </Typography>
                  </Box>
                </UseCaseTableCell>
              </UseCaseTableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}
