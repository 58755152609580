import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/styles";
import { ReactElement, Reducer, useEffect, useReducer } from "react";
import { StartupHttpService } from "../../Http/Startup/Startup.http.service";
import { CreateStartupDTO } from "../../Types/Startup";
import { useSnackbar } from "notistack";

interface CreateStartupModalProps {
  startupName: string;
  handleModalClose: () => void;
  modalOpen: boolean;
  handleCreateResponse: (startupId: number) => void;
}

const initialModalState = {
  name: "",
  website: "",
} as CreateStartupDTO;

export default function AddStartupExternalModal(
  props: CreateStartupModalProps
): ReactElement {
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const fullScreen = useMediaQuery(theme?.breakpoints?.down("md"));
  const [newStartup, setNewStartup] = useReducer<
    Reducer<CreateStartupDTO, Partial<CreateStartupDTO>>
  >((state, newState) => ({ ...state, ...newState }), initialModalState);

  useEffect(() => {
    setNewStartup({ name: props.startupName });
  }, [props.startupName]);

  const handleCreateStartup = async () => {
    try {
      const startupId = await StartupHttpService.createStartup({
        ...newStartup,
      });
      props.handleModalClose();
      props.handleCreateResponse(startupId);
      // eslint-disable-next-line
    } catch (error: any) {
      enqueueSnackbar(error.response.data.message || error.message, {
        variant: "error",
      });
    }
  };

  return (
    <Dialog
      data-testid="create-startup-modal"
      fullScreen={fullScreen}
      open={props.modalOpen}
      maxWidth="xs"
      fullWidth
      PaperProps={{
        sx: {
          gap: theme.spacing(4),
        },
      }}
    >
      <DialogTitle>Add New Startup</DialogTitle>
      <DialogContent>
        <Stack gap={4}>
          <TextField
            label="Company Name"
            inputProps={{
              "data-testid": "startup-name",
            }}
            required
            defaultValue={props.startupName}
            onChange={(event) => setNewStartup({ name: event.target.value })}
          />
          <TextField
            label="Website"
            inputProps={{
              "data-testid": "startup-website",
            }}
            required
            onChange={(event) => setNewStartup({ website: event.target.value })}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Box display="flex" alignItems="flex-end" gap={1}>
          <Button onClick={props.handleModalClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleCreateStartup} variant="contained">
            Save
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
}
