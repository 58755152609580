import { ReactElement } from "react";
import { Box, Button, Typography, styled, Stack, Link } from "@mui/material";
import { Auth } from "aws-amplify";
import { useSnackbar } from "notistack";
import theme from "../../theme";
import { getClientSSO, getErrorMessage } from "../../utils";
import useClient from "../../Hooks/useClient";

const ImageContainer = styled(Box)<{ src: string; alt: string }>(() => ({
  height: theme.spacing(5),
  marginTop: "auto",
}));

const BackgroundImage = styled(Box)<{ src: string; alt: string }>(() => ({
  objectFit: "cover",
  objectPosition: "bottom 15% left 0px",
  height: "100%",
  width: "100%",
  opacity: "45%",
}));

const LogoTextTop = styled(Typography)(() => ({
  color: theme.palette.text.primaryInvert.main,
  fontSize: "2.5rem",
  fontWeight: 600,
  lineHeight: 1,
}));

const LogoTextBottom = styled(Typography)(() => ({
  color: theme.palette.text.brand.main,
  fontSize: "1.75rem",
  fontWeight: 400,
  lineHeight: 1,
}));

const FooterRow = styled(Box)(() => ({
  position: "absolute",
  bottom: 24,
  left: 24,
  gap: theme.spacing(6),
  display: "flex",
}));

export default function DeloitteSignIn(): ReactElement {
  const { enqueueSnackbar } = useSnackbar();
  const { logo, background } = useClient();
  const clientSSO = getClientSSO();
  const currentYear = new Date().getFullYear();

  const handleSignInSSO = async () => {
    if (!clientSSO) return;
    try {
      await Auth.federatedSignIn({ customProvider: clientSSO.customProvider });
      // eslint-disable-next-line
    } catch (error: any) {
      const errorMessage = getErrorMessage(error);
      enqueueSnackbar(errorMessage, {
        variant: "error",
      });
    }
  };

  return (
    <Box
      display="flex"
      bgcolor="surface.primary.invert"
      width="100%"
      height="100%"
      position="fixed"
    >
      <Box position="relative" width="100%">
        <BackgroundImage
          component="img"
          alt="background"
          src={background}
          draggable="false"
        />
        <Stack position="absolute" top="10vw" left="10vw" gap={1.5}>
          <LogoTextTop>Venture Client</LogoTextTop>
          <LogoTextBottom>Operating System</LogoTextBottom>
        </Stack>
        <FooterRow>
          <Typography color="text.primaryInvert.main" variant="caption">
            © {currentYear} 27pilots Deloitte GmbH - All rights Reserved
          </Typography>
          <Link
            target="_blank"
            href="https://www.27pilots.com/legal/"
            variant="button"
            color="text.action.secondary"
          >
            Imprint
          </Link>
        </FooterRow>
      </Box>
      <Stack
        justifyContent="center"
        pb={5}
        alignItems="center"
        width="33%"
        maxWidth="978px"
        minWidth="fit-content"
      >
        <Stack maxWidth="288px" px={10} gap={5} width="100%" height="100%">
          <Stack gap={1} mt="auto">
            <Typography variant="h1" color="text.primaryInvert.main">
              Welcome
            </Typography>
            <Typography variant="h4" color="text.primaryInvert.main">
              It&apos;s time to discover, pilot, and adopt new startup
              solutions.
            </Typography>
          </Stack>
          {clientSSO && (
            <Button type="submit" variant="contained" onClick={handleSignInSSO}>
              Sign in with Deloitte SSO
            </Button>
          )}
          <ImageContainer
            component="img"
            alt="logo"
            src={logo}
            draggable="false"
          />
        </Stack>
      </Stack>
    </Box>
  );
}
