import axios from "axios";
import UseCase from "../../Types/UseCase";

export class UseCasesHttpService {
  public static async getUseCasesByName(name: string): Promise<UseCase[]> {
    const response = await axios.get<UseCase[]>(`/api/use-cases`, {
      params: { name },
    });
    return response.data;
  }

  public static async createUseCase(
    useCase: Omit<UseCase, "id">
  ): Promise<UseCase> {
    const response = await axios.post<UseCase>(`/api/use-cases`, useCase);
    return response.data;
  }
}
