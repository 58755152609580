import { useContext, useEffect } from "react";
import { GlobalProjectEditContext } from "../Context/ProjectDetailsContext";

function useSaveProject(handleSaveSection: () => Promise<void>): void {
  const { shouldSaveProject, setShouldSaveProject } = useContext(
    GlobalProjectEditContext
  );

  useEffect(() => {
    if (shouldSaveProject) {
      const saveChanges = async () => {
        await handleSaveSection();
        setShouldSaveProject(false);
      };

      saveChanges();
    }
  }, [shouldSaveProject, handleSaveSection, setShouldSaveProject]);
}

export default useSaveProject;
