import { ReactElement } from "react";
import { ClientCardContainer } from "./VentureClientList";
import { Skeleton, Stack } from "@mui/material";

export default function VentureClientListSkeleton(): ReactElement {
  return (
    <>
      {[...Array(18)].map((_, index) => (
        <ClientCardContainer key={index}>
          <Stack height={60} display="flex" justifyContent="center">
            <Skeleton height={40} width={80} />
          </Stack>
          <Stack gap={1}>
            <Skeleton height={20} width={64} />
            <Skeleton height={12} width={128} />
          </Stack>
        </ClientCardContainer>
      ))}
    </>
  );
}
