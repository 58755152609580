import { Paper, Stack, Box, Typography, Skeleton } from "@mui/material";
import { ReactElement } from "react";
import theme from "../../../theme";

export default function LeadProjectUpdatesSkeleton(): ReactElement {
  return (
    <Paper component={Stack} p={3} gap={3}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="h6">Lead Updates</Typography>
        <Skeleton
          width={60}
          height={24}
          sx={{ borderRadius: theme.shape.radius.full }}
        />
      </Box>
      <Skeleton width={264} height={104} />
      <Stack gap={1}>
        <Skeleton width={264} height={36} />
        <Skeleton width={264} height={36} />
      </Stack>
    </Paper>
  );
}
