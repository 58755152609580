import { ReactElement } from "react";
import { Box, Toolbar, Typography } from "@mui/material";
import useRoles from "../../Hooks/useRoles";
import useClient from "../../Hooks/useClient";

export default function Header(): ReactElement {
  const isExternalUser = useRoles().isExternalUser;
  const { logo: clientLogo, isExternalUrl } = useClient();

  const altTag = clientLogo?.split(/[._]+/)[0];

  return (
    <Toolbar>
      <Box display="flex" gap={4} alignItems="center">
        {(isExternalUser || isExternalUrl) && clientLogo ? (
          <Box
            component="img"
            sx={{
              maxWidth: "96px",
              maxHeight: "40px",
            }}
            alt={altTag}
            src={clientLogo}
            draggable={false}
          />
        ) : (
          <Box display="flex" flexDirection="column">
            <Typography variant="h6" color="text.primaryInvert.main">
              Venture Client
            </Typography>
            <Typography variant="caption" color="primary">
              Operating System
            </Typography>
          </Box>
        )}
      </Box>
    </Toolbar>
  );
}
