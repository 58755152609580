import { createContext } from "react";

export type StartupDetailsContextType = {
  globalEditMode: boolean;
  setGlobalEditMode: React.Dispatch<React.SetStateAction<boolean>>;
};

export const GlobalStartupEditContext =
  createContext<StartupDetailsContextType>({
    globalEditMode: false,
    setGlobalEditMode: () => null,
  });
