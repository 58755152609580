import { IconButton, Stack, StackProps, styled } from "@mui/material";
import theme from "../../../theme";
import { ReactElement, useContext, useRef } from "react";
import { GetAppOutlined } from "@mui/icons-material";
import { downloadElementAsImage } from "../../../utils";
import SkeletonWrapper from "../../UI/SkeletonWrapper";
import { GlobalLoaderContext } from "../../../Context/LoaderContext";
import { useSnackbar } from "notistack";

const WidgetWrapperContainer = styled(Stack)(() => ({
  flex: 3,
  height: "100%",
  backgroundColor: theme.palette.surface.primary.main,
  gap: theme.spacing(3),
  padding: theme.spacing(3),
  borderRadius: theme.shape.borderRadius,
  position: "relative",
}));

const DownloadButton = styled(IconButton)(() => ({
  position: "absolute",
  top: theme.spacing(2),
  right: theme.spacing(2),
}));

const StyledSkeletonWrapper = styled(SkeletonWrapper)(() => ({
  position: "absolute",
  top: theme.spacing(3),
  right: theme.spacing(3),
}));

interface WidgetWrapperProps extends StackProps {
  filename?: string;
  captureWidth?: string;
}

function WidgetWrapper(props: WidgetWrapperProps): ReactElement {
  const { filename, captureWidth = "500px", children } = props;

  const { globalLoader } = useContext(GlobalLoaderContext);
  const { enqueueSnackbar } = useSnackbar();

  const ref = useRef<HTMLDivElement>(null);

  const handleDownload = async () => {
    const element = ref.current;

    if (!element) {
      return enqueueSnackbar("Failed to capture element as image", {
        variant: "error",
      });
    }

    const clonedElement = element.cloneNode(true) as HTMLDivElement;
    clonedElement.style.width = "fit-content";
    clonedElement.style.minWidth = captureWidth;
    clonedElement.style.background = "transparent";

    document.body.appendChild(clonedElement);

    try {
      await downloadElementAsImage(clonedElement, "png", filename, [
        "download-button",
        "info-tooltip",
      ]);
    } catch (error) {
      enqueueSnackbar(error as string, {
        variant: "error",
      });
    }

    document.body.removeChild(clonedElement);
  };

  return (
    <WidgetWrapperContainer ref={ref}>
      <StyledSkeletonWrapper height={20} width={20} isLoading={globalLoader}>
        <DownloadButton className="download-button" onClick={handleDownload}>
          <GetAppOutlined color="secondary" fontSize="small" />
        </DownloadButton>
      </StyledSkeletonWrapper>
      {children}
    </WidgetWrapperContainer>
  );
}

export default WidgetWrapper;
