import { ReactElement, useState } from "react";
import { Box, Button, TextField, Typography } from "@mui/material";
import { Auth } from "aws-amplify";
import { useSnackbar } from "notistack";
import theme from "../../theme";
import AuthenticationCard from "./AuthenticationCard";

interface Props {
  setFormType: (x: string) => void;
}

export default function ResetPasswordToken(props: Props): ReactElement {
  const [email, setEmail] = useState<string>("");
  const { enqueueSnackbar } = useSnackbar();

  const handleEmailVerification = async () => {
    try {
      await Auth.forgotPassword(email);
      props.setFormType("resetPassword");
      // eslint-disable-next-line
    } catch (error: any) {
      enqueueSnackbar(error.message, {
        variant: "error",
      });
    }
  };

  return (
    <AuthenticationCard>
      <Typography variant="subtitle1" align="center">
        Reset Your Password
      </Typography>
      <Typography variant="body2">
        Enter your registered email and we will send you a token to reset your
        password.
      </Typography>
      <TextField
        id="verification-email"
        label="Email"
        autoComplete="off"
        value={email}
        onChange={(e) => setEmail(e.target.value as string)}
      />
      <Box
        gap={theme.spacing(1)}
        justifyContent="center"
        display="flex"
        marginTop={theme.spacing(1)}
      >
        <Button
          onClick={() => props.setFormType("signIn")}
          variant="text"
          id="redirect-signIn-button"
          fullWidth
        >
          Cancel
        </Button>
        <Button
          type="submit"
          onClick={handleEmailVerification}
          variant="contained"
          id="verify-email-button"
          fullWidth
        >
          Continue
        </Button>
      </Box>
    </AuthenticationCard>
  );
}
