import {
  Box,
  Accordion,
  AccordionDetails,
  AccordionProps,
  Button,
  Stack,
} from "@mui/material";
import { Project, ProjectStartupSupply } from "../../../../Types/Project";
import CustomTextField from "../../../UI/InputFields/CustomTextField";
import { SelectInput } from "../../../UI/InputFields/SelectInput";
import { ReactElement, Reducer, useContext, useReducer, useState } from "react";
import ProjectDetailsAccordionSummary from "../../SharedComponents/ProjectDetailsAccordionSummary";
import NumberCard from "../../../UI/NumberCard";
import { ProjectHttpService } from "../../../../Http/Project/Project.http.service";
import { useSnackbar } from "notistack";
import { getErrorMessage } from "../../../../utils";
import useSaveProject from "../../../../Hooks/useSaveProject";
import { GlobalProjectEditContext } from "../../../../Context/ProjectDetailsContext";
import useRoles from "../../../../Hooks/useRoles";

const ventureCapitalSelectValues = [
  { id: "High", name: "High (>$500 Mio)", display: "(>$500 Mio)" },
  { id: "Medium", name: "Medium (>$100 Mio)", display: "(>$100 Mio)" },
  { id: "Low", name: "Low (<$100 Mio)", display: "(<$100 Mio)" },
];

const availabilityOfStartupsSelectValues = [
  { id: "High", name: "High (>20)", display: "(>20)" },
  { id: "Medium", name: "Medium (6-20)", display: "(6-20)" },
  { id: "Low", name: "Low (<6)", display: "(<6)" },
];

const startupOpportunitySelectValues = [
  {
    id: "High",
    name: "High",
    description:
      "Startup technologies constitute a crucial factor in this market. The high number of Venture Capital backed companies indicates the maturity of the domain. With a high probability, we will identify suitable startup solutions.",
  },
  {
    id: "Medium",
    name: "Medium",
    description:
      "Startups are one of the primary players in this market. The number of Venture Capital backed companies indicates a promising discovery.",
  },
  {
    id: "Low",
    name: "Low",
    description:
      "Startup technologies have recently started to emerge. The number of Venture Capital backed companies is low. Hence, it could be challenging to find a suitable solution.",
  },
];

interface StartupSupplySectionProps extends Omit<AccordionProps, "children"> {
  project: Project;
  handleSave: (withScroll?: boolean | undefined) => void;
}

export default function StartupSupplySection(
  props: StartupSupplySectionProps
): ReactElement {
  const { enqueueSnackbar } = useSnackbar();
  const { globalEditMode, setGlobalEditMode } = useContext(
    GlobalProjectEditContext
  );
  const { canEdit } = useRoles(props.project);
  const [editMode, setEditMode] = useState(false);
  const [project, setProject] = useReducer<
    Reducer<ProjectStartupSupply, Partial<ProjectStartupSupply>>
  >((state, newState) => ({ ...state, ...newState }), {
    id: props.project.id,
    availabilityOfStartups: props.project.availabilityOfStartups,
    ventureCapital: props.project.ventureCapital,
    startupOpportunity: props.project.startupOpportunity,
  });

  const handleCancelEdit = () => {
    if (!props.expanded) return;

    setProject({
      availabilityOfStartups: props.project.availabilityOfStartups,
      ventureCapital: props.project.ventureCapital,
      startupOpportunity: props.project.startupOpportunity,
    });
    setEditMode(false);
    setGlobalEditMode(false);
  };

  const handleSaveSection = async () => {
    if (!props.expanded) return;

    await ProjectHttpService.updateProject(project as Project)
      .then(() => {
        props.handleSave(false);
        setEditMode(false);
        setGlobalEditMode(false);
      })
      .catch((error) => {
        const errorMessage = getErrorMessage(error);
        enqueueSnackbar(`could not save the project: ${errorMessage}`, {
          variant: "error",
        });
      });
  };

  const handleEditButtonClick = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    setEditMode(true);
    setGlobalEditMode(true);
  };

  const EditButton = () => {
    return (
      <Button
        variant="contained"
        onClick={handleEditButtonClick}
        data-testid="edit-startup-supply"
        disabled={globalEditMode}
      >
        Edit
      </Button>
    );
  };

  useSaveProject(handleSaveSection);

  return (
    <div>
      <Accordion
        expanded={props.expanded}
        onChange={props.onChange}
        disabled={editMode}
      >
        <ProjectDetailsAccordionSummary
          actionButton={
            props.expanded && !editMode && canEdit ? <EditButton /> : null
          }
        >
          Startup Supply
        </ProjectDetailsAccordionSummary>
        <AccordionDetails>
          <Stack gap={5}>
            <Box display="grid" gridTemplateColumns="repeat(3, 1fr)" gap={3}>
              {editMode ? (
                <SelectInput
                  required={true}
                  label="Availability of Startups"
                  selectValues={availabilityOfStartupsSelectValues}
                  value={project.availabilityOfStartups}
                  onChange={(e) =>
                    setProject({
                      availabilityOfStartups: e.target.value,
                    })
                  }
                  editMode={true}
                  fullWidth
                  disableSorting
                />
              ) : (
                <NumberCard
                  label="Availability of Startups"
                  value={project.availabilityOfStartups}
                  helperText={
                    availabilityOfStartupsSelectValues.find(
                      (option) => option.id === project.availabilityOfStartups
                    )?.display
                  }
                  hideCurrency
                  hideUnit
                  cardSize="big"
                  data-testid="availabilityOfStartups"
                />
              )}

              {editMode ? (
                <SelectInput
                  required={true}
                  label="Invested Amount of Venture Capital"
                  selectValues={ventureCapitalSelectValues}
                  value={project.ventureCapital}
                  onChange={(e) =>
                    setProject({ ventureCapital: e.target.value })
                  }
                  editMode={true}
                  disableSorting
                  fullWidth
                />
              ) : (
                <NumberCard
                  label="Invested Amount of Venture Capital"
                  value={project.ventureCapital}
                  helperText={
                    ventureCapitalSelectValues.find(
                      (option) => option.id === project.ventureCapital
                    )?.display
                  }
                  hideCurrency
                  hideUnit
                  cardSize="big"
                  data-testid="ventureCapital"
                />
              )}
              {editMode ? (
                <SelectInput
                  required={true}
                  label="Startup Opportunity"
                  selectValues={startupOpportunitySelectValues}
                  value={project.startupOpportunity}
                  onChange={(e) =>
                    setProject({
                      startupOpportunity: e.target.value,
                    })
                  }
                  editMode={true}
                  fullWidth
                  disableSorting
                />
              ) : (
                <NumberCard
                  label="Startup Opportunity"
                  value={project.startupOpportunity}
                  hideCurrency
                  hideUnit
                  cardSize="big"
                  data-testid="startupOpportunity"
                />
              )}
              {project.startupOpportunity && (
                <Box gridColumn="span 2">
                  <CustomTextField
                    id="startupOpportunityComment"
                    label="Startup Opportunity Comment"
                    editMode={false}
                    value={
                      startupOpportunitySelectValues.filter(
                        (option) => option.id === project.startupOpportunity
                      )[0]?.description
                    }
                  />
                </Box>
              )}
            </Box>
            {editMode && (
              <Box width="fit-content" marginLeft="auto" display="flex" gap={2}>
                <Button onClick={handleCancelEdit}>Cancel</Button>
                <Button variant="contained" onClick={handleSaveSection}>
                  Save
                </Button>
              </Box>
            )}
          </Stack>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
