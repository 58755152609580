import { Box, Paper, Skeleton, Stack } from "@mui/material";
import { ReactElement } from "react";
import ContactNameSkeleton from "../../../Contacts/ContactCard/ContactName/ContactNameSkeleton";

export default function VentureClientOverviewSkeleton(): ReactElement {
  return (
    <Paper component={Stack} p={3} gap={3}>
      <Stack gap={2} width="100%">
        <Box display="flex" justifyContent="space-between">
          <Skeleton width={80} height={40} />
          <Skeleton width={20} height={20} />
        </Box>
        <Stack gap={1}>
          <Skeleton width={64} height={20} />
          <Skeleton width={128} height={12} />
        </Stack>
      </Stack>
      <ContactNameSkeleton />
      <Box display="flex" gap={2} alignItems="center">
        <Skeleton width={64} height={16} />
        <Skeleton width={80} height={12} />
      </Box>
      <Box display="flex" justifyContent="space-between">
        <Stack gap={1}>
          <Stack gap={0.5}>
            <Skeleton width={160} height={12} />
            <Skeleton width={160} height={12} />
            <Skeleton width={64} height={12} />
          </Stack>
          <Skeleton width={64} height={12} />
        </Stack>
        <Skeleton width={20} height={20} />
      </Box>
      <Skeleton width="100%" height={140} />
    </Paper>
  );
}
