import { useEffect, useState, useContext } from "react";
import { enqueueSnackbar } from "notistack";

import { ProjectHttpService } from "../Http/Project/Project.http.service";
import { LeadProjectsHttpService } from "../Http/LeadProjects/LeadProjects.http.service";
import { VentureClientHttpService } from "../Http/VentureClient/VentureClient.http.service";
import UserHttpService from "../Http/User/User.Http.service";

import { GlobalLoaderContext } from "../Context/LoaderContext";
import { Project } from "../Types/Project";
import { LeadProject } from "../Types/LeadProject";
import { FocusArea, VentureClient } from "../Types/VentureClient";
import { User } from "../Types/User";

import { getErrorMessage } from "../utils";
import { BusinessUnit } from "../Types/BusinessUnit";
import { ClientContactDTO } from "../Types/ClientContact";

interface UseProjectKanbanDataResult {
  projects: Project[];
  leadProjects: LeadProject[];
  ventureClientSelectOptions: VentureClient[];
  projectOwnersSelectOptions: User[];
  businessUnitsSelectOptions: Partial<BusinessUnit>[];
  programManagersSelectOptions: Partial<ClientContactDTO>[];
  focusAreasSelectOptions: FocusArea[];
  isLoading: boolean;
  refreshProjectData: (preventLoading?: boolean) => Promise<void>;
}

const useProjectKanbanData = (
  user: User | null,
  isExternalUser: boolean,
  ventureClientId?: number
): UseProjectKanbanDataResult => {
  const { setGlobalLoader } = useContext(GlobalLoaderContext);

  const [isLoading, setIsLoading] = useState(false);
  const [projects, setProjects] = useState<Project[]>([]);
  const [leadProjects, setLeadProjects] = useState<LeadProject[]>([]);

  const [ventureClientSelectOptions, setVentureClientSelectOptions] = useState<
    VentureClient[]
  >([]);
  const [projectOwnersSelectOptions, setProjectOwnersSelectOptions] = useState<
    User[]
  >([]);
  const [businessUnitsSelectOptions, setBusinessUnitSelectOptions] = useState<
    Partial<BusinessUnit>[]
  >([]);
  const [programManagersSelectOptions, setProgramManagerSelectOptions] =
    useState<Partial<ClientContactDTO>[]>([]);
  const [focusAreasSelectOptions, setFocusAreaSelectOptions] = useState<
    FocusArea[]
  >([]);

  const defaultFilters = JSON.stringify({
    status: ["active"],
    userProjects: !isExternalUser,
    projectOwners: [],
    ventureClients: isExternalUser ? [ventureClientId] : [],
    programManagers: [],
  });

  const refreshProjectData = async (preventLoading = false) => {
    const filterQuery = JSON.parse(
      localStorage.getItem("projectFilters") || defaultFilters
    );
    if (!preventLoading) {
      setGlobalLoader(true);
      setIsLoading(true);
    }

    const getProjects = ProjectHttpService.getProjects(filterQuery);
    delete filterQuery.programManagerId;
    const getLeads = LeadProjectsHttpService.getLeadProjects(filterQuery);

    const [projectResponse, leadResponse] = await Promise.all([
      getProjects,
      getLeads,
    ]);

    setProjects(projectResponse);
    setLeadProjects(leadResponse);

    if (!preventLoading) {
      setGlobalLoader(false);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    refreshProjectData();
    document.title = "Kanban - Venture Client Platform";

    if (isExternalUser && ventureClientId) {
      VentureClientHttpService.getVentureClientById(ventureClientId).then(
        (ventureClient) => {
          setVentureClientSelectOptions([ventureClient]);

          const businessUnits =
            ventureClient?.businessUnits?.map((bu) => ({
              id: bu.id,
              name: bu.name,
            })) || [];

          const programManagers =
            ventureClient?.businessUnits
              ?.flatMap((bu) => bu.clientContacts || [])
              .map((contact) => ({
                id: contact.id,
                name: contact.name,
              })) || [];

          const focusAreas =
            ventureClient?.focusAreas?.map((focusArea) => ({
              id: focusArea.id,
              name: focusArea.name,
            })) || [];

          setBusinessUnitSelectOptions(businessUnits);
          setProgramManagerSelectOptions(programManagers);
          setFocusAreaSelectOptions(focusAreas);
        }
      );
    } else {
      VentureClientHttpService.getVentureClients().then((ventureClients) => {
        setVentureClientSelectOptions(ventureClients);

        const businessUnits = ventureClients
          ?.flatMap((client) => client.businessUnits || [])
          .map((bu) => ({
            id: bu.id,
            name: bu.name,
          }));

        const programManagers = ventureClients
          ?.flatMap((client) =>
            client.businessUnits?.flatMap((bu) => bu.clientContacts || [])
          )
          .map((contact) => ({
            id: contact.id,
            name: contact.name,
          }));

        const focusAreas = ventureClients
          ?.flatMap((client) => client.focusAreas || [])
          .map((focusArea) => ({
            id: focusArea.id,
            name: focusArea.name,
          }));

        setBusinessUnitSelectOptions(businessUnits);
        setProgramManagerSelectOptions(programManagers);
        setFocusAreaSelectOptions(focusAreas);
      });
    }

    UserHttpService.getUsers()
      .then(setProjectOwnersSelectOptions)
      .catch((error) => {
        enqueueSnackbar(`Could not fetch users: ${getErrorMessage(error)}`, {
          variant: "error",
        });
      });
  }, []);

  return {
    projects,
    leadProjects,
    ventureClientSelectOptions,
    projectOwnersSelectOptions,
    businessUnitsSelectOptions,
    programManagersSelectOptions,
    focusAreasSelectOptions,
    isLoading,
    refreshProjectData,
  };
};

export default useProjectKanbanData;
