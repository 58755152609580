import { Box, Stack, Tooltip, TooltipProps, Typography } from "@mui/material";
import {
  numberMagnitude,
  roundNumberToMagnitude,
  thousandSeparator,
} from "../../../utils";
import SkeletonWrapper from "../../UI/SkeletonWrapper";
import { ReactElement, useContext } from "react";
import { GlobalLoaderContext } from "../../../Context/LoaderContext";
import theme from "../../../theme";

const BusinessImpactMetricsSkeleton = () => {
  const { globalLoader } = useContext(GlobalLoaderContext);

  return (
    <Stack gap={0.5} height={37.5}>
      <SkeletonWrapper width={100} height={18} isLoading={globalLoader} />
      <SkeletonWrapper isLoading={globalLoader} width={100} height={10} />
    </Stack>
  );
};

const CustomTooltip = (props: TooltipProps) => {
  return (
    <Tooltip
      {...props}
      slotProps={{
        popper: {
          modifiers: [
            {
              name: "offset",
              options: {
                offset: [0, -14],
              },
            },
          ],
        },
      }}
    />
  );
};

interface ImpactMetricsProps {
  label: string;
  value: number;
  color: string;
  globalLoader: boolean;
}

const ImpactMetrics = ({
  label,
  value,
  color,
  globalLoader,
}: ImpactMetricsProps): ReactElement => {
  if (globalLoader) {
    return <BusinessImpactMetricsSkeleton />;
  }

  return (
    <Box>
      <CustomTooltip
        title={`€ ${thousandSeparator(value)}`}
        placement="top-start"
      >
        <Typography
          variant="subtitle1"
          color={color}
          display="flex"
          alignItems="center"
          gap={0.5}
        >
          <Typography variant="subtitle2" component="span">
            €
          </Typography>
          {roundNumberToMagnitude(value)}
          <Typography variant="subtitle2" component="span">
            {numberMagnitude(value)}
          </Typography>
        </Typography>
      </CustomTooltip>
      <Typography
        variant="overline"
        color={theme.palette.text.mediumEmphasis}
        display="block"
      >
        {label}
      </Typography>
    </Box>
  );
};

export default ImpactMetrics;
