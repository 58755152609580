import axios from "axios";
import {
  CalculationType,
  CreateCalculation,
  UpdateCalculation,
} from "../../Types/ImpactValue";

export class CalculationHttpService {
  public static async createCalculation(
    calculation: CreateCalculation
  ): Promise<CalculationType> {
    const response = await axios.post<CalculationType>(
      `/api/calculations`,
      calculation
    );
    return response.data;
  }

  public static async updateCalculation(
    id: number,
    calculation: UpdateCalculation
  ): Promise<CalculationType> {
    const response = await axios.put<CalculationType>(
      `/api/calculations/${id}`,
      calculation
    );
    return response.data;
  }

  public static async deleteCalculation(id: number): Promise<void> {
    await axios.delete<void>(`/api/calculations/${id}`);
  }
}
