import { forwardRef, ReactElement } from "react";
import { Box, styled, Typography, BoxProps, Tooltip } from "@mui/material";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import { Flag } from "@mui/icons-material";

type BannerType = "warning" | "flag";

interface BannerProps extends BoxProps {
  type: BannerType;
  mainText: string;
  subText?: string;
  tooltipText?: JSX.Element;
}

interface IconWrapperBoxProps {
  $flagged?: boolean;
}

const BannerContainer = styled(Box, {
  shouldForwardProp: (prop: string) => !prop.startsWith("$"),
})<IconWrapperBoxProps>(({ theme, $flagged }) => ({
  width: "100%",
  padding: theme.spacing(1),
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: theme.spacing(1),
  backgroundColor: $flagged
    ? theme.palette.surface.error.light
    : theme.palette.surface.cool,
  position: "sticky",
  zIndex: 4,
}));

const Banner = forwardRef(function ArchiveBanner(
  { mainText, subText, tooltipText, type, ...boxProps }: BannerProps,
  ref
): ReactElement {
  const getIcon = () => {
    switch (type) {
      case "warning":
        return <ReportProblemIcon color="warning" />;
      case "flag":
        return <Flag color="error" />;
      default:
        return null;
    }
  };
  return (
    <Tooltip title={tooltipText}>
      <BannerContainer
        $flagged={type === "flag"}
        data-testid="banner-container"
        ref={ref}
        {...boxProps}
      >
        {getIcon()}
        <Typography variant="subtitle2">{mainText}</Typography>
        <Typography>{subText}</Typography>
      </BannerContainer>
    </Tooltip>
  );
});

export default Banner;
