import { KeyboardArrowDown, ListAltOutlined } from "@mui/icons-material";
import {
  AccordionSummary,
  AccordionSummaryProps,
  Box,
  Button,
  styled,
} from "@mui/material";
import theme from "../../../theme";
import { ReactElement, ReactNode } from "react";

type ProjectDetailsAccordionSummaryProps = (
  | {
      $fullWidth: true;
      $fullyExpanded: boolean;
      $handleFullWidthButton: () => void;
    }
  | {
      $fullWidth?: false;
    }
) & {
  actionButton?: ReactNode;
} & AccordionSummaryProps;

const StyledAccordionSummary = styled(AccordionSummary, {
  shouldForwardProp: (prop: string) => !prop.startsWith("$"),
})(() => ({
  flexDirection: "row-reverse",
  gap: theme.spacing(1),
  padding: theme.spacing(3),
  "& .MuiAccordionSummary-content": {
    margin: 0,
    alignItems: "center",
  },
  ...theme.typography.h6,
}));

const ProjectDetailsAccordionSummary = (
  props: ProjectDetailsAccordionSummaryProps
): ReactElement => {
  const { children, actionButton, $fullWidth, ...defaultProps } = props;

  return (
    <StyledAccordionSummary
      expandIcon={
        <KeyboardArrowDown sx={{ color: theme.palette.icon.primary }} />
      }
      {...defaultProps}
    >
      {children}
      {actionButton && (
        <Box
          marginLeft="auto"
          onClick={(e) => {
            e.stopPropagation();
          }}
          sx={{
            cursor: "default",
          }}
        >
          {actionButton}
        </Box>
      )}
      {$fullWidth && (
        <Button
          variant="text"
          startIcon={<ListAltOutlined />}
          onClick={(e) => {
            e.stopPropagation();
            props.$handleFullWidthButton();
          }}
          sx={{ marginLeft: "auto" }}
        >
          {props.$fullyExpanded ? "Hide Full List" : "View Full List"}
        </Button>
      )}
    </StyledAccordionSummary>
  );
};

export default ProjectDetailsAccordionSummary;
