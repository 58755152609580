import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
} from "@mui/material";
import { useTheme } from "@mui/styles";
import { ReactElement, useEffect, Reducer, useReducer } from "react";
import {
  BusinessUnit,
  EditBusinessUnitDTO,
} from "../../../../Types/BusinessUnit";
import { BusinessUnitHttpService } from "../../../../Http/BusinessUnit/BusinessUnit.http.service";
import ClientContactSelect from "../../../UI/InputFields/ClientContactSelect";
import CustomTextField from "../../../UI/InputFields/CustomTextField";
import { enqueueSnackbar } from "notistack";
interface ManageBusinessUnitModalProps {
  businessUnit?: BusinessUnit;
  ventureClientId: number;
  modalOpen: boolean;
  handleModalClose: () => void;
  handleSave: () => void;
}

export default function ManageBusinessUnitModal(
  props: ManageBusinessUnitModalProps
): ReactElement {
  const theme = useTheme();

  const [newBusinessUnit, setNewBusinessUnit] = useReducer<
    Reducer<EditBusinessUnitDTO, Partial<EditBusinessUnitDTO>>
  >((state, newState) => ({ ...state, ...newState }), {
    ventureClientId: props.ventureClientId,
  } as EditBusinessUnitDTO);

  const isCreateMode = !props.businessUnit;

  useEffect(() => {
    setNewBusinessUnit({ ...props.businessUnit } as EditBusinessUnitDTO);
  }, [props.businessUnit]);

  const handleSaveButton = async () => {
    if (isCreateMode) {
      if (!isBusinessUnitNameValid()) {
        enqueueSnackbar("Please enter Name", {
          variant: "error",
        });
        return false;
      }

      if (!isShortDescriptionValid()) {
        enqueueSnackbar("Please enter Short Description", {
          variant: "error",
        });
        return false;
      }

      await BusinessUnitHttpService.createBusinessUnit(newBusinessUnit);
    } else {
      await BusinessUnitHttpService.updateBusinessUnit(newBusinessUnit);
    }
    props.handleSave();
    props.handleModalClose();
  };

  const isBusinessUnitNameValid = () => {
    return newBusinessUnit.name && newBusinessUnit.name.trim().length !== 0;
  };

  const isShortDescriptionValid = () => {
    return (
      newBusinessUnit.shortDescription &&
      newBusinessUnit.shortDescription.trim().length !== 0
    );
  };

  return (
    <Dialog
      fullWidth
      open={props.modalOpen}
      id="business-unit-edit-view"
      data-testid="organizational-unit-modal"
      PaperProps={{
        sx: {
          gap: theme.spacing(4),
        },
      }}
    >
      <DialogTitle>
        {isCreateMode ? "Add " : "Edit "}Organizational Unit
      </DialogTitle>
      <DialogContent>
        <Stack noValidate autoComplete="off" component="form" gap={4}>
          <CustomTextField
            editMode={true}
            label="Name"
            id="name"
            variant="outlined"
            required
            fullWidth
            value={newBusinessUnit.name || ""}
            onChange={(e) => setNewBusinessUnit({ name: e.target.value })}
          />
          <CustomTextField
            editMode={true}
            label="Short Description"
            id="shortDescription"
            variant="outlined"
            required
            fullWidth
            value={newBusinessUnit.shortDescription || ""}
            onChange={(e) =>
              setNewBusinessUnit({ shortDescription: e.target.value })
            }
          />
          <ClientContactSelect
            labelText="Venture Client Program Manager"
            ventureClientId={props.ventureClientId}
            onChange={setNewBusinessUnit}
            fieldId="mainContactId"
            multiSelect={false}
            contactData={props.businessUnit?.mainContact}
            editMode={true}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button
          autoFocus
          onClick={props.handleModalClose}
          id="close-business-unit-modal"
        >
          Cancel
        </Button>
        <Button
          onClick={handleSaveButton}
          variant="contained"
          id="save-business-unit"
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}
