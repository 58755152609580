import { Box, BoxProps, Skeleton, Stack } from "@mui/material";
import { ReactElement } from "react";

export interface ContactNameSkeletonProps extends BoxProps {
  orientation?: "horizontal" | "vertical";
}

export default function ContactNameSkeleton({
  orientation = "horizontal",
  ...props
}: ContactNameSkeletonProps): ReactElement {
  return (
    <Box
      display="flex"
      flexDirection={orientation === "horizontal" ? "row" : "column"}
      gap={2}
      {...props}
    >
      <Skeleton width={32} height={32} variant="circular" />
      <Stack justifyContent="center" gap={1}>
        <Skeleton width={112} height={12} />
        <Skeleton width={64} height={8} />
      </Stack>
    </Box>
  );
}
