import React, { ReactElement } from "react";
import {
  Dialog,
  DialogContent,
  CircularProgress,
  Typography,
  Box,
} from "@mui/material";

interface LoadingModalProps {
  isLoading: boolean;
  content: string;
}

const LoadingModal = (props: LoadingModalProps): ReactElement => {
  return (
    <Dialog data-testid="loading-modal" maxWidth="xs" open={props.isLoading}>
      <DialogContent>
        <Box display="flex" alignItems="center">
          <CircularProgress size={20} />
          <Typography style={{ marginLeft: "15px" }}>
            {props.content}
          </Typography>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default LoadingModal;
