import { useState, useEffect, useRef } from "react";
import PageHeader from "../UI/PageHeader";
import { useHistory, useParams } from "react-router-dom";
import { ProblemsHttpService } from "../../Http/Problems/Problems.http.service";
import { Box, Button, Stack, styled, Typography } from "@mui/material";
import StartupCard from "../Startup/StartupCard";
import theme from "../../theme";
import CreateLeadProjectModal from "../UI/Modals/CreateLeadProjectModal/CreateLeadProjectModal";
import { LeadProjectsHttpService } from "../../Http/LeadProjects/LeadProjects.http.service";
import { getErrorMessage } from "../../utils";
import { enqueueSnackbar } from "notistack";
import { AxiosError } from "axios";
import SkeletonWrapper from "../UI/SkeletonWrapper";
import { ProblemsDTO } from "../../Types/Problem";
import CustomExpendableText from "../UI/CustomExpendableText";

const baseS3Url =
  "https://vclos-frontend.s3.eu-central-1.amazonaws.com/problems/";

const ProblemContainer = styled(Stack)(() => ({
  gap: theme.spacing(2),
  margin: theme.spacing(6, 6),
  justifyContent: "center",
}));

const StyledProblemDetailsCard = styled(Stack)(() => ({
  backgroundColor: theme.palette.background.paper,
  padding: theme.spacing(3),
  borderRadius: theme.shape.radius.minimal,
  maxWidth: "1000px",
  gap: theme.spacing(2),
}));

const GradientWrapper = styled(Box, {
  shouldForwardProp: (prop) =>
    prop !== "showLeftBlur" && prop !== "showRightBlur",
})<{ showLeftBlur: boolean; showRightBlur: boolean }>(
  ({ showLeftBlur, showRightBlur }) => ({
    position: "relative",
    overflow: "hidden",
    "&::before": {
      content: showLeftBlur ? '""' : "none",
      position: "absolute",
      top: 0,
      left: 0,
      height: "100%",
      width: "50px",
      pointerEvents: "none",
      background: `linear-gradient(90deg, #FFF 0%, rgba(255, 255, 255, 0.00) 100%)`,
      zIndex: 1,
    },
    "&::after": {
      content: showRightBlur ? '""' : "none",
      position: "absolute",
      top: 0,
      right: 0,
      height: "100%",
      width: "50px",
      pointerEvents: "none",
      background: `linear-gradient(90deg, rgba(255, 255, 255, 0.00) 0%, #FFF 100%)`,
      zIndex: 1,
    },
  })
);

const HorizontalScrollableContainer = styled(Box)(() => ({
  display: "flex",
  flexWrap: "nowrap",
  gap: theme.spacing(3),
  overflowX: "auto",
  borderRadius: theme.shape.radius.minimal,
}));

const ProblemDiscoveryDetailsPage = (): JSX.Element => {
  const { id } = useParams<{ id: string }>();
  const history = useHistory();

  const [problem, setProblem] = useState<ProblemsDTO | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showLeftBlur, setShowLeftBlur] = useState(false);
  const [showRightBlur, setShowRightBlur] = useState(true);
  const scrollContainerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    fetchProblem(Number(id));
  }, []);

  const fetchProblem = async (problemId: number) => {
    try {
      const response = await ProblemsHttpService.getProblemById(problemId);
      setProblem(response);
    } catch (error) {
      const errorMessage = getErrorMessage(error as AxiosError);
      return enqueueSnackbar(
        `Could not get the problem details: ${errorMessage}`,
        {
          variant: "error",
        }
      );
    }
  };

  useEffect(() => {
    if (problem) {
      document.title = `${problem.name} - Venture Client Platform`;
    }
  }, [problem]);

  const handleModalClose = async (createdLeadId?: number) => {
    if (createdLeadId && problem) {
      try {
        await LeadProjectsHttpService.addUseCase({
          leadProjectId: createdLeadId,
          name: problem.name,
          description: problem.description,
        });

        history.push(`/lead-project/${createdLeadId}`);
      } catch (error) {
        const errorMessage = getErrorMessage(error as AxiosError);
        enqueueSnackbar(`Error while adding a lead use case: ${errorMessage}`, {
          variant: "error",
        });
      }
    }
    setIsModalOpen(false);
  };

  const handleScroll = () => {
    if (scrollContainerRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } =
        scrollContainerRef.current;

      // Update left blur visibility
      setShowLeftBlur(scrollLeft > 10);

      // Update right blur visibility
      setShowRightBlur(scrollLeft + clientWidth < scrollWidth - 10);
    }
  };

  return (
    <>
      {problem && (
        <>
          <PageHeader
            title={problem.name}
            subtitle={problem.shortDescription}
            dynamicBgImage={baseS3Url + problem.imageUrl}
            actions={
              <Button
                variant="contained"
                onClick={() => setIsModalOpen(true)}
                data-testid="create-lead-button"
              >
                Create a Lead
              </Button>
            }
          />
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <ProblemContainer>
              <StyledProblemDetailsCard>
                <Typography variant="h6">Problem</Typography>
                <CustomExpendableText
                  maxLines={7}
                  text={problem.description}
                ></CustomExpendableText>
              </StyledProblemDetailsCard>

              <StyledProblemDetailsCard>
                <Typography margin={theme.spacing(0, 0, 2, 0)} variant="h6">
                  Startups
                </Typography>
                <GradientWrapper
                  showLeftBlur={showLeftBlur}
                  showRightBlur={showRightBlur}
                >
                  <HorizontalScrollableContainer
                    ref={scrollContainerRef}
                    onScroll={handleScroll}
                    data-testid="startup-list"
                  >
                    {problem.startups.map((startup) => (
                      <Box key={startup.id} data-testid="startup-item">
                        <SkeletonWrapper isLoading={false} width={200}>
                          <StartupCard
                            startup={startup}
                            showWebsite={true}
                            showAddStartupToProject={false}
                            greyBgColor={true}
                          />
                        </SkeletonWrapper>
                      </Box>
                    ))}
                  </HorizontalScrollableContainer>
                </GradientWrapper>
              </StyledProblemDetailsCard>
            </ProblemContainer>
          </Box>
          {isModalOpen && (
            <CreateLeadProjectModal
              modalOpen={isModalOpen}
              handleModalClose={handleModalClose}
              defaultLeadProject={{
                name: problem?.name,
                originType: "Venture Client Unit",
              }}
            />
          )}
        </>
      )}
    </>
  );
};

export default ProblemDiscoveryDetailsPage;
