import {
  Paper,
  Stack,
  Box,
  Typography,
  Skeleton,
  TableContainer,
  Table,
  TableHead,
  TableBody,
} from "@mui/material";
import { ReactElement } from "react";
import theme from "../../../../theme";
import { UseCaseTableRow, UseCaseTableCell } from "../UseCaseItem/UseCaseItem";
import { UseCaseItemSkeleton } from "../UseCaseItem/UseCaseItemSkeleton";

export default function UseCasesListSkeleton(): ReactElement {
  return (
    <Paper component={Stack} p={3} gap={3}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="h6">Use Cases</Typography>
        <Skeleton width={128} height={36} />
      </Box>
      <TableContainer
        sx={{
          borderTopLeftRadius: theme.shape.radius.minimal,
          borderTopRightRadius: theme.shape.radius.minimal,
        }}
      >
        <Table
          aria-label="Use Cases"
          sx={{
            borderCollapse: "separate",
            borderSpacing: theme.spacing(0, 0.5),
          }}
        >
          <TableHead>
            <UseCaseTableRow>
              <UseCaseTableCell>Use Case</UseCaseTableCell>
              <UseCaseTableCell>Description</UseCaseTableCell>
              <UseCaseTableCell></UseCaseTableCell>
            </UseCaseTableRow>
          </TableHead>
          <TableBody>
            <UseCaseItemSkeleton />
            <UseCaseItemSkeleton />
            <UseCaseItemSkeleton />
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}
