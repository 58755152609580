import { Chip, Tooltip } from "@mui/material";
import { ReactElement } from "react";
import { TOP_CONTENDER_TOOLTIP } from "../../../../../../Constants/TooltipText";
import theme from "../../../../../../theme";

export default function TopContenderBadge(): ReactElement {
  return (
    <Tooltip title={TOP_CONTENDER_TOOLTIP}>
      <Chip
        variant="choice"
        label="Top Contender"
        size="small"
        sx={{ ml: "auto" }}
        icon={
          <span
            className="material-symbols-outlined"
            style={{ color: theme.palette.icon.action.main, fontSize: 16 }}
          >
            award_star
          </span>
        }
      />
    </Tooltip>
  );
}
