import { funnelStages } from "../Constants/FunnelStages";
import ProjectValidator from "../Services/ProjectValidator";
import { Project } from "../Types/Project";
import { MissingInformation } from "../Types/ProjectRules";

export const getSelectedStartupName = (
  project: Project
): string | undefined => {
  return project?.opportunities?.find(
    (opportunity) => opportunity.isSelectedForPilot === true
  )?.startup.name;
};

export const checkQualifiedOpportunities = (project: Project): boolean => {
  return !!project?.opportunities?.find(
    (opportunity) => opportunity.isQualified === true
  );
};

export default class StageValidator {
  static getInvalidProperties(project: Project): string[] {
    const stageIndex = funnelStages.indexOf(project.funnelStage);
    const invalidProperties: string[] = [];

    if (stageIndex >= 0 && !checkQualifiedOpportunities(project)) {
      invalidProperties.push("Select Startup(s) for Assess Phase");
    }
    if (stageIndex >= 0) {
      project.opportunities.forEach((opportunity) => {
        if (
          !opportunity.isQualified &&
          opportunity.rejectionReasons?.length === 0
        ) {
          invalidProperties.push("Provide Startup(s) rule out reasons");
        }
      });
    }

    if (stageIndex >= 1 && getSelectedStartupName(project) === undefined) {
      invalidProperties.push("Select Startup for Buy stage");
    }

    for (let i = 0; i <= stageIndex; i++) {
      const missingInformations = ProjectValidator.getMissingProjectInformation(
        project,
        funnelStages[i]
      );

      if (stageIndex > i) {
        missingInformations.files = missingInformations.files.filter(
          (f: MissingInformation) => f.stageSpecific === false
        );
        missingInformations.fields = missingInformations.fields.filter(
          (f: MissingInformation) => f.stageSpecific === false
        );
      }

      invalidProperties.push(
        ...missingInformations.fields.map((field) => field.name),
        ...missingInformations.files.map((field) => field.name)
      );
    }

    return Array.from(new Set(invalidProperties));
  }
}
