import { Box, Stack, styled, Typography, Chip } from "@mui/material";
import { ReactElement } from "react";
import theme from "../../../../theme";
import { Meeting } from "../../../../Types/Meeting";
import RocketIcon from "@mui/icons-material/Rocket";
import { CheckCircleOutline } from "@mui/icons-material";
import { getApproximateDuration } from "../../../ProjectDetails/FunnelStages/Buy/ProjectTimeline/ProjectTimeline";
import OverviewMeetingRow from "./OverviewMeetingRow";
import { compareMeetings, daysBetweenDates } from "../../../../utils";

const DurationDisplay = styled(Chip)(() => ({
  border: `1px solid ${theme.palette.borderOutline.main}`,
  borderRadius: theme.shape.radius.minimal,
  backgroundColor: "transparent",
  height: "24px",
  fontSize: theme.typography.caption.fontSize,
  pointerEvents: "none",
}));

const ProgressBar = styled("span", {
  shouldForwardProp: (prop: string) => !prop.startsWith("$"),
})(({ $rocketPosition }: { $rocketPosition: number }) => ({
  position: "absolute",
  right: 0,
  width: "1px",
  height: `${100 - $rocketPosition}%`,
  bottom: 0,
  border: `1px dashed ${theme.palette.borderOutline.main}`,
  zIndex: 0,
}));

const ElapsedBarWrapper = styled("span")(() => ({
  position: "absolute",
  right: 24,
  width: 1,
  display: "flex",
  alignItems: "center",
  height: "calc(100% - 74px)",
  transform: "translate(0, 36px)",
  zIndex: 11,
}));

const ElapsedBar = styled("span", {
  shouldForwardProp: (prop: string) => !prop.startsWith("$"),
})(({ $rocketPosition }: { $rocketPosition: number }) => ({
  position: "absolute",
  width: "100%",
  height: `${$rocketPosition}%`,
  border: `1px dashed ${theme.palette.brand.main}`,
  top: 0,
}));

const IconWrapper = styled("span")(() => ({
  position: "absolute",
  right: -16,
  width: "32px",
  aspectRatio: "1/1",
  borderRadius: "50%",
  display: "grid",
  placeItems: "center",
  zIndex: 10,
}));

const RocketIconWrapper = styled(IconWrapper, {
  shouldForwardProp: (prop: string) => !prop.startsWith("$"),
})(
  ({
    $rocketPosition,
    $disabled,
  }: {
    $rocketPosition: number;
    $disabled: boolean;
  }) => ({
    border: $disabled
      ? `1px solid ${theme.palette.borderOutline.main}`
      : `1px solid transparent`,
    backgroundColor: $disabled
      ? theme.palette.icon.primaryInvert.main
      : theme.palette.brand.main,
    top: `calc(${$rocketPosition}% - 16px)`,
  })
);

const RocketIndicator = styled(RocketIcon, {
  shouldForwardProp: (prop: string) => !prop.startsWith("$"),
})(({ $disabled }: { $disabled: boolean }) => ({
  fontSize: "18px",
  stroke: $disabled
    ? theme.palette.borderOutline.main
    : theme.palette.icon.primaryInvert.main,
  strokeWidth: "2px",
  fill: "transparent",
}));

const ConclusionIconWrapper = styled(IconWrapper, {
  shouldForwardProp: (prop: string) => !prop.startsWith("$"),
})<{
  $isConcluded: boolean;
}>(({ $isConcluded }) => ({
  top: "calc(100% - 16px)",
  fill: theme.palette.secondary.main,
  color: $isConcluded
    ? theme.palette.common.white
    : theme.palette.borderOutline.main,
  backgroundColor: $isConcluded
    ? theme.palette.primary.main
    : theme.palette.background.default,
}));

const getRocketPosition = (meetings: Meeting[]): number => {
  const today = new Date(Date.now());
  today.setHours(0, 0, 0, 0);
  const kickOffMeeting = new Date(meetings[0]?.dateStart || "");
  kickOffMeeting.setHours(0, 0, 0, 0);
  const conclusionMeeting = new Date(meetings.at(-1)?.dateStart || "");
  conclusionMeeting.setHours(0, 0, 0, 0);

  const totalDays = conclusionMeeting.getTime() - kickOffMeeting.getTime();
  const daysElapsed = today.getTime() - kickOffMeeting.getTime();

  const clampedRocketPosition = Math.max(
    Math.min((daysElapsed / totalDays) * 100, 100),
    0
  );

  if (isNaN(clampedRocketPosition)) return 0;

  return clampedRocketPosition;
};

const getDurationInfo = (
  meetings: Meeting[]
): { duration: number; label: string } => {
  const today = new Date(Date.now());
  today.setHours(0, 0, 0, 0);
  const kickOffMeeting = new Date(meetings[0]?.dateStart || "");
  kickOffMeeting.setHours(0, 0, 0, 0);
  const conclusionMeeting = new Date(meetings.at(-1)?.dateStart || "");
  conclusionMeeting.setHours(0, 0, 0, 0);

  if (today < kickOffMeeting) {
    return {
      duration: daysBetweenDates(today, kickOffMeeting),
      label: "Time until Kick-off",
    };
  } else if (today > conclusionMeeting) {
    return {
      duration: daysBetweenDates(conclusionMeeting, today),
      label: "Time since Conclusion",
    };
  } else {
    return {
      duration: daysBetweenDates(today, conclusionMeeting),
      label: "Time to Conclusion",
    };
  }
};

interface TimelineOverviewProps {
  meetings: Meeting[];
}

export default function TimelineOverview({
  meetings,
}: TimelineOverviewProps): ReactElement {
  meetings.sort(compareMeetings);
  const rocketPosition = getRocketPosition(meetings);
  const isConcluded = rocketPosition === 100;
  const kickOffMeeting = meetings[0];
  const conclusionMeeting = meetings.at(-1);
  const numberOfMilestones = meetings.length - 2;

  const durationInfo = getDurationInfo(meetings);
  const durationDisplayColor = durationInfo.duration
    ? theme.palette.brand.accessibility
    : theme.palette.text.disabled;
  const rocketDisabled = isNaN(durationInfo.duration);

  return (
    <Stack gap={2}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="subtitle1">Timeline</Typography>
        <Box display="flex" gap={2} alignItems="center">
          <Typography variant="caption" color="text.mediumEmphasis">
            {durationInfo.label}
          </Typography>
          <DurationDisplay
            variant="outlined"
            size="medium"
            label={
              <Typography variant="caption">
                {durationInfo.duration
                  ? getApproximateDuration(durationInfo.duration)
                  : "N/A"}
              </Typography>
            }
            sx={{
              borderColor: durationDisplayColor,
              color: durationDisplayColor,
            }}
          />
        </Box>
      </Box>
      {kickOffMeeting && conclusionMeeting ? (
        <Stack position="relative">
          <OverviewMeetingRow meeting={kickOffMeeting} />
          <Typography
            p={theme.spacing(1, 2)}
            color="text.mediumEmphasis"
            variant="subtitle2"
            alignSelf="flex-start"
          >
            {`${numberOfMilestones} ${
              numberOfMilestones === 1 ? "Milestone" : "Milestones"
            }`}
            <Typography component="span"> during this period</Typography>
          </Typography>
          <OverviewMeetingRow meeting={conclusionMeeting} />
          <ElapsedBarWrapper>
            {!isConcluded && (
              <RocketIconWrapper
                id="rocket"
                $rocketPosition={rocketPosition}
                $disabled={rocketDisabled}
              >
                <RocketIndicator $disabled={rocketDisabled} />
              </RocketIconWrapper>
            )}
            <ConclusionIconWrapper $isConcluded={isConcluded}>
              <CheckCircleOutline fontSize="small" />
            </ConclusionIconWrapper>
            <ElapsedBar $rocketPosition={rocketPosition} />
            <ProgressBar $rocketPosition={rocketPosition} />
          </ElapsedBarWrapper>
        </Stack>
      ) : (
        <></>
      )}
    </Stack>
  );
}
