import { Button, Paper, Stack, Typography, styled, Box } from "@mui/material";
import { ReactElement, useState } from "react";

import { formatDate } from "../../../utils";
import theme from "../../../theme";
import { LeadProject } from "../../../Types/LeadProject";
import EditLeadProjectStatus from "./EditLeadProjectStatus";
import ManageLeadStatusComment from "./ManageLeadStatusComment";
import { Pause, PlayArrow, Stop, Stream } from "@mui/icons-material";
import LeadProjectStatusOverview from "./LeadProjectStatusOverview";
import ContactName from "../../Contacts/ContactCard/ContactName/ContactName";
import { LeadProjectHistory } from "../../../Types/LeadProjectHistory";
import CustomExpendableText from "../../UI/CustomExpendableText";
import HoverableStatusChip from "../../UI/HoverableStatusChip";
import useRoles from "../../../Hooks/useRoles";

const Container = styled(Stack)(() => ({
  backgroundColor: theme.palette.background.default,
  padding: theme.spacing(2),
  gap: theme.spacing(2),
}));

const IconWrapper = styled(Box)(() => ({
  padding: theme.spacing(1),
  height: theme.spacing(4),
  borderRadius: "50%",
  backgroundColor: theme.palette.surface.mediumEmphasis,
}));

interface Props {
  leadProject: LeadProject;
  handleSave: () => void;
}

export default function LeadProjectStatus(props: Props): ReactElement {
  const { canEdit } = useRoles(undefined, props.leadProject);
  const [modalOpen, setModalOpen] = useState(false);
  const [editStatusModal, setEditStatusModal] = useState(false);
  const [manageCommentModal, setManageCommentModal] = useState(false);
  const [associatedModal, setAssociatedModal] = useState(false);

  const status = props.leadProject.status;
  const onHoldDeadline = props.leadProject.onHoldDeadline
    ? formatDate(new Date(props.leadProject.onHoldDeadline))
    : null;

  const statusCommentLastModifiedDate = props.leadProject.lastModifiedDate
    ? formatDate(props.leadProject.lastModifiedDate)
    : null;

  const reason =
    props.leadProject.leadProjectHistoryLatestChange?.[0]?.statusChangeReason
      ?.description ||
    props.leadProject.leadProjectHistoryLatestChange?.[1]?.statusChangeReason
      ?.description ||
    "";

  const latestLaunchedProject = props.leadProject.projects.at(-1);

  const handleViewMore = () => {
    setModalOpen(true);
    setAssociatedModal(true);
  };

  const handleAddNewComment = () => {
    setManageCommentModal(true);
    setAssociatedModal(false);
  };

  const handleEditStatus = () => {
    if (!canEdit) return;
    setEditStatusModal(true);
    setAssociatedModal(false);
  };

  const renderHistoryItem = (history: LeadProjectHistory) => {
    if (!history)
      return (
        <Typography color={theme.palette.text.disabled}>
          No updates have been added yet.
        </Typography>
      );

    const isExternalUser = history.user.roleName === "Client User";
    switch (history.type) {
      case "Lead Project Status Comment":
        return (
          <Stack gap={2} data-testid="status-comment-history">
            <ContactName
              isVentureAssociate={!isExternalUser}
              orientation="horizontal"
              name={history.user.name}
              title={formatDate(history.dateTriggered)}
            />
            <CustomExpendableText text={history.description} />
          </Stack>
        );

      case "Lead Project Status Change":
        let icon;
        switch (history.newValue) {
          case "on hold":
            icon = (
              <IconWrapper>
                <Pause sx={{ color: "white", fontSize: "1rem" }} />
              </IconWrapper>
            );
            break;
          case "archived":
            icon = (
              <IconWrapper>
                <Stop sx={{ color: "white", fontSize: "1rem" }} />
              </IconWrapper>
            );
            break;
          default:
            icon = (
              <IconWrapper>
                <PlayArrow sx={{ color: "white", fontSize: "1rem" }} />
              </IconWrapper>
            );
        }

        const isOnHold = history.newValue === "on hold";
        const isArchived = history.newValue === "archived";
        const isOnHoldOrArchived = isOnHold || isArchived;

        return (
          <Container data-testid="stage-change-history">
            <Box display="flex" gap={2} alignItems="center">
              {icon}
              <Typography>
                The lead has been set to <b>{history.newValue}</b>.
              </Typography>
            </Box>

            {(isOnHoldOrArchived || history.description) && (
              <Stack gap={1}>
                {isArchived && (
                  <Box display="flex" alignItems="start">
                    <Typography
                      variant="body2"
                      color={theme.palette.text.mediumEmphasis}
                    >
                      Reason:
                    </Typography>
                    &nbsp;
                    <Typography variant="body2">{reason || ""}</Typography>
                  </Box>
                )}

                {isOnHold && (
                  <Box display="flex" alignItems="center">
                    <Typography
                      variant="body2"
                      color={theme.palette.text.mediumEmphasis}
                    >
                      On hold until
                    </Typography>
                    &nbsp;
                    <Typography variant="body2">{onHoldDeadline}</Typography>
                  </Box>
                )}
                <CustomExpendableText text={history.description} />
              </Stack>
            )}
            <Box display="flex" justifyContent="space-between" gap={1}>
              <Box display="flex" gap={0.5}>
                <Typography variant="body2" color="text.mediumEmphasis">
                  By
                </Typography>
                <Typography variant="body2">{history.user.name}</Typography>
              </Box>
              <Typography
                variant="body2"
                color={theme.palette.text.mediumEmphasis}
              >
                {formatDate(history.dateTriggered)}
              </Typography>
            </Box>
          </Container>
        );

      case "Lead Project Created":
        return (
          <Container data-testid="lead-created-history">
            <Box display="flex" gap={2} alignItems="center">
              <IconWrapper>
                <Stream sx={{ color: "white", fontSize: "1rem" }} />
              </IconWrapper>
              <Typography>
                The lead has been <b>created</b>.
              </Typography>
            </Box>

            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              gap={1}
            >
              <Box display="flex" gap={0.5}>
                <Typography variant="body2" color="text.mediumEmphasis">
                  By
                </Typography>
                <Typography variant="body2">{history.user.name}</Typography>
              </Box>

              <Typography
                variant="body2"
                color={theme.palette.text.mediumEmphasis}
              >
                {formatDate(history.dateTriggered)}
              </Typography>
            </Box>
          </Container>
        );

      case "Project Launched":
        if (!latestLaunchedProject) return <></>;
        return (
          <Container data-testid="lead-created-history">
            <Box display="flex" gap={2} alignItems="center">
              <IconWrapper>
                <Stream sx={{ color: "white", fontSize: "1rem" }} />
              </IconWrapper>
              <Typography>
                Project
                <Typography
                  variant="subtitle2"
                  component="a"
                  href={`/projects/${latestLaunchedProject.id}`}
                  color="text.action.main"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {` ${latestLaunchedProject.name} `}
                </Typography>
                has been <b>launched</b>.
              </Typography>
            </Box>

            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              gap={1}
            >
              <Box display="flex" gap={0.5}>
                <Typography variant="body2" color="text.mediumEmphasis">
                  By
                </Typography>
                <Typography variant="body2">{history.user.name}</Typography>
              </Box>

              <Typography
                variant="body2"
                color={theme.palette.text.mediumEmphasis}
              >
                {formatDate(history.dateTriggered)}
              </Typography>
            </Box>
          </Container>
        );

      default:
        return null;
    }
  };

  return (
    <>
      <Paper component={Stack} p={3} gap={3} data-testid="lead-updates-section">
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb={1}
        >
          <Typography variant="h6">Lead Updates</Typography>
          <HoverableStatusChip status={status} onClick={handleEditStatus} />
        </Box>
        {props.leadProject.leadProjectHistoryLatestChange?.length > 0 &&
          renderHistoryItem(
            props.leadProject.leadProjectHistoryLatestChange[0]
          )}
        <Stack gap={1}>
          {canEdit && (
            <Button onClick={handleAddNewComment} variant="contained">
              Add Comment
            </Button>
          )}
          <Button variant="text" onClick={handleViewMore}>
            Show History
          </Button>
        </Stack>
      </Paper>
      {editStatusModal && (
        <EditLeadProjectStatus
          leadProjectId={props.leadProject.id}
          statusComment={props.leadProject.statusComment}
          status={props.leadProject.status}
          onHoldDeadline={props.leadProject.onHoldDeadline}
          modalOpen={editStatusModal}
          setModalOpen={setEditStatusModal}
          handleSave={props.handleSave}
          openAssociatedModal={associatedModal}
          setLeadProjectStatusOverviewModal={setModalOpen}
        />
      )}
      {modalOpen && (
        <LeadProjectStatusOverview
          leadProjectId={props.leadProject.id}
          status={status}
          onHoldDeadline={props.leadProject.onHoldDeadline}
          modalOpen={modalOpen}
          setModalOpen={setModalOpen}
          handleSave={props.handleSave}
          statusCommentLastModifiedDate={statusCommentLastModifiedDate}
          userName={props.leadProject.lastModifiedBy?.name}
          leadProjectName={props.leadProject.name}
          projectsLaunched={props.leadProject.projects}
          statusComment={props.leadProject.statusComment}
          setEditStatusModal={setEditStatusModal}
          setManageCommentModal={setManageCommentModal}
          leadProject={props.leadProject}
        />
      )}
      {manageCommentModal && (
        <ManageLeadStatusComment
          modalOpen={manageCommentModal}
          setModalOpen={setManageCommentModal}
          leadProjectId={props.leadProject.id}
          handleSave={props.handleSave}
          openAssociatedModal={associatedModal}
          setLeadProjectStatusOverviewModal={setModalOpen}
        />
      )}
    </>
  );
}
