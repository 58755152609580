import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
} from "@mui/material";
import { ReactElement, useState } from "react";
import theme from "../../../../theme";

interface Props {
  name: string;
  label: string;
  modalOpen: boolean;
  setModalOpen: (state: boolean) => void;
  handleCreate: (name: string) => void;
  maxCharacter?: number;
  initialValue?: string;
}

const CustomCreateEntityByName = (props: Props): ReactElement => {
  const [newValue, setNewValue] = useState<string>(props.initialValue || "");

  return (
    <Dialog
      fullWidth
      open={props.modalOpen}
      data-testid={`${props.name}-create-modal`}
      PaperProps={{
        sx: {
          gap: theme.spacing(4),
        },
      }}
    >
      <DialogTitle>Create {props.label}</DialogTitle>
      <DialogContent>
        <TextField
          id={props.name}
          label={props.label}
          fullWidth
          value={newValue}
          onChange={(event) => {
            setNewValue(event.target.value);
          }}
          inputProps={{
            ...(props.maxCharacter && {
              maxLength: props.maxCharacter,
            }),
          }}
          FormHelperTextProps={{
            sx: {
              position: "absolute",
              top: "50%",
              transform: "translateY(-50%)",
              right: 0,
              ...theme.typography.body2,
            },
          }}
          helperText={
            props.maxCharacter &&
            (newValue ? newValue.length : 0) + "/" + props.maxCharacter
          }
        />
      </DialogContent>
      <DialogActions>
        <Button
          autoFocus
          onClick={() => props.setModalOpen(false)}
          color="primary"
          data-testid={`${props.name}-cancel-button`}
        >
          Cancel
        </Button>
        <Button
          onClick={() => props.handleCreate(newValue)}
          variant="contained"
          data-testid={`${props.name}-create-button`}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CustomCreateEntityByName;
