import { Box, Chip, Stack, styled, Typography } from "@mui/material";
import { ReactElement, useState } from "react";
import theme from "../../../../theme";
import { LeadOpportunity } from "../../../../Types/LeadProject";
import { capitalizeFirstLetter, formatDate } from "../../../../utils";
import LeadProjectDetailsModal from "../../../UI/Modals/ProjectDetailsModal/LeadProjectDetailsModal";

interface LeadCardProps {
  opportunity: LeadOpportunity;
}

const ProjectsLaunchedContainer = styled(Box)({
  display: "flex",
  padding: theme.spacing(1, 2),
  alignItems: "center",
  backgroundColor: theme.palette.background.default,
  borderRadius: theme.shape.radius.minimal,
  justifyContent: "space-between",
  gap: theme.spacing(1),
  width: "100%",
  maxWidth: "202px",
});

const LeadName = styled(Typography)(() => ({
  cursor: "pointer",
  display: "-webkit-box",
  overflow: "hidden",
  WebkitLineClamp: "2",
  textOverflow: "ellipsis",
  WebkitBoxOrient: "vertical",
}));

export default function LeadCard(props: LeadCardProps): ReactElement {
  const [openDetails, setOpenDetails] = useState(false);
  const statusText = props.opportunity.leadProject.status
    .split(" ")
    .map(capitalizeFirstLetter)
    .join(" ");
  return (
    <>
      <Stack gap={1} py={2}>
        <Stack>
          <LeadName variant="subtitle2" onClick={() => setOpenDetails(true)}>
            {props.opportunity.leadProject.name}
          </LeadName>
          <Typography variant="body2" color="text.mediumEmphasis">
            {formatDate(props.opportunity.leadProject.dateCreated)}
          </Typography>
        </Stack>
        <Box display="flex" justifyContent="space-between" gap={4}>
          <Box display="flex" gap={4} width="fit-content">
            <Stack>
              <Typography variant="body2">
                {props.opportunity.leadProject.ventureClient.name}
              </Typography>
              <Typography variant="overline" color="text.mediumEmphasis" noWrap>
                Venture Client
              </Typography>
            </Stack>
            <Stack>
              <Typography variant="body2">{statusText}</Typography>
              <Typography variant="overline" color="text.mediumEmphasis" noWrap>
                Lead Status
              </Typography>
            </Stack>
          </Box>
          <ProjectsLaunchedContainer>
            <Typography variant="body2" noWrap>
              Projects Launched
            </Typography>
            <Chip
              variant="counter"
              color="info"
              label={props.opportunity.leadProject.projects?.length ?? 0}
            />
          </ProjectsLaunchedContainer>
        </Box>
      </Stack>
      {openDetails && (
        <LeadProjectDetailsModal
          leadProjectId={props.opportunity.leadProject.id}
          setModalOpen={setOpenDetails}
          modalOpen={openDetails}
          shouldOpenInNewTab
        />
      )}
    </>
  );
}
