import { styled } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Check from "@mui/icons-material/Check";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import { StepIconProps } from "@mui/material/StepIcon";
import { ReactElement } from "react";
import { funnelStages } from "../../../../Constants/FunnelStages";
import { capitalizeFirstLetter } from "../../../../utils";
import theme from "../../../../theme";

type ProjectCardStepperProps = {
  activeStep: number;
  isProjectActive: boolean;
  ruledOutStage: string;
  badgeColor: string;
};

const ProjectCardStep = styled(Step, {
  shouldForwardProp: (prop: string) => !prop.startsWith("$"),
})<{ $badgeColor?: string }>(({ $badgeColor }) => ({
  minWidth: "60px",
  padding: 0,
  "& .hasBadge .MuiStepLabel-label::before": {
    content: `""`,
    width: "6px",
    height: "6px",
    backgroundColor: $badgeColor,
    display: "block",
    borderRadius: "50%",
  },
}));

const ProjectCardConnector = styled(StepConnector, {
  shouldForwardProp: (prop: string) => !prop.startsWith("$"),
})<{ $isProjectActive?: boolean }>(({ theme, $isProjectActive }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 5,
    left: "-100%",
    right: "100%",
    zIndex: 1,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: $isProjectActive
        ? theme.palette.primary.main
        : theme.palette.surface.cool,
    },
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      opacity: 1,
      top: 5,
      left: "-100%",
      right: "100%",
      zIndex: 2,
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: $isProjectActive
        ? theme.palette.primary.main
        : theme.palette.surface.cool,
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: theme.palette.borderOutline.main,
    borderTopWidth: 1,
    borderRadius: 1,
  },
}));

const ProjectCardStepIconRoot = styled("div", {
  shouldForwardProp: (prop: string) => !prop.startsWith("$"),
})<{ $isProjectActive?: boolean }>(({ theme, $isProjectActive }) => ({
  color: theme.palette.grey[300],
  display: "flex",
  height: 11,
  alignItems: "center",
  "& .ProjectCardStepIcon-completedIcon": {
    color: "white",
    zIndex: 3,
    fontSize: 12,
    borderRadius: "50%",
    border: "1px solid",
    borderColor: $isProjectActive
      ? theme.palette.primary.main
      : theme.palette.surface.cool,
    backgroundColor: $isProjectActive
      ? theme.palette.primary.main
      : theme.palette.surface.cool,
  },
  "& .ProjectCardStepIcon-circle": {
    width: 12,
    height: 12,
    borderRadius: "50%",
    border: `1px solid ${theme.palette.borderOutline.main}`,
    backgroundColor: "white",
    zIndex: 4,
  },
}));

const ProjectCardStepLabel = styled(StepLabel)(() => ({
  alignItems: "flex-start",
  gap: theme.spacing(0.5),
  "& .MuiStepLabel-label": {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(0.5),
    ...theme.typography.caption,
  },
  "& .MuiStepLabel-label.MuiStepLabel-alternativeLabel": {
    marginTop: 0,
  },
  "& .MuiStepLabel-label.Mui-active, & .MuiStepLabel-label.Mui-completed,": {
    color: theme.palette.text.mediumEmphasis,
  },
  "& .Mui-disabled": {
    color: theme.palette.text.disabled,
  },
}));

function ProjectCardStepIcon(props: StepIconProps, isProjectActive: boolean) {
  const { active, completed, className } = props;
  return (
    <ProjectCardStepIconRoot
      $isProjectActive={isProjectActive}
      className={className}
    >
      {completed || active ? (
        <Check className="ProjectCardStepIcon-completedIcon" />
      ) : (
        <div className="ProjectCardStepIcon-circle" />
      )}
    </ProjectCardStepIconRoot>
  );
}

function stageToIndex(stage: string): number {
  if (stage === "Discover") return 0;
  if (stage === "Assess") return 1;
  return -1;
}

const steps = funnelStages.map((stage) => capitalizeFirstLetter(stage));

export default function ProjectCardStepper(
  props: ProjectCardStepperProps
): ReactElement {
  const ruledOutIndex = stageToIndex(props.ruledOutStage);
  const badgeIndex = ruledOutIndex !== -1 ? ruledOutIndex : props.activeStep;
  return (
    <Stack width="100%">
      <Stepper
        alternativeLabel
        activeStep={props.activeStep}
        connector={
          <ProjectCardConnector $isProjectActive={props.isProjectActive} />
        }
      >
        {steps.map((label, index) => (
          <ProjectCardStep key={label} $badgeColor={props.badgeColor}>
            <ProjectCardStepLabel
              StepIconComponent={(stepIconProps) =>
                ProjectCardStepIcon(stepIconProps, props.isProjectActive)
              }
              className={index === badgeIndex ? "hasBadge" : ""}
            >
              {label}
            </ProjectCardStepLabel>
          </ProjectCardStep>
        ))}
      </Stepper>
    </Stack>
  );
}
