import { Stack, Box, Typography, Skeleton } from "@mui/material";
import { ReactElement } from "react";
import theme from "../../../theme";

export default function LeadProjectOpportunitiesSkeleton(): ReactElement {
  return (
    <Stack width="100%" gap={theme.spacing(3)}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box display="flex" alignItems="center" gap={1}>
          <Typography variant="h6">Startups</Typography>
          <Skeleton
            width={32}
            height={17}
            sx={{ borderRadius: theme.shape.radius.full }}
          />
        </Box>
        <Skeleton width={128} height={36} />
      </Box>
    </Stack>
  );
}
