import { BookmarkBorderOutlined, BookmarkOutlined } from "@mui/icons-material";
import { Box } from "@mui/material";
import { ChangeEvent, ReactElement } from "react";
import theme from "../../theme";
import LabeledCheckbox from "./InputFields/LabeledCheckbox";

interface BookmarkProps {
  bookmarked: boolean;
  handleBookmark: (event: ChangeEvent<HTMLInputElement>) => void;
}

export default function Bookmark(props: BookmarkProps): ReactElement {
  return (
    <Box
      className="bookmark"
      maxWidth={props.bookmarked ? "24px" : 0}
      sx={{
        opacity: props.bookmarked ? 1 : 0,
        transition: "opacity 0s, max-width 0.3s;",
      }}
      marginRight={0.5}
    >
      <LabeledCheckbox
        dataTestId="bookmark-btn"
        checkboxProps={{
          icon: <BookmarkBorderOutlined />,
          checkedIcon: (
            <BookmarkOutlined
              style={{ fill: theme.palette.icon.action.main }}
            />
          ),
          size: "small",
          edge: false,
        }}
        checked={props.bookmarked}
        handleChange={props.handleBookmark}
        formControlLabelProps={{
          sx: {
            marginLeft: "-4px",
            "& .icon-toggle-btn": {
              padding: "4px",
            },
          },
        }}
      />
    </Box>
  );
}
